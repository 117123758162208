import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as templateHooks from "@rentiohq/shared-frontend/dist/redux/template/template.hooks";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import {
  ESendType,
  ITemplateDocument,
} from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.types";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import { EDocumentPackageTypes } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { appendQueryParams } from "@rentiohq/shared-frontend/dist/utils/url.utils";
import {
  Button,
  Card,
  DisplayText,
  ESpacings,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { StringParam } from "serialize-query-params";

interface IProps {
  templateDocument: ITemplateDocument;
  property: IProperty;
  onDrawerClose?: () => void;
}

const TemplateDocumentInfo: React.FC<IProps> = props => {
  const { templateDocument, onDrawerClose } = props;

  const navigate = useNavigate();

  const [, setQueryParamValue] = useQueryParams({
    documentId: StringParam,
    templateDocumentId: StringParam,
  });

  const { detail: template } = templateHooks.useDetail({
    shouldRefetch: false,
    id: templateDocument.templateId,
  });

  // Redux
  const dispatch = useDispatch();

  // Lifecycle
  React.useEffect(() => {
    if (templateDocument.documentId) {
      setQueryParamValue({
        documentId: templateDocument.documentId,
        templateDocumentId: null,
      });
    }
  }, [templateDocument.documentId]);

  // Event handlers
  const handlePressViewDocument = () => {
    setQueryParamValue({
      documentId: templateDocument.documentId,
      templateDocumentId: null,
    });
  };

  // Render
  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  return (
    <>
      {templateDocument.documentId && (
        <Card
          heading={renderCardHeader(getLocalizedText("system.document"))}
          space={ESpacings.base}
        >
          <Button appearance="link" onClick={handlePressViewDocument}>
            {getLocalizedText("summary.view.document")}
          </Button>
        </Card>
      )}

      {template?.tenantCanEdit && (
        <Card
          heading={renderCardHeader(
            getLocalizedText("document_package.edit.excerpt.heading"),
          )}
          space={ESpacings.base}
        >
          <Button
            onClick={() => {
              dispatch(
                templateDocumentActions.generateTemplateDocumentPdf.actions.start(
                  {
                    id: templateDocument.id,
                    data: {
                      sendType: ESendType.Tenant,
                    },
                    suppressSuccessMessage: true,
                    onSuccess: (updatedTemplateDocument: ITemplateDocument) => {
                      const documentId = updatedTemplateDocument?.documentId;

                      dispatch(
                        documentActions.getDetailStart.getAction({
                          id: documentId,
                          onSuccess: () => {
                            navigate(
                              appendQueryParams({
                                path: "/forms/document-package",
                                queryParams: {
                                  propertyId:
                                    updatedTemplateDocument?.propertyId,
                                  contractId:
                                    updatedTemplateDocument?.contractId ||
                                    undefined,
                                  documentIdToSign:
                                    updatedTemplateDocument?.documentId,
                                  sendToTenant: 1,

                                  documentPackageType:
                                    EDocumentPackageTypes.Contract,
                                },
                              }),
                            );
                          },
                        }),
                      );
                    },
                  },
                ),
              );
            }}
            appearance="link"
          >
            {getLocalizedText("editor.template_document.cta.can_edit")}
          </Button>
        </Card>
      )}

      <Card
        heading={renderCardHeader(
          getLocalizedText("template_document.detail.section.general.title"),
        )}
        subHeading={getLocalizedText(
          "template_document.detail.section.general.info",
        )}
        space={ESpacings.base}
        actions={[
          {
            content: (
              <TextStyle variation="negative">
                {getLocalizedText("system.delete")}
              </TextStyle>
            ),
            onClick: () => {
              confirm({
                title: getLocalizedText("system.remove_confirm.title", {
                  entity: getLocalizedText(
                    "system.entity.template_document",
                  ).toLowerCase(),
                }),
                primaryActions: [
                  {
                    title: getLocalizedText("system.remove"),
                    onPress: () => {
                      dispatch(
                        templateDocumentActions.deleteObject.actions.start({
                          id: templateDocument.id,
                        }),
                      );

                      onDrawerClose?.();
                    },
                  },
                ],
              });
            },
          },
        ]}
      >
        <Button
          appearance="link"
          onClick={() => {
            navigate(`/template-document/${templateDocument.id}`);
          }}
        >
          {getLocalizedText("document.template_document.cta.edit")}
        </Button>
      </Card>
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default TemplateDocumentInfo;

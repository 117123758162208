import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as registrationActions from "@rentiohq/shared-frontend/dist/redux/registration/registration.actions";
import * as registrationSelectors from "@rentiohq/shared-frontend/dist/redux/registration/registration.selectors";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  ERegistrationContractStatus,
  ERegistrationDocumentType,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  Error,
  Grid,
  Icon,
  Loading,
  Page,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../../services";
import RegistrationHistory from "./components/RegistrationHistory";
import RegistrationInfo from "./components/RegistrationInfo";
import { ERegistrationTab } from "./Registration.types";

interface IProps {
  registrationId: number;
  onDrawerClose?: () => void;
}

const Registration: React.FC<IProps> = props => {
  const { registrationId, onDrawerClose } = props;
  const navigate = useNavigate();

  // State
  const [tab, setTab] = React.useState(ERegistrationTab.Info);

  // Redux
  const dispatch = useDispatch();

  const registration = useSelector((state: IRootStore) =>
    registrationSelectors.registration(state, registrationId),
  );
  const isFetchingRegistration = useSelector((state: IRootStore) =>
    registrationSelectors.isFetching(state, registrationId),
  );
  const registrationFetchError = useSelector((state: IRootStore) =>
    registrationSelectors.fetchError(state, registrationId),
  );

  const {
    detail: property,
    isFetching: isFetchingProperty,
    fetchError: propertyFetchError,
  } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: registration?.propertyId,
  });

  // Lifecycle
  React.useEffect(() => {
    if (isFetchingRegistration) {
      return;
    }

    dispatch(
      registrationActions.getRegistration.actions.start({
        id: registrationId,
      }),
    );
  }, [registrationId]);

  // Render
  if (!registration || !property) {
    if (isFetchingProperty || isFetchingRegistration) {
      return <Loading />;
    }

    if (registrationFetchError) {
      return <Error errors={[registrationFetchError]} />;
    }

    if (propertyFetchError) {
      return <Error errors={[propertyFetchError]} />;
    }

    return null;
  }

  const metadata = (
    <>
      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon
            source={utils.properties.getIcon(property.typeId || 0)}
            size="small"
          />
        </Grid.Item>
        <Grid.Item>
          <Button appearance="link" url={`/properties/${property.id}`}>
            {formatAddress(property)}
          </Button>
        </Grid.Item>
      </Grid>

      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon source="calendar" size="small" />
        </Grid.Item>
        <Grid.Item>
          {getLocalizedText("system.created_at", {
            date: formatDate(registration.createdAt),
          })}
        </Grid.Item>
      </Grid>
    </>
  );

  const handleInfoTabClick = () => {
    setTab(ERegistrationTab.Info);
  };

  const handleHistoryTabClick = () => {
    setTab(ERegistrationTab.History);
  };

  let tabs = [
    {
      name: "dashboard",
      permission: "propertyDashboard:visit",
      content: ts.paymentRequestDetailTabsInfo(),
      onClick: handleInfoTabClick,
      isActive: tab === ERegistrationTab.Info,
    },
    {
      name: "payments",
      permission: "propertyPayments:visit",
      content: ts.paymentRequestDetailTabsHistory(),
      onClick: handleHistoryTabClick,
      isActive: tab === ERegistrationTab.History,
    },
  ];

  return (
    <Page
      title={getLocalizedText("registration.detail.title", {
        address: formatAddress(property, false, false),
      })}
      metadata={metadata}
      tabs={tabs}
      setDocumentTitle={false}
      dropdown={[
        ...(registration.rentContractStatus ===
        ERegistrationContractStatus.Registered
          ? [
              {
                content: (
                  <TextStyle>
                    {getLocalizedText("registration.addendum.title")}
                  </TextStyle>
                ),
                onClick: () => {
                  navigate(
                    `/properties/${property?.id}/contracts/${registration.contractId}/registration?id=${registration.id}&documentType=${ERegistrationDocumentType.Addendum}`,
                  );
                },
              },
            ]
          : []),
        {
          content: (
            <TextStyle variation={"negative"}>
              {getLocalizedText("system.delete")}
            </TextStyle>
          ),
          onClick: () => {
            confirm({
              type: "warning",
              modalProps: {
                hasDismiss: true,
                shouldCloseOnOverlayClick: true,
              },
              title: getLocalizedText("system.delete.confirm", {
                typePrefix: getLocalizedText(
                  "system.model.registration.prefix",
                ),
                type: getLocalizedText(
                  "system.model.registration",
                ).toLowerCase(),
              }),
              primaryActions: [
                {
                  title: getLocalizedText("system.remove"),
                  onPress: () => {
                    dispatch(
                      registrationActions.deleteRegistration.actions.start({
                        id: registration.id,
                        onSuccess: () => {
                          onDrawerClose?.();
                        },
                      }),
                    );
                  },
                },
              ],
            });
          },
        },
      ]}
    >
      <>
        {tab === ERegistrationTab.Info && (
          <RegistrationInfo
            registration={registration}
            property={property}
            setTab={setTab}
            onDrawerClose={onDrawerClose}
          />
        )}
        {tab === ERegistrationTab.History && (
          <RegistrationHistory registration={registration} setTab={setTab} />
        )}
      </>
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default Registration;

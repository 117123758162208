import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EmptyState, Grid } from "@rentiohq/web-shared/dist/components";
import { EPaymentRequestFilterPhase } from "scenes/FollowUp/Payments/Payments.utils";
import { IPaymentRequestsGridProps } from "../OpenPaymentRequests.types";
import { PaymentRequestsGridColumn } from "./PaymentRequestsGridColumn";

export const PaymentRequestsGrid = (props: IPaymentRequestsGridProps) => (
  <Grid>
    <Grid.Item width={[1, 1, 1 / 3]}>
      <PaymentRequestsGridColumn
        phase={EPaymentRequestFilterPhase.Incoming}
        propertyId={props.propertyId}
        heading={getLocalizedText("payments.follow_up.incoming.heading")}
        emptyState={
          <EmptyState
            backgroundColor="gray"
            heading={getLocalizedText(
              "payments.follow_up.incoming.empty.heading",
            )}
            icon="billStack"
          />
        }
        {...props}
      />
    </Grid.Item>

    <Grid.Item width={[1, 1, 1 / 3]}>
      <PaymentRequestsGridColumn
        phase={EPaymentRequestFilterPhase.Rentio}
        propertyId={props.propertyId}
        heading={getLocalizedText("payments.follow_up.reminder_rentio.heading")}
        emptyState={
          <EmptyState
            backgroundColor="gray"
            heading={getLocalizedText(
              "payments.follow_up.reminder_rentio.empty.heading",
            )}
            icon="party"
          />
        }
        {...props}
      />
    </Grid.Item>

    <Grid.Item width={[1, 1, 1 / 3]}>
      <PaymentRequestsGridColumn
        phase={EPaymentRequestFilterPhase.Action}
        propertyId={props.propertyId}
        heading={getLocalizedText("payments.follow_up.action_required.heading")}
        emptyState={
          <EmptyState
            backgroundColor="gray"
            heading={getLocalizedText(
              "payments.follow_up.action_required.empty.heading",
            )}
            icon="party"
          />
        }
        {...props}
      />
    </Grid.Item>
  </Grid>
);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { MultiStepForm } from "../../../../components/SharedForm/MultiStepForm";
import { FieldsContext } from "../Editor/contexts/Fields.context";
import { DEFAULT_VARIABLES } from "../Editor/Editor.config";
import { insertLoop } from "./InsertLoopModal.utils";
import schemas from "./schemas";
import { EField } from "./schemas/schema.insertLoop.types";
var InsertLoopModal = function (props) {
    var editorState = props.editorState, setEditorState = props.setEditorState, onClose = props.onClose;
    var _a = React.useContext(FieldsContext), fields = _a.fields, setFields = _a.setFields;
    // State
    var formId = React.useState(generateFormId())[0];
    // Helpers
    var getCleanFormData = function (formData) {
        var variableId = formData[EField.Name];
        var groupId = formData[EField.Group];
        var separator = formData[EField.Separator];
        var lastSeparator = formData[EField.LastSeparator];
        return {
            variableId: variableId,
            groupId: groupId,
            separator: separator,
            lastSeparator: lastSeparator,
        };
    };
    var insertFields = function (formData) {
        var _a = getCleanFormData(formData), groupId = _a.groupId, variableId = _a.variableId, newLoopConfig = __rest(_a, ["groupId", "variableId"]);
        var existingVariable = __spreadArray(__spreadArray([], fields.variables, true), DEFAULT_VARIABLES, true).find(function (x) { return x.id === variableId; });
        var loop = __assign({ id: "".concat(Math.random()), groupId: groupId, variableId: variableId }, newLoopConfig);
        setFields({
            groupId: (existingVariable === null || existingVariable === void 0 ? void 0 : existingVariable.groupId) || groupId,
            variableId: variableId,
            loop: loop,
        });
        return loop;
    };
    // Event handlers
    var onSubmitInsert = function (formData) {
        var loop = insertFields(formData);
        setEditorState(insertLoop(editorState, loop));
        onClose();
    };
    // Render
    return (_jsx(MultiStepForm, { formId: "insert-loop-".concat(formId), schemas: schemas({ fields: fields }), asModal: true, withAside: false, modalProps: {
            onClose: onClose,
            heading: getLocalizedText("editor.insert_custom.insert_loop.modal.title"),
            width: "medium",
        }, onSuccess: onSubmitInsert, submitLabel: getLocalizedText("system.insert") }));
};
// eslint-disable-next-line import/no-default-export
export default InsertLoopModal;

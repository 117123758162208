import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Stack } from "@mui/material";
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import List from "@mui/material/List";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { getTitle } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { EAssets } from "../../../assets";
import { Avatar } from "../../Avatar";
import { Icon } from "../../Icon";
import { ContactListStyles } from "./ContactList.styles";
export var ContactList = function (_a) {
    var chats = _a.chats, chatId = _a.chatId;
    var navigate = useNavigate();
    var sx = ContactListStyles();
    var renderAvatar = function (_a) {
        var pictureUrl = _a.pictureUrl, members = _a.members;
        if (pictureUrl) {
            return _jsx(Avatar, { src: pictureUrl || "", sx: sx.content.avatar });
        }
        if ((members === null || members === void 0 ? void 0 : members.length) > 2) {
            return (_jsx(Avatar, { sx: sx.content.avatar, children: _jsx(Icon, { path: EAssets.MultiUsersIcon }) }));
        }
        else {
            return (_jsx(Avatar, { sx: sx.content.avatar, children: _jsx(Icon, { path: EAssets.UserIcon, width: "18px", height: "18px" }) }));
        }
    };
    if ((chats === null || chats === void 0 ? void 0 : chats.length) === 0) {
        return (_jsx(Typography, { variant: "h5", my: 2, children: getLocalizedText("chats.list.empty.text") }));
    }
    return (_jsx(List, { component: "nav", children: chats.map(function (chat) {
            var pictureUrl = chat.pictureUrl, lastMessage = chat.lastMessage, id = chat.id, unreadCounter = chat.unreadCounter, lastMessageAt = chat.lastMessageAt, members = chat.members;
            var title = chat && getTitle({ chat: chat });
            return (_jsxs(Fragment, { children: [_jsxs(ListItemButton, { sx: sx.content.listItemBtn((chat === null || chat === void 0 ? void 0 : chat.id) === chatId), onClick: function () { return navigate("/chats/".concat(id)); }, children: [_jsx(ListItemAvatar, { sx: sx.content.listItemAvatar, children: renderAvatar({ pictureUrl: pictureUrl, members: members }) }), _jsx(ListItemText, { primary: _jsxs(Stack, { gap: 2, direction: "row", justifyContent: "space-between", children: [_jsx(Typography, { variant: "h6", sx: sx.content.contactName, children: title }), lastMessageAt && (_jsx(Typography, { variant: "caption", sx: sx.content.dateTime, children: formatDate(lastMessageAt, "dd/MM/yyyy HH:mm") }))] }), secondary: _jsxs(Stack, { gap: 2, direction: "row", justifyContent: "space-between", children: [_jsx(Typography, { variant: "caption", sx: sx.content.lastMessage, children: lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.text }), unreadCounter > 0 && (_jsx(Chip, { label: unreadCounter, color: "secondary", sx: sx.content.chip }))] }) })] }), _jsx(Divider, { sx: sx.content.divider })] }, id));
        }) }));
};

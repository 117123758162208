import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Page } from "@rentiohq/web-shared/dist/components";
import { TabAction } from "@rentiohq/web-shared/dist/types";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { BeneficiaryReport as BeneficiaryReportV2 } from "./BeneficiaryV2";

enum EReportsUrls {
  Beneficiary = "/reports/beneficiary",
}

const getTabs = (currentPath: string) => {
  const tabs: TabAction[] = [
    {
      url: EReportsUrls.Beneficiary,
      content: getLocalizedText("reports.beneficiary.title"),
      isActive: !!matchPath(EReportsUrls.Beneficiary, currentPath),
    },
  ];

  // Added tabs here so everything is set up, but they are not used until more than one
  // tab is present
  if (tabs.length > 1) return tabs;

  return undefined;
};

export const Reports = () => {
  const location = useLocation();

  return (
    <Page
      title={getLocalizedText("reports.title")}
      tabs={getTabs(location.pathname)}
      fullWidth={true}
    >
      <BeneficiaryReportV2 />
    </Page>
  );
};

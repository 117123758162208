import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { getLocale, getLocalizedText, } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "../assets";
export var EMenuItems;
(function (EMenuItems) {
    EMenuItems["Welcome"] = "welcome";
    EMenuItems["Property"] = "property";
    EMenuItems["Contacts"] = "contacts";
    EMenuItems["Applications"] = "applications";
    EMenuItems["Payments"] = "payments";
    EMenuItems["Tasks"] = "tasks";
    EMenuItems["Chats"] = "chats";
})(EMenuItems || (EMenuItems = {}));
var getApplicationsRoute = function () {
    switch (getLocale()) {
        case ELocale.NL:
            return "https://mijnhuurprofiel.be";
        case ELocale.FR:
            return "https://monprofillocataire.be";
        default:
            return "https://mytenantprofile.be";
    }
};
var menuItems = [
    {
        id: EMenuItems.Welcome,
        title: getLocalizedText("dashboard.welcome"),
        icon: EAssets.CalendarIcon,
        url: "",
        matchPaths: ["/"],
        breadcrumbs: true,
    },
    {
        id: EMenuItems.Property,
        title: getLocalizedText("system.properties"),
        icon: EAssets.HouseIcon,
        url: "/properties",
        matchPaths: [
            "/properties",
            "/properties/:propertyId/",
            "/properties/:propertyId/contracts",
            "/properties/:propertyId/documents",
            "properties/:propertyId/history",
        ],
        breadcrumbs: true,
    },
    {
        id: EMenuItems.Contacts,
        title: getLocalizedText("system.contacts"),
        icon: EAssets.UserOutlinedIcon,
        url: "/contacts",
        matchPaths: ["/contacts"],
        breadcrumbs: true,
    },
    {
        id: EMenuItems.Applications,
        title: getLocalizedText("system.entity.applications"),
        icon: EAssets.ApplicationIcon,
        url: getApplicationsRoute(),
        matchPaths: ["applications"],
        breadcrumbs: true,
        target: "_blank",
        openModal: true,
        message: getLocalizedText("tenant_dashboard.see_applications.modal.message"),
    },
    {
        id: EMenuItems.Payments,
        title: getLocalizedText("payments_overview.heading"),
        icon: EAssets.EuroIcon,
        url: "https://pay.rentio.be",
        matchPaths: ["payments"],
        breadcrumbs: true,
        target: "_blank",
        openModal: true,
        message: getLocalizedText("tenant_dashboard.see_payments.modal.message"),
    },
    {
        id: EMenuItems.Tasks,
        title: getLocalizedText("system.entity.tasks"),
        icon: EAssets.TasksIcon,
        url: "/tasks",
        matchPaths: ["tasks"],
        breadcrumbs: true,
        disabled: true,
    },
    {
        id: EMenuItems.Chats,
        title: getLocalizedText("system.entity.chats"),
        icon: EAssets.ChatsIcon,
        url: "/chats",
        matchPaths: ["chats"],
        breadcrumbs: true,
    },
];
// eslint-disable-next-line import/no-default-export
export default menuItems;

import { Box } from "@rebass/grid";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as applicationActions from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.actions";
import * as applicationHooks from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.hooks";
import * as propertySelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import {
  Can,
  Error,
  ESpacings,
  Loading,
} from "@rentiohq/web-shared/dist/components";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { getFlows } from "utils/application.utils";
import { DragAndDropContextProvider } from "./context/DragAndDropContextProvider";
import PropertyDetailApplications from "./PropertyDetailApplications";

export interface IStatusChange {
  id: string;
  step: number;
  tenantFeedbackText?: string;
}

export const PropertyDetailApplicationsContainer = () => {
  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const property = useSelector((state: IRootStore) =>
    propertyId ? propertySelectorsV2.getDetail(state, propertyId) : undefined,
  );

  const { broker } = authHooks.useSelf();

  const {
    items: applications,
    isFetching,
    fetchError,
  } = applicationHooks.useGetAll({
    query: {
      filter: { propertyId: { eq: propertyId } },
      sort: [{ field: "providedAt", method: "DESC" }],
    },
    shouldRefetch: true,
  });

  const dispatch = useDispatch();

  if (!applications || applications.length === 0) {
    if (isFetching) {
      return (
        <Box mb={ESpacings.base}>
          <Loading />
        </Box>
      );
    }

    if (fetchError) {
      return <Error errors={[fetchError]} />;
    }
  }

  const intakeType = broker?.intakeType || 2;
  const steps = getFlows(intakeType);

  const handleStatusChange = async (params: IStatusChange) => {
    const { id: applicationId, step: stepString, tenantFeedbackText } = params;
    const step = Number(stepString);

    dispatch(
      applicationActions.updateApplicationStepStart.getAction({
        id: applicationId,
        step,
        tenantFeedbackText,
      }),
    );
  };

  if (!property) {
    return null;
  }

  // We're using a Context here. That's not a pattern we use a lot, but it's
  // useful here because we need the drag & drop info in almost all components lower in the hierarchy,
  // but that info doesn't need to be stored in Redux (not used anywhere else)
  const renderContent = () => (
    <DragAndDropContextProvider>
      <PropertyDetailApplications
        applications={applications || []}
        handleOneStatusChange={handleStatusChange}
        type={intakeType}
        visitorSteps={steps.visitorSteps}
        candidateSteps={steps.candidateSteps}
        notEligibleSteps={steps.notEligibleSteps}
        property={property}
      />
    </DragAndDropContextProvider>
  );

  return (
    <Can
      role={property.roles}
      perform="propertyApplications:visit"
      yes={renderContent}
      no={() => <Navigate to={`/properties/${propertyId}`} />}
    />
  );
};

import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import { payInModule } from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import * as paymentRequestActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EPaymentRepetitionType,
  EPayoutType,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPaymentRequestStatus } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatIban } from "@rentiohq/shared-frontend/dist/utils/iban.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getTitle } from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import {
  Banner,
  DisplayText,
  Error,
  Loading,
  Modal,
  OptionListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { i18n } from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";

enum EStep {
  Intro,
  Confirm,
}

interface IProps {
  payInId: number;
  onClose: () => void;
  onSuccess: () => void;
}

export const PayInAllocationModal = (props: IProps) => {
  const { payInId, onClose, onSuccess } = props;

  const dispatch = useDispatch();

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const [step, setStep] = React.useState(EStep.Intro);

  const [selectedPaymentRequestId, setSelectedPaymentRequestId] =
    React.useState<number | undefined>(undefined);

  const [isMatching, setIsMatching] = React.useState(false);

  // Redux
  const {
    detail: payIn,
    isFetching: isFetchingPayIn,
    fetchError: fetchPayInError,
    refetch,
  } = payInModule.hooks.useDetail({
    shouldRefetch: true,
    id: payInId,
  });

  const contractId = payIn?.contractId;
  const propertyId =
    payIn?.contractInfo?.propertyId || payIn?.contract?.propertyId;

  const identifier = contractId ? `pay-in-allocation-${contractId}` : undefined;
  const queryV2 = {
    page: 1,
    limit: CONFIG.DEFAULT_FETCH_LIMIT,
    filter: {
      requestPropertyId: { eq: propertyId },
      status: {
        in: [EPaymentRequestStatus.New, EPaymentRequestStatus.FailedPaidIn],
      },
      payoutType: { eq: EPayoutType.Charge },
    },
  };

  const {
    items,
    isFetching: isFetchingPaymentRequests,
    fetchError: fetchPaymentRequestsError,
  } = useSelector((state: IRootStore) =>
    paymentSelectors.paymentRequestsByIdentifier(state, identifier),
  );
  const {
    items: itemsV2,
    isFetching: isFetchingPaymentRequestsV2,
    fetchError: fetchPaymentRequestsErrorV2,
  } = useSelector((state: IRootStore) =>
    paymentRequestSelectors.getPaged(state, queryV2),
  ) || {};

  const paymentRequests = (
    internalModeEnabled && hasPaymentV2ForBroker ? itemsV2 : items
  )?.filter(item => {
    if (!contractId) {
      return false;
    }

    const contractIdForPaymentRequest =
      item.paymentRequestItems?.[0]?.paymentOrder?.contractId;

    if (!contractIdForPaymentRequest) {
      return false;
    }

    return contractIdForPaymentRequest === contractId;
  });

  // Event handlers
  const handleClickAllocate = async () => {
    if (!payIn) {
      return;
    }

    const paymentRequest = paymentRequests?.find(
      x => x.id === selectedPaymentRequestId,
    );
    if (!paymentRequest) {
      return;
    }

    try {
      setIsMatching(true);

      const payInAmountRemaining = payInModule.utils.amountToBeAllocated(payIn);

      await api.post(`/pay-ins/${payIn.id}/allocation`, {
        isServerless: true,
        data: {
          amount: Math.min(paymentRequest.amount, payInAmountRemaining),
          paymentRequestId: paymentRequest.id,
        },
      });

      // TODO: Reset store for ID

      showAlert({
        type: "success",
        message: getLocalizedText("pay_in.allocation.alert.success.title"),
        content: getLocalizedText("pay_in.allocation.alert.success.info"),
      });

      refetch();

      setIsMatching(false);

      onSuccess();
    } catch (error) {
      setIsMatching(false);

      showAlert({
        error,
        type: "error",
      });
    }
  };

  // Hooks
  React.useEffect(() => {
    if (!propertyId || !identifier) {
      return;
    }

    if (internalModeEnabled && hasPaymentV2ForBroker) {
      if (isFetchingPaymentRequestsV2) {
        return;
      }

      dispatch(
        paymentRequestActions.getPagedStart.getAction({
          query: queryV2,
        }),
      );

      return;
    }

    if (isFetchingPaymentRequests) {
      return;
    }

    dispatch(
      paymentActions.getPaymentRequestsByIdentifier.actions.start({
        paymentRequestsIdentifier: identifier,
        filterData: {
          where: {
            requestPropertyId: propertyId,
            status: {
              inq: [
                EPaymentRequestStatus.New,
                EPaymentRequestStatus.FailedPaidIn,
              ],
            },
            payoutType: EPayoutType.Charge,
          },
        },
        refetch: true,
        limit: CONFIG.DEFAULT_FETCH_LIMIT,
      }),
    );
  }, [contractId, identifier]);

  // Render
  const error =
    fetchPayInError || fetchPaymentRequestsError || fetchPaymentRequestsErrorV2;
  if (error) {
    return (
      <Modal
        onClose={onClose}
        shouldCloseOnOverlayClick={true}
        hasDismiss={false}
        actions={[
          {
            content: getLocalizedText("system.close"),
            onClick: onClose,
          },
        ]}
        width={"medium"}
      >
        <Error errors={[error]} />
      </Modal>
    );
  }

  if ((isFetchingPayIn && !payIn) || isFetchingPaymentRequests) {
    return (
      <Modal
        onClose={onClose}
        shouldCloseOnOverlayClick={true}
        hasDismiss={true}
        actions={[]}
        width={"medium"}
      >
        <Loading asDots={true} />
      </Modal>
    );
  }

  if (!payIn || !paymentRequests) {
    return null;
  }

  if (step === EStep.Confirm) {
    const paymentRequest = paymentRequests.find(
      x => x.id === selectedPaymentRequestId,
    );
    if (!paymentRequest) {
      return null;
    }

    const payInAmountRemaining = payInModule.utils.amountToBeAllocated(payIn);

    const isAmountMatch = payInAmountRemaining === paymentRequest.amount;
    const hasRemainingAmountToAllocate =
      payInAmountRemaining > paymentRequest.amount;
    const hasRemainingAmountToPay =
      payInAmountRemaining < paymentRequest.amount;

    const values = {
      paymentRequestAmount: formatCurrency(paymentRequest.amount),
      payInAmountTotal: formatCurrency(payIn.amount),
      payInAmountRemaining: formatCurrency(payInAmountRemaining),
      payInAmountRemainingAfterAllocation: formatCurrency(
        Math.max(0, payInAmountRemaining - paymentRequest.amount),
      ),
      allocatedAmount: formatCurrency(
        Math.min(paymentRequest.amount, payInAmountRemaining),
      ),
      remainingAmount: formatCurrency(
        Math.max(0, paymentRequest.amount - payInAmountRemaining),
      ),
      payer: getName(paymentRequest.payerAccount),
    };

    return (
      <Modal
        onClose={onClose}
        shouldCloseOnOverlayClick={true}
        hasDismiss={true}
        heading={getLocalizedText("pay_in.allocation.title")}
        actions={[
          {
            content: getLocalizedText("system.back"),
            onClick: () => {
              setStep(EStep.Intro);
            },
            appearance: "outline",
          },
          {
            content: getLocalizedText("pay_in.allocation.cta.allocate"),
            appearance: "primary",
            isSubmitting: isMatching,
            onClick: () => {
              handleClickAllocate();
            },
          },
        ]}
        width={"medium"}
      >
        {isAmountMatch &&
          i18n.tm("pay_in.allocation.info.match", {
            markdownProps: { listAsChecklist: true },
            values,
          })}

        {hasRemainingAmountToAllocate &&
          i18n.tm("pay_in.allocation.info.remaining_allocation", {
            markdownProps: { listAsChecklist: true },
            values,
          })}

        {hasRemainingAmountToPay &&
          i18n.tm("pay_in.allocation.info.remaining_to_pay", {
            markdownProps: { listAsChecklist: true },
            values,
          })}
      </Modal>
    );
  }

  return (
    <Modal
      onClose={onClose}
      shouldCloseOnOverlayClick={true}
      hasDismiss={true}
      heading={getLocalizedText("pay_in.allocation.title")}
      actions={[
        {
          content: getLocalizedText("system.next"),
          appearance: "primary",
          isDisabled: !selectedPaymentRequestId,
          onClick: () => {
            if (!selectedPaymentRequestId) {
              return;
            }

            setStep(EStep.Confirm);
          },
        },
      ]}
      width={"medium"}
    >
      <DisplayText size="medium">
        {getLocalizedText("pay_in.allocation.section.pay_in", {
          value: formatCurrency(payIn.amount),
        })}
      </DisplayText>

      <OptionListItem
        type={"radio"}
        title={formatCurrency(payInModule.utils.amountToBeAllocated(payIn))}
        subtitle={
          <TextStyle variation="subdued">
            <TextStyle variation="strong">{payIn.authorName}</TextStyle>
            {` - ${formatIban(payIn.authorIban)}`}
            {payIn.reference ? ` - ${payIn.reference}` : undefined}
          </TextStyle>
        }
        selected={true}
        // disabled={true}
        onChange={() => {}}
      />

      <Spacer weight={ESpacerWeight.W24} />

      <DisplayText size="medium">
        {getLocalizedText("pay_in.allocation.section.payment_requests")}
      </DisplayText>

      {paymentRequests.length > 0 && (
        <>
          {paymentRequests.map(paymentRequest => {
            const title = getTitle(paymentRequest);

            const isRecurring =
              paymentRequest.repetitionType !== EPaymentRepetitionType.Once;

            const startedAt = paymentRequest.paymentRequestItems[0]?.startedAt;
            const endedAt = paymentRequest.paymentRequestItems[0]?.endedAt;

            let durationInfo: string | undefined = undefined;

            if (isRecurring && startedAt) {
              durationInfo = formatDate(startedAt);
              if (endedAt) {
                durationInfo = `${durationInfo} - ${formatDate(endedAt)}`;
              }
            }

            return (
              <OptionListItem
                type={"radio"}
                title={`${title}${durationInfo ? ` (${durationInfo})` : ""}`}
                subtitle={
                  <TextStyle variation="subdued">
                    <TextStyle variation="strong">
                      {formatCurrency(paymentRequest.amount)}
                    </TextStyle>
                    {` - ${getName(paymentRequest.payerAccount)}`}
                  </TextStyle>
                }
                selected={selectedPaymentRequestId === paymentRequest.id}
                disabled={false}
                onChange={() => {
                  setSelectedPaymentRequestId(paymentRequest.id);
                }}
              />
            );
          })}
        </>
      )}

      {paymentRequests.length === 0 && (
        <Banner hasDismiss={false} variation="info" icon="questionMark">
          {getLocalizedText("pay_in.allocation.no_requests")}
        </Banner>
      )}
    </Modal>
  );
};

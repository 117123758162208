import { Box } from "@rebass/grid";
import { noteModule } from "@rentiohq/shared-frontend/dist/reduxV2/note";
import { subDays } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  Card,
  ESpacings,
  ICardProps,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import * as t from "../../services/translationService";
import { NoteForm } from "./components";
import * as S from "./InlineNotes.styled";

export interface IProps {
  customPath: string;
  cardProps?: ICardProps;
}

export const InlineNotes = (props: IProps) => {
  const { customPath, cardProps } = props;

  const {
    items: notes,
    isFetching,
    refetch,
  } = noteModule.hooks.usePaged({
    shouldRefetch: true,
    query: {
      page: 1,
      limit: 1,
    },
    customPath,
  });
  const note = notes?.[0];

  const [modalOpen, setModalOpen] = React.useState<boolean>();

  const handleClickNote = () => {
    setModalOpen(true);
  };

  const handleNoteModalClose = () => {
    setModalOpen(false);
  };

  const handleAddSubmit = async () => {
    setModalOpen(false);
    refetch();
  };

  //helpers
  const getSubtitle = () => {
    if (!note) {
      return null;
    }

    const dateModified = note.modifiedAt || note.createdAt;

    return `${t.noteLastUpdated()}: ${utils.date.diffForHumans(dateModified, {
      to: subDays(new Date(), 7),
      firstWeekAsString: false,
    })}`;
  };

  // Render
  const renderContent = () => {
    if (modalOpen) {
      return (
        <>
          <Box mb={ESpacings.tight}>
            <NoteForm
              note={note}
              customPath={customPath}
              onClose={handleNoteModalClose}
              onSubmit={handleAddSubmit}
            />
          </Box>
          <Button onClick={handleNoteModalClose} appearance="link">
            {t.system("cancel")}
          </Button>
        </>
      );
    }

    if (!note) {
      if (isFetching) {
        return (
          <TextStyle variation="subdued">
            {getLocalizedText("system.loading")}
          </TextStyle>
        );
      }

      return (
        <TextStyle variation="subdued">
          {getLocalizedText("task.note_empty")}
        </TextStyle>
      );
    }

    return (
      <S.Wrapper
        onClick={handleClickNote}
        dangerouslySetInnerHTML={{
          __html: note.content.replaceAll("\n", "<br/>"),
        }}
      />
    );
  };

  return (
    <Card
      heading={t.noteTitle()}
      subHeading={getSubtitle()}
      space={ESpacings.loose}
      actions={[
        {
          content: !note ? t.noteAdd() : t.noteEdit(),
          onClick: handleClickNote,
          appearance: "link",
        },
      ]}
      {...cardProps}
    >
      {renderContent()}
    </Card>
  );
};

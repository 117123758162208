import { IBroker } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { MultiStepForm } from "@rentiohq/web-shared/dist/components";
import { isEmpty } from "lodash";
import shareDocumentMessage from "../../../../../../forms/shareDocumentMessage";
import * as t from "../../../../../../services/translationService";

interface IProps {
  formId: number;
  customMessageBody: string;
  setCustomMessageBody: (customMessageBody?: string) => void;
  broker?: IBroker;
  property?: IProperty;
  isLoadingShare?: boolean;
}

export const CustomMessageModal = ({
  formId,
  customMessageBody,
  setCustomMessageBody,
  broker,
  property,
  isLoadingShare,
}: IProps) => {
  const getCustomMessageBody = () => {
    const params: { [key: string]: string | null | undefined } = {
      broker: broker?.name,
      property: property?.propertyAddress?.full,
    };

    let result = customMessageBody;
    Object.keys(params).forEach(key => {
      result = result.replaceAll(`{{${key}}}`, params[key] || "");
    });

    return result;
  };

  return (
    <MultiStepForm
      preventSubmitThroughEnter={true}
      formId={`share-document-message-${formId}`}
      schemas={shareDocumentMessage({
        customMessageBody: getCustomMessageBody(),
      })}
      asModal={true}
      withAside={false}
      onSuccess={(input: { customBody: string }) => {
        const { customBody } = input;

        const customBodyClean = !isEmpty(customBody) ? customBody : undefined;
        setCustomMessageBody(customBodyClean);
      }}
      submitLabel={t.system("submit")}
      modalProps={{
        onClose: () => {
          setCustomMessageBody(undefined);
        },
      }}
      isLoading={isLoadingShare}
    />
  );
};

import { IActivityItem } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { mapActivityTypeToString } from "@rentiohq/shared-frontend/dist/utils/activity.utils";
import { date, i18n } from "@rentiohq/web-shared/dist/utils";
import { Box, Typography } from "@rentiohq/web-shared-next/dist/ui-components";
import orderBy from "lodash/orderBy";
import { ActivityText } from "../ActivityText";
import { ActivityGroupStyles } from "./ActivityGroup.styles";

interface IProps {
  groupedDate: string;
  groupedActivities: IActivityItem[];
}

export const ActivityGroup = ({ groupedDate, groupedActivities }: IProps) => {
  const sx = ActivityGroupStyles();

  return (
    <Box>
      <Typography variant="h6" sx={sx.content.dateTitle}>
        {date.diffForHumans(new Date(groupedDate))}
      </Typography>
      {orderBy(groupedActivities, ["datetime", "id"], ["desc"]).map(
        (activity: any) => {
          return (
            <Box key={activity.id} sx={sx.content.activityItem}>
              <Typography
                variant="caption"
                sx={sx.content.activityType}
              >{`${date.format(
                new Date(activity.datetime),
                "HH:mm",
              )} - ${i18n.t(
                `activity.${(mapActivityTypeToString(activity.type) || "")
                  .toString()
                  .toLowerCase()}`,
              )}`}</Typography>
              <div>
                <ActivityText activityItem={activity} />
              </div>
            </Box>
          );
        },
      )}
    </Box>
  );
};

import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { IUiSchema } from "@rentiohq/shared-frontend/dist/forms/forms.types";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { NON_USER_SELECTABLE_DOCUMENT_CATEGORIES } from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.constants";
import {
  getCategoriesSortedByTranslatedLabel,
  getDocumentCategoryName,
} from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import { JSONSchema6 } from "json-schema";
import { uniq } from "lodash";
import { ts } from "../../services";
import { EField, IExtraData } from "./schema.createDocument.types";

export const getSchema =
  (initialValues: { [key: string]: any }, extraData: IExtraData) =>
  (defaultValues: { [key: string]: any }): JSONSchema6 => {
    const categoryIds = getCategoriesSortedByTranslatedLabel().filter(
      x => !NON_USER_SELECTABLE_DOCUMENT_CATEGORIES.includes(x),
    );
    if (extraData.document?.categoryId) {
      // Add at beginning
      categoryIds.unshift(extraData.document.categoryId);
    }

    return {
      required: [EField.Name, EField.Category],
      properties: {
        [EField.Name]: {
          default: getValue(EField.Name, initialValues, defaultValues),
        },
        [EField.Category]: {
          default: getValue(EField.Category, initialValues, defaultValues),
          // @ts-ignore
          options:
            //
            uniq(categoryIds).map(categoryId => ({
              value: getDocumentCategoryName(categoryId),
              id: categoryId,
            })),
        },
      },
    };
  };

export const uiSchema = (_extraData: IExtraData): IUiSchema<EField> => ({
  "rentio:navigationTitle": ts.addFileModalHeading(),
  [EField.Name]: {
    "ui:field": ERentioFormField.Text,
    title: ts.addFileModalNameLabel(),
  },
  [EField.Category]: {
    "ui:field": ERentioFormField.DropdownSelect,
    title: ts.addFileModalCategoryLabel(),
  },
});

import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as registrationActions from "@rentiohq/shared-frontend/dist/redux/registration/registration.actions";
import * as registrationSelectors from "@rentiohq/shared-frontend/dist/redux/registration/registration.selectors";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EInsuranceExternalStatus,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { ResourceList } from "@rentiohq/web-shared/dist/components";
import { requestInsuranceValidationErrors } from "forms/requestInsurance/schema.requestInsurance.utils";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { NumberParam, StringParam } from "serialize-query-params";
import { IndexContractModal } from "../../../../../../../../components/IndexContractModal";
import { LocationDescriptionModal } from "../../../../../../../../components/LocationDescriptionModal";
import RequestInsuranceModal from "../../../../../../../../components/RequestInsuranceModal";
import RequestMoveServiceModal from "../../../../../../../../components/RequestMoveServiceModal";
import TemplateDocumentContractCreateModal from "../../../../../../../../components/TemplateDocumentContractCreateModal";
import { ChecklistItem, IChecklistItem } from "../ChecklistItem";
import { useGetChecklistItems } from "./useGetChecklistItems";

interface IProps {
  contract: IContract;
  property: IProperty;
}

export const ChecklistItems = (props: IProps) => {
  const dispatch = useDispatch();

  const { contract, property } = props;

  const [queryParams, setQueryParamValue] = useQueryParams({
    documentId: StringParam,
    templateDocumentId: StringParam,
    documentPackageId: StringParam,
    rentDepositId: NumberParam,
    insuranceContractId: StringParam,
    requestInsuranceContractId: StringParam,
    moveRequestId: NumberParam,
    registrationId: NumberParam,
  });

  const registration = useSelector((state: IRootStore) =>
    registrationSelectors.registration(state, contract?.rentRegistrationId),
  );
  const isFetchingRegistration = useSelector((state: IRootStore) =>
    registrationSelectors.isFetching(state, contract?.rentRegistrationId),
  );
  // const registrationFetchError = useSelector((state: IRootStore) =>
  //   registrationSelectors.fetchError(state, contract?.rentRegistrationId),
  // );

  // Local state
  const [showIndexContractModal, setShowIndexContractModal] = useState(false);
  const [
    showTemplateDocumentContractCreate,
    setShowTemplateDocumentContractCreate,
  ] = useState(false);
  const [showLocationDescriptionModal, setShowLocationDescriptionModal] =
    useState(false);
  const [showRequestInsuranceModal, setShowRequestInsuranceModal] =
    useState(false);
  const [didShowAutomaticInsuranceModal, setDidShowAutomaticInsuranceModal] =
    useState(false);
  const [showRequestMoveService, setShowRequestMoveServiceModal] =
    useState(false);

  // Redux selectors
  const { isBroker } = authHooks.useSelf();
  const hasInsuranceForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Insurance,
  );

  const checklistItems = useGetChecklistItems({
    contract,
    property,
    registration,

    // Callbacks
    onIndexContract: () => {
      setShowIndexContractModal(true);
    },
    onCreateContractFromTemplateDocument: () => {
      setShowTemplateDocumentContractCreate(true);
    },
    onShowDocument: documentId => {
      setQueryParamValue({
        documentId,
      });
    },
    onShowTemplateDocument: templateDocumentId => {
      setQueryParamValue({
        templateDocumentId,
      });
    },
    onShowDocumentPackage: documentPackageId => {
      setQueryParamValue({
        documentPackageId,
      });
    },
    onShowRentDeposit: rentDepositId => {
      setQueryParamValue({
        rentDepositId,
      });
    },
    onOrderPropertyInspectionReport: () => {
      setShowLocationDescriptionModal(true);
    },
    onRequestInsurance: () => {
      setShowRequestInsuranceModal(true);
    },
    onShowInsurance: (insuranceContractId: string) => {
      setQueryParamValue({
        insuranceContractId,
      });
    },
    onCreateMoveRequest: () => {
      setShowRequestMoveServiceModal(true);
    },
    onShowMoveRequest: (moveRequestId: number) => {
      setQueryParamValue({ moveRequestId });
    },
    onShowRentRegistration: (registrationId: number) => {
      setQueryParamValue({
        registrationId,
      });
    },

    // Config
    shouldFetchData: true,
  });

  useEffect(() => {
    if (isFetchingRegistration || !contract?.rentRegistrationId) {
      return;
    }

    dispatch(
      registrationActions.getRegistration.actions.start({
        id: contract?.rentRegistrationId,
      }),
    );
  }, [contract?.rentRegistrationId]);

  // Automatically show request insurance modal after opening rent deposit
  useEffect(() => {
    if (isBroker && !hasInsuranceForBroker) {
      return;
    }

    if (didShowAutomaticInsuranceModal) {
      return;
    }

    if (!queryParams.requestInsuranceContractId) {
      return;
    }

    if (contract.id !== queryParams.requestInsuranceContractId) {
      return;
    }

    if (contract.insuranceExternalStatus !== EInsuranceExternalStatus.None) {
      return;
    }

    const validationErrors = requestInsuranceValidationErrors({
      property,
      contract,
    });

    if (validationErrors.length > 0) {
      return;
    }

    setShowRequestInsuranceModal(true);
    setDidShowAutomaticInsuranceModal(true);
  }, [contract, hasInsuranceForBroker, queryParams.requestInsuranceContractId]);

  // Event handlers
  const handleIndexModalClose = (documentId?: string) => {
    setShowIndexContractModal(false);
    setQueryParamValue({
      documentId,
    });
  };

  const handleLocationDescriptionModalClose = () => {
    setShowLocationDescriptionModal(false);
  };

  const handleClickRequestInsuranceModalClose = () => {
    setShowRequestInsuranceModal(false);
    setQueryParamValue({
      requestInsuranceContractId: undefined,
    });
  };

  const handleClickRequestMoveServiceModalClose = () => {
    setShowRequestMoveServiceModal(false);
  };

  // Render
  return (
    <>
      <ResourceList
        renderItem={(item: IChecklistItem) => (
          <ChecklistItem item={item} contract={contract} />
        )}
        items={checklistItems}
      />

      {showIndexContractModal && (
        <IndexContractModal
          contractId={contract.id}
          onClose={handleIndexModalClose}
        />
      )}

      {showTemplateDocumentContractCreate && (
        <TemplateDocumentContractCreateModal
          property={property}
          contract={contract}
          onClose={() => {
            setShowTemplateDocumentContractCreate(false);
          }}
          type={"contract"}
        />
      )}

      {showLocationDescriptionModal && (
        <LocationDescriptionModal
          property={property}
          contract={contract}
          onClose={handleLocationDescriptionModalClose}
        />
      )}

      {showRequestInsuranceModal && (
        <RequestInsuranceModal
          property={property}
          contract={contract}
          onClose={handleClickRequestInsuranceModalClose}
        />
      )}

      {showRequestMoveService && (
        <RequestMoveServiceModal
          property={property}
          contract={contract}
          onClose={handleClickRequestMoveServiceModalClose}
        />
      )}
    </>
  );
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { openSocket } from "@rentiohq/shared-frontend/dist/redux/socket/socket.actions";
import { ISocketReadyState } from "@rentiohq/shared-frontend/dist/redux/socket/socket.types";
import { generateRef } from "@rentiohq/shared-frontend/dist/redux/socket/socket.utils";
import { getDetailStart, getMessagesStart, sendMessageStart, } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import * as chatSelectors from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { showDropErrors } from "@rentiohq/web-shared/dist/utils/file";
import React from "react";
import { useDropzone } from "react-dropzone";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { attachmentToBase64, canPreviewAttachment, getAttachmentIcon, } from "../../utils/attachment";
import { MainCard } from "../cards/MainCard";
import { ArchivedChatBanner } from "./ArchivedChatBanner";
import { ChatStyles } from "./Chat.styles";
import { ChatDrawer } from "./ChatDrawer";
import { ChatHeader } from "./ChatHeader";
import { ChatHistory } from "./ChatHistory";
import { ChatMessageInput } from "./ChatMessageInput";
export var ChatApp = function (_a) {
    var chats = _a.chats, showArchivedChats = _a.showArchivedChats, isLoading = _a.isLoading, handleCreateChat = _a.handleCreateChat, handleLoadMore = _a.handleLoadMore, queryInput = _a.queryInput, setQueryInput = _a.setQueryInput, handleEditChat = _a.handleEditChat, toggleShowArchivedChats = _a.toggleShowArchivedChats;
    var openSocketInterval;
    var params = useParams();
    var chatId = params.chatId ? +params.chatId : undefined;
    var sx = ChatStyles();
    var theme = useTheme();
    var downMD = useMediaQuery(theme.breakpoints.down("md"));
    var _b = React.useState(true), openChatDrawer = _b[0], setOpenChatDrawer = _b[1];
    var _c = React.useState(""), message = _c[0], setMessage = _c[1];
    var _d = React.useState(), attachment = _d[0], setAttachment = _d[1];
    var _e = React.useState(), base64 = _e[0], setBase64 = _e[1];
    var _f = React.useState(), sendingMessageRef = _f[0], setSendingMessageRef = _f[1];
    var _g = React.useState(false), forceIsSending = _g[0], setForceIsSending = _g[1];
    var chat = useSelector(function (state) {
        return chatSelectors.getDetail(state, chatId);
    });
    var _h = useSelector(function (state) { return state.socket; }), socketReadyState = _h.readyState, _j = _h.pendingRefs, pendingRefs = _j === void 0 ? [] : _j;
    var prevPendingRefs = usePrevious(pendingRefs);
    var dispatch = useDispatch();
    var location = useLocation();
    React.useEffect(function () {
        if (location.state) {
            var state = location.state;
            var keys = Object.keys(state);
            if (keys.includes("newChat") &&
                socketReadyState &&
                [ISocketReadyState.Closing, ISocketReadyState.Closed].includes(socketReadyState)) {
                dispatch(openSocket.actions.start({}));
            }
            else if (keys.includes("updateChat") && chatId) {
                dispatch(getDetailStart.getAction({ id: chatId }));
            }
        }
    }, [location]);
    React.useEffect(function () {
        openSocketInterval = setInterval(function () {
            if (socketReadyState &&
                [ISocketReadyState.Open, ISocketReadyState.Connecting].includes(socketReadyState)) {
                return;
            }
            dispatch(openSocket.actions.start({}));
        }, 3000);
        return function () {
            clearInterval(openSocketInterval);
        };
    }, [socketReadyState]);
    React.useEffect(function () {
        if (!chatId || socketReadyState !== ISocketReadyState.Open) {
            return;
        }
        dispatch(getMessagesStart.getAction({
            chatId: chatId,
            shouldReset: true,
            ref: generateRef(),
        }));
    }, [chatId, socketReadyState]);
    React.useEffect(function () {
        if (!chat) {
            dispatch(getDetailStart.getAction({ id: chatId }));
        }
        clearNewMessage();
    }, [chatId]);
    React.useEffect(function () {
        var wasSending = getIsSending(prevPendingRefs);
        var isSending = getIsSending(pendingRefs);
        if (wasSending && !isSending) {
            clearNewMessage();
        }
    }, [pendingRefs, prevPendingRefs]);
    var clearNewMessage = function () {
        setMessage("");
        setForceIsSending(false);
        setSendingMessageRef(undefined);
        setAttachment(undefined);
    };
    var handlePressMessage = function (_a) {
        var attachment = _a.attachment;
        if (attachment) {
            utils.general.openWindow(attachment.url);
        }
    };
    var handlePressSendMessage = function () { return __awaiter(void 0, void 0, void 0, function () {
        var ref;
        return __generator(this, function (_a) {
            ref = generateRef();
            setSendingMessageRef(ref);
            setForceIsSending(true);
            if (chatId) {
                dispatch(sendMessageStart.getAction({
                    message: {
                        chatId: chatId,
                        text: message.trim(),
                        attachment: attachment,
                    },
                    ref: ref,
                }));
            }
            setForceIsSending(false);
            return [2 /*return*/];
        });
    }); };
    var handlePressRemoveAttachment = function () {
        setAttachment(undefined);
    };
    var getIsSending = function (pRefs) {
        if (pRefs === void 0) { pRefs = []; }
        return forceIsSending || pRefs.includes(sendingMessageRef || -1);
    };
    var handleDrop = function (acceptedFiles, fileRejections, event) { return __awaiter(void 0, void 0, void 0, function () {
        var fileBase64, unknownError_1, error;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    showDropErrors({ acceptedFiles: acceptedFiles, fileRejections: fileRejections, event: event });
                    if (acceptedFiles.length === 0) {
                        return [2 /*return*/];
                    }
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, attachmentToBase64(acceptedFiles[0])];
                case 2:
                    fileBase64 = (_a.sent());
                    setBase64(fileBase64);
                    setAttachment(acceptedFiles[0]);
                    return [3 /*break*/, 4];
                case 3:
                    unknownError_1 = _a.sent();
                    error = unknownError_1;
                    throw new Error(error);
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var _k = useDropzone({
        onDrop: handleDrop,
        multiple: false,
        maxSize: CONFIG.UPLOAD_MAX_FILE_SIZE,
    }), getRootProps = _k.getRootProps, getInputProps = _k.getInputProps, open = _k.open;
    var _l = getRootProps(), _ = _l.onClick, __ = _l.onKeyDown, rootPropsWithoutKeyDown = __rest(_l, ["onClick", "onKeyDown"]);
    var handlePressUrl = function (url) {
        utils.general.openWindow(url);
    };
    var handleDrawerOpen = function () {
        setOpenChatDrawer(function (prevState) { return !prevState; });
    };
    var isActive = !(chat === null || chat === void 0 ? void 0 : chat.archivedAt);
    var showSidebar = downMD ? !chatId : true;
    var showMessages = downMD ? !!chatId : true;
    return (_jsxs(Box, { sx: sx.wrapper, children: [showSidebar && (_jsx(ChatDrawer, { chatId: chatId, chats: chats, showArchivedChats: showArchivedChats, isLoading: isLoading, queryInput: queryInput, setQueryInput: setQueryInput, openChatDrawer: openChatDrawer, handleDrawerOpen: handleDrawerOpen, handleLoadMore: handleLoadMore, handleCreateChat: handleCreateChat, toggleShowArchivedChats: toggleShowArchivedChats })), showMessages && (_jsx(MainCard, { sx: sx.content.card, children: (chat === null || chat === void 0 ? void 0 : chat.id) && (_jsxs("div", __assign({}, rootPropsWithoutKeyDown, { children: [isActive && _jsx("input", __assign({}, getInputProps())), _jsx(ChatHeader, { chat: chat, handleEditChat: handleEditChat }), _jsx(ChatHistory, { chat: chat, chatId: chat === null || chat === void 0 ? void 0 : chat.id, onPressMessage: handlePressMessage, onPressUrl: handlePressUrl }), !isActive && _jsx(ArchivedChatBanner, { chat: chat }), isActive && (_jsx(ChatMessageInput, { chat: chat, isSending: getIsSending(pendingRefs), onPressSend: handlePressSendMessage, message: message, setMessage: setMessage, onPressSelectAttachment: open, onPressRemoveAttachment: handlePressRemoveAttachment, attachmentPreviewText: attachment ? getAttachmentIcon(attachment) : undefined, attachmentPreviewData: attachment && canPreviewAttachment(attachment)
                                ? { uri: base64 }
                                : undefined }))] }))) }))] }));
};

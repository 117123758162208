import { jsx as _jsx } from "react/jsx-runtime";
import TextField from "@mui/material/TextField";
export var AddressTextField = function (props) {
    var id = props.id, name = props.name, disabled = props.disabled, value = props.value, onChange = props.onChange, placeholder = props.placeholder, maxLength = props.maxLength, formikProps = props.formikProps;
    var handleChange = function (value) {
        if ((value === null || value === void 0 ? void 0 : value.length) < maxLength(formikProps === null || formikProps === void 0 ? void 0 : formikProps.values)) {
            onChange(value);
        }
    };
    return (_jsx(TextField, { id: id, name: name, disabled: disabled, value: value, placeholder: placeholder, onChange: function (e) { return handleChange(e.target.value); } }));
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { EAssets } from "../../assets";
import { ECaptionVariant } from "../../utils/types/typography";
import { Icon } from "../Icon";
import { Menu } from "../Menu";
import { SpinningLoader } from "../SpinningLoader";
import { ContactFetchListItemStyles } from "./ContactFetchListItem.styles";
export var ContactFetchListItem = function (_a) {
    var contactId = _a.contactId, _b = _a.showActions, showActions = _b === void 0 ? true : _b, getSubtitle = _a.getSubtitle, getActions = _a.getActions;
    var dispatch = useDispatch();
    var isFetching = useSelector(function (state) {
        return contactSelectors.getContactIsFetching(state, undefined, contactId);
    });
    var contact = useSelector(function (state) {
        return contactSelectors.getContact(state, undefined, contactId);
    });
    React.useEffect(function () {
        if (contactId && !contact && !isFetching) {
            dispatch(contactActions.getContactsByAccountIds.actions.start({
                accountIds: [contactId],
            }));
        }
    }, [contactId, contact, isFetching]);
    if (!contact) {
        return null;
    }
    var _c = (getSubtitle === null || getSubtitle === void 0 ? void 0 : getSubtitle(contact)) || {}, _d = _c.subTitle, subTitle = _d === void 0 ? "" : _d, _e = _c.subTitleColor, subTitleColor = _e === void 0 ? ECaptionVariant.Default : _e;
    var sx = ContactFetchListItemStyles(subTitleColor);
    var actions = __spreadArray([], ((getActions === null || getActions === void 0 ? void 0 : getActions(contact)) || []), true);
    var iconSource = !!contact.company
        ? EAssets.SuitCaseIcon
        : EAssets.UserOutlinedIcon;
    if (isFetching) {
        return _jsx(SpinningLoader, {});
    }
    return (_jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 1, sx: sx.content.accountItem, children: [iconSource && _jsx(Icon, { path: iconSource, width: "24px", height: "24px" }), _jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle1", lineHeight: "16px", children: getName(contact) }), subTitle && (_jsx(Typography, { variant: "subtitle1", sx: sx.content.subtitle, children: subTitle }))] })] }), (actions === null || actions === void 0 ? void 0 : actions.length) > 0 && showActions && _jsx(Menu, { actions: actions })] }));
};

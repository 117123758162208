import { useTheme } from "@mui/material/styles";
export var MaskedInputStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            padding: "10px 14px",
            borderRadius: "8px",
            background: "#f8fafc",
            border: "1px solid #bdbdbd",
            height: "43px",
            color: theme.palette.text.dark,
            fontSize: "0.875rem",
        },
    };
};

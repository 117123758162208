import { Box } from "@rebass/grid";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestHooksV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.hooks";
import { useDetail } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { EPayoutType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  EPaymentRequestFeature,
  EPaymentRequestStatus,
  EPaymentRequestsDisplayType,
  IPaymentRequest,
} from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  canMarkPaid,
  getPaymentOrders,
} from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Card,
  EFilterType,
  ESpacings,
  Filters,
  Icon,
  RentioInternalRenderer,
  Stages,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IFilterGroupConfig } from "@rentiohq/web-shared/dist/components/Filters/Filters.types";
import * as systemActions from "@rentiohq/web-shared/dist/redux/system/system.actions";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { compact } from "lodash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  EPaymentRequestFilterFrom,
  EPaymentRequestFilterPhase,
  EPaymentRequestFilterStatus,
  getPaymentRequestsFilter,
  getPaymentRequestsFilterV2,
} from "scenes/FollowUp/Payments/Payments.utils";
import {
  ONE_TIME_PAYMENT_TYPES,
  RECURRING_PAYMENT_TYPES,
} from "scenes/PaymentsOverview/PaymentHistory/PaymentHistory";
import { EContractStatus } from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ContractStatusChip/contractStatusChip.types";
import usePreference from "scenes/Settings/hooks/usePreference";
import {
  NumberParam,
  StringParam,
  createEnumParam,
} from "serialize-query-params";
import { ts } from "../../services";
import { isFromRent, isRentDiscount } from "../../utils/payment";
import { PaymentFollowUpModal } from "../PaymentFollowUpModal";
import { ManuallyMarkPaidModal } from "./components/ManuallyMarkPaidModal";
import { PaymentRequestsGrid } from "./components/PaymentRequestsGrid";
import { PaymentRequestsTable } from "./components/PaymentRequestsTable";
import { ThirdPartyAccountModal } from "./components/ThirdPartyAccountModal";

const PREFERENCE_KEY_PAYMENT_REQUESTS_DISPLAY_TYPE =
  "PAYMENT_REQUESTS_DISPLAY_TYPE";

interface IProps {
  propertyId?: number;
}

export const OpenPaymentRequests: React.FC<IProps> = props => {
  const { propertyId } = props;
  const isPropertyDetail = !!propertyId;

  const PREFERENCE_KEY_OPEN_PAYMENT_REQUESTS_PAYMENT_TYPE_RECURRING = `OPEN_PAYMENT_REQUESTS_PAYMENT_TYPE_RECURRING${
    propertyId ? `_${propertyId}` : ""
  }`;
  const PREFERENCE_KEY_OPEN_PAYMENT_REQUESTS_PAYMENT_TYPE_ONE_OFF = `OPEN_PAYMENT_REQUESTS_PAYMENT_TYPE_ONE_OFF${
    propertyId ? `_${propertyId}` : ""
  }`;

  const [queryParams, setQueryParamValue] = useQueryParams({
    query: StringParam,
    paymentRequestId: NumberParam,
    phase: createEnumParam(Object.values(EPaymentRequestFilterPhase)),
    contractStatus: createEnumParam(Object.values(EContractStatus)),
    status: createEnumParam(Object.values(EPaymentRequestFilterStatus)),
    from: createEnumParam(Object.values(EPaymentRequestFilterFrom)),
    paymentRequestsTablePage: NumberParam,
  });
  const [queryDebounced] = useDebounce(queryParams.query);

  const [manuallyMarkPaidItem, setManuallyMarkPaidItem] =
    React.useState<IPaymentRequest>();
  const [modalFollowUpItem, setModalFollowUpItem] =
    React.useState<IPaymentRequest>();
  const [modalThirdPartyAccountItem, setModalThirdPartyAccountItem] =
    React.useState<IPaymentRequest>();

  // Redux
  const dispatch = useDispatch();

  const { detail: property } = useDetail({
    id: propertyId,
    shouldRefetch: false,
  });

  const paymentRequestsDisplay = useSelector(
    (state: IRootStore) =>
      systemSelectors.getPreference<EPaymentRequestsDisplayType>(
        state,
        PREFERENCE_KEY_PAYMENT_REQUESTS_DISPLAY_TYPE,
      ) || EPaymentRequestsDisplayType.Grid,
  );
  const isTable = paymentRequestsDisplay === EPaymentRequestsDisplayType.Table;

  // Hooks
  const { user, broker } = authHooks.useSelf();
  const { internalModeEnabled } = useInternalMode();
  // const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);
  const isV2 = true;

  const [paymentTypeRecurring = [], setPaymentTypeRecurring] = usePreference<
    typeof RECURRING_PAYMENT_TYPES
  >({
    preferenceKey: PREFERENCE_KEY_OPEN_PAYMENT_REQUESTS_PAYMENT_TYPE_RECURRING,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paymentTypeOneOff = [], setPaymentTypeOneOff] = usePreference<
    typeof ONE_TIME_PAYMENT_TYPES
  >({
    preferenceKey: PREFERENCE_KEY_OPEN_PAYMENT_REQUESTS_PAYMENT_TYPE_ONE_OFF,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const { count: paymentRequestsIncomingCountV1 } = useCount({
    shouldRefetch: true,
    countBase:
      !isV2 && isTable
        ? append("/payment-requests/count", {
            where: mapObjectWithTimeZonedDatesToUtcIsoString(
              getPaymentRequestsFilter({
                phase: EPaymentRequestFilterPhase.Incoming,
                propertyId,
              }).where,
            ),
          })
        : undefined,
  });

  const { count: paymentRequestsRentioCountV1 } = useCount({
    shouldRefetch: true,
    countBase:
      !isV2 && isTable
        ? append("/payment-requests/count", {
            where: mapObjectWithTimeZonedDatesToUtcIsoString(
              getPaymentRequestsFilter({
                phase: EPaymentRequestFilterPhase.Rentio,
                propertyId,
              }).where,
            ),
          })
        : undefined,
  });

  const { count: paymentRequestsActionRequiredCountV1 } = useCount({
    shouldRefetch: true,
    countBase:
      !isV2 && isTable
        ? append("/payment-requests/count", {
            where: mapObjectWithTimeZonedDatesToUtcIsoString(
              getPaymentRequestsFilter({
                phase: EPaymentRequestFilterPhase.Action,
                propertyId,
              }).where,
            ),
          })
        : undefined,
  });

  const { count: paymentRequestsIncomingCountV2 } =
    paymentRequestHooksV2.useCount({
      shouldRefetch: true,
      query:
        isV2 && isTable
          ? getPaymentRequestsFilterV2({
              propertyId,
              phase: EPaymentRequestFilterPhase.Incoming,
            })
          : undefined,
    });

  const { count: paymentRequestsRentioCountV2 } =
    paymentRequestHooksV2.useCount({
      shouldRefetch: true,
      query:
        isV2 && isTable
          ? getPaymentRequestsFilterV2({
              propertyId,
              phase: EPaymentRequestFilterPhase.Rentio,
            })
          : undefined,
    });

  const { count: paymentRequestsActionRequiredCountV2 } =
    paymentRequestHooksV2.useCount({
      shouldRefetch: true,
      query:
        isV2 && isTable
          ? getPaymentRequestsFilterV2({
              propertyId,
              phase: EPaymentRequestFilterPhase.Action,
            })
          : undefined,
    });

  const paymentRequestsIncomingCount = isV2
    ? paymentRequestsIncomingCountV2
    : paymentRequestsIncomingCountV1;
  const paymentRequestsRentioCount = isV2
    ? paymentRequestsRentioCountV2
    : paymentRequestsRentioCountV1;
  const paymentRequestsActionRequiredCount = isV2
    ? paymentRequestsActionRequiredCountV2
    : paymentRequestsActionRequiredCountV1;

  // Helpers
  const getPaymentRequestActions = (paymentRequest: IPaymentRequest) => {
    const paymentOrders = getPaymentOrders(paymentRequest);

    const hasFromRent = isFromRent(paymentOrders);
    const hasRentDiscount = isRentDiscount(paymentOrders);

    const isEditable = paymentOrders[0]?.isEditable;

    const hasThirdPartyAction =
      isEditable &&
      paymentRequest.payoutType === EPayoutType.Charge &&
      [EPaymentRequestStatus.New, EPaymentRequestStatus.FailedPaidIn].includes(
        paymentRequest.status,
      ) &&
      !!broker?.thirdPartyBankAccount?.mandateApprovedAt &&
      !paymentRequest.features.includes(
        EPaymentRequestFeature.ThirdPartyPayInBrokerDirect,
      );

    const isDeletable =
      isEditable &&
      [
        EPaymentRequestStatus.New,
        EPaymentRequestStatus.Partial,
        EPaymentRequestStatus.FailedPaidIn,
      ].includes(paymentRequest.status as EPaymentRequestStatus);

    const viewActions: IAction[] = [];
    const paymentRequestActions: IAction[] = [];
    const thirdPartyActions: IAction[] = [];

    viewActions.push({
      content: ts.paymentsFollowUpCardActionsDetailPaymentRequest(),
      onClick: handleShowPaymentRequestDetailClick(paymentRequest),
    });

    if (!isPropertyDetail) {
      viewActions.push({
        content: getLocalizedText("property.view_property.action"),
        url: `/properties/${paymentRequest.requestPropertyId}/payments`,
      });
    }

    if (
      !!(paymentRequest.payerAccount as IAccount & { contact?: IContact })
        .contact
    ) {
      viewActions.push({
        content: ts.contactViewContactAction(),
        // @ts-ignore
        url: `/contacts/${paymentRequest.payerAccount.contact!.id}`,
      });
    }

    if (!hasFromRent && !hasRentDiscount) {
      paymentRequestActions.push({
        content: ts.paymentRequestResendInvitationAction(),
        onClick: handleInvitationClick(paymentRequest),
      });
    }

    if (
      queryParams.phase !== EPaymentRequestFilterPhase.Incoming &&
      !hasFromRent
    ) {
      paymentRequestActions.push({
        content: ts.paymentsFollowUpCardActionsContactPayer(),
        onClick: handleContactPayerClick(paymentRequest),
      });
    }

    if (
      isPropertyDetail &&
      property &&
      user &&
      canMarkPaid({ paymentRequest, property, user })
    ) {
      paymentRequestActions.push({
        content: <TextStyle>{ts.paymentRequestMarkPaidAction()}</TextStyle>,
        onClick: handleMarkPaidClick(paymentRequest),
      });
    } else if (
      !isPropertyDetail &&
      user &&
      canMarkPaid({ paymentRequest, user })
    ) {
      paymentRequestActions.push({
        content: <TextStyle>{ts.paymentRequestMarkPaidAction()}</TextStyle>,
        onClick: handleMarkPaidClick(paymentRequest),
      });
    } else if (internalModeEnabled) {
      paymentRequestActions.push({
        content: (
          <RentioInternalRenderer>
            {ts.paymentRequestMarkPaidAction()}
          </RentioInternalRenderer>
        ),
        onClick: handleMarkPaidClick(paymentRequest),
      });
    }

    if (hasThirdPartyAction) {
      thirdPartyActions.push({
        content: ts.paymentsFollowUpCardActionsThirdPartyAccountAction(),
        onClick: handleThirdPartyClick(paymentRequest),
      });
    }

    if (isDeletable) {
      paymentRequestActions.push({
        content: <TextStyle variation="negative">{ts.remove()}</TextStyle>,
        onClick: () => handleRemoveWithConfirm(paymentRequest),
      });
    }

    return [viewActions, paymentRequestActions, thirdPartyActions];
  };

  const getPrimaryPaymentRequestActions = (
    paymentRequest: IPaymentRequest,
    phase: EPaymentRequestFilterPhase,
  ) => {
    let actions: IAction[] = [];

    if (
      phase === EPaymentRequestFilterPhase.Action &&
      paymentRequest.status !== EPaymentRequestStatus.Transferring
    ) {
      actions.push({
        content: ts.paymentsFollowUpActionRequiredAction(),
        onClick: handleContactPayerClick(paymentRequest),
        appearance: "danger",
      });
    }

    return actions;
  };

  // Events
  const handleTogglePaymentRequestsDisplay = () => {
    dispatch(
      systemActions.setPreferences({
        persistScope: EPreferencePersistScope.LocalStorage,
        preferences: {
          [PREFERENCE_KEY_PAYMENT_REQUESTS_DISPLAY_TYPE]:
            paymentRequestsDisplay === EPaymentRequestsDisplayType.Grid
              ? EPaymentRequestsDisplayType.Table
              : EPaymentRequestsDisplayType.Grid,
        },
      }),
    );
  };

  const handleChangeQuery = (newQuery: string | undefined) => {
    if (!newQuery || newQuery.length === 0) {
      setQueryParamValue({ query: undefined, paymentRequestsTablePage: 1 });
      return;
    }

    setQueryParamValue({ query: newQuery, paymentRequestsTablePage: 1 });
  };

  const handleClearQuery = () => {
    setQueryParamValue({
      query: undefined,
    });
  };

  const handleChangeContractStatuses = (value: EContractStatus[]) => {
    setQueryParamValue({
      contractStatus: value[0],
      paymentRequestsTablePage: 1,
    });
  };

  const handleClearContractStatus = () => {
    setQueryParamValue({
      contractStatus: undefined,
      paymentRequestsTablePage: 1,
    });
  };

  const handleChangeStatuses = (value: EPaymentRequestFilterStatus[]) => {
    setQueryParamValue({ status: value[0], paymentRequestsTablePage: 1 });
  };

  const handleClearStatus = () => {
    setQueryParamValue({ status: undefined, paymentRequestsTablePage: 1 });
  };

  const handleChangePhase = (value: EPaymentRequestFilterPhase[]) => {
    setQueryParamValue({ phase: value[0], paymentRequestsTablePage: 1 });
  };

  const handleClearPhase = () => {
    setQueryParamValue({ phase: undefined, paymentRequestsTablePage: 1 });
  };

  const handleChangeFrom = (value: EPaymentRequestFilterFrom[]) => {
    setQueryParamValue({ from: value[0], paymentRequestsTablePage: 1 });
  };

  const handleClearFrom = () => {
    setQueryParamValue({ from: undefined, paymentRequestsTablePage: 1 });
  };

  const handleInvitationClick = (item: IPaymentRequest) => () => {
    if (isV2) {
      dispatch(
        paymentActionsV2.sendPaymentRequestInvitationStart.getAction({
          id: item.id,
        }),
      );

      return;
    }

    dispatch(
      paymentActions.sendPaymentRequestInvitation.actions.start({
        paymentRequestId: item.id,
      }),
    );
  };

  const handleRemoveWithConfirm = (paymentRequest: IPaymentRequest) => {
    const handleRemove = () => {
      if (isV2) {
        dispatch(
          paymentActionsV2.removeStart.getAction({ id: paymentRequest.id }),
        );

        return;
      }

      dispatch(
        paymentActions.deletePaymentRequest.actions.start({
          id: paymentRequest.id,
        }),
      );
    };

    confirm({
      title: getLocalizedText("system.delete.confirm", {
        typePrefix: getLocalizedText("system.model.payment_request.prefix"),
        type: getLocalizedText("system.model.payment_request").toLowerCase(),
      }),
      primaryActions: [
        {
          title: getLocalizedText("system.remove"),
          onPress: handleRemove,
        },
      ],
    });
  };

  const handleContactPayerClick = (item: IPaymentRequest) => () => {
    setModalFollowUpItem(item);
  };

  const handleMarkPaidClick = (item: IPaymentRequest) => () => {
    setManuallyMarkPaidItem(item);
  };

  const handleThirdPartyClick = (item: IPaymentRequest) => () => {
    setModalThirdPartyAccountItem(item);
  };

  const handleShowPaymentRequestDetailClick = (item: IPaymentRequest) => () => {
    setQueryParamValue({
      paymentRequestId: item.id,
    });
  };

  const handleFollowUpModalClose = () => {
    setModalFollowUpItem(undefined);
  };

  const handleThirdPartyAccountModalClose = () => {
    setModalThirdPartyAccountItem(undefined);
  };

  const handleManuallyMarkPaidModalClose = () => {
    setManuallyMarkPaidItem(undefined);
  };

  const handleManuallyMarkPaidSuccess = () => {
    setManuallyMarkPaidItem(undefined);
  };

  // Render
  const renderFilter = () => {
    const filters: IFilterGroupConfig[] = [];

    if (paymentRequestsDisplay === EPaymentRequestsDisplayType.Table) {
      filters.push({
        label: getLocalizedText("payment_request.follow_up.filter.phase.label"),
        groupKey: "paymentRequestPhaseGroup",
        filters: [
          {
            filterKey: "paymentRequestPhase",
            type: EFilterType.SingleSelect,
            values: queryParams.phase ? [queryParams.phase] : [],
            options: Object.values(EPaymentRequestFilterPhase),
            onChange: handleChangePhase,
            onRemove: handleClearPhase,
            translate: (value: any) =>
              getLocalizedText(
                `payment_request.follow_up.filter.phase.option.${value.toLowerCase()}.label`,
              ),
          },
        ],
      });
    }

    filters.push(
      {
        label: getLocalizedText(
          "payment_request.follow_up.filter.status.label",
        ),
        groupKey: "paymentRequestStatusesGroup",
        filters: [
          {
            label: getLocalizedText("system.contract"),
            filterKey: "paymentRequestContractStatuses",
            type: EFilterType.SingleSelect,
            values: queryParams.contractStatus
              ? [queryParams.contractStatus]
              : [],
            options: Object.values(EContractStatus).filter(
              status => status !== EContractStatus.NoContract,
            ),
            onChange: handleChangeContractStatuses,
            onRemove: handleClearContractStatus,
            translate: (value: any) =>
              getLocalizedText(`contract.status.${stringToSnakeCase(value)}`),
          },
          {
            label: getLocalizedText("system.model.payment"),
            filterKey: "paymentRequestStatuses",
            type: EFilterType.SingleSelect,
            values: queryParams.status ? [queryParams.status] : [],
            options: Object.values(EPaymentRequestFilterStatus),
            onChange: handleChangeStatuses,
            onRemove: handleClearStatus,
            translate: (value: any) =>
              getLocalizedText(
                `payment_request.follow_up.filter.status.option.${stringToSnakeCase(
                  value,
                )}`,
              ),
          },
        ],
      },
      {
        label: getLocalizedText("payment_request.follow_up.filter.from.label"),
        groupKey: "paymentRequestFromGroup",
        filters: [
          {
            filterKey: "paymentRequestFrom",
            type: EFilterType.SingleSelect,
            values: queryParams.from ? [queryParams.from] : [],
            options: Object.values(EPaymentRequestFilterFrom),
            onChange: handleChangeFrom,
            onRemove: handleClearFrom,
            translate: (value: any) =>
              getLocalizedText(
                `payment_request.follow_up.filter.from.option.${value.toLowerCase()}.label`,
              ),
          },
        ],
      },
      {
        label: getLocalizedText("payment_history.filter.payment_type.title"),
        groupKey: "payment_type",
        filters: [
          {
            label: getLocalizedText(
              "payment_history.filter.payment_type.section.recurring.title",
            ),
            filterKey: "payment_type_recurring",
            type: EFilterType.MultiSelect,
            values: paymentTypeRecurring,
            options: RECURRING_PAYMENT_TYPES,
            onChange: newValues => {
              setPaymentTypeRecurring([...newValues]);
            },
            onRemove: (key: string) => {
              setPaymentTypeRecurring(
                paymentTypeRecurring.filter(v => v !== key),
              );
            },
            translate: (value: any) =>
              getLocalizedText(
                `payment.payment_order.type.${value}`.toLowerCase(),
              ),
          },
          {
            label: getLocalizedText(
              "payment_history.filter.payment_type.section.one_off.title",
            ),
            filterKey: "payment_type_one_off",
            type: EFilterType.MultiSelect,
            values: paymentTypeOneOff,
            options: ONE_TIME_PAYMENT_TYPES,
            onChange: newValues => {
              setPaymentTypeOneOff([...newValues]);
            },
            onRemove: (key: string) => {
              setPaymentTypeOneOff(paymentTypeOneOff.filter(v => v !== key));
            },
            translate: (value: any) =>
              getLocalizedText(
                `payment.payment_order.type.${value}`.toLowerCase(),
              ),
          },
        ],
      },
    );

    return (
      <Box mb={ESpacings.loose}>
        <Filters
          queryValue={queryParams.query || ""}
          onQueryChange={handleChangeQuery}
          onQueryClear={handleClearQuery}
          filterConfigs={filters}
          queryPlaceholder={getLocalizedText(
            "payment_requests.filter.query.placeholder",
          )}
        />
      </Box>
    );
  };

  const renderContent = () => {
    if (paymentRequestsDisplay === EPaymentRequestsDisplayType.Table) {
      return (
        <PaymentRequestsTable
          getPaymentRequestActions={getPaymentRequestActions}
          propertyId={propertyId}
          search={queryDebounced}
          phase={queryParams.phase}
          status={queryParams.status}
          contractStatus={queryParams.contractStatus}
          from={queryParams.from}
          paymentTypes={compact([
            ...paymentTypeRecurring,
            ...paymentTypeOneOff,
          ])}
        />
      );
    }

    return (
      <PaymentRequestsGrid
        getPaymentRequestActions={getPaymentRequestActions}
        getPrimaryPaymentRequestActions={getPrimaryPaymentRequestActions}
        propertyId={propertyId}
        search={queryDebounced}
        status={queryParams.status}
        contractStatus={queryParams.contractStatus}
        from={queryParams.from}
        paymentTypes={compact([...paymentTypeRecurring, ...paymentTypeOneOff])}
      />
    );
  };

  return (
    <Card
      heading={getLocalizedText("payments.follow_up.payment_requests.heading")}
      primaryActions={[
        {
          content:
            paymentRequestsDisplay === EPaymentRequestsDisplayType.Grid ? (
              <Icon source="listView" color="primary" />
            ) : (
              <Icon source="layout" color="primary" />
            ),
          appearance: "reset",
          onClick: handleTogglePaymentRequestsDisplay,
        },
      ]}
    >
      {paymentRequestsDisplay === EPaymentRequestsDisplayType.Table && (
        <Box mb={ESpacings.loose}>
          <Stages
            stages={[
              {
                heading: getLocalizedText(
                  "payments.follow_up.incoming.heading",
                ),
                icon: "billStack",
                count: paymentRequestsIncomingCount || 0,
                appearance: "success",
                isActive:
                  queryParams.phase === EPaymentRequestFilterPhase.Incoming,
                onClick: () => {
                  const isActive =
                    queryParams.phase === EPaymentRequestFilterPhase.Incoming;
                  setQueryParamValue({
                    phase: isActive
                      ? undefined
                      : EPaymentRequestFilterPhase.Incoming,
                  });
                },
              },

              {
                heading: getLocalizedText(
                  "payments.follow_up.reminder_rentio.heading",
                ),
                icon: "billStack",
                count: paymentRequestsRentioCount || 0,
                appearance: "warning",
                isActive:
                  queryParams.phase === EPaymentRequestFilterPhase.Rentio,
                onClick: () => {
                  const isActive =
                    queryParams.phase === EPaymentRequestFilterPhase.Rentio;
                  setQueryParamValue({
                    phase: isActive
                      ? undefined
                      : EPaymentRequestFilterPhase.Rentio,
                  });
                },
              },

              {
                heading: getLocalizedText(
                  "payments.follow_up.action_required.heading",
                ),
                icon: "alertDiamond",
                count: paymentRequestsActionRequiredCount || 0,
                appearance: "error",
                isActive:
                  queryParams.phase === EPaymentRequestFilterPhase.Action,
                onClick: () => {
                  const isActive =
                    queryParams.phase === EPaymentRequestFilterPhase.Action;
                  setQueryParamValue({
                    phase: isActive
                      ? undefined
                      : EPaymentRequestFilterPhase.Action,
                  });
                },
              },
            ]}
          />
        </Box>
      )}

      {!isPropertyDetail && renderFilter()}

      {renderContent()}

      {modalFollowUpItem && (
        <PaymentFollowUpModal
          onClose={handleFollowUpModalClose}
          followUpItem={modalFollowUpItem}
        />
      )}

      {modalThirdPartyAccountItem && (
        <ThirdPartyAccountModal
          paymentRequest={modalThirdPartyAccountItem}
          onClose={handleThirdPartyAccountModalClose}
        />
      )}

      {manuallyMarkPaidItem && (
        <ManuallyMarkPaidModal
          paymentRequest={manuallyMarkPaidItem}
          onClose={handleManuallyMarkPaidModalClose}
          onSuccess={handleManuallyMarkPaidSuccess}
        />
      )}
    </Card>
  );
};

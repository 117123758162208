import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  ICountQuery,
  TSort,
} from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import {
  Page,
  PageTitle,
  Stack,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import React from "react";
import { getContractStatusFilter } from "scenes/FollowUp/EndingContractsV2/EndingContracts.utils";
import { EContractStatus } from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ContractStatusChip/contractStatusChip.types";
import usePreference from "../../../scenes/Settings/hooks/usePreference";
import * as Constants from "./constants";
import { Property } from "./Property";

export const PropertyContainer = () => {
  const [page = 1] = usePreference<number>({
    preferenceKey: Constants.PREFERENCE_KEY_PROPERTY_PAGE_NUMBER,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const getFilters = ({
    isArchived,
    isActive,
    isContractEnded,
    isUpcoming,
  }: {
    isArchived?: boolean;
    isActive?: boolean;
    isContractEnded?: boolean;
    isUpcoming?: boolean;
  }) => {
    const result: ICountQuery = {};

    const andFilters: { [key: string]: any }[] = [];

    if (isArchived) {
      andFilters.push({ archivedAt: { isNot: null } });
    }

    if (isActive) {
      andFilters.push({ archivedAt: { is: null } });
      const contractStatusFilter = getContractStatusFilter(
        EContractStatus.Running,
      );
      contractStatusFilter && andFilters.push(contractStatusFilter);
    }

    if (isUpcoming) {
      andFilters.push({ archivedAt: { is: null } });
      const contractStatusFilter = getContractStatusFilter(
        EContractStatus.Upcoming,
      );
      contractStatusFilter && andFilters.push(contractStatusFilter);
    }

    if (isContractEnded) {
      andFilters.push({ archivedAt: { is: null } });
      const contractStatusFilter = getContractStatusFilter(
        EContractStatus.Finished,
      );
      contractStatusFilter && andFilters.push(contractStatusFilter);
    }

    result.filter = { and: andFilters };

    return result;
  };

  const sort = (() => {
    const result: TSort = [];

    result.push({
      field: { contracts: "startDate" },
      method: "DESC",
    });

    return result;
  })();

  const {
    items: activeProperties = [],
    isFetching: isFetchingActiveProperties,
    fetchError: fetchErrorForActiveProperties,
  } = propertyHooks.usePaged({
    shouldRefetch: true,
    query: {
      page,
      limit: CONFIG.DEFAULT_FETCH_LIMIT,
      sort,
      rolesFilter: [EPropertyMemberTypes.Tenant, EPropertyMemberTypes.Parent],
      ...getFilters({ isActive: true }),
    },
  });

  const {
    items: upcomingContractsProperties = [],
    isFetching: isFetchingUpcomingProperties,
    fetchError: fetchErrorForUpcomingProperties,
  } = propertyHooks.usePaged({
    shouldRefetch: true,
    query: {
      page,
      limit: CONFIG.DEFAULT_FETCH_LIMIT,
      sort,
      rolesFilter: [EPropertyMemberTypes.Tenant, EPropertyMemberTypes.Parent],
      ...getFilters({ isUpcoming: true }),
    },
  });

  const {
    items: contractEndedProperties = [],
    isFetching: isFetchingContractEndedProperties,
    fetchError: fetchErrorForContractEndedProperties,
  } = propertyHooks.usePaged({
    shouldRefetch: true,
    query: {
      page,
      limit: CONFIG.DEFAULT_FETCH_LIMIT,
      sort,
      rolesFilter: [EPropertyMemberTypes.Tenant, EPropertyMemberTypes.Parent],
      ...getFilters({ isContractEnded: true }),
    },
  });

  const {
    items: archivedProperties = [],
    isFetching: isFetchingArchivedProperties,
    fetchError: fetchErrorForArchivedProperties,
  } = propertyHooks.usePaged({
    shouldRefetch: true,
    query: {
      page,
      limit: CONFIG.DEFAULT_FETCH_LIMIT,
      sort,
      rolesFilter: [EPropertyMemberTypes.Tenant, EPropertyMemberTypes.Parent],
      ...getFilters({ isArchived: true }),
    },
  });

  React.useEffect(() => {
    document.title = `Rentio | ${getLocalizedText("properties.title")}`;
  }, []);

  React.useEffect(() => {
    const errorMessage = (
      fetchErrorForArchivedProperties ||
      fetchErrorForActiveProperties ||
      fetchErrorForContractEndedProperties ||
      fetchErrorForUpcomingProperties
    )?.message;
    if (errorMessage) {
      enqueueSnackbar(errorMessage, { variant: "error" });
    }
  }, [
    fetchErrorForArchivedProperties,
    fetchErrorForActiveProperties,
    fetchErrorForContractEndedProperties,
    fetchErrorForUpcomingProperties,
  ]);

  const isFetching =
    isFetchingActiveProperties ||
    isFetchingContractEndedProperties ||
    isFetchingArchivedProperties ||
    isFetchingUpcomingProperties;

  let isEmpty = false;
  isEmpty =
    !isFetching &&
    !activeProperties.length &&
    !contractEndedProperties.length &&
    !archivedProperties.length &&
    !upcomingContractsProperties.length;

  const renderPropertyList = ({
    titleKey,
    properties,
    isActive = true,
  }: {
    titleKey?: string;
    properties: IProperty[];
    isActive?: boolean;
  }) => {
    const hasData = properties.length > 0;
    return (
      hasData && (
        <div>
          {titleKey && <PageTitle title={getLocalizedText(titleKey)} />}
          <Property properties={properties} isActive={isActive} />
        </div>
      )
    );
  };

  return (
    <Page
      title="dashboard.card.properties.title"
      isLoading={isFetching}
      isEmpty={isEmpty}
      emptyHeading="tenant.property.empty.heading"
      emptyMessage="tenant.property.empty.details"
    >
      <Stack direction="column" gap={2}>
        {renderPropertyList({
          properties: [...activeProperties, ...upcomingContractsProperties],
        })}
        {renderPropertyList({
          titleKey: "property.dashboard.contract_ended_properties.heading",
          properties: contractEndedProperties,
          isActive: false,
        })}
        {renderPropertyList({
          titleKey: "property.dashboard.archived_properties.heading",
          properties: archivedProperties,
          isActive: false,
        })}
      </Stack>
    </Page>
  );
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { FieldsRenderer } from "../FieldsRenderer";
import { FormStepStyles } from "./FormStep.styles";
export var FormStep = function (_a) {
    var formStep = _a.formStep, formikProps = _a.formikProps, _b = _a.showHeading, showHeading = _b === void 0 ? true : _b, actions = _a.actions;
    var sx = FormStepStyles();
    var _c = formStep.uiSchema, heading = _c.heading, fieldsSchema = _c.fields;
    return (_jsxs(Box, { sx: sx.wrapper, children: [showHeading && (_jsx(Typography, { variant: "h4", sx: sx.content.formMainTitle, children: heading })), _jsx(Stack, { children: _jsx(FieldsRenderer, { formikProps: formikProps, fieldsSchema: fieldsSchema }) }), _jsx(Box, { sx: sx.content.actions, children: actions })] }));
};

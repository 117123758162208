import { compact } from "lodash";
import uniqBy from "lodash/uniqBy";
import * as requirementsSelectors from "../redux/requirements/requirements.selectors";
import { EBrokerFeature, EClientType } from "../types/broker.types";
import { EPaymentMethod, EPaymentRepetitionType, EPayoutType, } from "../types/payment.types";
import { EPaymentRequestStatus, } from "../types/paymentRequest.types";
import { EPropertyMemberTypes } from "../types/property.types";
import { getStore } from "../utils/redux/redux.utils";
import { differenceInBusinessDays } from "./date-fns.utils";
import * as paymentOrderUtils from "./paymentOrder.utils";
import { join } from "./string.utils";
var NOW = new Date();
export var getTitle = function (_a) {
    var paymentRequestItems = _a.paymentRequestItems;
    var paymentOrderTypesStrings = uniqBy(paymentRequestItems, "type").map(function (_a) {
        var paymentOrder = _a.paymentOrder;
        // TODO: Remove payment order check since the payment request should not be returned if it has no access to the payment order
        return paymentOrder && paymentOrderUtils.getTitle(paymentOrder);
    });
    return join(paymentOrderTypesStrings);
};
export var getAmount = function (_a) {
    var status = _a.status, amount = _a.amount, originalAmount = _a.originalAmount;
    return status === EPaymentRequestStatus.Partial ? amount : originalAmount;
};
export var isOpen = function (paymentRequest) {
    return [EPaymentRequestStatus.New, EPaymentRequestStatus.FailedPaidIn].includes(paymentRequest.status);
};
/**
 * @info number of days from now when SEPA payment will be deducted from payee's bank account
 */
export var sepaDeductionDaysFromNow = function (paymentRequest) {
    var result = 3;
    // Get offset from store
    var store = getStore();
    if (store) {
        var requirements = requirementsSelectors.getRequirements(store.getState());
        if (requirements) {
            result = requirements.sepaBankDaysOffset;
        }
    }
    // Subtract offset from payment due date
    result = Math.max(result, differenceInBusinessDays(paymentRequest.dueDateAt, NOW) - result);
    return result;
};
var iAmReceiver = function (paymentRequest, myAccountId) {
    if (paymentRequest.payoutType !== EPayoutType.Payout) {
        return false;
    }
    return myAccountId === paymentRequest.requestPayeeAccountId;
};
export var EUIPaymentMethod;
(function (EUIPaymentMethod) {
    EUIPaymentMethod["RecurringDirectDebit"] = "recurring_direct_debit";
    EUIPaymentMethod["RecurringBankWire"] = "recurring_bank_wire";
    EUIPaymentMethod["RecurringIbanisation"] = "recurring_ibanisation";
    EUIPaymentMethod["OnceDirectDebit"] = "once_direct_debit";
    EUIPaymentMethod["OnceBankWire"] = "once_bank_wire";
    EUIPaymentMethod["OnceIbanisation"] = "once_ibanisation";
    EUIPaymentMethod["OnceBancontact"] = "once_bancontact";
    EUIPaymentMethod["OnceIdeal"] = "once_ideal";
    EUIPaymentMethod["OncePayconiq"] = "once_payconiq";
})(EUIPaymentMethod || (EUIPaymentMethod = {}));
export var getAvailableRecurringPaymentMethods = function (_a) {
    var allowedPaymentMethods = _a.allowedPaymentMethods, repetitionType = _a.repetitionType;
    var result = [];
    var isRecurring = repetitionType !== EPaymentRepetitionType.Once;
    if (!isRecurring) {
        return [];
    }
    var sepaAllowed = allowedPaymentMethods.includes(EPaymentMethod.Sepa) ||
        allowedPaymentMethods.includes(EPaymentMethod.SepaRecurring);
    if (sepaAllowed) {
        result.push(EUIPaymentMethod.RecurringDirectDebit);
    }
    var ibanisationAllowed = allowedPaymentMethods.includes(EPaymentMethod.Ibanisation);
    if (ibanisationAllowed) {
        result.push(EUIPaymentMethod.RecurringIbanisation);
    }
    // const bankWireAllowed = allowedPaymentMethods.includes(
    //   EPaymentMethod.BankWire,
    // );
    // if (bankWireAllowed && isRecurring) {
    //   result.push(EUIPaymentMethod.RecurringBankWire);
    // }
    return result;
};
export var getAvailableOncePaymentMethods = function (_a) {
    var allowedPaymentMethods = _a.allowedPaymentMethods, repetitionType = _a.repetitionType;
    var result = [];
    if (allowedPaymentMethods.includes(EPaymentMethod.Bancontact)) {
        result.push(EUIPaymentMethod.OnceBancontact);
    }
    else if (allowedPaymentMethods.includes(EPaymentMethod.Payconiq)) {
        result.push(EUIPaymentMethod.OncePayconiq);
    }
    if (allowedPaymentMethods.includes(EPaymentMethod.Ideal)) {
        result.push(EUIPaymentMethod.OnceIdeal);
    }
    if (allowedPaymentMethods.includes(EPaymentMethod.Sepa) ||
        allowedPaymentMethods.includes(EPaymentMethod.SepaOnce)) {
        result.push(EUIPaymentMethod.OnceDirectDebit);
    }
    var isRecurring = repetitionType !== EPaymentRepetitionType.Once;
    if (!isRecurring &&
        allowedPaymentMethods.includes(EPaymentMethod.Ibanisation)) {
        result.push(EUIPaymentMethod.OnceIbanisation);
    }
    if (allowedPaymentMethods.includes(EPaymentMethod.BankWire)) {
        result.push(EUIPaymentMethod.OnceBankWire);
    }
    return result;
};
export var getPaymentOrders = function (params) {
    return uniqBy(compact(params.paymentRequestItems.map(function (item) { return item.paymentOrder; })), "id");
};
export var isEditable = function (paymentRequest) {
    return getPaymentOrders(paymentRequest).every(function (x) { return x.isEditable; });
};
export var canMarkPaid = function (params) {
    var paymentRequest = params.paymentRequest, property = params.property, user = params.user;
    if (!isEditable(paymentRequest)) {
        return false;
    }
    if (![
        EPaymentRequestStatus.New,
        EPaymentRequestStatus.FailedPaidIn,
        EPaymentRequestStatus.Partial,
    ].includes(paymentRequest.status)) {
        return false;
    }
    var isFinancialManager = property === null || property === void 0 ? void 0 : property.roles.includes(EPropertyMemberTypes.FinancialManager);
    var isBroker = user.personalAccountId !== user.activeAccountId;
    if (!(isFinancialManager ||
        // Fallback if property is not passed => check if broker
        (!property && isBroker) ||
        iAmReceiver(paymentRequest, user.activeAccountId))) {
        return false;
    }
    return true;
};
export var canCreateInvoice = function (params) {
    var paymentRequest = params.paymentRequest, broker = params.broker, user = params.user;
    if (!(broker === null || broker === void 0 ? void 0 : broker.features.includes(EBrokerFeature.Invoicing))) {
        return false;
    }
    if (paymentRequest.invoicedAt) {
        return false;
    }
    if ((broker === null || broker === void 0 ? void 0 : broker.type) === EClientType.Broker) {
        return (user === null || user === void 0 ? void 0 : user.activeAccountId) === paymentRequest.requestPayeeAccountId;
    }
    if ((broker === null || broker === void 0 ? void 0 : broker.type) === EClientType.PatrimonyCompany) {
        return true;
    }
    return false;
};

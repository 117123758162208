var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box } from "@rebass/grid";
import * as propertyApi from "@rentiohq/shared-frontend/dist/reduxV2/property/property.api";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import * as t from "@rentiohq/web-shared/dist/services/translationService";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { createImperativePromise } from "awesome-imperative-promise";
import React from "react";
import { EAssets } from "../../assets";
import { AsyncAutoComplete } from "../AsyncAutoComplete";
import { PropertyList } from "../PropertyList";
import { PropertyListItem } from "../PropertyListItem";
export var PropertySelectInline = function (_a) {
    var _b = _a.showPropertyList, showPropertyList = _b === void 0 ? true : _b, hideForm = _a.hideForm, placeholder = _a.placeholder, maxItems = _a.maxItems, _c = _a.customFilter, customFilter = _c === void 0 ? {} : _c, onChange = _a.onChange, value = _a.value;
    var _d = React.useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var _e = propertyHooks.useDetail({
        shouldRefetch: false,
        id: value,
    }), selectedProperty = _e.detail, isFetching = _e.isFetching;
    var globalSearch = function (query) {
        if (query === void 0) { query = ""; }
        return createImperativePromise(query === ""
            ? undefined
            : propertyApi.searchProperties(query, __assign(__assign({}, customFilter), { archivedAt: { is: null } })));
    };
    var debouncedSearch = AwesomeDebouncePromise(globalSearch, 200);
    var handleLoadOptions = function (query, callback) { return __awaiter(void 0, void 0, void 0, function () {
        var _a, promise, cancel, response, properties, err_1;
        var _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    setIsLoading(true);
                    return [4 /*yield*/, debouncedSearch(query)];
                case 1:
                    _a = _d.sent(), promise = _a.promise, cancel = _a.cancel;
                    _d.label = 2;
                case 2:
                    _d.trys.push([2, 4, , 5]);
                    if (!query && query === "") {
                        cancel();
                        return [2 /*return*/, []];
                    }
                    return [4 /*yield*/, promise];
                case 3:
                    response = _d.sent();
                    properties = ((_c = (_b = response === null || response === void 0 ? void 0 : response.data) === null || _b === void 0 ? void 0 : _b.data) === null || _c === void 0 ? void 0 : _c.items) || [];
                    callback(properties
                        .filter(function (property) { return (property === null || property === void 0 ? void 0 : property.id) !== (selectedProperty === null || selectedProperty === void 0 ? void 0 : selectedProperty.id); })
                        .map(function (property) { return ({
                        data: property,
                        value: property === null || property === void 0 ? void 0 : property.id,
                        label: formatAddress(property),
                    }); }));
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _d.sent();
                    setIsLoading(false);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var handleAutocompleteChange = function (option) {
        onChange(option === null || option === void 0 ? void 0 : option.value);
    };
    var handlePropertyRemove = function (propertyId) {
        onChange();
    };
    var renderOption = function (option) {
        return (_jsx(Box, { p: 1, children: _jsx(PropertyListItem, { property: option === null || option === void 0 ? void 0 : option.data, showActions: false }) }));
    };
    var getActions = function (property) {
        return [
            {
                label: t.propertySelectRemoveProperty(),
                icon: EAssets.DeleteIcon,
                onClick: function () { return handlePropertyRemove(property === null || property === void 0 ? void 0 : property.id); },
            },
        ];
    };
    var showForm = hideForm
        ? !hideForm
        : !maxItems ||
            !selectedProperty ||
            (maxItems && [selectedProperty].length < maxItems);
    return (_jsxs("div", { children: [showPropertyList && selectedProperty && !isFetching && (_jsx(Box, { mb: 3, children: _jsx(PropertyList, { properties: [selectedProperty], getActions: getActions }) })), showForm && (_jsx(AsyncAutoComplete, { loadOptions: handleLoadOptions, renderOption: renderOption, onChange: handleAutocompleteChange, placeholder: placeholder || t.propertySelectSearchPlaceholder(), isLoading: isLoading }))] }));
};

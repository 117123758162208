import { useTheme } from "@mui/material/styles";
export var SummaryStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            px: 2,
        },
        content: {
            formMainTitle: {
                color: theme.palette.text.dark,
            },
            editIcon: {
                width: "16px",
                height: "16px",
                color: theme.palette.success.main,
                cursor: "pointer",
            },
            label: {
                width: "400px",
            },
            actions: {
                mt: 2,
            },
        },
    };
};

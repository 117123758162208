import {
  EPaymentOrderOwnerPaymentMethod,
  EPaymentOrderType,
  ETransferType,
  IPaymentTransaction,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { IPaymentRequest } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { IBeneficiaryReportPayout } from "@rentiohq/shared-frontend/dist/types/report.types";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { round, sum } from "lodash";

const getFromRentsForType = (params: {
  payout: IBeneficiaryReportPayout;
  linkedPayouts: IPaymentRequest[];
  types: EPaymentOrderType[];
}): number => {
  const { payout, linkedPayouts, types } = params;

  // Only get from rents if the payment is rent
  if (payout.paymentRequestItems[0].type !== EPaymentOrderType.Rent) {
    return 0;
  }

  // No from rents if manually paid
  if (payout.paidManuallyAt) {
    return 0;
  }

  const allLinkedCostsWithType = linkedPayouts.filter(x => {
    const { paymentOrder } = x.paymentRequestItems[0];
    if (!paymentOrder) {
      return false;
    }

    return (
      // Only from rents
      paymentOrder.ownerPaymentMethod ===
        EPaymentOrderOwnerPaymentMethod.FromRent &&
      // Correct type
      types.includes(paymentOrder.type)
    );
  });

  const result = allLinkedCostsWithType.map(x => {
    const firstPayoutTransaction = x.paymentTransactions?.find(
      (currentTransaction: IPaymentTransaction) =>
        currentTransaction.transferType === ETransferType.TransferPayout,
    );

    return firstPayoutTransaction?.amount || 0;
  });

  return sum(result);
};

const calculateNumbers = (params: {
  payout: IBeneficiaryReportPayout;
  linkedPayouts?: IPaymentRequest[];
}) => {
  const { payout, linkedPayouts } = params;

  const revenue = payout.originalAmount;
  const payoutAmount = payout.paidManuallyAt
    ? payout.originalAmount
    : payout.paymentTransactions[0]?.amount;
  const fromRentAmount = revenue - payoutAmount;

  if (!linkedPayouts) {
    return {
      revenue,
      payoutAmount,

      commissionAmount: undefined,
      managementAmount: undefined,
      otherAmount: undefined,
    };
  }

  const commissionAmount = getFromRentsForType({
    payout,
    linkedPayouts,
    types: [EPaymentOrderType.BrokerFee],
  });
  const managementAmount = getFromRentsForType({
    payout,
    linkedPayouts,
    types: [EPaymentOrderType.ManagementCosts],
  });
  const otherAmount = fromRentAmount - (commissionAmount + managementAmount);

  return {
    revenue,
    payoutAmount,

    commissionAmount,
    managementAmount,
    otherAmount: round(otherAmount),
  };
};

interface ICostProps {
  linkedPayouts?: IPaymentRequest[];
  isLoadingLinkedPayouts: boolean;

  payout: IBeneficiaryReportPayout;
  costType: "revenue" | "payout" | "management" | "commission" | "other";
}

export const Cost = (props: ICostProps) => {
  const {
    linkedPayouts,
    // isLoadingLinkedPayouts,

    costType,
    payout,
  } = props;

  const renderEmpty = () => <div>-</div>;

  const renderAmount = (amount: number | undefined) => {
    if (!amount) {
      return renderEmpty();
    }

    const formattedCost = amount !== 0 ? formatCurrency(amount) : "-";

    return (
      <div>
        {costType === "payout" ? <b>{formattedCost}</b> : formattedCost}
      </div>
    );
  };

  const numbers = calculateNumbers({
    payout,
    linkedPayouts,
  });

  switch (costType) {
    case "revenue":
      return renderAmount(numbers.revenue);

    case "payout":
      return renderAmount(numbers.payoutAmount);

    case "commission":
      if (numbers.commissionAmount) {
        return renderAmount(-numbers.commissionAmount);
      }
      return renderEmpty();

    case "management":
      if (numbers.managementAmount) {
        return renderAmount(-numbers.managementAmount);
      }
      return renderEmpty();

    case "other":
      if (numbers.otherAmount) {
        return renderAmount(-numbers.otherAmount);
      }
      return renderEmpty();

    default:
      return null;
  }
};

import { IChat } from "@rentiohq/shared-frontend/dist/types/chat.types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";

export enum EField {
  Members = "members",
  Name = "name",
  Property = "property",
}

export interface IExtraData {
  accountIds?: number[];
  hideActions?: boolean;
  contactMeMaster?: IContact;
  chat?: IChat;
}

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CONFIG } from "../../config/app.config";
import api from "../../utils/api/api.utils";
import { appendFilter } from "../../utils/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "../../utils/date.utils";
var paymentOrderInclude = [
    "paymentGroup",
    "mandate",
    "property",
    "contract",
    { payer: ["contact"] },
    { payee: ["contact"] },
];
var paymentRequestInclude = [
    "mandate",
    "property",
    { payer: ["contact"] },
    { payee: ["contact"] },
];
export var getPaymentOrdersForProperty = function (propertyId) {
    return api.get(appendFilter("/properties/".concat(propertyId, "/payment-orders"), {
        include: paymentOrderInclude,
        order: "createdAt DESC",
    }));
};
export var getPaymentOrders = function (params) {
    var skip = params.skip, _a = params.filterData, filterData = _a === void 0 ? {} : _a, _b = params.limit, limit = _b === void 0 ? CONFIG.DEFAULT_FETCH_LIMIT : _b;
    var filter = __assign({ limit: limit, skip: skip, include: paymentOrderInclude, order: "createdAt DESC" }, filterData);
    return api.get(appendFilter("/payment-orders", mapObjectWithTimeZonedDatesToUtcIsoString(filter)));
};
export var getPaymentOrder = function (id) {
    var filter = {
        include: paymentOrderInclude,
    };
    return api.get(appendFilter("/payment-orders/".concat(id), filter));
};
export var getPayeeIban = function (paymentOrderId) {
    return api.get("/payment-orders/".concat(paymentOrderId, "/payeeBankAccount"));
};
export var getPayerIban = function (paymentOrderId) {
    return api.get("/payment-orders/".concat(paymentOrderId, "/payerBankAccount"));
};
export var createPaymentOrder = function (data) {
    return api.post("/payment-orders", {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var updatePaymentOrder = function (id, data) {
    return api.patch("/payment-orders/".concat(id), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var deletePaymentOrder = function (paymentOrderId) {
    return api.delete("/payment-orders/".concat(paymentOrderId));
};
export var getPaymentRequests = function (params) {
    var skip = params.skip, _a = params.filterData, filterData = _a === void 0 ? {} : _a, _b = params.limit, limit = _b === void 0 ? CONFIG.DEFAULT_FETCH_LIMIT : _b, customTimeout = params.customTimeout;
    var filter = __assign({ limit: limit, skip: skip, order: "createdAt DESC, payoutType DESC" }, filterData);
    return api.get(appendFilter("/payment-requests", mapObjectWithTimeZonedDatesToUtcIsoString(filter)), {
        customTimeout: customTimeout,
    });
};
export var getPaymentRequest = function (id, publicId) {
    var filter = {
        include: paymentRequestInclude,
    };
    var publicIdQuery = publicId ? "&publicId=".concat(publicId) : "";
    return api.get("".concat(appendFilter("/payment-requests/".concat(id), mapObjectWithTimeZonedDatesToUtcIsoString(filter))).concat(publicIdQuery));
};
export var updatePaymentRequest = function (id, data) {
    return api.patch("/payment-requests/".concat(id), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var updatePaymentRequestInvoicedAt = function (id, data) {
    return api.post("/payment-requests/".concat(id, "/invoiced-at"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var deletePaymentRequest = function (paymentRequestId) {
    return api.delete("/payment-requests/".concat(paymentRequestId));
};
export var payPaymentRequest = function (id, ibanId, createRentioMandate) {
    return api.post("/payment-requests/".concat(id, "/pay"), {
        data: { bankaccountId: ibanId, createRentioMandate: createRentioMandate },
        isServerless: true,
    });
};
export var generateBankWirePayment = function (id) {
    return api.post("/generate-payments/".concat(id, "/mangopay-bankwire"), {
        data: {},
        isServerless: true,
    });
};
export var getMandate = function (ibanId, callbackUrl) {
    return api.post("/bankaccounts/".concat(ibanId, "/mandate"), {
        data: {
            callbackUrl: callbackUrl,
        },
        isServerless: true,
    });
};
export var deleteMandate = function (mandateId) {
    return api.delete("/mandates/".concat(mandateId));
};
export var getMollieUrl = function (paymentRequestId, callbackUrl) {
    return api.post("/generate-payments/".concat(paymentRequestId, "/mollie"), {
        data: {
            callbackUrl: callbackUrl,
        },
        isServerless: true,
    });
};
export var markThirdPartyReceivedPaymentRequest = function (paymentRequestId) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/received"));
};
export var markPaymentRequestPaid = function (params) {
    var paymentRequestId = params.paymentRequestId, manuallyPaidAt = params.manuallyPaidAt, amount = params.amount;
    return api.post("/payment-requests/".concat(paymentRequestId, "/mark-manually-paid"), {
        data: { manuallyPaidAt: manuallyPaidAt, amount: amount },
        isServerless: true,
    });
};
export var paymentRequestFollowUpMail = function (paymentRequestId, data) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/reminder/email"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var paymentRequestFollowUpApp = function (paymentRequestId, data) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/reminder/chat"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var paymentRequestFollowUpPhone = function (paymentRequestId, data) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/reminder/phone"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var paymentRequestFollowUpSms = function (paymentRequestId, data) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/reminder/sms"), {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(data),
    });
};
export var paymentRequestFollowUpRegisteredMail = function (paymentRequestId, data) {
    return api.post("/registered-mails", {
        data: mapObjectWithTimeZonedDatesToUtcIsoString(__assign({ paymentRequestId: paymentRequestId }, data)),
        isServerless: true,
    });
};
export var getMissingKYCs = function (skip, limit, propertyId) {
    if (limit === void 0) { limit = CONFIG.DEFAULT_FETCH_LIMIT; }
    var filter = {
        limit: limit,
        skip: skip,
    };
    var path = propertyId
        ? "/properties/".concat(propertyId, "/payments/missing-kyc")
        : "/payment-orders/missing-kycs";
    return api.get(appendFilter(path, filter));
};
export var sendKYCReminder = function (contactId) {
    return api.post("/contacts/".concat(contactId, "/kyc/reminder"));
};
export var sendPaymentRequestInvitation = function (paymentRequestId) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/invite"));
};
export var paymentRequestPaidIncorrectWallet = function (paymentRequestId, data) {
    return api.post("/payment-requests/".concat(paymentRequestId, "/paid-incorrect-wallet"), {
        data: __assign({ paymentRequestId: paymentRequestId }, data),
        isServerless: true,
    });
};

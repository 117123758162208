var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Chip from "@mui/material/Chip";
import MuiTab from "@mui/material/Tab";
import MuiTabs from "@mui/material/Tabs";
import { Link } from "react-router-dom";
import { TabsStyles } from "./Tabs.styles";
function a11yProps(index) {
    return {
        id: "rentio-tab-".concat(index),
        "aria-controls": "rentio-tabpanel-".concat(index),
    };
}
export var ETabOrientation;
(function (ETabOrientation) {
    ETabOrientation["Horizontal"] = "horizontal";
    ETabOrientation["Vertical"] = "vertical";
})(ETabOrientation || (ETabOrientation = {}));
export var Tabs = function (_a) {
    var tabs = _a.tabs, activeTab = _a.activeTab, _b = _a.orientation, orientation = _b === void 0 ? ETabOrientation.Horizontal : _b, handleTabChange = _a.handleTabChange;
    var sx = TabsStyles({
        isVertical: orientation === ETabOrientation.Vertical,
    });
    return (_jsx(_Fragment, { children: _jsx(MuiTabs, { orientation: orientation, value: activeTab, variant: "scrollable", onChange: handleTabChange, sx: sx.wrapper, children: tabs.map(function (tab) {
                var label = tab.label, icon = tab.icon, link = tab.link, count = tab.count, showChip = tab.showChip, tabIndex = tab.tabIndex, _a = tab.isDisabled, isDisabled = _a === void 0 ? false : _a;
                var otherProps = __assign(__assign({}, (link ? { component: Link, to: link } : {})), (icon ? { icon: icon } : {}));
                return (_jsx(MuiTab, __assign({ disabled: isDisabled, sx: sx.content.tab(tabIndex < activeTab), label: _jsxs(_Fragment, { children: [label, " ", showChip && (_jsx(Chip, { label: count, size: "small", sx: sx.content.tabChip }))] }) }, otherProps, a11yProps(tabIndex)), tabIndex));
            }) }) }));
};

import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { IPagedQuery } from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import {
  EPaymentOrderType,
  EPayoutType,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  EPaymentRequestFeature,
  EPaymentRequestStatus,
} from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import {
  addMonths,
  endOfDay,
  startOfDay,
  subDays,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { EContractStatus } from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ContractStatusChip/contractStatusChip.types";
import { STATUS_IS_ENDING_WHEN_IN_MONTHS } from "scenes/Properties/scenes/Contracts/components/ContractCard/ContractCard.utils";
import { END_RENTIO_FOLLOW_UP_DSO } from "utils/payment";

const NOW = new Date();
const START_OF_DAY = startOfDay(NOW);

export enum EPaymentRequestFilterPhase {
  Incoming = "INCOMING",
  Rentio = "RENTIO",
  Action = "ACTION",
}

export enum EPaymentRequestFilterStatus {
  New = "new",
  Approved = "approved",
  Pending = "pending",
  Partial = "partial",
  Failed = "failed",
}

export enum EPaymentRequestFilterFrom {
  PayerDirect = "PAYER_DIRECT",
  BrokerThirdParty = "BROKER_THIRD_PARTY",
}

export const getContractStatusFilterV2 = (status: EContractStatus) => {
  switch (status) {
    case EContractStatus.Ending:
      return {
        paymentOrder: {
          contract: {
            and: [
              {
                stopDate: { gte: START_OF_DAY },
              },
              {
                stopDate: {
                  lte: addMonths(START_OF_DAY, STATUS_IS_ENDING_WHEN_IN_MONTHS),
                },
              },
            ],
          },
        },
      };

    case EContractStatus.Upcoming:
      return {
        paymentOrder: { contract: { and: [{ startDate: { gte: NOW } }] } },
      };

    case EContractStatus.Running:
      return {
        paymentOrder: {
          contract: {
            and: [
              {
                startDate: { lte: NOW },
              },

              {
                stopDate: {
                  gte: addMonths(START_OF_DAY, STATUS_IS_ENDING_WHEN_IN_MONTHS),
                },
              },
            ],
          },
        },
      };

    case EContractStatus.Finished:
      return {
        paymentOrder: {
          contract: {
            and: [{ stopDate: { lte: NOW } }],
          },
        },
      };

    default:
      return undefined;
  }
};

export const getContractStatusFilter = (status: EContractStatus) => {
  switch (status) {
    case EContractStatus.Ending:
      return {
        and: [
          {
            stopDate: { gte: START_OF_DAY },
          },
          {
            stopDate: {
              lte: addMonths(START_OF_DAY, STATUS_IS_ENDING_WHEN_IN_MONTHS),
            },
          },
        ],
      };

    case EContractStatus.Upcoming:
      return {
        and: [{ startDate: { gte: NOW } }],
      };

    case EContractStatus.Running:
      return {
        and: [
          {
            startDate: { lte: NOW },
          },

          {
            stopDate: {
              gte: addMonths(START_OF_DAY, STATUS_IS_ENDING_WHEN_IN_MONTHS),
            },
          },
        ],
      };

    case EContractStatus.Finished:
      return {
        and: [{ stopDate: { lte: NOW } }],
      };

    default:
      return undefined;
  }
};

export const getPaymentTypesFilter = (paymentTypes: EPaymentOrderType[]) => {
  return {
    type: { inq: paymentTypes },
  };
};

export const getPaymentTypesFilterV2 = (paymentTypes: EPaymentOrderType[]) => {
  return {
    paymentOrder: {
      type: {
        in: paymentTypes,
      },
    },
  };
};

export const getPaymentRequestsFilter = (params: {
  propertyId?: number;
  search?: string;
  status?: EPaymentRequestFilterStatus;
  contractStatus?: EContractStatus;
  phase?: EPaymentRequestFilterPhase;
  from?: EPaymentRequestFilterFrom;
  paymentTypes?: EPaymentOrderType[];
}) => {
  const {
    propertyId,
    search,
    status,
    contractStatus,
    phase,
    from,
    paymentTypes,
  } = params;

  let filters: {}[] = [];

  if (propertyId) {
    filters.push({ requestPropertyId: propertyId });
  }

  const contractWhere = contractStatus
    ? getContractStatusFilter(contractStatus)
    : undefined;
  const paymentOrderWhere =
    paymentTypes && paymentTypes?.length > 0
      ? getPaymentTypesFilter(paymentTypes)
      : undefined;

  if (status) {
    let statuses: EPaymentRequestStatus[] = [];

    switch (status) {
      case EPaymentRequestFilterStatus.New:
        statuses = [EPaymentRequestStatus.New];

        break;

      case EPaymentRequestFilterStatus.Approved:
        statuses = [EPaymentRequestStatus.Approved];

        break;

      case EPaymentRequestFilterStatus.Pending:
        statuses = [
          EPaymentRequestStatus.Pending,
          EPaymentRequestStatus.PendingThirdParty,
          EPaymentRequestStatus.PayInPlanned,
          EPaymentRequestStatus.PaidIn,
          EPaymentRequestStatus.Transferring,
          EPaymentRequestStatus.Transferred,
          EPaymentRequestStatus.PayingOut,
        ];

        break;

      case EPaymentRequestFilterStatus.Partial:
        statuses = [EPaymentRequestStatus.Partial];

        break;

      case EPaymentRequestFilterStatus.Failed:
        statuses = [
          EPaymentRequestStatus.FailedPaidIn,
          EPaymentRequestStatus.FailedTransfer,
          EPaymentRequestStatus.FailedPayout,
          EPaymentRequestStatus.FailedKyc,
          EPaymentRequestStatus.Failed,
        ];

        break;

      default:
        break;
    }

    filters.push({
      status: { inq: statuses },
    });
  }

  if (phase) {
    switch (phase) {
      case EPaymentRequestFilterPhase.Incoming:
        filters.push({ dueDateAt: { gte: startOfDay(NOW.getTime()) } });

        break;

      case EPaymentRequestFilterPhase.Rentio:
        filters.push(
          ...[
            {
              dueDateAt: {
                gt: endOfDay(subDays(NOW, END_RENTIO_FOLLOW_UP_DSO)).getTime(),
              },
            },
            {
              dueDateAt: {
                lt: startOfDay(NOW).getTime(),
              },
            },
          ],
        );

        break;

      case EPaymentRequestFilterPhase.Action:
        filters.push({
          dueDateAt: {
            lte: endOfDay(subDays(NOW, END_RENTIO_FOLLOW_UP_DSO)).getTime(),
          },
        });

        break;
    }
  }

  if (from) {
    switch (from) {
      case EPaymentRequestFilterFrom.PayerDirect:
        filters.push({
          features: {
            nlike: `%${EPaymentRequestFeature.ThirdPartyPayInBrokerDirect}%`,
          },
        });

        break;

      case EPaymentRequestFilterFrom.BrokerThirdParty:
        filters.push({
          features: {
            like: `%${EPaymentRequestFeature.ThirdPartyPayInBrokerDirect}%`,
          },
        });

        break;

      default:
        break;
    }
  }

  return {
    order: "dueDateAt ASC",
    search,
    where: {
      or: [
        {
          and: [...filters, { status: { neq: EPaymentRequestStatus.Paid } }],
        },
        {
          and: [
            ...filters,

            { status: { eq: EPaymentRequestStatus.Paid } },

            { plannedExecutionAt: { gte: START_OF_DAY } },

            { payoutType: { eq: EPayoutType.Charge } },

            {
              features: {
                like: `%${EPaymentRequestFeature.PlannedPayOut}%`,
              },
            },
          ],
        },
      ],
    },
    contractWhere,
    paymentOrderWhere,
  };
};

export const getPaymentRequestsFilterV2 = (params: {
  propertyId?: number;
  search?: string;
  status?: EPaymentRequestFilterStatus;
  contractStatus?: EContractStatus;
  phase?: EPaymentRequestFilterPhase;
  from?: EPaymentRequestFilterFrom;
  paymentTypes?: EPaymentOrderType[];
}): IPagedQuery => {
  const {
    propertyId,
    search,
    status,
    contractStatus,
    phase,
    from,
    paymentTypes,
  } = params;

  let filters: {}[] = [];

  if (propertyId) {
    filters.push({ requestPropertyId: { eq: propertyId } });
  }

  if (contractStatus) {
    const contractStatusFilterMapped =
      getContractStatusFilterV2(contractStatus);

    if (contractStatusFilterMapped) {
      filters.push(contractStatusFilterMapped);
    }
  }

  if (paymentTypes && paymentTypes?.length > 0) {
    const paymentOrderFilterMapped = getPaymentTypesFilterV2(paymentTypes);

    if (paymentOrderFilterMapped) {
      filters.push(paymentOrderFilterMapped);
    }
  }

  if (status) {
    let statuses: EPaymentRequestStatus[] = [];

    switch (status) {
      case EPaymentRequestFilterStatus.New:
        statuses = [EPaymentRequestStatus.New];

        break;

      case EPaymentRequestFilterStatus.Approved:
        statuses = [EPaymentRequestStatus.Approved];

        break;

      case EPaymentRequestFilterStatus.Pending:
        statuses = [
          EPaymentRequestStatus.Pending,
          EPaymentRequestStatus.PendingThirdParty,
          EPaymentRequestStatus.PayInPlanned,
          EPaymentRequestStatus.PaidIn,
          EPaymentRequestStatus.Transferring,
          EPaymentRequestStatus.Transferred,
          EPaymentRequestStatus.PayingOut,
        ];

        break;

      case EPaymentRequestFilterStatus.Partial:
        statuses = [EPaymentRequestStatus.Partial];

        break;

      case EPaymentRequestFilterStatus.Failed:
        statuses = [
          EPaymentRequestStatus.FailedPaidIn,
          EPaymentRequestStatus.FailedTransfer,
          EPaymentRequestStatus.FailedPayout,
          EPaymentRequestStatus.FailedKyc,
          EPaymentRequestStatus.Failed,
        ];

        break;

      default:
        break;
    }

    filters.push({
      status: { in: statuses },
    });
  }

  if (phase) {
    switch (phase) {
      case EPaymentRequestFilterPhase.Incoming:
        filters.push({ dueDateAt: { gte: startOfDay(NOW.getTime()) } });

        break;

      case EPaymentRequestFilterPhase.Rentio:
        filters.push(
          ...[
            {
              dueDateAt: {
                gt: endOfDay(subDays(NOW, END_RENTIO_FOLLOW_UP_DSO)),
              },
            },
            {
              dueDateAt: {
                lt: startOfDay(NOW),
              },
            },
          ],
        );

        break;

      case EPaymentRequestFilterPhase.Action:
        filters.push({
          dueDateAt: {
            lte: endOfDay(subDays(NOW, END_RENTIO_FOLLOW_UP_DSO)),
          },
        });

        break;
    }
  }

  if (from) {
    switch (from) {
      case EPaymentRequestFilterFrom.PayerDirect:
        filters.push({
          features: {
            notlike: `%${EPaymentRequestFeature.ThirdPartyPayInBrokerDirect}%`,
          },
        });

        break;

      case EPaymentRequestFilterFrom.BrokerThirdParty:
        filters.push({
          features: {
            like: `%${EPaymentRequestFeature.ThirdPartyPayInBrokerDirect}%`,
          },
        });

        break;

      default:
        break;
    }
  }

  return {
    limit: CONFIG.DEFAULT_FETCH_LIMIT,
    page: 1,
    sort: [{ field: "dueDateAt", method: "ASC" }],
    search,
    filter: {
      or: [
        {
          and: [...filters, { status: { neq: EPaymentRequestStatus.Paid } }],
        },
        {
          and: [
            ...filters,

            { status: { eq: EPaymentRequestStatus.Paid } },

            { plannedExecutionAt: { gte: START_OF_DAY } },

            { payoutType: { eq: EPayoutType.Charge } },

            {
              features: {
                like: `%${EPaymentRequestFeature.PlannedPayOut}%`,
              },
            },
          ],
        },
      ],
    },
  };
};

import api from "../../utils/api/api.utils";
export var getPartnerships = function () {
    return api.get("/partnerships");
};
export var requestLocationDescription = function (data) {
    return api.post("/tasks/request-property-inspection-report", {
        data: data,
        isV2: true,
    });
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { isEmpty } from "lodash";
import * as S from "./ActivityContent/ActivityContent.styled";
import { ContentMeta } from "./ContentMeta";
export var PhoneContent = function (_a) {
    var _b;
    var phone = _a.phone, phoneHasAnswered = _a.phoneHasAnswered, phonePaymentPromisedAt = _a.phonePaymentPromisedAt, phoneSummary = _a.phoneSummary;
    return (_jsxs(S.Wrapper, { children: [_jsx(ContentMeta, { meta: (_b = {},
                    _b[getLocalizedText("activity.content.phone")] = phone,
                    _b[getLocalizedText("activity.content.phone.has_answered")] = phoneHasAnswered
                        ? getLocalizedText("system.yes")
                        : getLocalizedText("system.no"),
                    _b[getLocalizedText("activity.content.phone.promised_payment_on")] = phonePaymentPromisedAt
                        ? formatDate(phonePaymentPromisedAt)
                        : undefined,
                    _b) }), !isEmpty(phoneSummary) && (_jsxs(_Fragment, { children: [_jsx(S.ContentTitle, { children: getLocalizedText("activity.content.phone.summary") }), _jsx(S.MessageWrapper, { children: phoneSummary })] }))] }));
};

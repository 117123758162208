import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { ELocale } from "@rentiohq/shared-frontend/dist/types/i18n.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Banner,
  DisplayText,
  Select,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import TenantProfileNotificationPreferenceGroup from "./components/TenantProfileNotificationPreferenceGroup";
import * as CONFIG from "./TenantProfileNotificationPreferences.config";

const MailingPreferences = () => {
  const TABS = [
    {
      key: "visitor",
      label: getLocalizedText("tenant_profile.notification.visitor.tab"),
      enabledKey: CONFIG.PREFERENCE_VISITOR_ENABLED,
      enabledByDefault: true,
      preferencesConfig: [
        {
          label: getLocalizedText(
            "tenant_profile.notification.visitor.label.nl",
          ),
          key: CONFIG.PREFERENCE_VISITOR_NL,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.visitor.placeholder",
            undefined,
            undefined,
            ELocale.NL,
          ),
        },
        {
          label: getLocalizedText(
            "tenant_profile.notification.visitor.label.fr",
          ),
          key: CONFIG.PREFERENCE_VISITOR_FR,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.visitor.placeholder",
            undefined,
            undefined,
            ELocale.FR,
          ),
        },
        {
          label: getLocalizedText(
            "tenant_profile.notification.visitor.label.en",
          ),
          key: CONFIG.PREFERENCE_VISITOR_EN,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.visitor.placeholder",
            undefined,
            undefined,
            ELocale.EN,
          ),
        },
      ],
    },

    {
      key: "not_eligible",
      label: getLocalizedText("tenant_profile.notification.not_eligible.tab"),
      enabledKey: CONFIG.PREFERENCE_NOT_ELIGIBLE_ENABLED,
      enabledByDefault: true,
      preferencesConfig: [
        {
          label: getLocalizedText(
            "tenant_profile.notification.not_eligible.label.nl",
          ),
          key: CONFIG.PREFERENCE_NOT_ELIGIBLE_NL,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.not_eligible.placeholder",
            undefined,
            undefined,
            ELocale.NL,
          ),
        },
        {
          label: getLocalizedText(
            "tenant_profile.notification.not_eligible.label.fr",
          ),
          key: CONFIG.PREFERENCE_NOT_ELIGIBLE_FR,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.not_eligible.placeholder",
            undefined,
            undefined,
            ELocale.FR,
          ),
        },
        {
          label: getLocalizedText(
            "tenant_profile.notification.not_eligible.label.en",
          ),
          key: CONFIG.PREFERENCE_NOT_ELIGIBLE_EN,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.not_eligible.placeholder",
            undefined,
            undefined,
            ELocale.EN,
          ),
        },
      ],
    },
    {
      key: "plan_for_visit",
      label: getLocalizedText("tenant_profile.notification.plan_for_visit.tab"),
      enabledKey: CONFIG.PREFERENCE_PLAN_FOR_VISIT_ENABLED,
      enabledByDefault: true,
      preferencesConfig: [
        {
          label: getLocalizedText(
            "tenant_profile.notification.plan_for_visit.label.nl",
          ),
          key: CONFIG.PREFERENCE_PLAN_FOR_VISIT_NL,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.plan_for_visit.placeholder",
            undefined,
            undefined,
            ELocale.NL,
          ),
        },
        {
          label: getLocalizedText(
            "tenant_profile.notification.plan_for_visit.label.fr",
          ),
          key: CONFIG.PREFERENCE_PLAN_FOR_VISIT_FR,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.plan_for_visit.placeholder",
            undefined,
            undefined,
            ELocale.FR,
          ),
        },
        {
          label: getLocalizedText(
            "tenant_profile.notification.plan_for_visit.label.en",
          ),
          key: CONFIG.PREFERENCE_PLAN_FOR_VISIT_EN,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.plan_for_visit.placeholder",
            undefined,
            undefined,
            ELocale.EN,
          ),
        },
      ],
    },
    {
      key: "waiting_list",
      label: getLocalizedText("tenant_profile.notification.waiting_list.tab"),
      enabledKey: CONFIG.PREFERENCE_WAITING_LIST_ENABLED,
      enabledByDefault: true,
      preferencesConfig: [
        {
          label: getLocalizedText(
            "tenant_profile.notification.waiting_list.label.nl",
          ),
          key: CONFIG.PREFERENCE_WAITING_LIST_NL,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.waiting_list.placeholder",
            undefined,
            undefined,
            ELocale.NL,
          ),
        },
        {
          label: getLocalizedText(
            "tenant_profile.notification.waiting_list.label.fr",
          ),
          key: CONFIG.PREFERENCE_WAITING_LIST_FR,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.waiting_list.placeholder",
            undefined,
            undefined,
            ELocale.FR,
          ),
        },
        {
          label: getLocalizedText(
            "tenant_profile.notification.waiting_list.label.en",
          ),
          key: CONFIG.PREFERENCE_WAITING_LIST_EN,
          defaultValue: getLocalizedText(
            "tenant_profile.notification.waiting_list.placeholder",
            undefined,
            undefined,
            ELocale.EN,
          ),
        },
      ],
    },
  ];

  // State
  const [activeTabKey, setActiveTabKey] = React.useState<string | undefined>();

  // Render
  const activeTab = TABS.find(x => x.key === activeTabKey);
  return (
    <>
      <Banner
        hasDismiss={false}
        icon="contentPaperEdit"
        title={getLocalizedText("broker.mailing_preferences.info.heading")}
      />

      <Spacer weight={ESpacerWeight.W08} />

      <Select
        id={"select_notification"}
        label={getLocalizedText("tenant_profile.notification.select.title")}
        options={[
          {
            value: undefined,
            label: "-",
          },
          ...TABS.map(x => ({
            value: x.key,
            label: x.label,
          })),
        ]}
        value={activeTabKey}
        onChange={(option: { value: string }) => {
          setActiveTabKey(option.value);
        }}
      />

      {activeTab && (
        <>
          <Spacer weight={ESpacerWeight.W32} />

          <DisplayText size={"small"}>{activeTab.label}</DisplayText>

          {/* <Spacer weight={ESpacerWeight.W02} /> */}

          <TenantProfileNotificationPreferenceGroup
            enabledKey={activeTab.enabledKey}
            enabledByDefault={activeTab.enabledByDefault}
            preferencesConfig={activeTab.preferencesConfig}
          />
        </>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default MailingPreferences;

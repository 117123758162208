import { Box } from "@rebass/grid";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import * as paymentRequestHooksV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.hooks";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { mapObjectWithTimeZonedDatesToUtcIsoString } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  DisplayText,
  ESpacings,
  Grid,
  Loading,
  Lozenge,
} from "@rentiohq/web-shared/dist/components";
import { isEqual } from "lodash";
import hash from "object-hash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  getPaymentRequestsFilter,
  getPaymentRequestsFilterV2,
} from "scenes/FollowUp/Payments/Payments.utils";
import { IPaymentRequestsGridColumnProps } from "../OpenPaymentRequests.types";
import { PaymentRequestsGridItem } from "./PaymentRequestsGridItem";

// TODO: Move to separate hook file
const useEffectIsEqual = (
  effect: React.EffectCallback,
  deps?: React.DependencyList,
) => {
  const prevDeps = usePrevious(deps);

  return React.useEffect(() => {
    if (isEqual(deps, prevDeps)) {
      return;
    }

    effect();
  }, deps);
};

const FETCH_LIMIT = 3;

const PaymentRequestsGridColumnBase = (
  props: IPaymentRequestsGridColumnProps,
) => {
  const {
    propertyId,
    search,
    phase,
    status,
    contractStatus,
    from,
    paymentTypes,
    heading,
    emptyState,
    getPaymentRequestActions,
    getPrimaryPaymentRequestActions,
  } = props;

  // const { internalModeEnabled } = useInternalMode();
  // const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);
  const isV2 = true;

  const filter = getPaymentRequestsFilter({
    propertyId,
    search,
    phase,
    status,
    contractStatus,
    from,
    paymentTypes,
  });
  const identifierV1 = hash(filter);
  const filterV2 = getPaymentRequestsFilterV2({
    propertyId,
    search,
    status,
    contractStatus,
    phase,
    from,
    paymentTypes,
  });

  // Redux
  const dispatch = useDispatch();

  const {
    items: paymentRequestsV2,
    isFetching: isFetchingV2,
    fetchError: fetchErrorV2,
    isExhausted: isExhaustedV2,
    loadMore: loadMoreV2,
  } = paymentRequestHooksV2.useInfiniteLoad({
    query: isV2 ? { ...filterV2, limit: FETCH_LIMIT } : undefined,
  });
  const { count: countV2 = 0 } = paymentRequestHooksV2.useCount({
    shouldRefetch: true,
    query: isV2 ? filterV2 : undefined,
  });

  const paymentRequestsV1 = useSelector((state: IRootStore) =>
    !isV2
      ? paymentSelectors.getPaymentRequestsByIdentifier(state, identifierV1)
      : undefined,
  );
  const isFetchingV1 = useSelector((state: IRootStore) =>
    !isV2
      ? paymentSelectors.isFetchingPaymentRequestsByIdentifier(
          state,
          identifierV1,
        )
      : undefined,
  );
  const isExhaustedV1 = useSelector((state: IRootStore) =>
    !isV2
      ? paymentSelectors.isExhaustedPaymentRequestsByIdentifier(
          state,
          identifierV1,
        )
      : undefined,
  );
  const fetchErrorV1 = useSelector((state: IRootStore) =>
    !isV2
      ? paymentSelectors.paymentRequestsByIdentifierFetchError(
          state,
          identifierV1,
        )
      : undefined,
  );
  const { count: countV1 = 0 } = useCount({
    shouldRefetch: true,
    countBase: !isV2
      ? append("/payment-requests/count", {
          where: mapObjectWithTimeZonedDatesToUtcIsoString(filter.where),
        })
      : undefined,
  });

  const paymentRequests = isV2 ? paymentRequestsV2 : paymentRequestsV1;
  const isFetching = isV2 ? isFetchingV2 : isFetchingV1;
  const isExhausted = isV2 ? isExhaustedV2 : isExhaustedV1;
  const fetchError = isV2 ? fetchErrorV2 : fetchErrorV1;
  const count = isV2 ? countV2 : countV1;

  // Data
  const loadDataV1 = (refetch: boolean) => {
    if (isV2) {
      return;
    }

    dispatch(
      paymentActions.getPaymentRequestsByIdentifier.actions.start({
        paymentRequestsIdentifier: identifierV1,
        refetch,
        filterData: filter,
        limit: FETCH_LIMIT,
      }),
    );
  };

  // Events
  const handleLoadMoreClick = () => {
    if (isV2) {
      loadMoreV2();
    } else {
      loadDataV1(false);
    }
  };

  useEffectIsEqual(() => {
    if (!isV2 && !isFetchingV1) {
      loadDataV1(true);
    }
  }, [
    isV2,
    propertyId,
    search,
    status,
    contractStatus,
    phase,
    from,
    paymentTypes,
  ]);

  const renderContent = () => {
    if (paymentRequests && paymentRequests.length > 0) {
      return (
        <>
          {paymentRequests.map(paymentRequest => (
            <PaymentRequestsGridItem
              key={paymentRequest.id}
              item={paymentRequest}
              actions={getPaymentRequestActions(paymentRequest)}
              primaryActions={getPrimaryPaymentRequestActions(
                paymentRequest,
                phase,
              )}
              phase={phase}
              isPropertyDetail={!!propertyId}
            />
          ))}

          {!isExhausted && count > paymentRequests.length && (
            <Box mt={ESpacings.base}>
              <Button
                onClick={handleLoadMoreClick}
                isSubmitting={isFetching}
                size="small"
                appearance="outline"
                isFullWidth
              >
                {getLocalizedText("system.load_more")}
              </Button>
            </Box>
          )}
        </>
      );
    }
    if (fetchError) {
      return <p>{getLocalizedText("fetch.error")}</p>;
    }

    if (isFetching) {
      return <Loading />;
    }

    return emptyState;
  };

  return (
    <div>
      <Grid alignItems="center" spacing="tight" mb={ESpacings.base}>
        <DisplayText subdued={true} space="none" size="extraSmall">
          {heading}
        </DisplayText>
        <Lozenge>{count}</Lozenge>
      </Grid>
      {renderContent()}
    </div>
  );
};

export const PaymentRequestsGridColumn = React.memo(
  PaymentRequestsGridColumnBase,
);

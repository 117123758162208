import { jsx as _jsx } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { useNavigate } from "react-router-dom";
import { getExportActivitiesSchemas, } from "../../../../forms/exportActivities";
import { MultiStepForm } from "../../../SharedForm/MultiStepForm";
import { isPerformingSubmitActionSelector, performingSubmitActionErrorSelector, performingSubmitActionResultSelector, submitActionCreator, } from "../../.../../../../forms/exportActivities/schema.exportActivities.actions";
export var ExportActivities = function (props) {
    var onClose = props.onClose, propertyId = props.propertyId, selectedTypes = props.selectedTypes, selectedDateRange = props.selectedDateRange, lastLoadedActivity = props.lastLoadedActivity;
    var navigate = useNavigate();
    // Render
    var extraData = {
        propertyId: propertyId,
        selectedTypes: selectedTypes,
        selectedDateRange: selectedDateRange,
        lastLoadedActivity: lastLoadedActivity,
    };
    return (_jsx(MultiStepForm, { formId: "export-activities", schemas: getExportActivitiesSchemas(extraData), asModal: true, withAside: false, modalProps: { onClose: onClose, shouldCloseOnOverlayClick: true, width: "medium" }, onSuccess: function () {
            navigate("/profile/export-files");
        }, isPerformingSubmitActionSelector: isPerformingSubmitActionSelector, performingSubmitActionResultSelector: performingSubmitActionResultSelector, performingSubmitActionErrorSelector: performingSubmitActionErrorSelector, submitActionCreator: submitActionCreator(extraData), submitLabel: getLocalizedText("system.export"), getPrecommitConfirm: function (params) { } }));
};

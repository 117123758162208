import { Box, Flex } from "@rebass/grid";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { EPayoutType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  EPaymentRequestFeature,
  IPaymentRequest,
} from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { differenceInCalendarDays } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getPaymentOrders } from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import {
  ActionCard,
  DisplayText,
  ESpacings,
  Lozenge,
  RentioInternalRenderer,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction, TActions } from "@rentiohq/web-shared/dist/types";
import { compact, join } from "lodash";
import { useParams } from "react-router-dom";
import { EPaymentRequestFilterPhase } from "scenes/FollowUp/Payments/Payments.utils";
import { ts } from "../../../services";
import {
  createTitle,
  getAppearanceByPhase,
  getFollowUpReason,
  getTextStyleVariationByPhase,
  isFromRent,
} from "../../../utils/payment";
import { IbanisationStatusInfo } from "./IbanisationStatusInfo";

interface IFollowUpGridItemProps {
  item: IPaymentRequest;
  phase: EPaymentRequestFilterPhase;
  actions: TActions;
  primaryActions?: IAction[];
  isPropertyDetail: boolean;
}

export const PaymentRequestsGridItem = (props: IFollowUpGridItemProps) => {
  const { item, phase, actions, primaryActions, isPropertyDetail } = props;

  const params = useParams<{ propertyId: string }>();

  const propertyId = item.requestPropertyId;

  // const { detail: property } = useDetail({
  //   id: propertyId,
  //   shouldRefetch: false,
  // });

  const paymentOrders = getPaymentOrders(item);
  const dso = differenceInCalendarDays(new Date(), item.dueDateAt);
  const hasFromRent = isFromRent(paymentOrders);

  const payeeNames = item.payeeAccount
    ? [getName(item.payeeAccount)]
    : compact(paymentOrders.map(paymentOrder => paymentOrder.payee)).map(x =>
        getName(x),
      );

  const contractId = item.paymentRequestItems[0]?.paymentOrder?.contractId;

  // Render
  return (
    <ActionCard
      key={item.id}
      appearance={getAppearanceByPhase(phase)}
      // appearance={"danger"}
      heading={
        <TextStyle>
          <DisplayText size="small">
            <TextStyle variation="code">
              {formatCurrency(
                item.amount === 0 ? item.originalAmount : item.amount,
              )}
            </TextStyle>

            {
              //
              // TODO: Remove check
              // Check if initial amount exists (this is not available in backend an ibanisation migration)
              item.initialAmount !== undefined &&
                item.payoutType === EPayoutType.Charge &&
                item.amount !== 0 &&
                item.amount < item.initialAmount && (
                  <DisplayText
                    size="extraSmall"
                    element="span"
                    preventUppercase={true}
                  >
                    {` ${getLocalizedText(
                      "payments.follow_up.original_amount",
                      {
                        value: formatCurrency(item.initialAmount),
                      },
                    )}`}
                  </DisplayText>
                )
            }
          </DisplayText>
        </TextStyle>
      }
      subheading={createTitle({
        paymentOrders,
        paymentRequest: item,
        withPayoutType: true,
      })}
      actions={actions}
      reason={
        <>
          {getFollowUpReason(item)}

          {isPropertyDetail && propertyId && contractId && (
            <IbanisationStatusInfo
              propertyId={propertyId}
              contractId={contractId}
            />
          )}
        </>
      }
      propertyId={item.requestPropertyId}
      propertyAddress={item.propertyAddress}
      primaryActions={primaryActions}
      isDetailView={!!params.propertyId}
    >
      <Flex alignItems="center">
        {item.payoutType === "CHARGE" && item.payerAccount && (
          <Box flex={1} mr={ESpacings.tight}>
            <DisplayText size="small">{getName(item.payerAccount)}</DisplayText>
          </Box>
        )}
        {item.payoutType === "PAYOUT" && payeeNames.length > 0 && (
          <Box flex={1} mr={ESpacings.tight}>
            <DisplayText size="small">{join(payeeNames)}</DisplayText>
          </Box>
        )}
        {!hasFromRent && (
          <TextStyle variation={getTextStyleVariationByPhase(phase)}>
            {ts.paymentsFollowUpCardDaysMessage({ days: dso })}
          </TextStyle>
        )}
      </Flex>

      <RentioInternalRenderer items={{ status: item.status }} />

      {!!(item.features || []).includes(
        EPaymentRequestFeature.ThirdPartyPayInBrokerDirect,
      ) && (
        <Lozenge isBold={true}>
          {ts.paymentRequestFeatureThirdPartyAccountLabel()}
        </Lozenge>
      )}
    </ActionCard>
  );
};

import { IPartnership } from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.types";

export const genInitialModal = (partnerships?: IPartnership[]) => {
  if (!partnerships) {
    return "intro";
  }

  if (partnerships.length === 0) {
    return "intro";
  }

  if (partnerships.length === 1) {
    // Only has MEXP
    if (partnerships[0].locationDescriptorKey === "MEXP") {
      return "intro";
    }

    // Single partner
    return "form";
  }

  // Multiple partners
  return "partnerPicker";
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CONFIG } from "../../config/app.config";
import api from "../../utils/api/api.utils";
import { appendFilter } from "../../utils/api.utils";
export var getRegistrationDocuments = function (params) {
    var skip = params.skip, _a = params.limit, limit = _a === void 0 ? CONFIG.DEFAULT_FETCH_LIMIT : _a, filter = params.filter;
    var filterFinal = __assign({ order: ["createdAt DESC"], skip: skip, limit: limit }, filter);
    return api.get(appendFilter("/rent-registration-documents", filterFinal));
};

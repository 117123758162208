var _a;
import { isEqual } from "lodash";
import { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { usePrevious } from "../../utils/hooks.utils";
import { generateHooks } from "../generators/generator.hooks";
import { getCountStepsStart } from "./application.actions";
import { GENERATOR_PARAMS } from "./application.constants";
import { fetchCountStepsError, getCountSteps, isFetchingCountSteps, } from "./application.selectors";
export var useDetail = (_a = generateHooks(GENERATOR_PARAMS), _a.useDetail), usePaged = _a.usePaged, useUpdate = _a.useUpdate, useCount = _a.useCount, useGetAll = _a.useGetAll, useArchive = _a.useArchive, useUnarchive = _a.useUnarchive;
export var useCountSteps = function (params) {
    var query = params.query, shouldRefetch = params.shouldRefetch;
    var prevQuery = usePrevious(query);
    // Redux
    var dispatch = useDispatch();
    var countSteps = useSelector(function (state) { return getCountSteps(state, query); });
    var isFetching = useSelector(function (state) {
        return isFetchingCountSteps(state, query);
    });
    var fetchError = useSelector(function (state) {
        return fetchCountStepsError(state, query);
    });
    // Data
    var startFetching = useCallback(function () {
        if (!query) {
            return;
        }
        if (isFetching) {
            return;
        }
        if (!shouldRefetch && countSteps !== undefined) {
            return;
        }
        if (isEqual(query, prevQuery)) {
            return;
        }
        dispatch(getCountStepsStart.getAction({
            query: query,
        }));
    }, [dispatch, isFetching, query, prevQuery]);
    // Lifecycle
    useEffect(startFetching, [startFetching]);
    return {
        countSteps: countSteps,
        isFetching: isFetching,
        fetchError: fetchError,
    };
};

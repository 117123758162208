import styled, { FontWeights } from "@rentiohq/web-theme";
import { rem, transitions } from "polished";

interface IProps {
  isDragging: boolean;
  isDisabled: boolean;
  theme?: any;
}

export const StyledDragHandle = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 22px;
  padding: 0 ${({ theme }) => theme.spacing("extraTight")};

  > * {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 100%;
    height: 100%;
    fill: currentColor;
  }
`;

// @ts-ignore
export const StyledDragItem = styled.div<{
  isDisabled: boolean;
  isDragging: boolean;
}>`
  ${transitions(
    "background 150ms ease-in, border 150ms ease-in, box-shadow 150ms ease-in",
  )};
  position: relative;
  padding: ${({ theme }) => theme.spacing()}
    ${({ theme }) => theme.spacing("tight")};
  padding-left: 26px;
  ${(props: IProps) => props.isDisabled && "opacity: 0.4"}
  ${(props: IProps) => props.isDragging && "opacity: 1;"}
  background: ${({ theme, ...props }: IProps) =>
    props.isDragging ? theme.getColor("gray", 200) : theme.getColor("gray", 0)};
  box-shadow: ${(props: IProps) =>
    props.isDragging
      ? "0 0 3px 2px rgba(6, 44, 82, 0.1)"
      : "0 0 0 0 rgba(6, 44, 82, 0.1)"};
  border-bottom: 5px solid
    ${({ theme, ...props }: IProps) =>
      props.isDragging ? "transparent" : theme.getColor("white", 800)};
  color: ${({ theme }) => theme.getColor("gray", 1000)};
  font-weight: ${FontWeights.Regular};
  &:focus,
  &:hover {
    outline: none;
    background: ${({ theme }) => theme.getColor("gray", 0)};
  }

  ${StyledDragHandle} {
    color: ${({ theme, ...props }: IProps) =>
      props.isDragging
        ? theme.getColor("gray", 400)
        : theme.getColor("gray", 200)};
  }
`;

export const StyledDragItemMeta = styled.div`
  color: ${({ theme }) => theme.getColor("gray", 600)};
  font-size: ${({ theme }) => rem("14px", theme.baseFontSize)};
  font-weight: ${FontWeights.Regular};
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  column-gap: 4px;
`;

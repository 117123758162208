import { Box } from "@rebass/grid";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import { IMember } from "@rentiohq/shared-frontend/dist/types/auth.types";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  AccountList,
  Activities,
  Button,
  Card,
  ESpacings,
  Grid,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { spacing } from "@rentiohq/web-theme";
import { DocumentListItem } from "components/DetailDrawers/Drawers/RentDeposit/components/RentDepositInfo/components/DocumentRow";
import { useNavigate } from "react-router-dom";
import { ts } from "../../../../../../services";

interface IProps {
  contract: IContract;
  onMoreHistoryClick: () => void;
}

const getMemberThatCancelled = (
  idOfMember: number | undefined | null,
  members: IMember<EContractMemberTypes>[],
) => {
  if (!idOfMember) return null;
  const foundMember = members.find(member => member.account.id === idOfMember);
  return foundMember?.account;
};

export const CancelledContractInfo = ({
  contract,
  onMoreHistoryClick,
}: IProps) => {
  const navigate = useNavigate();

  const accountOfMemberThatCancelled = getMemberThatCancelled(
    contract.cancelledForAccountId,
    contract.members,
  );

  const { items: cancellationDocuments } = documentHooks.useGetAll({
    shouldRefetch: true,
    query: {
      filter: {
        contractId: {
          eq: contract.id,
        },
        categoryId: {
          eq: 21,
        },
      },
    },
  });

  return (
    <>
      {accountOfMemberThatCancelled && (
        <Card heading={getLocalizedText("contract.cancellation.who_cancelled")}>
          <AccountList accounts={[accountOfMemberThatCancelled]} />
        </Card>
      )}
      <Card heading="Data">
        <Grid>
          {contract.dateOfCancellation && (
            <Grid.Item width={[1]}>
              <Grid>
                <Grid.Item width={[1 / 4]}>
                  <TextStyle
                    style={{ marginRight: spacing("base") }}
                    variation="strong"
                  >
                    Cancelled on
                  </TextStyle>
                </Grid.Item>
                <Grid.Item>
                  <TextStyle>
                    {formatDate(contract.dateOfCancellation)}
                  </TextStyle>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          )}
          {contract.stopDate && (
            <Grid.Item width={[1]}>
              <Grid>
                <Grid.Item width={[1 / 4]}>
                  <TextStyle
                    style={{ marginRight: spacing("base") }}
                    variation="strong"
                  >
                    New enddate
                  </TextStyle>
                </Grid.Item>
                <Grid.Item>
                  <TextStyle>{formatDate(contract.stopDate)}</TextStyle>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          )}
          {contract.reasonForCancellation && (
            <Grid.Item width={[1]}>
              <Grid>
                <Grid.Item width={[1 / 4]}>
                  <TextStyle variation="strong">Reason</TextStyle>
                </Grid.Item>
                <Grid.Item>
                  <TextStyle>
                    {getLocalizedText(
                      `contract.cancellation.${contract.reasonForCancellation}`,
                    )}
                  </TextStyle>
                </Grid.Item>
              </Grid>
            </Grid.Item>
          )}
        </Grid>
      </Card>
      <Card heading={ts.system("documents")}>
        {cancellationDocuments?.map(doc => (
          <DocumentListItem key={doc.id} documentId={doc.id} />
        ))}
        {cancellationDocuments?.length === 0 &&
          getLocalizedText("documents.empty")}
      </Card>
      <Card heading={ts.system("history")} space={ESpacings.base}>
        <Box mb={ESpacings.base}>
          <Activities
            identifier={`cancelled-contract-${contract.id}`}
            filter={{
              where: {
                contractId: contract.id,
              },
            }}
            limit={3}
            isCompact={true}
            onMoreClick={onMoreHistoryClick}
          />
        </Box>
      </Card>
      <Button
        appearance="outline"
        color="red"
        isFullWidth
        style={{ marginTop: spacing("extraLoose") }}
        onClick={() => {
          navigate(
            `/properties/${contract.propertyId}/contracts/${contract.id}/delete-cancellation`,
          );
        }}
      >
        {getLocalizedText("contract.cancellation.delete_cancellation")}
      </Button>
    </>
  );
};

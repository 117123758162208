import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { payInModule } from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatIban } from "@rentiohq/shared-frontend/dist/utils/iban.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Button,
  Error,
  Grid,
  Icon,
  Loading,
  Page,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { PayInAllocationModal } from "scenes/Properties/scenes/Payments/components/PayInsFollowUp/PayInAllocationModal";
import { NumberParam } from "serialize-query-params";
import { ts } from "../../../../services";
import PaymentPayInHistory from "./components/PaymentPayInHistory";
import PaymentPayInInfo from "./components/PaymentPayInInfo";
import { EPaymentPayInTab } from "./PaymentPayIn.types";

interface IProps {
  payInId: number;
  onDrawerClose?: () => void;
}

const PaymentPayIn: React.FC<IProps> = props => {
  const { payInId, onDrawerClose } = props;

  const [queryParams, setQueryParams] = useQueryParams({
    payInToAllocateIdDetail: NumberParam,
    payInId: NumberParam,
  });

  // State
  const [tab, setTab] = React.useState(EPaymentPayInTab.Info);

  // Redux
  const {
    detail: payIn,
    isFetching,
    fetchError,
  } = payInModule.hooks.useDetail({
    shouldRefetch: true,
    id: payInId,
  });

  const propertyId =
    payIn?.contractInfo?.propertyId || payIn?.contract?.propertyId;

  const {
    detail: property,
    isFetching: isFetchingProperty,
    fetchError: propertyFetchError,
  } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: propertyId,
  });

  // Event handlers
  const handleStartAllocation = () => {
    setQueryParams({
      payInToAllocateIdDetail: payInId,
    });
  };

  // Render
  if (!payIn || !property) {
    if (isFetchingProperty || isFetching) {
      return <Loading />;
    }

    if (fetchError) {
      return <Error errors={[fetchError]} />;
    }

    if (propertyFetchError) {
      return <Error errors={[propertyFetchError]} />;
    }

    return null;
  }

  const allocatedAmount = payInModule.utils.allocatedAmount(payIn);
  const amountToBeAllocated = payInModule.utils.amountToBeAllocated(payIn);

  const metadata = (
    <>
      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon
            source={utils.properties.getIcon(property.typeId || 0)}
            size="small"
          />
        </Grid.Item>
        <Grid.Item>
          <Button appearance="link" url={`/properties/${propertyId}`}>
            {formatAddress(property)}
          </Button>
        </Grid.Item>
      </Grid>

      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon source="calendar" size="small" />
        </Grid.Item>
        <Grid.Item>
          {getLocalizedText("pay_in.info.payment_date", {
            date: formatDate(payIn.createdAt),
          })}
        </Grid.Item>
      </Grid>

      {payIn.bankaccount?.iban && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="questionMark" size="small" />
            </Grid.Item>
            <Grid.Item>
              {getLocalizedText("pay_in.info.virtual_iban", {
                value: formatIban(payIn.bankaccount.iban) || "",
              })}
            </Grid.Item>
          </Grid>
        </>
      )}

      {payIn.reference && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Grid spacing="extraTight" alignItems="center">
            <Grid.Item>
              <Icon source="questionMark" size="small" />
            </Grid.Item>
            <Grid.Item>
              {getLocalizedText("pay_in.info.reference", {
                value: payIn.reference,
              })}
            </Grid.Item>
          </Grid>
        </>
      )}

      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon source="questionMark" size="small" />
        </Grid.Item>
        <Grid.Item>
          {getLocalizedText("pay_in.info.status", {
            status: getLocalizedText(
              `pay_in.status.extended.${stringToSnakeCase(payIn.status)}`,
            ),
          })}
        </Grid.Item>
      </Grid>

      {/* <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon source="contact" size="small" />
        </Grid.Item>
        <Grid.Item>
          {getLocalizedText("pay_in.info.payer.author", {
            value: formatIban(payIn.authorName) || "",
          })}
        </Grid.Item>
      </Grid>

      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon source="billStack" size="small" />
        </Grid.Item>
        <Grid.Item>
          {getLocalizedText("pay_in.info.payer.iban", {
            value: formatIban(payIn.authorIban) || "",
          })}
        </Grid.Item>
      </Grid> */}
    </>
  );

  const handleInfoTabClick = () => {
    setTab(EPaymentPayInTab.Info);
  };

  const handleHistoryTabClick = () => {
    setTab(EPaymentPayInTab.History);
  };

  let tabs = [
    {
      content: ts.paymentRequestDetailTabsInfo(),
      onClick: handleInfoTabClick,
      isActive: tab === EPaymentPayInTab.Info,
    },
    {
      content: ts.paymentRequestDetailTabsHistory(),
      onClick: handleHistoryTabClick,
      isActive: tab === EPaymentPayInTab.History,
    },
  ];

  return (
    <>
      <Page
        title={getLocalizedText("pay_in.detail.title", {
          amount: formatCurrency(payIn.amount),
          allocatedAmount: formatCurrency(allocatedAmount),
          amountToBeAllocated: formatCurrency(amountToBeAllocated),
          address: formatAddress(property, false, false),
        })}
        metadata={metadata}
        tabs={tabs}
        setDocumentTitle={false}
      >
        {tab === EPaymentPayInTab.Info && (
          <PaymentPayInInfo
            payIn={payIn}
            property={property}
            setTab={setTab}
            onStartAllocation={handleStartAllocation}
            onDrawerClose={onDrawerClose}
          />
        )}

        {tab === EPaymentPayInTab.History && (
          <PaymentPayInHistory payIn={payIn} setTab={setTab} />
        )}
      </Page>

      {!!queryParams.payInToAllocateIdDetail && (
        <PayInAllocationModal
          payInId={queryParams.payInToAllocateIdDetail}
          onClose={() => {
            setQueryParams({ payInToAllocateIdDetail: undefined });
          }}
          onSuccess={() => {
            setQueryParams({
              payInToAllocateIdDetail: undefined,
              payInId: queryParams.payInToAllocateIdDetail,
            });
          }}
        />
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default PaymentPayIn;

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import * as S from "./ActivityContent/ActivityContent.styled";
import { ContentMeta } from "./ContentMeta";
export var SmsContent = function (_a) {
    var _b;
    var smsNumber = _a.smsNumber, smsContent = _a.smsContent;
    return (_jsxs(S.Wrapper, { children: [_jsx(ContentMeta, { meta: (_b = {},
                    _b[getLocalizedText("activity.content.sms.number")] = smsNumber,
                    _b) }), _jsx(S.ContentTitle, { children: getLocalizedText("activity.content.sms.content") }), _jsx(S.MessageWrapper, { children: smsContent })] }));
};

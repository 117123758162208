import { Box } from "@rebass/grid";
import { PaymentRequestFetch } from "@rentiohq/shared-frontend/dist/components/components/PaymentRequestFetch";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { payInModule } from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import { IPaymentRequest } from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.types";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EPayInStatus,
  IPayIn,
  IPayInAllocation,
} from "@rentiohq/shared-frontend/dist/types/payin.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatDateAndTime } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatIban } from "@rentiohq/shared-frontend/dist/utils/iban.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getPaymentOrders } from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import {
  Banner,
  Button,
  Card,
  DisplayText,
  ESpacings,
  Error,
  Grid,
  Icon,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { NumberParam } from "serialize-query-params";
import { createTitle } from "utils/payment";
import { EPaymentPayInTab } from "../../PaymentPayIn.types";

interface IProps {
  payIn: IPayIn;
  property: IProperty;
  setTab: (tab: EPaymentPayInTab) => void;
  onStartAllocation: () => void;
  onDrawerClose?: () => void;
}

const PaymentPayInInfo: React.FC<IProps> = props => {
  const { payIn, onStartAllocation } = props;

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const [, setQueryParams] = useQueryParams({
    payInId: NumberParam,
    payInToAllocateId: NumberParam,
    paymentRequestId: NumberParam,
  });

  // Event handlers
  const handleClickMatch = () => {
    onStartAllocation();
  };

  // Render
  const renderRow = (item: IPayInAllocation) => {
    return (
      <ResourceListItem
        boxProps={{ py: ESpacings.tight }}
        item={item}
        media={<Icon source="forRentSign" />}
        mediaSize="medium"
        actions={[
          {
            content: getLocalizedText(
              "payments.follow_up.card_actions.detail_payment_request",
            ),
            onClick: () => {
              setQueryParams({
                payInId: undefined,
                paymentRequestId: item.paymentRequestId,
              });
            },
          },
        ]}
      >
        <Grid alignItems="center" justifyContent="space-between">
          <Grid.Item>
            <PaymentRequestFetch
              id={item.paymentRequestId}
              paymentRequestV2Enabled={
                internalModeEnabled && hasPaymentV2ForBroker
              }
              renderLoading={() => <>getLocalizedText("system.loading")</>}
              renderError={(_retry: () => void, error: Error) => (
                <Error errors={[error]} />
              )}
              renderPaymentRequest={(paymentRequest: IPaymentRequest) => (
                <div>
                  {createTitle({
                    paymentRequest,
                    paymentOrders: getPaymentOrders({
                      paymentRequestItems: paymentRequest.paymentRequestItems,
                    }),
                  })}
                </div>
              )}
            />

            <Spacer weight={ESpacerWeight.W04} />

            <div>
              <TextStyle variation={"subdued"}>
                {getLocalizedText(`pay_in.linking_type.${item.linkingType}`)}
                {" - "}
                {formatDateAndTime(item.createdAt)}
              </TextStyle>
            </div>
          </Grid.Item>

          <Grid.Item>
            <DisplayText size="medium" element="div">
              <TextStyle
                variation="code"
                element="div"
                style={{ textAlign: "right" }}
              >
                {formatCurrency(item.amount)}
              </TextStyle>
            </DisplayText>
          </Grid.Item>
        </Grid>
      </ResourceListItem>
    );
  };

  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  return (
    <>
      {payIn.authorName && (
        <Card
          heading={renderCardHeader(getLocalizedText("system.payer"))}
          space={ESpacings.base}
        >
          <ResourceListItem
            boxProps={{ py: ESpacings.tight }}
            item={payIn}
            media={<Icon source="profile" />}
            mediaSize="medium"
          >
            <Grid alignItems="center" justifyContent="space-between">
              <Grid.Item>
                <div>{payIn.authorName}</div>
                <TextStyle variation="subdued" element="div">
                  {formatIban(payIn.authorIban)}
                </TextStyle>
              </Grid.Item>
            </Grid>
          </ResourceListItem>
        </Card>
      )}

      {payIn.paymentPayInAllocations &&
      payIn.paymentPayInAllocations.length > 0 ? (
        <Card
          heading={renderCardHeader(
            getLocalizedText("pay_in.detail.section.allocations.title"),
          )}
          space={ESpacings.base}
        >
          <ResourceList
            items={payIn.paymentPayInAllocations}
            renderItem={renderRow}
          />
        </Card>
      ) : (
        <Banner
          icon="alertDiamond"
          title={getLocalizedText(
            "pay_in.detail.banner.allocations.pristine.title",
          )}
          hasDismiss={false}
        >
          {getLocalizedText("pay_in.detail.banner.allocations.pristine.info")}
        </Banner>
      )}

      {payIn.status !== EPayInStatus.Matched && (
        <Box mt={ESpacings.loose}>
          <Button appearance="outline" onClick={handleClickMatch}>
            {getLocalizedText(
              `pay_in.cta.match_${
                payInModule.utils.isPristine(payIn) ? "pristine" : "balance"
              }`,
              {
                amount: formatCurrency(
                  payInModule.utils.amountToBeAllocated(payIn),
                ),
              },
            )}
          </Button>
        </Box>
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default PaymentPayInInfo;

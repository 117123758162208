import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import * as paymentRequestActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import * as beneficiaryReportSelectors from "@rentiohq/shared-frontend/dist/reduxV2/reportBeneficiary/report.beneficiary.selectors";
import { EPayoutType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  EPaymentRequestStatus,
  IPaymentRequest,
} from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { IBeneficiaryReportPayout } from "@rentiohq/shared-frontend/dist/types/report.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  AddressCell,
  Icon,
  IDataTableItem,
  TruncateTooltip,
} from "@rentiohq/web-shared/dist/components";
import { isEqual, uniq } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { Cost } from "./components/Costs";
import { PayoutInfo } from "./components/PayoutInfo";

const getDateBestEffort = (payout: IBeneficiaryReportPayout): string =>
  formatDate(payout.payoutDate || payout.paidAt || payout.createdAt);

export const getItems = (params: {
  allLinkedPayouts: {
    [payoutId: number]:
      | {
          isFetching: boolean | undefined;
          items: IPaymentRequest[] | undefined;
        }
      | undefined;
  };
}): IDataTableItem<IBeneficiaryReportPayout>[] => {
  const { allLinkedPayouts } = params;

  return [
    {
      heading: getLocalizedText("reports.beneficiary.header.beneficiary"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => (
        <TruncateTooltip width={100}>
          {payout.requestPayeeAccount
            ? payout.requestPayeeAccount.fullName ??
              `${payout.requestPayeeAccount.firstname} ${payout.requestPayeeAccount.lastname}`
            : "No requestPayeeAccount names"}
        </TruncateTooltip>
      ),
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.payout_date"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => {
        if (payout.status !== EPaymentRequestStatus.Paid) {
          return (
            <span style={{ color: "red" }}>
              {getLocalizedText("reports.beneficiary.payouts.not_paid")}
            </span>
          );
        }

        return getDateBestEffort(payout);
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.rp"),
      columnContentType: "icon",
      sortable: false,
      renderContent: payout =>
        payout.paymentRequestReported ? (
          <div style={{ marginTop: -10, padding: 0 }}>
            <Icon source="checkboxMark" size="large" color="green" />
          </div>
        ) : (
          <Icon source="close" size="small" color="red" />
        ),
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.payment_ref"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => (
        <TruncateTooltip width={100}>
          {payout.payoutReference ?? "-"}
        </TruncateTooltip>
      ),
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.payout"),
      columnContentType: "numeric",
      sortable: false,
      renderContent: payout => {
        const linkedPayouts = allLinkedPayouts[payout.id]?.items;
        const isLoadingLinkedPayouts =
          allLinkedPayouts[payout.id]?.isFetching || false;

        return (
          <Cost
            linkedPayouts={linkedPayouts}
            isLoadingLinkedPayouts={isLoadingLinkedPayouts}
            costType="payout"
            payout={payout}
          />
        );
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.revenue"),
      columnContentType: "numeric",
      sortable: false,
      renderContent: payout => {
        const linkedPayouts = allLinkedPayouts[payout.id]?.items;
        const isLoadingLinkedPayouts =
          allLinkedPayouts[payout.id]?.isFetching || false;

        return (
          <Cost
            linkedPayouts={linkedPayouts}
            isLoadingLinkedPayouts={isLoadingLinkedPayouts}
            costType="revenue"
            payout={payout}
          />
        );
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.commission"),
      columnContentType: "numeric",
      sortable: false,
      renderContent: payout => {
        const linkedPayouts = allLinkedPayouts[payout.id]?.items;
        const isLoadingLinkedPayouts =
          allLinkedPayouts[payout.id]?.isFetching || false;

        return (
          <Cost
            linkedPayouts={linkedPayouts}
            isLoadingLinkedPayouts={isLoadingLinkedPayouts}
            costType="commission"
            payout={payout}
          />
        );
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.management_fee"),
      columnContentType: "numeric",
      sortable: false,
      renderContent: payout => {
        const linkedPayouts = allLinkedPayouts[payout.id]?.items;
        const isLoadingLinkedPayouts =
          allLinkedPayouts[payout.id]?.isFetching || false;

        return (
          <Cost
            linkedPayouts={linkedPayouts}
            isLoadingLinkedPayouts={isLoadingLinkedPayouts}
            costType="management"
            payout={payout}
          />
        );
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.other"),
      columnContentType: "action",
      sortable: false,
      renderContent: payout => {
        const linkedPayouts = allLinkedPayouts[payout.id]?.items;
        const isLoadingLinkedPayouts =
          allLinkedPayouts[payout.id]?.isFetching || false;

        return (
          <div style={{ width: 100 }}>
            <Cost
              linkedPayouts={linkedPayouts}
              isLoadingLinkedPayouts={isLoadingLinkedPayouts}
              costType="other"
              payout={payout}
            />
          </div>
        );
      },
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.property"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => (
        <AddressCell
          propertyId={payout.requestPropertyId}
          address={payout.propertyAddress}
        />
      ),
    },
    {
      heading: getLocalizedText("reports.beneficiary.header.payment"),
      columnContentType: "text",
      sortable: false,
      renderContent: payout => <PayoutInfo payout={payout} />,
    },
  ];
};

const getQueryForParentId = (params: {
  payoutId: number;
  payouts: IBeneficiaryReportPayout[];
}) => {
  const { payoutId, payouts } = params;

  const payout = payouts.find(payout => payout.id === payoutId);
  if (!payout) {
    return;
  }

  return {
    page: 1,
    limit: CONFIG.DEFAULT_FETCH_LIMIT,
    filter: {
      payoutType: { eq: EPayoutType.Payout },
      parent: { eq: payout.parent },
    },
  };
};

export const useLinkedPayouts = (params: {
  // payouts?: IBeneficiaryReportPayout[];
  // selectedPayoutIds?: number[];
  payoutIdsToGetAndFetch?: number[];
  payoutIdsToGet?: number[];
}) => {
  const { payoutIdsToGetAndFetch = [], payoutIdsToGet = [] } = params;
  const prevPayoutIdsToGetAndFetch = usePrevious(payoutIdsToGetAndFetch);
  const allPayoutIds = uniq([...payoutIdsToGetAndFetch, ...payoutIdsToGet]);

  // Redux
  const dispatch = useDispatch();

  const allPayouts = useSelector(beneficiaryReportSelectors.getAllRaw);

  const linkedPayouts = useSelector((state: IRootStore) => {
    const result: {
      [payoutId: number]:
        | {
            isFetching: boolean | undefined;
            items: IPaymentRequest[] | undefined;
          }
        | undefined;
    } = {};

    for (const payoutId of allPayoutIds) {
      const query = getQueryForParentId({ payoutId, payouts: allPayouts });
      if (!query) {
        continue;
      }

      const pagedResult = paymentRequestSelectors.getPaged(state, query);
      result[payoutId] = pagedResult;
    }

    return result;
  });

  // Lifecycle
  useEffect(() => {
    if (isEqual(payoutIdsToGetAndFetch, prevPayoutIdsToGetAndFetch)) {
      return;
    }

    for (const payoutId of allPayoutIds) {
      if (linkedPayouts[payoutId]?.isFetching) {
        continue;
      }

      const query = getQueryForParentId({ payoutId, payouts: allPayouts });
      if (!query) {
        continue;
      }

      dispatch(
        paymentRequestActions.getPagedStart.getAction({
          query,
        }),
      );
    }
  }, [payoutIdsToGetAndFetch]);

  // Result
  return linkedPayouts;
};

import { Box } from "@rebass/grid";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import {
  ESpacings,
  TextStyle,
  TruncateMiddle,
} from "@rentiohq/web-shared/dist/components";
import { compact } from "lodash";

export interface IAddressCell {
  property: IProperty;
}

export const AddressCell = (props: IAddressCell) => {
  const { property } = props;

  const isRentioRef = property.reference === `RENTIO-${property?.id}`;

  return (
    <>
      <TextStyle>
        <TruncateMiddle
          input={formatAddress(property, false, false)}
          skipInitialChars={14}
        />
      </TextStyle>

      <TextStyle
        variation={"subdued"}
        element={"div"}
        style={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        }}
      >
        {`${property.zip} ${property.city}`}

        <Box mb={ESpacings.extraTight} />

        <TextStyle variation="subdued" size="small">
          {compact(
            isRentioRef
              ? [property.name, property.reference]
              : [property.reference, property.name],
          ).join(" | ")}
        </TextStyle>
      </TextStyle>
    </>
  );
};

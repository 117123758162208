import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as costCenterHooks from "@rentiohq/shared-frontend/dist/reduxV2/costCenter/costCenter.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  EPropertyMemberTypes,
  EPropertyTypeId,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMembersWithOneOfRoles } from "@rentiohq/shared-frontend/dist/utils/roles.utils";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { Fragment } from "react";
import { TSelectableItemProps } from "scenes/Properties/Properties.types";
import PropertyListItem from "../PropertyListItem";
import { PropertyListItemItem } from "../PropertyListItem/PropertyListItem.header";

interface IProps {
  property: IProperty;
  selectedParentId?: number;
  onSetParentId: (parentId?: number) => void;
  selectable?: TSelectableItemProps;
}

// Render
export const renderMembers = (
  roles: EPropertyMemberTypes[],
  property: IProperty,
) => {
  const members = getMembersWithOneOfRoles(property.members, roles);

  if (members.length === 0) {
    return (
      <PropertyListItemItem style={{ flex: 1 }}>
        <TextStyle variation={"subdued"}>
          {getLocalizedText(
            `properties.overview.property.no_${roles[0].toLowerCase()}s`,
          )}
        </TextStyle>
      </PropertyListItemItem>
    );
  }

  const tenants = (
    <TextStyle>
      {members.map(owner => (
        <Fragment key={`account-${owner.account.id}`}>
          {getName(owner.account)}
          <br />
        </Fragment>
      ))}
    </TextStyle>
  );

  return (
    <PropertyListItemItem style={{ flex: 1 }} tooltipContent={tenants}>
      {tenants}
    </PropertyListItemItem>
  );
};

export const PropertyListItemBookkeeping = (props: IProps) => {
  const { property, selectedParentId, onSetParentId, selectable } = props;

  const isGroup = property.typeId === EPropertyTypeId.Group;

  const { detail: parent } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: property.parentId,
  });

  let costCenter: string | undefined;
  if (isGroup) {
    // For group: Show own cost center
    if (property.costCenter) {
      ({ costCenter } = property);
    }
  } else {
    // For properties: Show parent's cost center
    if (parent?.costCenter) {
      ({ costCenter } = parent);
    }
  }

  // Get cost centers for the owner
  const ownerMembers = getMembersWithOneOfRoles(property.members, [
    EPropertyMemberTypes.Owner,
  ]);
  const ownerAccount = ownerMembers[0]?.account;

  const { items: costCenters } = costCenterHooks.usePaged(
    // Only fetch if it's not a group, there is no cost center yet & the parent is fetched since it the parent can also have the cost center
    ownerAccount &&
      !isGroup &&
      !costCenter &&
      ((property.parentId && parent) || !property.parentId)
      ? {
          shouldRefetch: false,
          query: {
            page: 1,
            limit: 100,
            sort: [{ field: "propertyTypeId", method: "ASC" }],
            filter: {
              accountId: { eq: ownerAccount.id },
            },
          },
        }
      : { shouldRefetch: false },
  );

  const costCenterForPropertyType = costCenters?.find(
    cc => cc.propertyTypeId === property.typeId,
  );
  if (costCenterForPropertyType) {
    costCenter = costCenterForPropertyType.name;
  }

  return (
    <PropertyListItem
      property={property}
      selectedParentId={selectedParentId}
      onSetParentId={onSetParentId}
      selectable={selectable}
    >
      <div style={{ width: 40 }} />

      <PropertyListItemItem style={{ flex: 1 }}>
        {costCenter || "-"}
      </PropertyListItemItem>

      <PropertyListItemItem style={{ flex: 1 }}>
        {property.costUnit || "-"}
      </PropertyListItemItem>

      <PropertyListItemItem style={{ flex: 1 }}>
        {!isGroup && property.bookkeepingPaidOutStartDate
          ? formatDate(property.bookkeepingPaidOutStartDate)
          : "-"}
      </PropertyListItemItem>

      {/* <div style={{ width: 40 }} />
      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.Owner}
      />
      <PropertyListItemSpacer />

      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.FinancialManager}
      />
      <PropertyListItemSpacer />

      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.TechnicalManager}
      />
      <PropertyListItemSpacer />

      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.IndexationManager}
      />
      <PropertyListItemSpacer />

      <PropertyMembersWithRole
        members={property.members}
        role={EPropertyMemberTypes.AdministrativeManager}
      /> */}
    </PropertyListItem>
  );
};

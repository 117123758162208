import { useTheme } from "@mui/material/styles";
export var FieldStyles = function () {
    var theme = useTheme();
    return {
        content: {
            infoBtn: {
                cursor: "pointer",
                color: theme.palette.success.main,
            },
        },
    };
};

var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, select, takeEvery, takeLatest } from "redux-saga/effects";
import { CONFIG } from "../../config/app.config";
import logger from "../../logger";
import * as documentApi from "../../reduxV2/documents/document.api";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralCreateFailureText, getGeneralCreateSuccessGoToDetailText, getGeneralCreateSuccessText, getGeneralRemoveFailureText, getGeneralRemoveSuccessText, getGeneralUpdateFailureText, getGeneralUpdateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import * as authActions from "../auth/auth.actions";
import * as authApi from "../auth/auth.api";
import * as authSelectors from "../auth/auth.selectors";
import * as contactActions from "./contact.actions";
import * as contactApi from "./contact.api";
import { addIban, deleteContact, deleteIban, getIbans, payoutRentDeposit, updateContact, } from "./contact.api";
import * as contactSelectors from "./contact.selectors";
import { EContactCustomId } from "./contact.types";
function getContactsStartFlow(action) {
    var payload, refetch, limit, filterData, contacts, _a, _b, data, isExhausted, unknownError_1, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                payload = action.payload;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 7]);
                refetch = payload.refetch, limit = payload.limit, filterData = payload.filterData;
                _b = (_a = contactSelectors).getContacts;
                return [4 /*yield*/, select()];
            case 2:
                contacts = _b.apply(_a, [_c.sent(), {
                        includeSupport: true,
                    }]);
                return [4 /*yield*/, call(contactApi.getContacts, refetch ? 0 : (contacts || []).length, limit, filterData)];
            case 3:
                data = (_c.sent()).data;
                isExhausted = data.length < (limit || CONFIG.DEFAULT_FETCH_LIMIT);
                return [4 /*yield*/, put(contactActions.getContacts.actions.success({
                        refetch: refetch,
                        isExhausted: isExhausted,
                        contacts: data,
                    }))];
            case 4:
                _c.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_1 = _c.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.getContacts.actions.failure({ error: error }))];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function searchContactsStartFlow(action) {
    var payload, query, data, unknownError_2, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                query = payload.query;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(contactApi.searchContacts, query)];
            case 2:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(contactActions.searchContacts.actions.success({
                        query: query,
                        contacts: data,
                    }))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_2 = _a.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.searchContacts.actions.failure({ error: error, query: query }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getContactStartFlow(action) {
    var payload, contactId, contact, _a, data, brokerId, data, data, unknownError_3, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                payload = action.payload;
                contactId = payload.contactId;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 10, , 12]);
                contact = undefined;
                _a = contactId;
                switch (_a) {
                    case EContactCustomId.Support: return [3 /*break*/, 2];
                    case EContactCustomId.Legal: return [3 /*break*/, 4];
                    case EContactCustomId.Financial: return [3 /*break*/, 4];
                    case EContactCustomId.Billing: return [3 /*break*/, 4];
                    case EContactCustomId.Me: return [3 /*break*/, 6];
                    case EContactCustomId.MeMaster: return [3 /*break*/, 6];
                }
                return [3 /*break*/, 6];
            case 2: return [4 /*yield*/, call(contactApi.getContactSupport)];
            case 3:
                data = (_b.sent()).data;
                contact = data[0];
                return [3 /*break*/, 8];
            case 4:
                brokerId = payload.brokerId;
                if (!brokerId) {
                    throw new Error("No broker id");
                }
                return [4 /*yield*/, call(contactApi.getContactBroker, brokerId, contactId)];
            case 5:
                data = (_b.sent()).data;
                contact = data;
                return [3 /*break*/, 8];
            case 6: return [4 /*yield*/, call(contactApi.getContact, contactId)];
            case 7:
                data = (_b.sent()).data;
                contact = data;
                _b.label = 8;
            case 8:
                if (!contact) {
                    throw new Error("No contact");
                }
                return [4 /*yield*/, put(contactActions.getContact.actions.success({
                        contactId: contactId,
                        contact: contact,
                    }))];
            case 9:
                _b.sent();
                return [3 /*break*/, 12];
            case 10:
                unknownError_3 = _b.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.getContact.actions.failure({ contactId: contactId, error: error }))];
            case 11:
                _b.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
function getContactsByAccountIdsStartFlow(action) {
    var payload, accountIds, data, unknownError_4, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                accountIds = payload.accountIds;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(contactApi.getContactsByAccountIds, accountIds)];
            case 2:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(contactActions.getContactsByAccountIds.actions.success({
                        accountIds: accountIds,
                        contacts: data,
                    }))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_4 = _a.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.getContactsByAccountIds.actions.failure({
                        accountIds: accountIds,
                        error: error,
                    }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function createContactStartStartFlow(action) {
    var payload, contact, sendInvite, createdContact, fetchedContact, unknownError_5, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                contact = payload.contact, sendInvite = payload.sendInvite;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                return [4 /*yield*/, call(contactApi.createContact, contact, sendInvite)];
            case 2:
                createdContact = (_a.sent()).data;
                return [4 /*yield*/, call(contactApi.getContact, createdContact.id)];
            case 3:
                fetchedContact = (_a.sent()).data;
                return [4 /*yield*/, put(contactActions.createContact.actions.success(fetchedContact))];
            case 4:
                _a.sent();
                return [4 /*yield*/, put(contactActions.getContacts.actions.start({ refetch: true }))];
            case 5:
                _a.sent();
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("contact"),
                    action: {
                        content: getGeneralCreateSuccessGoToDetailText("contact"),
                        link: "/contacts/".concat(fetchedContact.id),
                    },
                });
                return [3 /*break*/, 8];
            case 6:
                unknownError_5 = _a.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.createContact.actions.failure({ error: error }))];
            case 7:
                _a.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function createContactBrokerStartStartFlow(action) {
    var payload, customId, IDCardFrontside, IDCardBackside, createdContact, presignedUrlIdCardFront, presignedUrlIdCardBack, fetchedContact, unknownError_6, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 10, , 12]);
                customId = payload.customId, IDCardFrontside = payload.IDCardFrontside, IDCardBackside = payload.IDCardBackside;
                return [4 /*yield*/, call(contactApi.createContactBroker, payload)];
            case 2:
                createdContact = (_a.sent()).data;
                if (!(customId === EContactCustomId.Financial &&
                    IDCardFrontside &&
                    IDCardBackside)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(documentApi.uploadDocumentToS3, 
                    // TODO: Check task.saga for app support
                    IDCardFrontside)];
            case 3:
                presignedUrlIdCardFront = _a.sent();
                return [4 /*yield*/, call(documentApi.uploadDocumentToS3, 
                    // TODO: Check task.saga for app support
                    IDCardBackside)];
            case 4:
                presignedUrlIdCardBack = _a.sent();
                return [4 /*yield*/, call(contactApi.kycFinancialContact, createdContact.id, presignedUrlIdCardFront, presignedUrlIdCardBack)];
            case 5:
                _a.sent();
                _a.label = 6;
            case 6: return [4 /*yield*/, call(contactApi.getContact, createdContact.id)];
            case 7:
                fetchedContact = (_a.sent()).data;
                return [4 /*yield*/, put(contactActions.createContactBroker.actions.success({
                        customId: customId,
                        contact: fetchedContact,
                    }))];
            case 8:
                _a.sent();
                return [4 /*yield*/, put(contactActions.getContacts.actions.start({ refetch: true }))];
            case 9:
                _a.sent();
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("contact"),
                });
                return [3 /*break*/, 12];
            case 10:
                unknownError_6 = _a.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.createContact.actions.failure({ error: error }))];
            case 11:
                _a.sent();
                showAlert({
                    type: "error",
                    message: getGeneralCreateFailureText("contact"),
                });
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
function updateContactBrokerStartStartFlow(action) {
    var payload, contactId, contact, unknownError_7, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                contactId = payload.contactId, contact = payload.data;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                // Update contact
                return [4 /*yield*/, put(contactActions.updateContact.actions.start({ contactId: contactId, contact: contact }))];
            case 2:
                // Update contact
                _a.sent();
                // Refetch contacts
                return [4 /*yield*/, put(contactActions.getContacts.actions.start({ refetch: true }))];
            case 3:
                // Refetch contacts
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_7 = _a.sent();
                error = unknownError_7;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.createContact.actions.failure({ error: error }))];
            case 5:
                _a.sent();
                showAlert({
                    type: "error",
                    message: getGeneralUpdateFailureText("contact"),
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function updateContactStartFlow(action) {
    var payload, contactId, contact, updatedContact, user, _a, _b, data, unknownError_8, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                payload = action.payload;
                contactId = payload.contactId, contact = payload.contact;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 11, , 13]);
                return [4 /*yield*/, call(updateContact, contactId, contact)];
            case 2:
                updatedContact = (_c.sent()).data;
                _b = (_a = authSelectors).getUser;
                return [4 /*yield*/, select()];
            case 3:
                user = _b.apply(_a, [_c.sent()]);
                if (!user) return [3 /*break*/, 9];
                if (!(user.personalContactId === updatedContact.id)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(authApi.getUser)];
            case 4:
                data = (_c.sent()).data;
                return [4 /*yield*/, put(authActions.getUser.actions.success(data))];
            case 5:
                _c.sent();
                _c.label = 6;
            case 6:
                if (!(updatedContact.accountIds.includes(user.activeAccountId) ||
                    updatedContact.accountIds.includes(user.personalAccountId))) return [3 /*break*/, 9];
                return [4 /*yield*/, put(contactActions.getContact.actions.start({
                        contactId: EContactCustomId.MeMaster,
                    }))];
            case 7:
                _c.sent();
                return [4 /*yield*/, put(contactActions.getContact.actions.start({
                        contactId: EContactCustomId.Me,
                    }))];
            case 8:
                _c.sent();
                _c.label = 9;
            case 9: return [4 /*yield*/, put(contactActions.updateContact.actions.success({
                    contactId: contactId,
                    contact: updatedContact,
                }))];
            case 10:
                _c.sent();
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("contact"),
                });
                return [3 /*break*/, 13];
            case 11:
                unknownError_8 = _c.sent();
                error = unknownError_8;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.updateContact.actions.failure({ contactId: contactId, error: error }))];
            case 12:
                _c.sent();
                showAlert({
                    type: "error",
                    error: error,
                    message: getGeneralUpdateFailureText("contact"),
                });
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}
function kycContactStartStartFlow(action) {
    var payload, contactId, contact, idCardFront, idCardBack, _a, asBroker, updatedContact, user, _b, _c, data, presignedUrlIdCardFront, presignedUrlIdCardBack, unknownError_9, error;
    return __generator(this, function (_d) {
        switch (_d.label) {
            case 0:
                payload = action.payload;
                contactId = payload.contactId, contact = payload.contact, idCardFront = payload.idCardFront, idCardBack = payload.idCardBack, _a = payload.asBroker, asBroker = _a === void 0 ? false : _a;
                _d.label = 1;
            case 1:
                _d.trys.push([1, 11, , 13]);
                return [4 /*yield*/, call(updateContact, contactId, contact)];
            case 2:
                updatedContact = (_d.sent()).data;
                _c = (_b = authSelectors).getUser;
                return [4 /*yield*/, select()];
            case 3:
                user = _c.apply(_b, [_d.sent()]);
                if (!(user && user.personalContactId === updatedContact.id)) return [3 /*break*/, 6];
                return [4 /*yield*/, call(authApi.getUser)];
            case 4:
                data = (_d.sent()).data;
                return [4 /*yield*/, put(authActions.getUser.actions.success(data))];
            case 5:
                _d.sent();
                _d.label = 6;
            case 6: return [4 /*yield*/, call(documentApi.uploadDocumentToS3, 
                // TODO: Check task.saga for app support
                idCardFront)];
            case 7:
                presignedUrlIdCardFront = _d.sent();
                return [4 /*yield*/, call(documentApi.uploadDocumentToS3, 
                    // TODO: Check task.saga for app support
                    idCardBack)];
            case 8:
                presignedUrlIdCardBack = _d.sent();
                return [4 /*yield*/, call(contactApi.kycContact, contactId, presignedUrlIdCardFront, presignedUrlIdCardBack, asBroker)];
            case 9:
                _d.sent();
                // Success
                return [4 /*yield*/, put(contactActions.kycContact.actions.success({
                        contactId: contactId,
                        contact: updatedContact,
                    }))];
            case 10:
                // Success
                _d.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("form.kyc_receiver_as_broker.success.title"),
                });
                return [3 /*break*/, 13];
            case 11:
                unknownError_9 = _d.sent();
                error = unknownError_9;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.kycContact.actions.failure({ contactId: contactId, error: error }))];
            case 12:
                _d.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 13];
            case 13: return [2 /*return*/];
        }
    });
}
function deleteContactStartStartFlow(action) {
    var payload, contactId, unknownError_10, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                contactId = payload.contactId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(deleteContact, contactId)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(contactActions.deleteContact.actions.success({ contactId: contactId }))];
            case 3:
                _a.sent();
                showAlert({
                    type: "success",
                    message: getGeneralRemoveSuccessText("contact"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_10 = _a.sent();
                error = unknownError_10;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.deleteContact.actions.failure({ contactId: contactId, error: error }))];
            case 5:
                _a.sent();
                showAlert({
                    type: "success",
                    message: getGeneralRemoveFailureText("contact"),
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getContactForAccountId(accountId) {
    var cachedContact, _a, _b, _c, _d;
    return __generator(this, function (_e) {
        switch (_e.label) {
            case 0:
                _b = (_a = contactSelectors).getContact;
                return [4 /*yield*/, select()];
            case 1:
                cachedContact = _b.apply(_a, [_e.sent(), undefined,
                    accountId]);
                if (cachedContact) {
                    return [2 /*return*/, cachedContact];
                }
                // Fetch new contact
                return [4 /*yield*/, call(getContactsByAccountIdsStartFlow, contactActions.getContactsByAccountIds.actions.start({
                        accountIds: [accountId],
                    }))];
            case 2:
                // Fetch new contact
                _e.sent();
                _d = (_c = contactSelectors).getContact;
                return [4 /*yield*/, select()];
            case 3: 
            // Get contact from cache after fetching
            return [2 /*return*/, _d.apply(_c, [_e.sent(), undefined, accountId])];
        }
    });
}
function getIbansStartStartFlow(action) {
    var payload, contactId, accountId, mappedContactId, contact, data, unknownError_11, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                contactId = payload.contactId, accountId = payload.accountId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                mappedContactId = contactId;
                if (!accountId) return [3 /*break*/, 3];
                return [4 /*yield*/, call(getContactForAccountId, accountId)];
            case 2:
                contact = _a.sent();
                if (contact) {
                    mappedContactId = contact.id;
                }
                _a.label = 3;
            case 3:
                if (!mappedContactId) {
                    throw new Error("No contact");
                }
                return [4 /*yield*/, call(getIbans, mappedContactId)];
            case 4:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(contactActions.getIbans.actions.success({
                        contactId: contactId,
                        accountId: accountId,
                        ibans: data,
                    }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 8];
            case 6:
                unknownError_11 = _a.sent();
                error = unknownError_11;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.getIbans.actions.failure({ contactId: contactId, error: error }))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function createIbansStartStartFlow(action) {
    var payload, contactId, accountId, iban, onSuccess, mappedContactId, contact, data, unknownError_12, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                contactId = payload.contactId, accountId = payload.accountId, iban = payload.iban, onSuccess = payload.onSuccess;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                mappedContactId = contactId;
                if (!accountId) return [3 /*break*/, 3];
                return [4 /*yield*/, call(getContactForAccountId, accountId)];
            case 2:
                contact = _a.sent();
                if (contact) {
                    mappedContactId = contact.id;
                }
                _a.label = 3;
            case 3:
                if (!mappedContactId) {
                    throw new Error("No contact");
                }
                return [4 /*yield*/, call(addIban, iban, mappedContactId)];
            case 4:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(contactActions.addIban.actions.success({
                        contactId: contactId,
                        accountId: accountId,
                        iban: data,
                    }))];
            case 5:
                _a.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(data.id);
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("iban"),
                });
                return [3 /*break*/, 8];
            case 6:
                unknownError_12 = _a.sent();
                error = unknownError_12;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.addIban.actions.failure({ contactId: contactId, accountId: accountId, error: error }))];
            case 7:
                _a.sent();
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function deleteIbansStartStartFlow(action) {
    var payload, contactId, ibanId, unknownError_13, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                contactId = payload.contactId, ibanId = payload.ibanId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(deleteIban, contactId, ibanId)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(contactActions.deleteIban.actions.success({
                        contactId: contactId,
                        ibanId: ibanId,
                    }))];
            case 3:
                _a.sent();
                showAlert({
                    type: "success",
                    message: getGeneralRemoveSuccessText("iban"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_13 = _a.sent();
                error = unknownError_13;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.deleteIban.actions.failure({ error: error }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function addIbanForRentDepositPayoutStartFlow(action) {
    var payload, iban, _a, ibanId, contractId, onSuccess, ibanIdToUse, dataIban, dataPayoutRentDeposit, unknownError_14, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                payload = action.payload;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                iban = payload.iban, _a = payload.ibanId, ibanId = _a === void 0 ? -1 : _a, contractId = payload.contractId, onSuccess = payload.onSuccess;
                ibanIdToUse = ibanId;
                if (!iban) return [3 /*break*/, 3];
                return [4 /*yield*/, call(addIban, iban)];
            case 2:
                dataIban = (_b.sent()).data;
                ibanIdToUse = dataIban.id;
                _b.label = 3;
            case 3: return [4 /*yield*/, call(payoutRentDeposit, contractId, ibanIdToUse)];
            case 4:
                dataPayoutRentDeposit = (_b.sent()).data;
                return [4 /*yield*/, put(contactActions.addIbanForRentDepositPayout.actions.success({
                        kycValidated: dataPayoutRentDeposit.kycValidated,
                    }))];
            case 5:
                _b.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(ibanIdToUse);
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("iban"),
                });
                return [3 /*break*/, 8];
            case 6:
                unknownError_14 = _b.sent();
                error = unknownError_14;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.addIbanForRentDepositPayout.actions.failure({ error: error }))];
            case 7:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function getContactsPagedStartFlow(action) {
    var payload, id, page, _a, limit, filter, skip, data, unknownError_15, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                payload = action.payload;
                id = payload.id, page = payload.page, _a = payload.limit, limit = _a === void 0 ? CONFIG.DEFAULT_FETCH_LIMIT : _a, filter = payload.filter;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 4, , 6]);
                skip = (page - 1) * limit;
                return [4 /*yield*/, call(contactApi.getContacts, skip, limit, filter)];
            case 2:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(contactActions.getContactsPaged.actions.success({
                        id: id,
                        data: data,
                        page: page,
                    }))];
            case 3:
                _b.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_15 = _b.sent();
                error = unknownError_15;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.getContactsPaged.actions.failure({
                        id: id,
                        page: page,
                        error: error,
                    }))];
            case 5:
                _b.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function breakUserConnectionStartFlow(action) {
    var payload, contactId, data, unknownError_16, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                contactId = payload.contactId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(contactApi.breakUserConnection, contactId)];
            case 2:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(contactActions.breakUserConnection.actions.success({
                        contactId: contactId,
                        account: data,
                    }))];
            case 3:
                _a.sent();
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("Contact"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_16 = _a.sent();
                error = unknownError_16;
                logger.logError({ error: error });
                return [4 /*yield*/, put(contactActions.breakUserConnection.actions.failure({ contactId: contactId, error: error }))];
            case 5:
                _a.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(contactActions.getContacts.types.START, getContactsStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.searchContacts.types.START, searchContactsStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeEvery(contactActions.getContact.types.START, getContactStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeEvery(contactActions.getContactsByAccountIds.types.START, getContactsByAccountIdsStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.createContact.types.START, createContactStartStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.createContactBroker.types.START, createContactBrokerStartStartFlow)];
            case 6:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.updateContactBroker.types.START, updateContactBrokerStartStartFlow)];
            case 7:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.updateContact.types.START, updateContactStartFlow)];
            case 8:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.kycContact.types.START, kycContactStartStartFlow)];
            case 9:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.deleteContact.types.START, deleteContactStartStartFlow)];
            case 10:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.getIbans.types.START, getIbansStartStartFlow)];
            case 11:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.addIban.types.START, createIbansStartStartFlow)];
            case 12:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.deleteIban.types.START, deleteIbansStartStartFlow)];
            case 13:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.addIbanForRentDepositPayout.types.START, addIbanForRentDepositPayoutStartFlow)];
            case 14:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.getContactsPaged.types.START, getContactsPagedStartFlow)];
            case 15:
                _a.sent();
                return [4 /*yield*/, takeLatest(contactActions.breakUserConnection.types.START, breakUserConnectionStartFlow)];
            case 16:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;

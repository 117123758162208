import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
} from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.actions";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import { ECountIdentifier } from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import { IContact } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import { TContactType } from "@rentiohq/web-shared/dist/types";
import {
  Form,
  Grid,
  Page,
  Pagination,
  SplitButton,
  Stack,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { NumberParam, StringParam, withDefault } from "serialize-query-params";
import useDeepCompareEffect from "use-deep-compare-effect";
import { UserCard } from "../../components/UserCard";
import { addCompanySchema } from "./addCompany";
import { addContactSchema } from "./addContact";
import {
  DEFAULT_FILTER,
  contactsOverviewWhereFilter,
  getFilterQuery,
} from "./Contacts.utils";

export const ContactsContainer = () => {
  const dispatch = useDispatch();

  const [contactType, setContactType] = React.useState<TContactType>("contact");
  const [showAddContactModal, setShowAddContactModal] = React.useState(false);
  const [selectedContact, setSelectedContact] = React.useState<
    IContact | undefined
  >();

  const state = getStore()?.getState();

  const submitErrors = performingSubmitActionErrorSelector(selectedContact?.id)(
    state as IPartialRootState,
  );
  const isSubmitting = isPerformingSubmitActionSelector(selectedContact?.id)(
    state as IPartialRootState,
  );

  const [queryParams, setQueryParamValue] = useQueryParams({
    query: withDefault(StringParam, ""),
    page: withDefault(NumberParam, 1),
  });

  const contactsIdentifier = React.useMemo(() => {
    if (queryParams.query.length > 0) {
      return `all-contacts-query-${queryParams.query}`;
    }
    return "all-contacts";
  }, [queryParams.query]);

  const countIdentifier = React.useMemo(() => {
    return `${ECountIdentifier.ContactsOverviewPaged}-${contactsIdentifier}`;
  }, [contactsIdentifier]);

  const countBase = React.useMemo(() => {
    let countFilter: { [key: string]: any } = {
      where: contactsOverviewWhereFilter,
    };

    if (queryParams.query) {
      countFilter.search = queryParams.query;
    }

    return append("/contacts/count", countFilter);
  }, [queryParams.query]);

  const contactsPagedByPage = useSelector((state: IRootStore) =>
    contactSelectors.getContactsPaged(
      state,
      contactsIdentifier,
      queryParams.page || 1,
    ),
  );

  const contactsPagedTotalCount = useSelector((state: IRootStore) =>
    countSelectors.getCount(state, countIdentifier),
  );

  const isFetching = useSelector((state: IRootStore) =>
    contactSelectors.isFetchingContactsForPage(
      state,
      contactsIdentifier,
      queryParams.page || 1,
    ),
  );

  const fetchData = () => {
    const filter = getFilterQuery(queryParams, DEFAULT_FILTER);
    dispatch(
      contactActions.getContactsPaged.actions.start({
        id: contactsIdentifier,
        page: queryParams.page || 1,
        filter,
      }),
    );
  };

  const handleEdit = (contact: IContact) => {
    setSelectedContact(contact);
    setShowAddContactModal(true);
  };

  const handleDelete = (contact: IContact) => {};

  useDeepCompareEffect(() => {
    fetchData();
  }, [queryParams]);

  React.useEffect(() => {
    document.title = `Rentio | ${getLocalizedText(
      "tenant.contact.page_title",
    )}`;
  }, []);

  React.useEffect(() => {
    dispatch(
      countActions.getCount.actions.start({
        countIdentifier,
        countBase,
      }),
    );
  }, [queryParams.query]);

  React.useEffect(() => {
    if (submitErrors) {
      enqueueSnackbar({
        message: submitErrors.message,
        variant: "error",
      });
    }
  }, [submitErrors]);

  const handlePageClick = (page: number) => {
    setQueryParamValue({ page });
  };

  const handleModalClose = () => setShowAddContactModal(false);

  const initialPage = queryParams.page || 1;
  const pageCount = contactsPagedTotalCount
    ? Math.ceil(contactsPagedTotalCount / 20)
    : 0;

  const renderContent = () => {
    return (
      <Grid container spacing={1}>
        {contactsPagedByPage?.map(
          contact =>
            contact && (
              <Grid item key={contact?.id} xs={12} sm={6} md={4} lg={4} xl={3}>
                <UserCard
                  contact={contact}
                  handleEdit={() => handleEdit(contact)}
                  handleDelete={() => handleDelete(contact)}
                />
              </Grid>
            ),
        )}
      </Grid>
    );
  };

  const options = [
    {
      label: getLocalizedText("contact.add"),
      onClick: () => {
        setContactType("contact");
        setSelectedContact(undefined);
        setShowAddContactModal(true);
      },
    },
    {
      label: getLocalizedText("company.company.add"),
      onClick: () => {
        setContactType("company");
        setSelectedContact(undefined);
        setShowAddContactModal(true);
      },
    },
  ];

  const isEmpty = !contactsPagedTotalCount && pageCount === 0 && !isFetching;

  return (
    <Page
      title="tenant.contact.page_title"
      isLoading={isFetching || isSubmitting}
      isEmpty={isEmpty}
      emptyHeading="tenant.contact.empty.heading"
      emptyMessage="tenant.contact.empty.details"
      actions={
        <SplitButton
          btnLabel={getLocalizedText("contact.add")}
          options={options}
        />
      }
    >
      {renderContent()}
      {pageCount > 1 && (
        <Stack direction="row" justifyContent="center" mt={3}>
          <Pagination
            color="primary"
            page={initialPage}
            count={pageCount}
            onChange={(_, page) => handlePageClick(page)}
          />
        </Stack>
      )}
      {showAddContactModal && (
        <Form
          renderAsModal={true}
          onSubmit={formData => {
            const { sendInvite, ...rest } = formData;

            if (selectedContact) {
              dispatch(
                contactActions.updateContact.actions.start({
                  contactId: selectedContact?.id,
                  contact: formData,
                }),
              );
            } else {
              dispatch(
                contactActions.createContact.actions.start({
                  contact: rest,
                  sendInvite: sendInvite === "yes",
                }),
              );
            }

            handleModalClose();
            fetchData();
          }}
          getSchema={
            contactType === "company"
              ? addCompanySchema({
                  isCompany: true,
                  showSendInvite: true,
                  contact: selectedContact,
                })
              : addContactSchema({
                  isCompany: false,
                  showSendInvite: true,
                  contact: selectedContact,
                })
          }
          asMultiStep={true}
          modalProps={{
            modalTitle: getLocalizedText(
              contactType === "company" ? "company.add" : "contact.add",
            ),
            openDialog: showAddContactModal,
            handleClose: handleModalClose,
            maxWidth: "1000px",
            width: "1000px",
          }}
        />
      )}
    </Page>
  );
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as registrationsHooks from "@rentiohq/shared-frontend/dist/redux/registration/registration.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { ArrayParam, NumberParam, StringParam, withDefault, } from "serialize-query-params";
import { Card } from "../Card";
import { DataTable } from "../DataTable";
import { EmptyState } from "../EmptyState";
import { Error } from "../Error";
import { Loading } from "../Loading";
import { Pagination } from "../Pagination";
export var Registrations = function (props) {
    var baseFilter = props.filter, renderFilters = props.renderFilters, headings = props.headings, orderMap = props.orderMap, renderRowContent = props.renderRowContent, getRowActions = props.getRowActions, columnContentTypes = props.columnContentTypes;
    var _a = useQueryParams({
        stage: ArrayParam,
        signatureStatus: ArrayParam,
        paymentStatus: ArrayParam,
        order: StringParam,
        page: withDefault(NumberParam, 1),
        registrationId: NumberParam,
    }), queryParams = _a[0], setQueryParamValue = _a[1];
    // State
    var _b = React.useState({
        field: orderMap.find(function (x) { return !!x; }) || "createdAt",
        direction: "DESC",
    }), sort = _b[0], setSort = _b[1];
    var filter = __assign(__assign({}, baseFilter), { order: "".concat(sort.field, " ").concat(sort.direction) });
    // Redux
    var _c = registrationsHooks.usePaged({
        shouldRefetch: true,
        page: queryParams.page - 1,
        filter: filter,
        withCount: true,
    }), data = _c.data, isFetching = _c.isFetching, fetchError = _c.fetchError, pageCount = _c.pageCount;
    //Event handlers
    var handlePageClick = function (_a) {
        var selected = _a.selected;
        setQueryParamValue({ page: selected + 1 });
    };
    var handleClickRow = function (index) {
        var registration = data === null || data === void 0 ? void 0 : data[index];
        if (!registration) {
            return;
        }
        setQueryParamValue({ registrationId: registration.id });
    };
    var handleSort = function (headingIndex, direction) {
        var field = orderMap[headingIndex];
        if (!field) {
            return;
        }
        setSort({ field: field, direction: direction });
    };
    // Renders
    var renderTable = function () {
        if (data && data.length > 0) {
            return (_jsx(DataTable, { onRowClick: handleClickRow, columnContentTypes: columnContentTypes, headings: headings, sortable: orderMap.map(function (x) { return !!x; }), sortColumnIndex: orderMap.indexOf(sort.field), sortDirection: sort.direction, onSort: handleSort, rows: data.map(function (registration) { return ({
                    id: registration.id,
                    content: renderRowContent(registration),
                    actions: getRowActions(registration),
                }); }) }));
        }
        if (fetchError) {
            return _jsx(Error, { errors: [fetchError], backLink: "/" });
        }
        if (isFetching) {
            return _jsx(Loading, {});
        }
        return _jsx(EmptyState, { heading: getLocalizedText("registrations.empty") });
    };
    var renderPaging = function () {
        if (pageCount <= 1) {
            return null;
        }
        return (_jsx(Pagination, { initialPage: queryParams.page - 1, pageCount: pageCount, onPageChange: handlePageClick }));
    };
    return (_jsxs(Card, { children: [renderFilters(), renderTable(), renderPaging()] }));
};

var _a, _b;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./templateDocument.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["TemplateDocumentExternalCompletion"] = "TEMPLATE_DOCUMENT_EXTERNAL_COMPLETION";
})(EActionCustom || (EActionCustom = {}));
var generateTemplateDocumentExternalCompletionActions = function () { return ({
    templateDocumentExternalCompletionStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.TemplateDocumentExternalCompletion,
        state: EActionState.Start,
    }),
    templateDocumentExternalCompletionSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.TemplateDocumentExternalCompletion,
        state: EActionState.Success,
    }),
    templateDocumentExternalCompletionFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.TemplateDocumentExternalCompletion,
        state: EActionState.Fail,
    }),
}); };
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, 
// getInfiniteLoadStart,
// getInfiniteLoadFailed,
// getInfiniteLoadSucceeded,
createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed;
export var templateDocumentExternalCompletionStart = (_b = generateTemplateDocumentExternalCompletionActions(), _b.templateDocumentExternalCompletionStart), templateDocumentExternalCompletionSucceeded = _b.templateDocumentExternalCompletionSucceeded, templateDocumentExternalCompletionFailed = _b.templateDocumentExternalCompletionFailed;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import * as activityActions from "@rentiohq/shared-frontend/dist/redux/activity/activity.actions";
import * as activitySelectors from "@rentiohq/shared-frontend/dist/redux/activity/activity.selectors";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countTypes from "@rentiohq/shared-frontend/dist/redux/count/count.types";
import { ACTIVITY_TYPES } from "@rentiohq/shared-frontend/dist/types/activity.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { endOfMonth, startOfMonth, } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Activities } from "./Activities";
var getFilterQuery = function (values, defaultFilter) {
    var activityTypes = values.activityTypes, activityDateTime = values.activityDateTime, activityQuery = values.activityQuery;
    var types = (activityTypes === null || activityTypes === void 0 ? void 0 : activityTypes.length)
        ? activityTypes.map(function (type) { return ACTIVITY_TYPES[type]; })
        : undefined;
    var newFilter = {
        skip: defaultFilter.filter.skip,
        order: defaultFilter.filter.order,
        limit: defaultFilter.filter.limit,
    };
    if (types || activityDateTime || defaultFilter.filter.where) {
        newFilter.filter = { where: { and: [] } };
        if (types) {
            newFilter.filter.where.and.push({ type: { inq: types } });
        }
        if (activityDateTime) {
            var start = activityDateTime.start, end = activityDateTime.end;
            if (start) {
                newFilter.filter.where.and.push({
                    datetime: { gte: startOfMonth(start) },
                });
            }
            if (end) {
                newFilter.filter.where.and.push({ datetime: { lte: endOfMonth(end) } });
            }
        }
        if (defaultFilter.filter.where) {
            newFilter.filter.where.and.push(defaultFilter.filter.where);
        }
    }
    if (activityQuery) {
        newFilter.filter = newFilter.filter || {};
        newFilter.filter.search = activityQuery;
    }
    return newFilter;
};
export var ActivitiesContainer = function (_a) {
    var _b = _a.identifier, identifier = _b === void 0 ? "activities" : _b, _c = _a.filter, filter = _c === void 0 ? {} : _c, _d = _a.limit, limit = _d === void 0 ? CONFIG.DEFAULT_FETCH_LIMIT : _d, props = __rest(_a, ["identifier", "filter", "limit"]);
    var dispatch = useDispatch();
    var defaultFilter = useMemo(function () { return ({
        filter: __assign({ limit: !!props.moreLink || !!props.onMoreClick ? limit + 1 : limit, skip: 0, order: "datetime DESC" }, filter),
    }); }, [filter]);
    var _e = React.useState({}), currentFilter = _e[0], setCurrentFilter = _e[1];
    var activitiesCount = useSelector(function (state) {
        return countSelectors.getCount(state, "activities-".concat(identifier));
    }) || 0;
    var activities = useSelector(function (state) {
        return activitySelectors.getActivities(state, identifier);
    });
    var activitiesFetchError = useSelector(function (state) {
        return activitySelectors.getFetchActivitiesError(state, identifier);
    });
    var isFetchingActivities = useSelector(function (state) {
        return activitySelectors.getIsFetchingActivities(state, identifier);
    });
    React.useEffect(function () {
        if (isFetchingActivities) {
            return;
        }
        fetchCount();
        fetchActivities(true);
    }, [currentFilter]);
    var fetchCount = function () {
        var where = getFilterQuery(currentFilter, defaultFilter).filter.where;
        dispatch(countActions.getCount.actions.start({
            countIdentifier: "activities-".concat(identifier),
            countBase: append(countTypes.COUNT_BASE[countTypes.ECountIdentifier.Activities], { where: where }),
        }));
    };
    var fetchActivities = function (refetch) {
        var where = getFilterQuery(currentFilter, defaultFilter).filter.where;
        dispatch(activityActions.getActivities.actions.start({
            activityIdentifier: identifier,
            refetch: refetch,
            limit: !!props.moreLink || !!props.onMoreClick ? limit + 1 : limit,
            extraFilterData: where,
        }));
    };
    if (activitiesFetchError) {
        return _jsx("p", { children: getLocalizedText("fetch.error") });
    }
    var fetchMore = function () {
        fetchActivities(false);
    };
    return (_jsx(Activities, __assign({}, props, { count: activitiesCount, fetchMore: fetchMore, limit: limit, activities: activities || [], loading: isFetchingActivities, currentFilter: currentFilter, setFilter: setCurrentFilter })));
};

import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactHooks from "@rentiohq/shared-frontend/dist/redux/contact/contact.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { EContactType } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ContactEditModal,
  Error,
  Loading,
  Page,
  ProfilePicture,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { ContactMeta } from "@rentiohq/web-shared/dist/components/ContactOverviewRow/ContactMeta";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Outlet,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../../../services/translationService";

export const ContactDetail = () => {
  const params = useParams<{ contactId: string }>();
  const contactId = params.contactId ? +params.contactId : undefined;

  const { internalModeEnabled } = useInternalMode();

  let location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const hasDuplicateContactsForBroker = useBrokerFeature(
    EBrokerFeature.DuplicateContacts,
  );

  const [modalOpen, setModalOpen] = React.useState<boolean>(false);

  const contact = useSelector((state: IRootStore) =>
    contactSelectors.getContact(state, contactId),
  );
  const isFetchingContact = useSelector((state: IRootStore) =>
    contactSelectors.getContactIsFetching(state, contactId),
  );
  const getContactError = useSelector((state: IRootStore) =>
    contactSelectors.getContactFetchError(state, contactId),
  );

  const { totalCount: duplicateContactsCount } = contactHooks.usePaged(
    hasDuplicateContactsForBroker && contact
      ? {
          shouldRefetch: true,
          id: `duplicate-contacts-count-${contact.id}`,
          withPaged: false,
          withCount: true,
          page: 1,
          limit: CONFIG.DEFAULT_FETCH_LIMIT,
          filter: {
            where: {
              and: [
                { or: [{ email: contact.email }, { phone: contact.phone }] },
                { id: { neq: contact.id } },
              ],
            },
          },
        }
      : {
          shouldRefetch: true,
        },
  );

  const { isDeletingContact, deleteContactError } = useSelector(
    (state: IRootStore) => {
      const contactState = contactId
        ? state.contact.contactsById[contactId]
        : undefined;
      return {
        isDeletingContact: contactState
          ? contactState.isDeleting || false
          : false,
        deleteContactError: contactState ? contactState.deleteError : undefined,
      };
    },
  );
  const prevIsDeletingContact = usePrevious(isDeletingContact);

  React.useEffect(() => {
    if (!contactId) {
      return;
    }

    dispatch(contactActions.getContact.actions.start({ contactId }));
  }, [contactId]);

  React.useEffect(() => {
    const wasDeleting = prevIsDeletingContact && !isDeletingContact;

    if (wasDeleting && !deleteContactError) {
      navigate("/contacts/");
    }
  }, [deleteContactError, history, isDeletingContact, prevIsDeletingContact]);

  if (getContactError) {
    return <Error errors={[getContactError as any]} backLink="/contacts" />;
  }

  if (isFetchingContact && !contact) {
    return <Loading />;
  }

  if (!contact) {
    return null;
  }

  const isNormalContact = ![
    EContactType.Personal,
    EContactType.Support,
  ].includes(contact.type);

  const metadata = <ContactMeta contact={{ ...contact }} />;

  const title = contact ? getName(contact) : "";
  const dropdownLabel = contact.isCompany
    ? t.editContactCompany()
    : t.editContact();

  const tabs = [];
  tabs.push({
    name: "general",
    content: t.contactDetailTabsInformation(),
    url: `/contacts/${contactId}/`,
    isActive: !!matchPath("/contacts/:contactId/", location.pathname),
  });
  if (isNormalContact) {
    tabs.push({
      name: "documents",
      content: t.contactDetailTabsDocuments(),
      url: `/contacts/${contactId}/documents`,
      isActive: !!matchPath(
        "/contacts/:contactId/documents",
        location.pathname,
      ),
    });
  }
  if (hasDuplicateContactsForBroker && duplicateContactsCount > 0) {
    tabs.push({
      name: "duplicateContacts",
      content: getLocalizedText("contact.detail.tabs.duplicates", {
        value: `${duplicateContactsCount}`,
      }),
      url: `/contacts/${contactId}/duplicate-contacts`,
      isActive: !!matchPath(
        "/contacts/:contactId/duplicate-contacts",
        location.pathname,
      ),
    });
  }
  if (isNormalContact) {
    tabs.push({
      name: "history",
      content: t.contactDetailTabsHistory(),
      url: `/contacts/${contactId}/history`,
      isActive: !!matchPath("/contacts/:contactId/history", location.pathname),
    });
  }
  if (contact.hasBookkeeping) {
    tabs.push({
      name: "costCenters",
      content: getLocalizedText("system.entity.cost_centers"),
      url: `/contacts/${contactId}/cost-centers`,
      isActive: !!matchPath(
        "/contacts/:contactId/cost-centers",
        location.pathname,
      ),
    });

    tabs.push({
      name: "generalLedgerAccounts",
      content: getLocalizedText("system.entity.general_ledger_accounts"),
      url: `/contacts/${contactId}/general-ledger-accounts`,
      isActive: !!matchPath(
        "/contacts/:contactId/general-ledger-accounts",
        location.pathname,
      ),
    });
  }

  const editContact = () => {
    setModalOpen(true);
  };

  const deleteContact = () => {
    // setConfirmModalOpen(true);
    confirm({
      title: getLocalizedText("contact.delete"),
      info: getLocalizedText("contact.delete.confirm.body", {
        name: contact.firstname,
      }),
      primaryActions: [
        {
          onPress: onDeleteConfirm,
          title: getLocalizedText("system.delete"),
        },
      ],
    });
  };

  const onDeleteConfirm = () => {
    handleDelete();
  };

  const handleDelete = async () => {
    if (!contactId) {
      return;
    }

    dispatch(contactActions.deleteContact.actions.start({ contactId }));
  };

  const dropdown = [];
  if (isNormalContact) {
    dropdown.push({
      content: dropdownLabel,
      onClick: editContact,
    });

    dropdown.push({
      content: <TextStyle variation="negative">{t.removeContact()}</TextStyle>,
      onClick: deleteContact,
    });
  }

  if (internalModeEnabled) {
    dropdown.push({
      // cspell:disable
      content: contact.isCompany
        ? "[Internal] Aanpassen naar contact"
        : "[Internal] Aanpassen naar bedrijf",
      // cspell:enable
      onClick: () => {
        dispatch(
          contactActions.updateContact.actions.start({
            contactId: contact.id,
            contact: {
              // @ts-ignore
              isCompany: contact.isCompany ? 0 : 1,
            },
          }),
        );
      },
    });
  }

  const handleClose = () => {
    setModalOpen(false);
  };

  const handleSubmit = () => {
    handleClose();
  };

  return (
    <Page
      title={title}
      image={contact ? <ProfilePicture profile={contact} /> : undefined}
      error={getContactError}
      breadcrumbs={{ to: "/contacts", content: t.contactDetailBreadcrumb() }}
      tabs={tabs}
      metadata={metadata}
      loading={isFetchingContact}
      dropdown={dropdown}
    >
      <Outlet />

      {contact && modalOpen && (
        <ContactEditModal
          contactId={contact.id}
          onClose={handleClose}
          onSubmit={handleSubmit}
        />
      )}
    </Page>
  );
};

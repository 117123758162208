import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import {
  EDocumentPackageSignerStatus,
  IDocumentPackage,
  IDocumentPackageSigner,
} from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import utils from "@rentiohq/web-shared/dist/utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import { ts } from "../../../services";
import { ECaptionVariant } from "../../types";
import { AccountList } from "../AccountList";
interface IProps {
  documentPackage: IDocumentPackage;
}

export const SignerList = ({ documentPackage }: IProps) => {
  const { user } = useSelf();

  const renderSignerSubtitle = (signer: IDocumentPackageSigner) => {
    const hasSigned = signer.status === EDocumentPackageSignerStatus.Finished;
    let subTitle = "";
    const subTitleColor = hasSigned
      ? ECaptionVariant.Success
      : ECaptionVariant.Warning;

    if (!!signer.usedSigningType && signer.completedAt) {
      subTitle = ts.documentPackageSignerUsedSigningType({
        values: {
          method: ts.documentPackageSigningType(signer.usedSigningType),
          date: signer.completedAt
            ? utils.date.format(signer.completedAt, "dd/MM/yyyy HH:mm")
            : "",
        },
      });
    } else {
      subTitle = `${ts.system(hasSigned ? "signed_at" : "not_signed")} ${
        hasSigned && signer.completedAt
          ? utils.date.format(signer.completedAt, "dd/MM/yyyy HH:mm")
          : ""
      }`;
    }

    return {
      subTitle,
      subTitleColor,
    };
  };

  const createSignerSubtitle = (accountIds: number[]) => {
    const signer = documentPackage?.signers.find(signer =>
      accountIds.includes(signer.accountId),
    );

    if (!signer) {
      return;
    }
    return renderSignerSubtitle(signer);
  };

  const getActions = (params: { accountIds: number[] }) => {
    const { accountIds } = params;

    if (!documentPackage) {
      return [];
    }

    const signer = documentPackage.signers.find(randomSigner =>
      accountIds.includes(randomSigner.accountId),
    );

    if (!signer) {
      return [];
    }

    const result = [];

    const isSignedInUser =
      signer.accountId === user?.activeAccountId ||
      (user?.accountIds || []).includes(signer.accountId);

    if (
      signer.connectiveUrl &&
      signer.status !== EDocumentPackageSignerStatus.Finished &&
      isSignedInUser
    ) {
      result.push({
        label: "document_package.sign_for_signer.cta.title",
        icon: EAssets.ContentPenWriteIcon,
        onClick: () => window.open(signer.connectiveUrl, "_blank"),
      });
    }
    return result;
  };

  return (
    <AccountList
      accounts={
        documentPackage
          ? (documentPackage.signers.map(signer => ({
              id: signer.accountId,
              firstname: signer.firstname,
              lastname: signer.lastname,
              company: signer.company,
            })) as IAccount[])
          : []
      }
      getActions={getActions}
      getSubtitle={createSignerSubtitle}
    />
  );
};

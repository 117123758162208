import * as rentDepositActions from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.actions";
import { ERentDepositStatus } from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { ERentDepositStageSimple } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { getSimpleStage } from "@rentiohq/web-shared/dist/utils/rentDeposit";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  ETagVariant,
  Icon,
  SpinningLoader,
  Stack,
  TabPanel,
  Tabs,
  Tag,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../../../services";
import { RentDepositInfo } from "../RentDeposit/components/RentDepositInfo";
import { RentDepositHistory } from "./components/RentDepositHistory";
import { RentDepositMetaStyles } from "./RentDeposit.styles";

export enum ERentDepositTabs {
  Info,
  History,
}

interface IProps {
  rentDepositId: number;
}

export const RentDeposit = ({ rentDepositId }: IProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sx = RentDepositMetaStyles();

  const [activeTab, setActiveTab] = React.useState(ERentDepositTabs.Info);

  const rentDeposit = useSelector((state: IRootStore) => {
    if (
      !state.rentDeposit.rentDepositsById[rentDepositId] ||
      state.rentDeposit.rentDepositsById[rentDepositId].isFetching
    ) {
      return;
    }

    return state.rentDeposit.rentDeposits[rentDepositId];
  });

  React.useEffect(() => {
    dispatch(
      rentDepositActions.getRentDeposit.actions.start({
        rentDepositId,
      }),
    );
  }, []);

  // if (rentDepositFetchError) {
  //   return <Error errors={[rentDepositFetchError]} />;
  // }

  if (!rentDeposit) {
    return <SpinningLoader />;
  }

  const simpleStage = getSimpleStage(rentDeposit.status);
  const signerCounts =
    simpleStage !== ERentDepositStageSimple.Out
      ? `${rentDeposit.signerInSigned}/${rentDeposit.signerInTotal}`
      : `${rentDeposit.signerOutSigned}/${rentDeposit.signerOutTotal}`;

  const signingStatuses = [
    ERentDepositStatus.OpenToSign,
    ERentDepositStatus.ReleaseToSign,
    ERentDepositStatus.New,
  ];

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) =>
    setActiveTab(newValue);

  const renderMeta = () => {
    return (
      <Stack direction="column" gap={1} py={1.25}>
        <Tag
          variant={
            [
              ERentDepositStatus.Established,
              ERentDepositStatus.Released,
            ].includes(rentDeposit?.status)
              ? ETagVariant.Success
              : ETagVariant.Warning
          }
        >
          {ts.followUpRentDepositsFilterStatusLabel({
            extra: { key: rentDeposit.status },
          })}
          {signingStatuses.includes(rentDeposit.status)
            ? `: ${signerCounts}`
            : ""}
        </Tag>
        <Typography variant="h3">
          {getLocalizedText("contract.type.rent_deposit")}
        </Typography>
        <Stack direction="row" gap={1} alignItems="center">
          <Icon path={EAssets.ApartmentIcon} width="16px" height="16px" />
          <Typography
            variant="body1"
            sx={sx.content.propertyTitle}
            onClick={() => navigate(`/properties/${rentDeposit.property.id}`)}
          >
            {formatAddress(rentDeposit.property)}
          </Typography>
        </Stack>
        <Stack direction="row" gap={1} alignItems="center">
          <Icon path={EAssets.CalendarIcon} width="16px" height="16px" />
          <Typography variant="body1">
            {ts.system("drawn_up_at")}:{" "}
            {utils.date.format(rentDeposit.createdAt, "dd/MM/yyyy")}
          </Typography>
        </Stack>
      </Stack>
    );
  };

  const renderInfo = () => {
    return (
      <RentDepositInfo rentDeposit={rentDeposit} setActiveTab={setActiveTab} />
    );
  };

  const renderHistory = () => {
    return <RentDepositHistory rentDeposit={rentDeposit} />;
  };

  const tabs = [
    {
      label: getLocalizedText("payment_request.detail.tabs.info"),
      tabIndex: ERentDepositTabs.Info,
    },
    {
      label: getLocalizedText("payment_request.detail.tabs.history"),
      tabIndex: ERentDepositTabs.History,
    },
  ];

  return (
    <>
      {renderMeta()}
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        handleTabChange={handleTabChange}
      />
      <TabPanel value={activeTab} activeTab={ERentDepositTabs.Info}>
        {renderInfo()}
      </TabPanel>
      <TabPanel value={activeTab} activeTab={ERentDepositTabs.History}>
        {renderHistory()}
      </TabPanel>
    </>
  );
};

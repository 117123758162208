var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./paymentRequest.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["MarkPaid"] = "MARK_PAID";
    EActionCustom["PaymentLink"] = "PAYMENT_LINK";
    EActionCustom["GrantAccessPaymentRequest"] = "GRANT_ACCESS_PAYMENT_REQUEST";
    EActionCustom["SendInvitation"] = "SEND_INVITATION";
    EActionCustom["InvoicedAt"] = "INVOICED_AT";
    EActionCustom["ReminderSms"] = "REMINDER_SMS";
    EActionCustom["ReminderPhone"] = "REMINDER_PHONE";
    EActionCustom["ReminderMail"] = "REMINDER_MAIL";
    EActionCustom["ReminderChatApp"] = "REMINDER_CHAT_APP";
    EActionCustom["ReminderRegisteredMail"] = "REMINDER_REGISTERED_MAIL";
})(EActionCustom || (EActionCustom = {}));
var generateInvoicedAtActions = function () { return ({
    invoicedAtStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.InvoicedAt,
        state: EActionState.Start,
    }),
}); };
var generateMarkPaidActions = function () { return ({
    markPaidStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkPaid,
        state: EActionState.Start,
    }),
    markPaidSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkPaid,
        state: EActionState.Success,
    }),
    markPaidFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.MarkPaid,
        state: EActionState.Fail,
    }),
}); };
var generateGetPaymentLinkActions = function () { return ({
    getPaymentLinkStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PaymentLink,
        state: EActionState.Start,
    }),
    getPaymentLinkSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PaymentLink,
        state: EActionState.Success,
    }),
    getPaymentLinkFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.PaymentLink,
        state: EActionState.Fail,
    }),
}); };
var generateGrantAccessPaymentRequestActions = function () { return ({
    grantAccessPaymentRequestStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GrantAccessPaymentRequest,
        state: EActionState.Start,
    }),
    grantAccessPaymentRequestSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GrantAccessPaymentRequest,
        state: EActionState.Success,
    }),
    grantAccessPaymentRequestFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.GrantAccessPaymentRequest,
        state: EActionState.Fail,
    }),
}); };
var sendPaymentRequestInvitationActions = function () { return ({
    sendPaymentRequestInvitationStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.SendInvitation,
        state: EActionState.Start,
    }),
    sendPaymentRequestInvitationSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.SendInvitation,
        state: EActionState.Success,
    }),
    sendPaymentRequestInvitationFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.SendInvitation,
        state: EActionState.Fail,
    }),
}); };
var generateReminderSmsActions = function () { return ({
    reminderSmsStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderSms,
        state: EActionState.Start,
    }),
    reminderSmsSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderSms,
        state: EActionState.Success,
    }),
    reminderSmsFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderSms,
        state: EActionState.Fail,
    }),
}); };
var generateReminderPhoneActions = function () { return ({
    reminderPhoneStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderPhone,
        state: EActionState.Start,
    }),
    reminderPhoneSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderPhone,
        state: EActionState.Success,
    }),
    reminderPhoneFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderPhone,
        state: EActionState.Fail,
    }),
}); };
var generateReminderMailActions = function () { return ({
    reminderMailStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderMail,
        state: EActionState.Start,
    }),
    reminderMailSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderMail,
        state: EActionState.Success,
    }),
    reminderMailFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderMail,
        state: EActionState.Fail,
    }),
}); };
var generateReminderChatAppActions = function () { return ({
    reminderChatAppStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderChatApp,
        state: EActionState.Start,
    }),
    reminderChatAppSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderChatApp,
        state: EActionState.Success,
    }),
    reminderChatAppFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderChatApp,
        state: EActionState.Fail,
    }),
}); };
var generateReminderRegisteredMailActions = function () { return ({
    reminderRegisteredMailStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderRegisteredMail,
        state: EActionState.Start,
    }),
    reminderRegisteredMailSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderRegisteredMail,
        state: EActionState.Success,
    }),
    reminderRegisteredMailFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ReminderRegisteredMail,
        state: EActionState.Fail,
    }),
}); };
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, 
// getInfiniteLoadStart,
// getInfiniteLoadFailed,
// getInfiniteLoadSucceeded,
createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed;
export var markPaidStart = (_b = generateMarkPaidActions(), _b.markPaidStart), markPaidSucceeded = _b.markPaidSucceeded, markPaidFailed = _b.markPaidFailed;
export var invoicedAtStart = generateInvoicedAtActions().invoicedAtStart;
export var getPaymentLinkStart = (_c = generateGetPaymentLinkActions(), _c.getPaymentLinkStart), getPaymentLinkSucceeded = _c.getPaymentLinkSucceeded, getPaymentLinkFailed = _c.getPaymentLinkFailed;
export var grantAccessPaymentRequestStart = (_d = generateGrantAccessPaymentRequestActions(), _d.grantAccessPaymentRequestStart), grantAccessPaymentRequestSucceeded = _d.grantAccessPaymentRequestSucceeded, grantAccessPaymentRequestFailed = _d.grantAccessPaymentRequestFailed;
export var sendPaymentRequestInvitationStart = (_e = sendPaymentRequestInvitationActions(), _e.sendPaymentRequestInvitationStart), sendPaymentRequestInvitationSucceeded = _e.sendPaymentRequestInvitationSucceeded, sendPaymentRequestInvitationFailed = _e.sendPaymentRequestInvitationFailed;
export var reminderSmsStart = (_f = generateReminderSmsActions(), _f.reminderSmsStart), reminderSmsSucceeded = _f.reminderSmsSucceeded, reminderSmsFailed = _f.reminderSmsFailed;
export var reminderPhoneStart = (_g = generateReminderPhoneActions(), _g.reminderPhoneStart), reminderPhoneSucceeded = _g.reminderPhoneSucceeded, reminderPhoneFailed = _g.reminderPhoneFailed;
export var reminderChatAppStart = (_h = generateReminderChatAppActions(), _h.reminderChatAppStart), reminderChatAppSucceeded = _h.reminderChatAppSucceeded, reminderChatAppFailed = _h.reminderChatAppFailed;
export var reminderMailStart = (_j = generateReminderMailActions(), _j.reminderMailStart), reminderMailSucceeded = _j.reminderMailSucceeded, reminderMailFailed = _j.reminderMailFailed;
export var reminderRegisteredMailStart = (_k = generateReminderRegisteredMailActions(), _k.reminderRegisteredMailStart), reminderRegisteredMailSucceeded = _k.reminderRegisteredMailSucceeded, reminderRegisteredMailFailed = _k.reminderRegisteredMailFailed;

import { Route } from "react-router-dom";
import Dashboard from "./";

export const getDashboardScene = () => {
  return (
    <Route path="">
      <Route index element={<Dashboard />} />
    </Route>
  );
};

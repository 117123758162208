import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import * as tasksHooks from "@rentiohq/shared-frontend/dist/reduxV2/task/task.hooks";
import { IPagedQuery } from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  ETaskMemberType,
  ITask,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { isAfter } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  AddressListItem,
  Button,
  Card,
  DataTable,
  EmptyState,
  Lozenge,
  ModalConfirmation,
  Page,
  Pagination,
  RepairLozenge,
  TColumnContentType,
  TSortDirection,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { compact, isEmpty, without } from "lodash";
import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import usePreference from "scenes/Settings/hooks/usePreference";
import { getArchivedBackground } from "utils/color.utils";
import * as t from "../../services/translationService";
import DueDate from "./components/DueDate";
import { TaskMembersWithRole } from "./components/TaskDetails/components/TaskMembersWithRole";
import { TasksFilter } from "./components/TasksFilter/TasksFilter";
import {
  IResultingFilter,
  getTaskStatus,
} from "./components/TasksFilter/TasksFilter.utils";
import TaskTitle from "./components/TaskTitle";
import * as TS from "./Tasks.styled";

export interface ITasksProps {
  propertyId?: number;
  asPage?: boolean;
  showCounts?: boolean;
}

export const Tasks = ({
  propertyId,
  asPage = true,
  showCounts = true,
}: ITasksProps) => {
  // State
  const [showFinishConfirmationModal, setShowFinishConfirmationModal] =
    useState<boolean>(false);

  const [selectedTask, setSelectedTask] = useState<ITask | null>(null);
  const { employees } = brokerHooks.useGetBrokerEmployees();
  const { broker } = useSelf();
  const [query, setQuery] = usePreference<string | undefined>({
    preferenceKey: "task_filter_query",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });
  const [sortPreference, setSortPreference] = usePreference<string | undefined>(
    {
      preferenceKey: "task_filter_sorting",
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    },
  );
  const [queryDebounced] = useDebounce(query);

  const setSort = ({
    field,
    direction,
  }: {
    field: string;
    direction: TSortDirection;
  }) => {
    setPage(1);
    setSortPreference(JSON.stringify({ field, direction }));
  };

  const sort = useMemo(() => {
    if (!sortPreference)
      return {
        field: "createdAt",
        direction: "DESC",
      };
    const parsed = JSON.parse(sortPreference);
    return parsed;
  }, [sortPreference]);

  const [page = 1, setPage] = usePreference<number>({
    preferenceKey: "task_filter_page",
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [filter, setFilter] = useState<IResultingFilter | undefined>();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hasKeypointRepair = brokerHooks.useBrokerFeature(
    EBrokerFeature.KeypointRepair,
  );

  const headings = compact([
    getLocalizedText("task.subject.placeholder"),
    getLocalizedText("task.category"),
    getLocalizedText("system.property"),
    getLocalizedText("task.due_date"),
    getLocalizedText("task.executor.label"),
    getLocalizedText("system.role"),
    hasKeypointRepair ? getLocalizedText("task.status.keypoint") : null,
    getLocalizedText("system.action"),
  ]);
  const orderMap = without(
    [
      "createdAt",
      "category",
      undefined,
      "dueDate",
      undefined,
      undefined,
      hasKeypointRepair ? "status" : null,
      undefined,
    ],
    null,
  );
  const sortableColumns = orderMap.map(x => !!x);
  const columnContentTypes: TColumnContentType[] = [
    ...Array(orderMap.length - 1).fill("text"),
    "action",
  ];

  //Queries
  const {
    items: tasks,
    isFetching,
    totalPages,
  } = tasksHooks.usePaged({
    shouldRefetch: true,
    query: getTasksQuery(),
  });

  const { unarchive } = tasksHooks.useUnarchive({});

  // Helpers

  function getTasksQuery(): IPagedQuery {
    return {
      page,
      limit: CONFIG.DEFAULT_FETCH_LIMIT,
      search: queryDebounced,
      // @ts-ignore
      sort: [
        {
          field: sort.field,
          method: sort.direction === "ASC" ? "ASC" : "DESC",
        },
      ],
      rolesFilter: filter?.rolesFilter,
      employeeAccountIds: filter?.employeeAccountIds?.filter(id => id !== -1),
      unassignedToEmployee: filter?.unassignedToEmployee,
      customFilters: filter?.customFilters,
      filter: getFilter(),
    };
  }

  function getFilter() {
    let taskFilter = {};

    if (propertyId) {
      taskFilter = {
        ...taskFilter,
        propertyId: { eq: propertyId },
      };
    }

    if (filter?.filter?.and && filter?.filter?.and.length > 0) {
      taskFilter = {
        ...taskFilter,
        ...filter?.filter,
      };
    }

    return isEmpty(taskFilter) ? undefined : taskFilter;
  }

  // Event handlers
  const handlePageClick = ({ selected }: { selected: number }) => {
    setPage(selected + 1);
  };

  const handleUnarchiveTask = (task: ITask) => {
    if (task.archivedAt) {
      unarchive({}, task.id);
    }
  };

  const handleFinishTask = (task: ITask) => {
    const shouldFinish = !task.finishedAt;

    const now = new Date();

    if (
      !showFinishConfirmationModal &&
      shouldFinish &&
      isAfter(task.startDate, now)
    ) {
      setShowFinishConfirmationModal(true);
      return;
    }

    if (showFinishConfirmationModal) {
      setShowFinishConfirmationModal(false);
    }

    taskModule.promises.update(dispatch, {
      id: task.id,
      data: {
        finishedAt: shouldFinish ? new Date() : null,
      },
      customSuccessMessage: getLocalizedText(
        shouldFinish ? "task.completed" : "task.activated",
      ),
    });
  };

  const renderTaskStatus = (task: ITask) => {
    const status = getTaskStatus(task);

    return (
      <Lozenge appearance="default">
        {getLocalizedText(`tasks.section.${status.toLowerCase()}`)}
      </Lozenge>
    );
  };

  const renderKeyPointRepairStatus = (task: ITask) =>
    task.status ? (
      <RepairLozenge
        text={getLocalizedText(`task.repair.keypoint.status.${task.status}`)}
        status={task.status}
      />
    ) : (
      <p>-</p>
    );

  const renderActionButton = (task: ITask) =>
    task.archivedAt ? (
      <Button
        appearance={"outline"}
        onClick={() => {
          setSelectedTask(task);
          handleUnarchiveTask(task);
        }}
      >
        {getLocalizedText("task.unarchive")}
      </Button>
    ) : (
      <Button
        appearance={task.finishedAt ? "outline" : "primary"}
        onClick={() => {
          setSelectedTask(task);
          handleFinishTask(task);
        }}
      >
        {task.finishedAt
          ? getLocalizedText("task.activate")
          : getLocalizedText("system.check")}
      </Button>
    );

  const renderRowContent = (
    task: ITask,
  ): (string | JSX.Element | undefined)[] => {
    return compact([
      <TS.TaskActive task={task}>
        <TaskTitle {...task} />
      </TS.TaskActive>,

      <TS.TaskActive task={task}>
        {task.category
          ? getLocalizedText(`task.category.${task.category.toLowerCase()}`)
          : "-"}
      </TS.TaskActive>,

      <TS.TaskActive task={task} style={{ width: "187px" }}>
        {task.propertyAddress ? <AddressListItem {...task} /> : "-"}
      </TS.TaskActive>,

      <TS.TaskActive task={task}>
        <DueDate {...task} />
      </TS.TaskActive>,

      <TS.TaskActive task={task}>
        <TaskMembersWithRole
          members={task.members}
          role={ETaskMemberType.Executor}
        />
      </TS.TaskActive>,

      <TS.TaskActive task={task}>{renderTaskStatus(task)}</TS.TaskActive>,

      hasKeypointRepair ? (
        <TS.TaskActive task={task}>
          {renderKeyPointRepairStatus(task)}
        </TS.TaskActive>
      ) : null,

      renderActionButton(task),
    ]);
  };

  const handleSort = (headingIndex: number, direction: TSortDirection) => {
    const field = orderMap[headingIndex];
    if (!field) {
      return;
    }

    setSort({ field, direction });
  };

  const navigateToDetail = (taskId: number) => {
    navigate(`/tasks/${taskId}`);
  };

  const renderTable = () => {
    return (
      <DataTable
        verticalAlign="top"
        isLoadingData={isFetching && (tasks || []).length === 0}
        emptyState={
          <EmptyState
            backgroundColor="gray"
            heading={getLocalizedText("tasks.empty")}
          />
        }
        sortable={sortableColumns}
        sortColumnIndex={orderMap.indexOf(sort.field)}
        sortDirection={sort.direction}
        headings={headings}
        onSort={handleSort}
        onRowClick={(rowIndex: number) => navigateToDetail(tasks![rowIndex].id)}
        columnContentTypes={columnContentTypes}
        rows={
          tasks && tasks.length > 0
            ? tasks.map(task => ({
                id: task.id,
                content: renderRowContent(task),
                background: task.archivedAt
                  ? getArchivedBackground()
                  : undefined,
              }))
            : []
        }
      />
    );
  };

  const renderPaging = () => {
    if (!totalPages || totalPages <= 1) {
      return null;
    }

    return (
      <Pagination
        initialPage={page - 1}
        pageCount={totalPages}
        onPageChange={handlePageClick}
      />
    );
  };

  const body = (
    <>
      <Card>
        <TasksFilter
          showCounts={showCounts}
          searchQuery={query}
          onSearchChange={searchValue => {
            setPage(1);
            setQuery(searchValue);
          }}
          onFilterChange={(filter, firstTime) => {
            if (!firstTime) setPage(1);
            setFilter(filter);
          }}
          employeeAccounts={employees}
          companyName={broker?.name}
        />
        {renderTable()}
        {renderPaging()}
      </Card>
      {showFinishConfirmationModal && (
        <ModalConfirmation
          heading={getLocalizedText("task.finish.confirm.title")}
          onConfirm={() => {
            if (!selectedTask) return;
            handleFinishTask(selectedTask);
          }}
          onCancel={() => {
            setShowFinishConfirmationModal(false);
          }}
        >
          <TextStyle>{getLocalizedText("task.finish.confirm.body")}</TextStyle>
        </ModalConfirmation>
      )}
    </>
  );

  if (!asPage) return body;

  return (
    <Page
      title={t.tasksTitle()}
      fullWidth={true}
      actions={[
        ...(hasKeypointRepair
          ? [
              {
                content: getLocalizedText("task.repair.order_keypoint_repair"),
                url: "/tasks/add?keypoint=1",
                appearance: "outline" as const,
                color: "blue" as const,
              },
            ]
          : []),
        {
          content: t.tasksAddActionTitle(),
          url: "/tasks/add",
          appearance: "primary",
        },
      ]}
    >
      {body}
    </Page>
  );
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Flex } from "@rebass/grid";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { EProduct } from "@rentiohq/shared-frontend/dist/config/app.config.types";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as invoiceHooks from "@rentiohq/shared-frontend/dist/redux/invoice/invoice.hooks";
import * as invoiceItemHooks from "@rentiohq/shared-frontend/dist/redux/invoiceItem/invoiceItem.hooks";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency, getAmountWithVat, } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { round } from "lodash";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NumberParam } from "serialize-query-params";
import { DataTable, DisplayText, Drawer, ESpacings, Grid, Icon, Loading, Lozenge, Page, TextStyle, } from "../";
import { Button } from "../Button";
import { getGroupId, getInvoiceItemsDataSource } from "./InvoiceDetail.utils";
export var InvoiceDetail = function () {
    var navigate = useNavigate();
    var params = useParams();
    var invoiceId = params.invoiceId ? +params.invoiceId : undefined;
    var _a = useQueryParams({
        invoiceItemId: NumberParam,
    }), setQueryParamValue = _a[1];
    // State
    var _b = React.useState(), selectedKey = _b[0], setSelectedKey = _b[1];
    var _c = React.useState(), invoiceItemId = _c[0], setInvoiceItemId = _c[1];
    var _d = React.useState(false), expandAllGroups = _d[0], setExpandAllGroups = _d[1];
    // Redux
    var _e = invoiceHooks.useDetail({
        shouldRefetch: false,
        id: invoiceId,
    }), invoice = _e.detail, refetchDetail = _e.refetchDetail;
    var _f = invoiceItemHooks.usePaged({
        shouldRefetch: true,
        limit: 1000,
        filter: {
            where: {
                invoiceId: invoiceId,
            },
        },
    }), invoiceItems = _f.data, isFetching = _f.isFetching, fetchError = _f.fetchError;
    var invoiceItem = invoiceItemHooks.useDetail({
        shouldRefetch: false,
        id: invoiceItemId,
    }).detail;
    // Helpers
    var invoiceItemsDataSource = getInvoiceItemsDataSource({
        invoiceItems: invoiceItems,
        selectedKey: selectedKey,
        expandAllGroups: expandAllGroups,
    });
    // Event handlers
    var handleRowClick = function (rowIndex) {
        var row = invoiceItemsDataSource === null || invoiceItemsDataSource === void 0 ? void 0 : invoiceItemsDataSource[rowIndex];
        if (!row) {
            return;
        }
        if (row.header) {
            if (selectedKey === getGroupId(row.header)) {
                setSelectedKey(undefined);
                return;
            }
            setSelectedKey(getGroupId(row.header));
            return;
        }
        if (row.item) {
            if (CONFIG.PRODUCT === EProduct.Backoffice) {
                setInvoiceItemId(row.item.id);
            }
            else {
                setQueryParamValue({ invoiceItemId: row.item.id });
            }
        }
    };
    // Render methods
    var renderItems = function () {
        if (invoiceItems && invoiceItems.length > 0) {
            return (_jsx(DataTable, { columnContentTypes: [
                    "text",
                    "text",
                    "numeric",
                    "numeric",
                    "numeric",
                    "numeric",
                    "numeric",
                ], headings: [
                    "",
                    getLocalizedText("invoices.detail.name.heading"),
                    getLocalizedText("invoices.detail.quantity.heading"),
                    getLocalizedText("invoices.detail.unit_price.heading"),
                    getLocalizedText("invoices.detail.total_excl.heading"),
                    getLocalizedText("invoices.detail.vat_percentage.heading"),
                    getLocalizedText("invoices.detail.total_incl.heading"),
                ], rows: invoiceItemsDataSource.map(function (_a) {
                    var header = _a.header, item = _a.item;
                    if (header) {
                        var isOpen = expandAllGroups || getGroupId(header) === selectedKey;
                        return {
                            id: header.id,
                            content: [
                                _jsx(Flex, { alignItems: "left", children: _jsx(Icon, { source: isOpen ? "chevronUp" : "chevronDown" }) }),
                                _jsxs(Flex, { alignItems: "center", children: [_jsx(DisplayText, { size: "small", space: "none", children: getLocalizedText("invoice.item.".concat(header.resourceType)) }), header.isPrepaid && (_jsx(Box, { ml: ESpacings.tight, children: _jsx(Lozenge, { appearance: "info", children: "Prepaid" }) }))] }),
                                _jsx(TextStyle, { variation: "code", children: header.totalAmount }),
                                header.isPrepaid ? ("-") : (_jsx(TextStyle, { variation: "code", children: formatCurrency(header.price) })),
                                header.isPrepaid ? ("-") : (_jsx(TextStyle, { variation: "code", children: formatCurrency(header.totalPrice) })),
                                header.isPrepaid ? ("-") : (_jsx(TextStyle, { variation: "code", children: "".concat(header.VATPercentage * 100, "%") })),
                                header.isPrepaid ? ("-") : (_jsx(TextStyle, { variation: "code", children: formatCurrency(round(header.totalPriceIncludingVat, 2)) })),
                            ],
                        };
                    }
                    if (item) {
                        var isOpen = expandAllGroups || getGroupId(item) === selectedKey;
                        return {
                            id: item.id,
                            content: [
                                null,
                                _jsxs(Flex, { alignItems: "center", children: [_jsxs(Flex, { flexDirection: "column", children: [_jsx(DisplayText, { size: "small", space: "none", subdued: isOpen, children: getLocalizedText("invoice.item.".concat(item.resourceType).toLowerCase()) }), _jsxs(TextStyle, { children: [item.comment, CONFIG.PRODUCT === EProduct.Backoffice &&
                                                            item.propertyId && (_jsx(TextStyle, { size: "small", children: " (Property ID: ".concat(item.propertyId, ")") }))] })] }), item.isPrepaid && (_jsx(Box, { ml: ESpacings.tight, children: _jsx(Lozenge, { appearance: "info", children: getLocalizedText("invoice.item.prepaid") }) }))] }),
                                _jsx(TextStyle, { variation: ["code", isOpen ? "subdued" : "default"], children: item.amount }),
                                _jsx(TextStyle, { variation: ["code", isOpen ? "subdued" : "default"], children: formatCurrency(item.price) }),
                                _jsx(TextStyle, { variation: ["code", isOpen ? "subdued" : "default"], children: formatCurrency(item.amount * item.price) }),
                                _jsx(TextStyle, { variation: ["code", isOpen ? "subdued" : "default"], children: "".concat(item.VATPercentage * 100, "%") }),
                                _jsx(TextStyle, { variation: ["code", isOpen ? "subdued" : "default"], children: formatCurrency(item.amount *
                                        getAmountWithVat(item.price, item.VATPercentage)) }),
                            ],
                        };
                    }
                    return {
                        id: -1,
                        content: [],
                    };
                }), onRowClick: handleRowClick }));
        }
        if (isFetching) {
            return _jsx(Loading, {});
        }
        if (fetchError) {
            return _jsx("p", { children: getLocalizedText("fetch.error") });
        }
        return _jsx("p", { children: getLocalizedText("invoice_items.empty") });
    };
    var renderHeading = function () {
        if (!invoice) {
            return null;
        }
        return (_jsxs(Grid, { mb: ESpacings.extraLoose, alignItems: "center", justifyContent: "space-between", children: [_jsxs(Grid.Item, { children: [_jsxs(DisplayText, { space: "extraTight", children: [getLocalizedText("invoices.invoice.label"), " - ", formatDate(invoice.billingDate)] }), _jsx(TextStyle, { variation: "subdued", children: getLocalizedText("invoice.license_info", {
                                startDate: formatDate(invoice.licensesStartDate),
                                endDate: formatDate(invoice.licensesEndDate),
                            }) }), _jsx(Spacer, { weight: ESpacerWeight.W08 }), invoice.manuallyPaidAt ? (_jsx(Lozenge, { isBold: true, appearance: "success", children: getLocalizedText("invoice.manually_paid_at", {
                                date: formatDate(invoice.manuallyPaidAt),
                            }) })) : (_jsx(Lozenge, { isBold: true, appearance: "error", children: getLocalizedText("invoices.status.not_paid") })), CONFIG.PRODUCT === EProduct.Backoffice &&
                            invoice.teamleaderInvoiceId && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsx(Button, { appearance: "link", onClick: function () {
                                        window.open("https://focus.teamleader.eu/invoice_detail.php?id=".concat(invoice.teamleaderInvoiceId), "_blank");
                                    }, children: getLocalizedText("invoices.detail.open_in_teamleader") })] })), CONFIG.PRODUCT === EProduct.Backoffice &&
                            !invoice.teamleaderInvoiceId && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsx(Button, { appearance: "link", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        var error_1;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    _a.trys.push([0, 2, , 3]);
                                                    return [4 /*yield*/, api.post("/billing/create-teamleader-invoice/".concat(invoice.id), {
                                                            isServerless: true,
                                                            data: {
                                                                // TODO: Pass correct count of applications
                                                                applications: 0,
                                                            },
                                                        })];
                                                case 1:
                                                    _a.sent();
                                                    refetchDetail();
                                                    showAlert({
                                                        type: "success",
                                                        message: getLocalizedText("general.success"),
                                                    });
                                                    return [3 /*break*/, 3];
                                                case 2:
                                                    error_1 = _a.sent();
                                                    showAlert({
                                                        type: "error",
                                                        error: error_1,
                                                    });
                                                    return [3 /*break*/, 3];
                                                case 3: return [2 /*return*/];
                                            }
                                        });
                                    }); }, children: getLocalizedText("invoices.detail.create_teamleader_invoice") })] })), CONFIG.PRODUCT === EProduct.Backoffice && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsx(Button, { appearance: "link", onClick: function () {
                                        setExpandAllGroups(!expandAllGroups);
                                    }, children: "Toggle expand all groups" })] }))] }), _jsxs(Grid.Item, { style: { textAlign: "right" }, children: [_jsx(Box, { mb: ESpacings.extraTight, children: _jsxs(TextStyle, { variation: "code", size: "large", children: [formatCurrency(invoice.totalPriceVATExcluded), " ", getLocalizedText("system.vat.exclusive")] }) }), _jsxs(TextStyle, { variation: ["subdued", "code"], element: "div", children: [formatCurrency(invoice.totalPriceVATIncluded), " ", getLocalizedText("system.vat.inclusive")] })] })] }));
    };
    return (_jsxs(_Fragment, { children: [_jsx(Box, { mb: ESpacings.loose, children: _jsxs(Button, { appearance: "link", onClick: function () {
                        navigate(-1);
                    }, children: ["\u2039 ", getLocalizedText("invoices.detail.back.action")] }) }), renderHeading(), renderItems(), _jsx(Drawer, { isOpen: !!invoiceItem, onClose: function () {
                    setInvoiceItemId(undefined);
                }, width: "wide", position: "right", isFullWidth: true, children: _jsx(Page, { children: _jsx("pre", { children: JSON.stringify(invoiceItem, null, 2) }) }) })] }));
};

import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import logger from "@rentiohq/shared-frontend/dist/logger";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as documentApi from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.api";
import { IDocumentMetaData } from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.types";
import * as propertyActions from "@rentiohq/shared-frontend/dist/reduxV2/property/property.actions";
import {
  ICreateGroupAction,
  IGroupActionMessage,
} from "@rentiohq/shared-frontend/dist/reduxV2/property/property.types";
import {
  EGroupActionRoleType,
  TGroupActionsLocale as TLocale,
  getRole,
} from "@rentiohq/shared-frontend/dist/types/groupAction.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { appendQueryParams } from "@rentiohq/shared-frontend/dist/utils/url.utils";
import {
  Loading,
  Modal,
  MultiStepForm,
} from "@rentiohq/web-shared/dist/components";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { showDropErrors } from "@rentiohq/web-shared/dist/utils/file";
import { isEmpty } from "lodash";
import React from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import usePreference from "scenes/Settings/hooks/usePreference";
import createDocumentForm from "../../forms/createDocument";
import { ts } from "../../services";
import AddGroupActionContent from "./components/AddGroupActionContent";
import SelectGroupAction from "./components/SelectGroupAction";
import SelectGroupActionsMembers from "./components/SelectGroupActionsMembers";
import {
  getLocalizedGroupActionsContent,
  stepsForType,
} from "./GroupActions.util";
import {
  EGroupActionModalStep,
  EGroupActionType,
} from "./GroupActionsModal.types";

export const PREFERENCE_KEY_GROUP_ACTIONS_PROPERTY_IDS =
  "PREFERENCE_KEY_GROUP_ACTIONS_PROPERTY_IDS_V2";

const formId = generateFormId();

export interface IGroupActionsModalProps {
  propertyIds: number[];
  onConfirm: () => void;
  onCancel: () => void;
}

const GroupActionsModal = (props: IGroupActionsModalProps) => {
  const { propertyIds, onCancel, onConfirm: onModelConfirm } = props;

  // State
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [files, setFiles] = React.useState<File[]>();
  const [meta, setMeta] = React.useState<IDocumentMetaData>();
  const [isUploading, setIsUploading] = React.useState<boolean>();
  const [isSelectingDocumentInfo, setSelectingDocumentInfo] =
    React.useState<boolean>(false);
  const [type, setType] = React.useState<EGroupActionType>();
  const [step, setStep] = React.useState(EGroupActionModalStep.SelectAction);
  const [selectedMembers, setSelectedMembers] = React.useState<number[]>([]);
  const [contents, setContents] = React.useState<Record<TLocale, string>>();
  const [error, setError] = React.useState<string | undefined>();
  const [pushTitle, setPushTitle] = React.useState<Record<TLocale, string>>({
    nl_BE: "",
    fr_FR: "",
    en_UK: "",
  });
  const [emailSubject, setEmailSubject] = React.useState<
    Record<TLocale, string>
  >({ nl_BE: "", fr_FR: "", en_UK: "" });

  const dispatch = useDispatch();

  const [_, setGroupActionPropertyIds, clearGroupActionPropertyIds] =
    usePreference<number[]>({
      preferenceKey: PREFERENCE_KEY_GROUP_ACTIONS_PROPERTY_IDS,
      preferencePersistScope: EPreferencePersistScope.InMemory,
    });

  const navigate = useNavigate();

  // Effects
  React.useEffect(() => {
    setIsDisabled(!type);
  }, [type]);

  React.useEffect(() => {
    if (step === EGroupActionModalStep.SelectDocument && !files) {
      open();
    }
    if (step === EGroupActionModalStep.AddContent) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [step]);

  React.useEffect(() => {
    if (step === EGroupActionModalStep.AddContent && !error) {
      if (type === EGroupActionType.Email) {
        const isEmailSubject =
          !isEmpty(emailSubject.en_UK) &&
          !isEmpty(emailSubject.nl_BE) &&
          !isEmpty(emailSubject.fr_FR);

        setIsDisabled(!isEmailSubject);

        return;
      }

      if (type === EGroupActionType.Notification) {
        const isPushTitle =
          !isEmpty(pushTitle.en_UK) &&
          !isEmpty(pushTitle.nl_BE) &&
          !isEmpty(pushTitle.fr_FR);

        setIsDisabled(!isPushTitle);

        return;
      }

      setIsDisabled(false);
    } else if (step === EGroupActionModalStep.AddContent && error) {
      setIsDisabled(true);
    }
  }, [error, emailSubject, pushTitle]);

  // Helpers
  const dispatchGroupAction = (data: ICreateGroupAction) => {
    dispatch(
      propertyActions.propertyGroupActionsStart.getAction({
        data,
        onSuccess(result) {
          onModelConfirm();
        },
      }),
    );
  };

  const handleSendMessage = (
    message: Partial<IGroupActionMessage>,
    roleType: EGroupActionRoleType,
  ) => {
    const { body } = message;

    if (!type || !body) {
      return;
    }

    const role = getRole(selectedMembers, roleType);

    const data: ICreateGroupAction = {
      propertyIds,
      role,
      type,
      groupMessage: { ...message, body },
    };

    dispatchGroupAction(data);
  };

  const handleAddDocument = async () => {
    if (!files || !meta || !type) {
      return;
    }

    try {
      setIsUploading(true);

      const file = files[0] as any;

      const preSignedUrl = await documentApi.uploadDocumentToS3(file);

      const document = await documentApi.uploadDocument(preSignedUrl, meta);

      const role = getRole(selectedMembers, EGroupActionRoleType.viewer);

      const data: ICreateGroupAction = {
        propertyIds,
        role,
        type,
        documentId: document.id,
      };

      dispatchGroupAction(data);

      clearGroupActionPropertyIds();
      setFiles(undefined);
    } catch (unknownError) {
      const error = unknownError as any;
      logger.logError({ error: new Error(error as any) });
    }

    setIsUploading(false);
  };

  const handleFinished = () => {
    // Handle finished action
    setGroupActionPropertyIds(propertyIds);

    switch (type) {
      case EGroupActionType.Task:
        navigate(
          appendQueryParams({
            path: "/tasks/add",
            queryParams: {
              groupActions: 1,
            },
          }),
        );

        break;

      case EGroupActionType.Notification:
        handleSendMessage(
          { body: contents, title: pushTitle },
          EGroupActionRoleType.recipient,
        );

        break;

      case EGroupActionType.Sms:
        handleSendMessage({ body: contents }, EGroupActionRoleType.recipient);

        break;

      case EGroupActionType.Email:
        handleSendMessage(
          { body: contents, subject: emailSubject },
          EGroupActionRoleType.recipient,
        );

        break;

      case EGroupActionType.Chat:
        handleSendMessage({ body: contents }, EGroupActionRoleType.participant);

        break;

      case EGroupActionType.Document:
        handleAddDocument();

        break;

      default:
        break;
    }
  };

  const updateStep = (next: boolean = true) => {
    if (!type) {
      setStep(EGroupActionModalStep.SelectAction);
      return;
    }

    const steps = stepsForType[type];
    const currentIndex = steps.indexOf(step);
    if (currentIndex === -1) return;

    const newIndex = next ? currentIndex + 1 : currentIndex - 1;
    if (newIndex < 0 || newIndex >= steps.length) {
      handleFinished();
    } else {
      setStep(steps[newIndex]);
    }
  };

  // Events
  const onPrevious = () => {
    if (step === EGroupActionModalStep.SelectMembers && files) {
      setFiles(undefined);
      setStep(EGroupActionModalStep.SelectAction);
    } else {
      updateStep(false);
    }
  };
  const onConfirm = () => {
    updateStep();
  };

  const onChangeAction = (selectedType: EGroupActionType) => {
    setType(selectedType);
  };

  const onChangeMembers = (selection: number[]) => {
    setSelectedMembers(selection);
  };

  const handleDrop = (
    acceptedFiles: any[],
    fileRejections: FileRejection[],
    event: DropEvent,
  ) => {
    showDropErrors({ acceptedFiles, fileRejections, event });

    if (acceptedFiles.length === 0) {
      return;
    }

    setFiles(acceptedFiles);
    setSelectingDocumentInfo(true);
  };

  const handleAdd = async (values: any) => {
    setSelectingDocumentInfo(false);

    setMeta(values);

    updateStep();
  };

  const handleModalClose = () => {
    setFiles(undefined);
  };

  const { getInputProps, open } = useDropzone({
    multiple: false,
    onDrop: handleDrop,
    maxSize: CONFIG.UPLOAD_MAX_FILE_SIZE,
  });

  // Actions
  const modalActions: IAction[] = [
    {
      content:
        step === EGroupActionModalStep.SelectAction
          ? ts.system("cancel")
          : ts.system("previous"),
      type: "button",
      onClick:
        step === EGroupActionModalStep.SelectAction ? onCancel : onPrevious,
      appearance: "outline",
    },
    {
      content:
        files && files?.length > 0 ? ts.system("save") : ts.system("next"),
      type: "button",
      onClick: onConfirm,
      appearance: "primary",
      isDisabled,
    },
  ];

  // Renders
  const renderModalSteps = () => {
    switch (step) {
      case EGroupActionModalStep.SelectDocument:
        return <Loading />;
      case EGroupActionModalStep.SelectMembers:
        return (
          <SelectGroupActionsMembers
            title={
              type === EGroupActionType.Document
                ? getLocalizedText("group_actions.modal.share.members.label")
                : getLocalizedText("group_actions.modal.select.members.label")
            }
            type={type}
            onChange={onChangeMembers}
          />
        );
      case EGroupActionModalStep.AddContent:
        return (
          type && (
            <AddGroupActionContent
              type={type}
              initialContents={getLocalizedGroupActionsContent(type)}
              onChange={contents => setContents(contents)}
              onChangeError={error => setError(error)}
              onChangeSubject={(key, value) => {
                setEmailSubject(prevPushTitle => ({
                  ...prevPushTitle,
                  [key]: value,
                }));
              }}
              onChangeTitle={(key, value) => {
                setPushTitle(prevPushTitle => ({
                  ...prevPushTitle,
                  [key]: value,
                }));
              }}
            />
          )
        );
      default:
        return <SelectGroupAction type={type} onChange={onChangeAction} />;
    }
  };

  return (
    <Modal
      onClose={onCancel}
      heading={getLocalizedText("group_actions.modal.heading")}
      hasDismiss={true}
      shouldCloseOnOverlayClick={false}
      width="medium"
      actions={modalActions}
    >
      {renderModalSteps()}
      <input {...getInputProps()} />
      {isSelectingDocumentInfo && (
        <MultiStepForm
          formId={`create-document-${formId}`}
          schemas={createDocumentForm({
            files,
          })}
          asModal={true}
          withAside={false}
          onSuccess={handleAdd}
          modalProps={{ onClose: handleModalClose }}
          submitLabel={ts.system("next")}
          isLoading={isUploading}
        />
      )}
    </Modal>
  );
};

// eslint-disable-next-line import/no-default-export
export default GroupActionsModal;

import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import { ETaskCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/task/task.utils";
import { ETaskMemberType } from "@rentiohq/shared-frontend/dist/types/task.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import StatusDot, { EStatus } from "../StatusDot";

interface IProps {
  propertyId: number;
}

const StatusDotPropertyTasks: React.FC<IProps> = props => {
  const { propertyId } = props;

  const paged = taskModule.hooks.usePaged({
    shouldRefetch: true,
    query: {
      page: 1,
      limit: CONFIG.DEFAULT_FETCH_LIMIT,
      filter: {
        propertyId: { eq: propertyId },
        finishedAt: { is: null },
      },
      customFilters: [ETaskCustomFilter.NotArchived],
    },
  });
  const { items: tasks } = paged;

  const tasksExecutor = tasks?.filter(task =>
    task.roles.includes(ETaskMemberType.Executor),
  );
  const tasksFollower = tasks?.filter(task =>
    task.roles.includes(ETaskMemberType.Follower),
  );

  // Render
  const getStatus = () => {
    if (!tasks) {
      return EStatus.None;
    }

    if (tasksExecutor && tasksExecutor.length > 0) {
      return EStatus.Error;
    }

    if (tasksFollower && tasksFollower.length > 0) {
      return EStatus.Warning;
    }

    return EStatus.Success;
  };

  const getTooltipContent = () => {
    if (!tasks) {
      return;
    }

    if (tasksExecutor && tasksExecutor.length > 0) {
      return getLocalizedText(
        "properties.overview.tasks.todo",
        { count: `${tasksExecutor.length}` },
        tasksExecutor.length,
      );
    }

    if (tasksFollower && tasksFollower.length > 0) {
      return getLocalizedText(
        "properties.overview.tasks.follow_up",
        { count: `${tasksFollower.length}` },
        tasksFollower.length,
      );
    }

    return getLocalizedText("properties.overview.tasks.todo", {}, 0);
  };

  return (
    <StatusDot status={getStatus()} tooltipContent={getTooltipContent()} />
  );
};

// eslint-disable-next-line import/no-default-export
export default StatusDotPropertyTasks;

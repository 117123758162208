import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EFontWeights } from "../../themes/types";
import { ELinkTarget } from "../../utils/types";
import { Link } from "../Link";
import { SimpleInfoBoxStyles } from "./SimpleInfoBox.styles";
export var SimpleInfoBox = function (_a) {
    var headline = _a.headline, details = _a.details, actions = _a.actions, _b = _a.link, link = _b === void 0 ? getLocalizedText("property.dashboard_pro.info.url") : _b;
    var sx = SimpleInfoBoxStyles();
    return (_jsxs(Paper, { sx: sx.wrapper, children: [_jsx(Typography, { variant: "h3", mb: 1, fontWeight: EFontWeights.Bold, children: headline }), _jsxs(Stack, { direction: "column", gap: 2, children: [_jsxs(Stack, { direction: "row", gap: 1, flexWrap: "wrap", children: [_jsx(Typography, { variant: "body1", sx: sx.content.description, children: details }), !actions && (_jsx(Link, { label: getLocalizedText("system.more_info"), link: link, target: ELinkTarget.blank, fontSize: 16 }))] }), actions] })] }));
};

var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import isEmpty from "ramda/es/isEmpty";
import { useNavigate } from "react-router-dom";
import { EAssets } from "../../assets";
import { Icon } from "../Icon";
import { Menu } from "../Menu";
import { PropertyListItemStyles } from "./PropertyListItem.styles";
import { PropertyIcon } from "./PropertyListItem.types";
var defaultPropertyMeta = function (property) {
    var propertyMetaDataKeys = ["name"];
    return propertyMetaDataKeys.reduce(function (currentKeys, key) {
        // @ts-ignore
        return property.hasOwnProperty(key) && property[key]
            ? // @ts-ignore
             __spreadArray(__spreadArray([], currentKeys, true), [property[key]], false) : currentKeys;
    }, []);
};
export var PropertyListItem = function (_a) {
    var property = _a.property, getActions = _a.getActions, _b = _a.showActions, showActions = _b === void 0 ? true : _b;
    var sx = PropertyListItemStyles();
    var navigate = useNavigate();
    var propertyMetaData = defaultPropertyMeta(property);
    var handleViewProperty = function () { return navigate("/properties/".concat(property.id)); };
    var actions = __spreadArray(__spreadArray([], ((getActions === null || getActions === void 0 ? void 0 : getActions(property)) || []), true), [
        {
            label: "actions.to_property",
            // @ts-ignore
            icon: PropertyIcon[property === null || property === void 0 ? void 0 : property.typeId],
            onClick: handleViewProperty,
        },
    ], false);
    return (_jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", children: [_jsxs(Stack, { direction: "row", alignItems: "center", gap: 1, sx: sx.content.item, children: [_jsx(Icon, { path: (property === null || property === void 0 ? void 0 : property.typeId)
                            ? //@ts-ignore
                                PropertyIcon[property === null || property === void 0 ? void 0 : property.typeId]
                            : EAssets.HouseIcon, width: "24px", height: "24px" }), _jsxs(Box, { children: [_jsx(Typography, { variant: "subtitle1", lineHeight: "16px", children: formatAddress(property) }), !isEmpty(propertyMetaData) && (_jsx(Typography, { variant: "subtitle1", sx: sx.content.subtitle, children: propertyMetaData.join(" | ") }))] })] }), (actions === null || actions === void 0 ? void 0 : actions.length) > 0 && showActions && _jsx(Menu, { actions: actions })] }));
};

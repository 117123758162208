import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { EContactCustomId } from "@rentiohq/shared-frontend/dist/redux/contact/contact.types";
import * as registrationActions from "@rentiohq/shared-frontend/dist/redux/registration/registration.actions";
import * as registrationSelectors from "@rentiohq/shared-frontend/dist/redux/registration/registration.selectors";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import {
  ERegistrationDocumentType,
  IRegistration,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Card,
  Error,
  Loading,
  MultiStepForm,
  Page,
} from "@rentiohq/web-shared/dist/components";
import createRegistrationForm from "forms/registration";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "forms/registration/schema.registration.actions";
import { IExtraData as IExtraDataCreateRegistration } from "forms/registration/schema.registration.types";
import registrationAddDocumentForm from "forms/registrationAddDocument";
import {
  isPerformingSubmitActionSelector as isPerformingSubmitActionSelectorAddDocument,
  performingSubmitActionErrorSelector as performingSubmitActionErrorSelectorAddDocument,
  performingSubmitActionResultSelector as performingSubmitActionResultSelectorAddDocument,
  submitActionCreator as submitActionCreatorAddDocument,
} from "forms/registrationAddDocument/schema.registrationAddDocument.actions";
import { IExtraData as IExtraDataAddDocument } from "forms/registrationAddDocument/schema.registrationAddDocument.types";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { NumberParam, createEnumParam } from "serialize-query-params";
import { ts } from "../../services";

const RegistrationForm: React.FC<{}> = () => {
  const navigate = useNavigate();

  const [queryParams] = useQueryParams({
    id: NumberParam,
    documentType: createEnumParam(Object.values(ERegistrationDocumentType)),
    existingDocument: NumberParam,
  });

  const {
    id: registrationId,
    documentType = ERegistrationDocumentType.Addendum,
    existingDocument: existingDocumentId,
  } = queryParams;

  const { contractId, ...params } = useParams<{
    propertyId: string;
    contractId: string;
  }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  // Redux
  const dispatch = useDispatch();

  const { isBroker } = authHooks.useSelf();
  const contactMeMaster = useSelector((state: IRootStore) =>
    contactSelectors.getContactMeMaster(state),
  );

  const legalContact = useSelector((state: IRootStore) =>
    isBroker
      ? contactSelectors.getContactByCustomId(state, EContactCustomId.Legal)
      : undefined,
  );
  const isFetchingLegalContact = useSelector((state: IRootStore) =>
    isBroker
      ? state.contact.contactsById[EContactCustomId.Legal].isFetching
      : false,
  );

  const { detail: property, fetchError: propertyFetchError } =
    propertyHooks.useDetail({
      shouldRefetch: false,
      id: propertyId,
    });

  const { detail: contract, fetchError: contractFetchError } =
    contractHooks.useDetail({
      shouldRefetch: false,
      id: contractId,
    });

  const fetchError = useSelector(
    (state: IRootStore) =>
      contractFetchError ||
      propertyFetchError ||
      registrationSelectors.fetchError(state, registrationId),
  );

  const registration = useSelector((state: IRootStore) =>
    registrationSelectors.registration(state, registrationId),
  );
  const isFetchingRegistration = useSelector((state: IRootStore) =>
    registrationSelectors.isFetching(state, registrationId),
  );

  React.useEffect(() => {
    if (!registrationId) {
      return;
    }
    if (registration || isFetchingRegistration) {
      return;
    }

    dispatch(
      registrationActions.getRegistration.actions.start({
        id: registrationId,
      }),
    );
  }, [registrationId]);

  const onSuccess = (registration: IRegistration) => {
    if (registration) {
      navigate(`/properties/${propertyId}?registrationId=${registration.id}`);
      return;
    }
    navigate(`/properties/${propertyId}`);
  };

  // Render
  if (!propertyId || !contractId) {
    return null;
  }

  if (isFetchingLegalContact && !legalContact) {
    return <Loading />;
  }

  if (fetchError) {
    return <Error errors={[fetchError]} />;
  }

  if (!property || !contract) {
    return null;
  }

  if (registrationId && !registration) {
    if (isFetchingRegistration) {
      return <Loading />;
    }

    return null;
  }

  if (registration) {
    // Form for location description & addendum
    const extraData: IExtraDataAddDocument = {
      documentType,
      registrationId: registration.id,
      registration,
      existingDocumentId,
      contract,
      property,
      contactMeMaster,
      legalContact,
      isBroker,
    };

    const title = getLocalizedText(
      `registration.${stringToSnakeCase(documentType)}.title`,
    );

    return (
      <Page
        title={title}
        subtitle={formatAddress(property)}
        breadcrumbs={{
          goBack: true,
          content: getLocalizedText("system.back"),
        }}
      >
        <Card>
          <MultiStepForm
            formId={`registration-document-${propertyId}-${contractId}`}
            schemas={registrationAddDocumentForm(extraData)}
            withSummary={true}
            submitLabel={ts.system("save_send")}
            isPerformingSubmitActionSelector={isPerformingSubmitActionSelectorAddDocument(
              extraData,
            )}
            performingSubmitActionResultSelector={performingSubmitActionResultSelectorAddDocument(
              extraData,
            )}
            performingSubmitActionErrorSelector={performingSubmitActionErrorSelectorAddDocument(
              extraData,
            )}
            submitActionCreator={(formData: any) =>
              submitActionCreatorAddDocument(extraData)(formData)
            }
            onSuccess={onSuccess}
          />
        </Card>
      </Page>
    );
  }

  const extraData: IExtraDataCreateRegistration = {
    contract,
    property,
    contactMeMaster,
    legalContact,
    isBroker,
  };

  return (
    <Page
      title={getLocalizedText("registration.create.title")}
      subtitle={formatAddress(property)}
      breadcrumbs={{
        to: `/properties/${propertyId}`,
        content: getLocalizedText("system.back"),
      }}
    >
      <Card>
        <MultiStepForm
          formId={`registration-general-${propertyId}-${contractId}`}
          schemas={createRegistrationForm(extraData)}
          withSummary={true}
          submitLabel={ts.system("save_send")}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            extraData,
          )}
          performingSubmitActionResultSelector={performingSubmitActionResultSelector(
            extraData,
          )}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            extraData,
          )}
          submitActionCreator={(formData: any) =>
            submitActionCreator(extraData)(formData)
          }
          onSuccess={onSuccess}
        />
      </Card>
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default RegistrationForm;

import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as templateSelectors from "@rentiohq/shared-frontend/dist/redux/template/template.selectors";
import * as templateUtils from "@rentiohq/shared-frontend/dist/redux/template/template.utils";
import * as bankAccountHooks from "@rentiohq/shared-frontend/dist/reduxV2/bankAccount/bankAccount.hooks";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Error, Loading, Page } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../../../../../services/translationService";
import { getAvailableTemplatesForType } from "../../components/ContractCard/ContractCard.utils";
import { BasicContractForm } from "./BasicContractForm";

interface IProps {
  deleteCancellation?: boolean;
}

export const ContractInfo = ({ deleteCancellation = false }: IProps) => {
  const params = useParams<{ contractId: string; propertyId: string }>();
  const { contractId } = params;

  const { isBroker } = authHooks.useSelf();

  const { internalModeEnabled } = useInternalMode();
  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const {
    detail: contract,
    isFetching: isFetchingContract,
    fetchError: contractFetchError,
  } = contractHooks.useDetail({
    shouldRefetch: false,
    id: contractId,
  });

  const propertyId = params.propertyId ? +params.propertyId : undefined;
  const {
    detail: property,
    isFetching: isFetchingProperty,
    fetchError: propertyFetchError,
  } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: propertyId,
  });

  const {
    detail: ibanisationBankAccount,
    isFetching: isFetchingIbanisationBankAccount,
  } = bankAccountHooks.useDetail({
    shouldRefetch: false,
    skipLegacy: internalModeEnabled && hasPaymentV2ForBroker,
    customPath: contract?.id
      ? `/contracts/${contract?.id}/ibanisation-bank-account`
      : undefined,
  });

  const isFetching = useSelector((state: IRootStore) => {
    return (
      isFetchingContract ||
      isFetchingProperty ||
      isFetchingIbanisationBankAccount ||
      templateSelectors.paged.isFetchingPage(state, {
        id: templateUtils.getPagedId({}),
        page: 0,
      })
    );
  });

  const fetchError = contractFetchError || propertyFetchError;

  const templates = useSelector((state: IRootStore) =>
    templateSelectors.paged.dataForPage(state, {
      id: templateUtils.getPagedId({}),
      page: 0,
    }),
  );

  const availableTemplatesForContract = useMemo(() => {
    return property
      ? getAvailableTemplatesForType({
          type: "contract",
          isBroker,
          templates,
          contract,
          property,
        })
      : undefined;
  }, [property]);

  if (isFetching) {
    return <Loading />;
  }

  if (!!fetchError) {
    return <Error errors={[fetchError]} />;
  }

  if (!property) {
    return null;
  }

  return (
    <Page
      title={
        contract
          ? getLocalizedText("contract_info.update.heading")
          : getLocalizedText("contract_info.create.heading")
      }
      subtitle={formatAddress(property, false)}
      breadcrumbs={{
        to: `/properties/${property.id}/contracts`,
        content: t.contractDetailBreadcrumb(),
      }}
    >
      <BasicContractForm
        property={property}
        contract={contract}
        ibanisationBankAccount={ibanisationBankAccount}
        availableTemplatesForContract={availableTemplatesForContract}
        deleteCancellation={deleteCancellation}
      />
    </Page>
  );
};

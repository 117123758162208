import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import { formatBytes } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { SpinningLoader } from "@rentiohq/web-shared-next/dist/ui-components";
import {
  Box,
  Menu,
  Stack,
  Typography,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import React from "react";
import { DocumentListItemStyles } from "./DocumentListItem.styles";

interface IProps {
  documentId: string;
}

export const DocumentListItem = ({ documentId }: IProps) => {
  const sx = DocumentListItemStyles();

  const {
    detail: fetchedDocument,
    isFetching,
    fetchError,
  } = documentHooks.useDetail({
    shouldRefetch: false,
    id: documentId,
  });

  React.useEffect(() => {
    if (fetchError?.message) {
      enqueueSnackbar(fetchError.message, { variant: "error" });
    }
  }, [fetchError]);

  if (isFetching) {
    return <SpinningLoader />;
  }

  const actions = [];

  if (fetchedDocument?.url) {
    actions.push({
      label: "document.view",
      onClick: () => utils.document.viewDocument(fetchedDocument),
    });
  }

  if (fetchedDocument?.downloadUrl) {
    actions.push({
      label: "document.download",
      onClick: () => (window.location.href = fetchedDocument.downloadUrl),
    });
  }

  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between">
      <Stack direction="row" alignItems="center" gap={1}>
        <Box>
          <Typography variant="subtitle1" lineHeight="16px">
            {fetchedDocument?.filename}
          </Typography>
          {fetchedDocument?.filesize && (
            <Typography variant="caption" sx={sx.content.caption}>
              {formatBytes(fetchedDocument.filesize)}
            </Typography>
          )}
        </Box>
      </Stack>
      <Menu actions={actions} />
    </Stack>
  );
};

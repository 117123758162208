var _a;
import { generateSelectors } from "../generators/generator.selectors";
import { getQueryHash } from "../utils/hash.utils";
import { GENERATOR_PARAMS, REDUCER_KEY } from "./application.constants";
export var getDetail = (_a = generateSelectors(GENERATOR_PARAMS), _a.getDetail), getPaged = _a.getPaged, getCreatedDetail = _a.getCreatedDetail, getCount = _a.getCount, 
// getInfiniteLoad,
isCreating = _a.isCreating, createError = _a.createError, isUpdating = _a.isUpdating, updateError = _a.updateError, isRemoving = _a.isRemoving, removeError = _a.removeError, isArchiving = _a.isArchiving, archivingError = _a.archivingError;
var getCountStepsBase = function (state, query) {
    if (!query) {
        return;
    }
    var key = getQueryHash(query);
    return state.APPLICATIONS_V2.countSteps[key]; //getEntityState(state)?.count[key];
};
export var getCountSteps = function (state, query) {
    var _a;
    return (_a = getCountStepsBase(state, query)) === null || _a === void 0 ? void 0 : _a.result;
};
export var isFetchingCountSteps = function (state, query) {
    var _a;
    return ((_a = getCountStepsBase(state, query)) === null || _a === void 0 ? void 0 : _a.pending) || false;
};
export var fetchCountStepsError = function (state, query) {
    var _a;
    return (_a = getCountStepsBase(state, query)) === null || _a === void 0 ? void 0 : _a.error;
};

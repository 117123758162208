import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import * as S from "./ActivityContent/ActivityContent.styled";
import { ContentMeta } from "./ContentMeta";
export var PushContent = function (props) {
    var _a;
    var pushTitle = props.pushTitle, pushMessage = props.pushMessage;
    return (_jsxs(S.Wrapper, { children: [_jsx(ContentMeta, { meta: (_a = {},
                    _a[getLocalizedText("activity.content.push.title")] = pushTitle,
                    _a) }), _jsx(S.ContentTitle, { children: getLocalizedText("activity.content.push.content") }), _jsx(S.MessageWrapper, { children: pushMessage })] }));
};

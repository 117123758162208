import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as applicationActions from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.actions";
import * as applicationHooks from "@rentiohq/shared-frontend/dist/reduxV2/applications/application.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { formatPhone } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
import {
  Card,
  DisplayText,
  ESpacings,
  Loading,
  Page,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import { InlineNotes } from "components/InlineNotes";
import flatten from "lodash/flatten";
import React from "react";
import { useDispatch } from "react-redux";
import * as applicationUtils from "./application.utils";

interface IProps {
  applicationId: string;
}

const renderCardHeader = (heading: string) => (
  <DisplayText size="extraSmall" space="tight">
    {heading}
  </DisplayText>
);

const renderRow = (item: {
  title: string;
  value?: string | number;
  action?: IAction;
}) => {
  let formattedValue = item.value;
  if (
    !item.value ||
    (typeof item.value === "string" && item.value.length === 0)
  ) {
    formattedValue = getLocalizedText("tenant_profile.pdf.item.empty");
  }

  return (
    <ResourceListItem
      item={item}
      primaryActions={item.action ? [item.action] : undefined}
    >
      <TextStyle variation="subdued" element="div">
        {item.title}
      </TextStyle>
      <div style={{ whiteSpace: "pre-line" }}>{formattedValue}</div>
    </ResourceListItem>
  );
};

export const ApplicationDetail: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();

  const { detail: application, isFetching } = applicationHooks.useDetail({
    shouldRefetch: false,
    id: props.applicationId,
  });

  React.useEffect(() => {
    dispatch(
      applicationActions.getDetailStart.getAction({ id: props.applicationId }),
    );
  }, [props.applicationId]);

  if (isFetching && !application) {
    return <Loading />;
  }

  if (!application) {
    return null;
  }

  const {
    contacts,
    dateOfOccupancy,
    reasonForRent,
    reasonForRentOther,
    currentResidenceAddress,
    financialRemarks,
  } = application;
  const contact = contacts[0];

  const subtitle = applicationUtils.getSetInfo(application);

  let reasonForRentValue = "";
  if (reasonForRentOther) {
    reasonForRentValue = reasonForRentOther;
  } else if (reasonForRent) {
    reasonForRentValue = getLocalizedText(reasonForRent);
  }

  const sections: {
    title: string;
    subtitle: string;
    items: {
      title: string;
      value?: string | number;
      action?: IAction;
    }[];
  }[] = [
    {
      title: getLocalizedText("tenant_profile.pdf.section.general"),
      subtitle: "",
      items: [
        {
          title: getLocalizedText("system.full_name"),
          value: getName(contact),
        },
        {
          title: getLocalizedText("system.mobile_number"),
          value: contact.phone ? formatPhone(contact.phone) : "-",
        },
        { title: getLocalizedText("system.email"), value: contact.email },
        {
          title: getLocalizedText("system.locale"),
          value: contact.language
            ? getLocalizedText(`system.locale.${contact.language}`)
            : "-",
        },
      ],
    },
    {
      title: getLocalizedText("tenant_profile.pdf.section.residents"),
      subtitle: "",
      items: application.residents.map(({ id, value }) => ({
        title: getLocalizedText(id),
        value:
          value === 0 ? getLocalizedText(`${id}.empty`.toLowerCase()) : value,
      })),
    },
    {
      title: getLocalizedText("tenant_profile.pdf.section.rent_info"),
      subtitle: "",
      items: [
        {
          title: getLocalizedText("tenant_profile.pdf.item.date_of_occupancy"),
          value: dateOfOccupancy ? utils.date.format(dateOfOccupancy) : "-",
        },
        {
          title: getLocalizedText("tenant_profile.pdf.item.reason_for_rent"),
          value: reasonForRentValue,
        },
        {
          title: getLocalizedText("tenant_profile.pdf.item.current_residence"),
          value: formatAddress(currentResidenceAddress),
        },
      ],
    },
    // All tenants
    ...contacts.map((contact, index: number) => ({
      title: `${getName(contact)}`,
      subtitle: "",
      items: [
        {
          title: contact.isGuarantor
            ? getLocalizedText("system.guarantor")
            : getLocalizedText("system.tenant"),
          value: getName(contact),
        },
        // TODO: @dries: add address to contact type
        // @ts-ignore
        {
          title: getLocalizedText("system.address"),
          // @ts-ignore
          value: contact.address ? formatAddress(contact.address) : "",
        },
        {
          title: getLocalizedText("system.mobile_number"),
          value: contact.phone ? formatPhone(contact.phone) : "-",
        },
        { title: getLocalizedText("system.email"), value: contact.email },
        ...(contact.income || []).map(income => ({
          title: getLocalizedText(income.id),
          value: formatCurrency(income.value || 0, true),
        })),
        ...(index === 0
          ? [
              {
                title: getLocalizedText("system.comments"),
                value: financialRemarks,
              },
            ]
          : []),
      ],
    })),
    {
      title: getLocalizedText("system.comments_other"),
      subtitle: "",
      items: [{ title: getLocalizedText("system.comments"), value: "" }],
    },
    {
      title: getLocalizedText("system.documents"),
      subtitle: "",
      items: [
        // ID's
        ...flatten(
          contacts.map(contact =>
            (contact.documents || []).map(document => ({
              title: getLocalizedText("intake.contract.id.title"),
              value: getName(contact),
              action: {
                id: "open",
                content: getLocalizedText("document.open"),
                onClick: () => {
                  window.open(document, "_blank");
                },
              },
            })),
          ),
        ),
        // Income
        ...flatten(
          flatten(
            contacts.map(contact =>
              (contact.income || []).map(income =>
                (income.documents || []).map(document => ({
                  title: `${getLocalizedText(income.id)} (${formatCurrency(
                    income.value || 0,
                  )})`,
                  value: getName(contact),
                  action: {
                    id: "open",
                    content: getLocalizedText("document.open"),
                    onClick: () => {
                      window.open(document, "_blank");
                    },
                  },
                })),
              ),
            ),
          ),
        ),
      ],
    },
  ];

  return (
    <Page title={getName(contact)} subtitle={subtitle}>
      <InlineNotes
        customPath={`/applications/${application.id}/notes`}
        cardProps={{
          heading: renderCardHeader(getLocalizedText("system.model.note")),
        }}
      />

      {sections.map(({ title, items }) => (
        <Card
          key={title}
          heading={renderCardHeader(title)}
          subHeading={undefined}
          space={ESpacings.loose}
        >
          <ResourceList items={items} renderItem={renderRow} />
        </Card>
      ))}
    </Page>
  );
};

import { IPagedQuery } from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import {
  EContractEndingTenantStatus,
  EContractLengthType,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  addMonths,
  startOfDay,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { TAppearance } from "@rentiohq/web-shared/dist/types";
import {
  EContractEndingStatus,
  EContractStatus,
} from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ContractStatusChip/contractStatusChip.types";
import { STATUS_IS_ENDING_WHEN_IN_MONTHS } from "scenes/Properties/scenes/Contracts/components/ContractCard/ContractCard.utils";
import {
  EEndDateWithinMonths,
  ERenewalDateWithinMonths,
} from "./EndingContracts.types";

const NOW = new Date();
const START_OF_DAY = startOfDay(NOW);

export const getSingleContractRenewalDateFilterForProperties = (
  renewalDate: ERenewalDateWithinMonths,
) => {
  switch (renewalDate) {
    case ERenewalDateWithinMonths.Within1Month:
      return {
        or: [
          {
            contracts: {
              and: [
                {
                  contractLengthType: {
                    eq: EContractLengthType.Short,
                  },
                },
                { stopDate: { gte: addMonths(START_OF_DAY, 3) } },
                { stopDate: { lte: addMonths(START_OF_DAY, 4) } },
              ],
            },
          },

          {
            contracts: {
              and: [
                {
                  contractLengthType: {
                    eq: EContractLengthType.Long,
                  },
                },
                { stopDate: { gte: addMonths(START_OF_DAY, 6) } },
                { stopDate: { lte: addMonths(START_OF_DAY, 7) } },
              ],
            },
          },
        ],
      };

    case ERenewalDateWithinMonths.Within3Months:
      return {
        or: [
          {
            contracts: {
              and: [
                {
                  contractLengthType: {
                    eq: EContractLengthType.Short,
                  },
                },
                { stopDate: { gte: addMonths(START_OF_DAY, 3) } },
                { stopDate: { lte: addMonths(START_OF_DAY, 6) } },
              ],
            },
          },
          {
            contracts: {
              and: [
                {
                  contractLengthType: {
                    eq: EContractLengthType.Long,
                  },
                },
                { stopDate: { gte: addMonths(START_OF_DAY, 6) } },
                { stopDate: { lte: addMonths(START_OF_DAY, 9) } },
              ],
            },
          },
        ],
      };

    default:
      return undefined;
  }
};

export const getSingleContractRenewalDateFilter = (
  renewalDate: ERenewalDateWithinMonths,
) => {
  switch (renewalDate) {
    case ERenewalDateWithinMonths.Within1Month:
      return {
        or: [
          {
            and: [
              {
                contractLengthType: {
                  eq: EContractLengthType.Short,
                },
              },
              { stopDate: { gte: addMonths(START_OF_DAY, 3) } },
              { stopDate: { lte: addMonths(START_OF_DAY, 4) } },
            ],
          },

          {
            and: [
              {
                contractLengthType: {
                  eq: EContractLengthType.Long,
                },
              },
              { stopDate: { gte: addMonths(START_OF_DAY, 6) } },
              { stopDate: { lte: addMonths(START_OF_DAY, 7) } },
            ],
          },
        ],
      };

    case ERenewalDateWithinMonths.Within3Months:
      return {
        or: [
          {
            and: [
              {
                contractLengthType: {
                  eq: EContractLengthType.Short,
                },
              },
              { stopDate: { gte: addMonths(START_OF_DAY, 3) } },
              { stopDate: { lte: addMonths(START_OF_DAY, 6) } },
            ],
          },
          {
            and: [
              {
                contractLengthType: {
                  eq: EContractLengthType.Long,
                },
              },
              { stopDate: { gte: addMonths(START_OF_DAY, 6) } },
              { stopDate: { lte: addMonths(START_OF_DAY, 9) } },
            ],
          },
        ],
      };

    default:
      return undefined;
  }
};

export const getRenewalDateFilterQuery = (params: {
  order?: "ASC" | "DESC";
  query?: string;
  renewalDateWithinMonths: ERenewalDateWithinMonths;
  lengthType?: EContractLengthType;
  tacitRenewal?: boolean;
  tenantStatus?: EContractEndingTenantStatus;
}): Partial<IPagedQuery> => {
  const {
    order = "ASC",
    query,
    renewalDateWithinMonths,
    lengthType,
    tacitRenewal,
    tenantStatus,
  } = params;

  let and: Object[] = [];

  // Length type
  if (lengthType !== undefined) {
    and.push({
      contractLengthType: { eq: lengthType },
    });
  }

  // contract ending tenant status
  if (tenantStatus !== undefined) {
    and.push({ contractEndingTenantStatus: { eq: tenantStatus } });
  }

  // Tacit renewal
  if (tacitRenewal !== undefined) {
    and.push({
      tacitRenewal: { is: tacitRenewal },
    });
  }

  const renewalDateFilter = getSingleContractRenewalDateFilter(
    renewalDateWithinMonths,
  );
  if (renewalDateFilter) {
    and.push(renewalDateFilter);
  }

  return {
    sort: [
      { field: "stopDate", method: order },
      { field: "createdAt", method: "DESC" },
    ],
    search: query,
    filter: {
      and,
    },
  };
};

export const getContractStatusFilter = (status: EContractStatus) => {
  switch (status) {
    case EContractStatus.Ending:
      return {
        contracts: {
          and: [
            {
              stopDate: { gte: START_OF_DAY },
            },
            {
              stopDate: {
                lte: addMonths(START_OF_DAY, STATUS_IS_ENDING_WHEN_IN_MONTHS),
              },
            },
          ],
        },
      };

    case EContractStatus.Upcoming:
      return {
        contracts: { and: [{ startDate: { gte: NOW } }] },
      };

    case EContractStatus.Running:
      return {
        contracts: {
          and: [
            {
              startDate: { lte: NOW },
            },

            {
              stopDate: {
                gte: addMonths(START_OF_DAY, STATUS_IS_ENDING_WHEN_IN_MONTHS),
              },
            },
          ],
        },
      };

    case EContractStatus.Finished:
      return {
        contracts: {
          and: [{ stopDate: { lte: NOW } }],
        },
      };

    default:
      return undefined;
  }
};

const getSingleContractEndingStatusFilter = (
  endingStatus: EContractEndingStatus,
) => {
  switch (endingStatus) {
    case EContractEndingStatus.AutoEnding:
      return { contracts: { and: [{ tacitRenewal: { is: false } }] } };

    case EContractEndingStatus.Cancelled:
      return { contracts: { and: [{ dateOfCancellation: { isNot: null } }] } };

    case EContractEndingStatus.ShortTerm:
      return {
        contracts: {
          and: [
            { tacitRenewal: { is: true } },
            { contractLengthType: { eq: EContractLengthType.Short } },
          ],
        },
      };

    case EContractEndingStatus.LongTerm:
      return {
        contracts: {
          and: [
            { tacitRenewal: { is: true } },
            { contractLengthType: { eq: EContractLengthType.Long } },
          ],
        },
      };

    default:
      return undefined;
  }
};

export const getContractEndingStatusFilter = (
  endingStatuses: EContractEndingStatus[],
) => {
  if (endingStatuses.length === 0) {
    return undefined;
  }

  if (endingStatuses.length === 1) {
    return getSingleContractEndingStatusFilter(endingStatuses[0]);
  }

  return {
    or: endingStatuses.map(status =>
      getSingleContractEndingStatusFilter(status),
    ),
  };
};

export const getContractEndDateFilterForProperties = (
  endDateWithinMonths: EEndDateWithinMonths,
) => {
  switch (endDateWithinMonths) {
    case EEndDateWithinMonths.Within1Month:
      return {
        contracts: {
          and: [
            {
              stopDate: { gte: START_OF_DAY },
            },
            {
              stopDate: { lte: addMonths(START_OF_DAY, 1) },
            },
          ],
        },
      };

    case EEndDateWithinMonths.Within4Months:
      return {
        contracts: {
          and: [
            {
              stopDate: { gte: START_OF_DAY },
            },
            { stopDate: { lte: addMonths(START_OF_DAY, 4) } },
          ],
        },
      };

    case EEndDateWithinMonths.Within7Months:
      return {
        contracts: {
          and: [
            {
              stopDate: { gte: START_OF_DAY },
            },
            {
              stopDate: { lte: addMonths(START_OF_DAY, 7) },
            },
          ],
        },
      };

    default:
      return undefined;
  }
};

export const getContractEndDateFilter = (
  endDateWithinMonths: EEndDateWithinMonths,
) => {
  switch (endDateWithinMonths) {
    case EEndDateWithinMonths.Within1Month:
      return {
        and: [
          {
            stopDate: { gte: START_OF_DAY },
          },
          {
            stopDate: { lte: addMonths(START_OF_DAY, 1) },
          },
        ],
      };

    case EEndDateWithinMonths.Within4Months:
      return {
        and: [
          {
            stopDate: { gte: START_OF_DAY },
          },
          { stopDate: { lte: addMonths(START_OF_DAY, 4) } },
        ],
      };

    case EEndDateWithinMonths.Within7Months:
      return {
        and: [
          {
            stopDate: { gte: START_OF_DAY },
          },
          {
            stopDate: { lte: addMonths(START_OF_DAY, 7) },
          },
        ],
      };

    default:
      return undefined;
  }
};

export const getEndDateFilterQuery = (params: {
  order?: "ASC" | "DESC";
  query?: string;
  endDateWithinMonths: EEndDateWithinMonths;
  lengthType?: EContractLengthType;
  tacitRenewal?: boolean;
  tenantStatus?: EContractEndingTenantStatus;
}): Partial<IPagedQuery> => {
  const {
    order = "ASC",
    query,
    endDateWithinMonths,
    lengthType,
    tacitRenewal,
    tenantStatus,
  } = params;

  const and: Object[] = [];

  // Length type
  if (lengthType !== undefined) {
    and.push({
      contractLengthType: { eq: lengthType },
    });
  }

  // contract ending tenant status
  if (tenantStatus !== undefined) {
    and.push({ contractEndingTenantStatus: { eq: tenantStatus } });
  }

  const endDateFilter = getContractEndDateFilter(endDateWithinMonths);
  if (endDateFilter) {
    and.push(endDateFilter);
  }

  // Tacit renewal
  if (tacitRenewal !== undefined) {
    and.push({
      tacitRenewal: { is: tacitRenewal },
    });
  }

  return {
    sort: [
      { field: "stopDate", method: order },
      { field: "createdAt", method: "DESC" },
    ],
    search: query,
    filter: {
      and,
    },
  };
};

export const getEndingTenantStatusVariation = (
  contractEndingTenantStatus?: EContractEndingTenantStatus | null,
): TAppearance => {
  switch (contractEndingTenantStatus) {
    case EContractEndingTenantStatus.End:
      return "error";

    case EContractEndingTenantStatus.Asked:
      return "warning";

    case EContractEndingTenantStatus.Renew:
    default:
      return "success";
  }
};

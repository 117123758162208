var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { TabPanelStyles } from "./TabPanel.styles";
export var TabPanel = function (_a) {
    var children = _a.children, value = _a.value, activeTab = _a.activeTab, other = __rest(_a, ["children", "value", "activeTab"]);
    var sx = TabPanelStyles();
    return (_jsx(Box, __assign({ role: "tabpanel", hidden: value !== activeTab, id: "rentio-tabpanel-".concat(activeTab), "aria-labelledby": "rentio-tab-".concat(activeTab), sx: sx.wrapper }, other, { children: value === activeTab && _jsx(Box, { children: children }) })));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { groupBy, reverse, sortBy } from "lodash";
import { createSelector } from "reselect";
import { compareAsc, startOfDay } from "../../utils/date-fns.utils";
import { generateSelectors } from "../generators/generator.selectors";
import { GENERATOR_PARAMS, REDUCER_KEY as REDUCER_KEY_CHAT, } from "./chat.constants";
export var getDetail = (_a = generateSelectors(GENERATOR_PARAMS), _a.getDetail), getPaged = _a.getPaged, getCreatedDetail = _a.getCreatedDetail, getCount = _a.getCount, 
// getInfiniteLoad,
isCreating = _a.isCreating, createError = _a.createError, isUpdating = _a.isUpdating, updateError = _a.updateError, isRemoving = _a.isRemoving, removeError = _a.removeError, isArchiving = _a.isArchiving, archivingError = _a.archivingError, getAllRaw = _a.getAllRaw;
export var getMessagesRequest = function (state, ref) {
    return state.CHATS_V2.messagesRequests[ref];
};
export var getMessagesSelector = function (state, chatId) {
    return state.CHATS_V2.messages[chatId];
};
export var getMostRecentMessage = function (state, chatId) {
    var messages = state.CHATS_V2.messages[chatId] || [];
    if (messages.length === 0) {
        return;
    }
    return messages[0];
};
export var getMostRecentMessageGeneral = function (state) {
    var messages = Object.keys(state.CHATS_V2.messages).reduce(function (latestMessages, chatId) {
        var lastMessage = state.CHATS_V2.messages[Number(chatId)][0];
        return (lastMessage === null || lastMessage === void 0 ? void 0 : lastMessage.sendBy)
            ? __spreadArray(__spreadArray([], latestMessages, true), [lastMessage], false) : latestMessages;
    }, []);
    if (messages.length === 0) {
        return;
    }
    var sortedBySendAt = messages.sort(function (a, b) {
        return compareAsc(new Date(b.sendAt), new Date(a.sendAt));
    });
    return __assign({}, sortedBySendAt[0]);
};
export var getMessagesCount = function (state, chatId) {
    return (state.CHATS_V2.messages[chatId] || []).length;
};
export var getIsTyping = function (state, chatId) {
    return state.CHATS_V2.typing.includes(chatId);
};
export var makeGetMessagesDataSource = function () {
    return createSelector([getMessagesSelector, getIsTyping], function (messages, isTyping) {
        var sortedMessages = reverse(sortBy(messages, ["sendAt", "id"]));
        var groupedMessages = groupBy(sortedMessages, function (message) {
            return startOfDay(message.sendAt);
        });
        var dataSource = Object.keys(groupedMessages).map(function (date) {
            return { key: date, data: groupedMessages[date] };
        });
        if (isTyping) {
            dataSource.unshift({ data: ["typing"] });
        }
        return dataSource;
    });
};
export var getIsChatExhausted = function (state, chatId) { var _a; return (_a = state.CHATS_V2.chatExhausted) === null || _a === void 0 ? void 0 : _a[chatId]; };
export var messagesSelector = function (state) {
    return state.CHATS_V2.messages;
};
export var makeGetUnreadChatsCount = function () {
    return createSelector([getAllRaw, messagesSelector], function (chats, messages) {
        var chatsCounter = (chats || []).reduce(function (accumulator, currentValue) {
            return (accumulator += currentValue.unreadCounter);
        }, 0);
        var messagesCounter = Object.keys(messages || {}).reduce(function (accumulator, chatId) {
            var foundChat = (chats || []).find(function (chat) { return chat.id === parseInt(chatId); });
            if (foundChat) {
                return accumulator;
            }
            return (accumulator += 1);
        }, 0);
        return chatsCounter + messagesCounter;
    });
};
export var hasMessagesForNotFetchedChats = function (state) {
    var _a;
    var chatIdsForMessages = Object.keys(state.CHATS_V2.messages).map(function (chatId) {
        return parseInt(chatId, 0);
    });
    var chatIdsForFetchedChats = (_a = getAllRaw(state)) === null || _a === void 0 ? void 0 : _a.map(function (chat) {
        return typeof chat.id === "string" ? parseInt(chat.id, 0) : chat.id;
    });
    return !!chatIdsForMessages.find(function (chatIdForMessage) { return !(chatIdsForFetchedChats === null || chatIdsForFetchedChats === void 0 ? void 0 : chatIdsForFetchedChats.includes(chatIdForMessage)); });
};
export var getNewCreatedChat = function (state) {
    var _a;
    var newChatId = state.CHATS_V2.create.id;
    return newChatId ? (_a = state.CHATS_V2.detail[newChatId]) === null || _a === void 0 ? void 0 : _a.entity : undefined;
};

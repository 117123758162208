import { alpha, getFontSize, useTheme } from "../../themes";
import { EFontSizes, EFontWeights } from "../../themes/types";
import { ECaptionVariant } from "../../utils/types/typography";
export var ContactFetchListItemStyles = function (captionColor) {
    if (captionColor === void 0) { captionColor = ECaptionVariant.Default; }
    var theme = useTheme();
    var getCaptionColor = function () {
        switch (captionColor) {
            case ECaptionVariant.Success:
                return theme.palette.success.main;
            case ECaptionVariant.Warning:
                return theme.palette.warning.main;
            case ECaptionVariant.Error:
                return theme.palette.error.main;
            default:
                return theme.palette.text.secondary;
        }
    };
    return {
        content: {
            accountItem: {
                svg: {
                    color: alpha(theme.palette.grey[700], 0.5),
                },
            },
            subtitle: {
                color: getCaptionColor(),
                fontSize: getFontSize(EFontSizes.ExtraSmall),
                fontWeight: EFontWeights.Regular,
                lineHeight: "16px",
            },
        },
    };
};

import { Box } from "@rebass/grid";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactApi from "@rentiohq/shared-frontend/dist/redux/contact/contact.api";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IChat } from "@rentiohq/shared-frontend/dist/types/chat.types";
import { EContactType } from "@rentiohq/shared-frontend/dist/types/contact.types";
import {
  EPropertyMemberTypes,
  IProperty,
} from "@rentiohq/shared-frontend/dist/types/property.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import api from "@rentiohq/shared-frontend/dist/utils/api/api.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Activities,
  Banner,
  Button,
  Card,
  DisplayText,
  Error,
  ESpacings,
  Grid,
  Icon,
  Loading,
  Pagination,
  ResourceList,
  ResourceListItem,
  TextStyle,
  Washout,
} from "@rentiohq/web-shared/dist/components";
import { ContactAddress } from "@rentiohq/web-shared/dist/components/ContactOverviewRow/ContactAddress";
import { ContactDetails } from "@rentiohq/web-shared/dist/components/ContactOverviewRow/ContactDetails";
import { toast } from "@rentiohq/web-shared/dist/managers/Toast";
import utils from "@rentiohq/web-shared/dist/utils";
import { triggerLiveChat } from "@rentiohq/web-shared/dist/utils/zendesk.utils";
import isEmpty from "ramda/es/isEmpty";
import { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { ChatOverview } from "../../../../components/ChatOverview";
import { InlineNotes } from "../../../../components/InlineNotes";
import * as t from "../../../../services/translationService";
import { getContactActivityFilterContactPart } from "../../utils";
import { IProps } from "./ContactDashboard.types";

const PAGE_LIMIT = 8;

export const ContactDashboard: FC<IProps> = (props: IProps) => {
  const {
    contact,
    chats = [],
    chatsFetchError,
    isFetchingChats = false,
  } = props;

  const [page, setPage] = useState<number>(1);
  const dispatch = useDispatch();

  const {
    items: properties,
    isFetching: isFetchingProperties,
    fetchError: propertiesFetchError,
    refetch: refetchProperties,
    totalPages = 0,
  } = propertyHooks.usePaged({
    shouldRefetch: true,
    customPath: `/contacts/${contact.id}/properties`,
    query: {
      filter: { archivedAt: { is: null } },
      page,
      limit: PAGE_LIMIT,
    },
  });

  const { items: archivedProperties } = propertyHooks.usePaged({
    shouldRefetch: true,
    customPath: `/contacts/${contact.id}/properties`,
    query: {
      filter: { archivedAt: { isNot: null } },
      page,
      limit: PAGE_LIMIT,
    },
  });

  const bankAccounts = useSelector((state: IRootStore) =>
    contactSelectors.getIbans(state, contact.id),
  );

  useEffect(() => {
    dispatch(contactActions.getIbans.actions.start({ contactId: contact.id }));
  }, [contact]);

  const isNormalContact = ![
    EContactType.Personal,
    EContactType.Support,
  ].includes(contact.type);

  const isConnectionLess = !!contact.duplicateWithoutConnection;

  const isSupportContact = contact.type === EContactType.Support;

  const [invitedAt, setInvitedAt] = useState<Date | undefined>(
    contact.invitedAt,
  );
  const navigate = useNavigate();
  const addressData = [
    "street",
    "number",
    "city",
    "state",
    "zip",
    "box",
    "countryId",
  ];

  const { id, isCompany, firstname, lastname, company, accountId } = contact;
  const address: any = utils.object.filterKeys(contact, addressData);
  const hasAddress = !isEmpty(utils.object.removeEmpty(address));

  const resendInvitation = async () => {
    try {
      await contactApi.inviteContact(id);

      setInvitedAt(new Date());
    } catch (err) {
      toast({
        heading: t.contactResendInvitationError(),
        variation: "error",
      });
    }
  };

  const renderPropertyItem = (property: IProperty) => {
    const { members = [] } = property;
    const member = members.find(member => {
      return member.account.id === accountId;
    });
    const roles = member?.roles || [];

    return (
      <ResourceListItem
        item={property}
        media={
          property.typeId && (
            <Icon source={utils.properties.getIcon(property.typeId)} />
          )
        }
        link={`/properties/${property.id}`}
      >
        <DisplayText size="small" space="extraTight">
          {formatAddress(property)}
        </DisplayText>
        {roles && (
          <TextStyle variation="subdued">
            {roles
              .filter(role =>
                Object.values(EPropertyMemberTypes).includes(role as any),
              )
              .map((role: string) => role && t.role(role.toLowerCase()))
              .join(", ")}
          </TextStyle>
        )}
      </ResourceListItem>
    );
  };

  const renderArchivedPropertyItem = (property: IProperty) => {
    return <Washout variation="medium">{renderPropertyItem(property)}</Washout>;
  };

  const propertiesHeading = isEmpty(properties)
    ? t.contactPropertyEmptyHeading()
    : t.system("properties");

  const handleCreateChatPress = async () => {
    if (isSupportContact) {
      triggerLiveChat();
      return;
    }

    try {
      const { data } = await api.post<IChat>("/chats", {
        data: {
          members: [{ accountId: contact.accountId, roles: ["PARTICIPANT"] }],
        },
      });

      navigate(`/chats/${data.id}`);
    } catch (unknownError) {
      toast({
        heading: t.generalError(),
        variation: "error",
      });
    }
  };

  const renderConnectionCard = () => {
    if (!!contact.inviteAcceptedAt) {
      return (
        <Card heading="Rentio" space={ESpacings.loose}>
          <Box>
            {t.contactConnectedSince({
              values: { name: isCompany ? company : firstname },
            })}{" "}
            <b>{utils.date.format(contact.inviteAcceptedAt)}</b>
          </Box>
        </Card>
      );
    }

    if (!!invitedAt) {
      return (
        <Card heading="Rentio" space={ESpacings.loose}>
          <Box>
            <Box>
              {t.contactInvitedOn()} <b>{utils.date.format(invitedAt)}</b>
            </Box>
            <Box mt={2}>
              <Button appearance="link" onClick={resendInvitation}>
                {t.contactResendInvitation()}
              </Button>
            </Box>
          </Box>
        </Card>
      );
    }

    return (
      <Card heading="Rentio" space={ESpacings.loose}>
        <Box>
          <Box>
            <TextStyle variation={"negative"}>
              {getLocalizedText("contact.not_invited")}
            </TextStyle>
          </Box>
          <Box mt={2}>
            <Button appearance="link" onClick={resendInvitation}>
              {getLocalizedText("contact.send_invitation")}
            </Button>
          </Box>
        </Box>
      </Card>
    );
  };

  return (
    <Grid spacing="loose">
      <Grid.Item width={[1, 1, 11 / 16]}>
        {isConnectionLess && (
          <Banner
            title={getLocalizedText("contact.connectionless.banner.title")}
            icon="alertDiamond"
            variation="warning"
            hasDismiss={false}
          >
            {getLocalizedText("contact.connectionless.banner.info")}
          </Banner>
        )}

        {isNormalContact && (
          <Card heading={propertiesHeading}>
            {isFetchingProperties && !properties && <Loading />}

            {!isFetchingProperties && propertiesFetchError && !properties && (
              <Error
                errors={[propertiesFetchError]}
                onRetry={refetchProperties}
              />
            )}

            {properties && (
              <Box>
                <ResourceList
                  items={properties}
                  renderItem={renderPropertyItem}
                />
                {totalPages > 1 && (
                  <Pagination
                    initialPage={page ? page - 1 : 0}
                    pageCount={totalPages}
                    onPageChange={({ selected }) => {
                      setPage(selected + 1);
                    }}
                  />
                )}
                {archivedProperties && archivedProperties.length > 0 && (
                  <Box mt={[3, 3, 4]}>
                    <DisplayText element="h3" size="small">
                      {t.propertyDashboardArchivedPropertiesHeading()}
                    </DisplayText>
                    <ResourceList
                      items={archivedProperties}
                      renderItem={renderArchivedPropertyItem}
                    />
                  </Box>
                )}
              </Box>
            )}
          </Card>
        )}

        {!chatsFetchError && chats.length <= 0 && !isFetchingChats ? (
          <Card
            heading={
              isSupportContact
                ? t.contactDashboardChatsHeading({
                    values: {
                      firstname:
                        contact.firstname || contact.company || "contact",
                    },
                  })
                : t.contactDashboardNoChatsHeading({
                    values: {
                      firstname:
                        contact.firstname || contact.company || "contact",
                    },
                  })
            }
            footerActions={[
              {
                content: t.contactDashboardNoChatsAction({
                  values: {
                    firstname:
                      contact.firstname || contact.company || "contact",
                  },
                }),
                onClick: handleCreateChatPress,
                appearance: "outline",
              },
            ]}
          />
        ) : (
          <Card
            heading={t.contactDashboardChatsHeading({
              values: {
                firstname: contact.firstname || contact.company || "contact",
              },
            })}
            actions={[
              {
                content: t.contactDashboardNoChatsAction({
                  values: {
                    firstname:
                      contact.firstname || contact.company || "contact",
                  },
                }),
                onClick: handleCreateChatPress,
                appearance: "outline",
              },
            ]}
          >
            {isFetchingChats ? <Loading /> : <ChatOverview chats={chats} />}
          </Card>
        )}

        {isNormalContact && (
          <Card heading={t.contactDashboardHistoryHeading()}>
            <Box mb={ESpacings.base}>
              <Activities
                identifier={`contact-${contact.id}-compact`}
                limit={3}
                filter={getContactActivityFilterContactPart(contact)}
                isCompact={true}
                moreLink={`/contacts/${contact.id}/history`}
              />
            </Box>
          </Card>
        )}
      </Grid.Item>

      <Grid.Item width={[1, 1, 5 / 16]}>
        <>
          {isCompany ? (
            <>
              {hasAddress && (
                <Card
                  heading={t.contactDetailsAddress()}
                  space={ESpacings.loose}
                >
                  <Box>
                    <ContactAddress address={address} />
                  </Box>
                </Card>
              )}
              <Card
                heading={t.contactCompanyDetailsContact()}
                space={ESpacings.loose}
              >
                <Box>
                  <Box mb={2}>
                    <b>
                      {firstname} {lastname}
                    </b>
                  </Box>
                  <ContactDetails contact={contact} />
                </Box>
              </Card>
            </>
          ) : (
            <Card
              heading={t.contactDashboardContactDataHeading()}
              space={ESpacings.loose}
            >
              <Box mb={hasAddress ? 2 : 0}>
                <ContactDetails contact={contact} />
              </Box>
              {hasAddress && (
                <Box>
                  <Box mb={2}>
                    <b>{t.contactDetailsAddress()}</b>
                  </Box>
                  <ContactAddress address={address} />
                </Box>
              )}
            </Card>
          )}
        </>

        {bankAccounts && bankAccounts.length > 0 && (
          <Card heading={t.contactDetailsFinancial()} space={ESpacings.loose}>
            <Box>
              <Box mb={2}>
                <b>{t.contactBankaccountsLabel()}</b>
              </Box>
              {bankAccounts.map((bankAccounts, index) => (
                <Box mb={1} key={index}>
                  <div>{bankAccounts.iban}</div>
                  <TextStyle variation="subdued">
                    {bankAccounts.ownerName}
                  </TextStyle>
                </Box>
              ))}
            </Box>
          </Card>
        )}

        {isNormalContact && (
          <InlineNotes customPath={`/contacts/${contact.id}/notes`} />
        )}

        {isNormalContact && !isConnectionLess && renderConnectionCard()}
      </Grid.Item>
    </Grid>
  );
};

import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import PropertyListItemHeader, {
  IPropertyListAddressSortProps,
  PropertyListItemItem,
} from "../PropertyListItem/PropertyListItem.header";

export interface IPropertyListItemGeneralHeaderProps
  extends IPropertyListAddressSortProps {}

export const PropertyListItemBookkeepingHeader = (
  props: IPropertyListItemGeneralHeaderProps,
) => {
  const { addressOnChangeOrder, addressOrder, selectable } = props;

  // Render
  return (
    <PropertyListItemHeader
      addressOnChangeOrder={addressOnChangeOrder}
      addressOrder={addressOrder}
      selectable={selectable}
    >
      <div style={{ width: 40 }} />

      <PropertyListItemItem
        text={getLocalizedText(
          "property.extra.bookkeeping.item.cost_center.title",
        )}
        style={{ flex: 1 }}
      />

      <PropertyListItemItem
        text={getLocalizedText(
          "property.extra.bookkeeping.item.cost_unit.title",
        )}
        style={{ flex: 1 }}
      />

      <PropertyListItemItem
        text={getLocalizedText(
          "property.extra.bookkeeping.item.bookkeeping_paid_out_start_date.title",
        )}
        style={{ flex: 1 }}
      />

      {/* <div style={{ width: 40 }} />

      <PropertyListItemItem
        text={getLocalizedText("properties.owners")}
        style={{ flex: 1 }}
      />

      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.financial_managers")}
        style={{ flex: 1 }}
      />

      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.technical_managers")}
        style={{ flex: 1 }}
      />
      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.indexation_managers")}
        style={{ flex: 1 }}
      />
      <PropertyListItemSpacer />

      <PropertyListItemItem
        text={getLocalizedText("properties.administrative_managers")}
        style={{ flex: 1 }}
      /> */}
    </PropertyListItemHeader>
  );
};

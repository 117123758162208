import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getStore } from "@rentiohq/shared-frontend/dist/utils/redux/redux.utils";
import { EAssets } from "../../assets";
import { Icon } from "../Icon";
import { SummaryStyles } from "./Summary.styles";
export var Summary = function (_a) {
    var formData = _a.formData, filteredFormSteps = _a.filteredFormSteps, handleGotoStep = _a.handleGotoStep, actions = _a.actions;
    var sx = SummaryStyles();
    var renderSummaryLabel = function (heading, tabIndex) {
        return (_jsxs(Stack, { direction: "row", gap: 2, alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: sx.content.formMainTitle, children: heading }), _jsx(Box, { sx: sx.content.editIcon, onClick: function () { return handleGotoStep(tabIndex); }, children: _jsx(Icon, { path: EAssets.PencilIcon }) })] }));
    };
    var renderSummaryItem = function (fieldSchema) {
        var _a;
        var fieldKey = fieldSchema.fieldKey, title = fieldSchema.title, summaryTitle = fieldSchema.summaryTitle, showIf = fieldSchema.showIf, formatSummary = fieldSchema.formatSummary, valueSelector = fieldSchema.valueSelector, shouldShowOnSummary = fieldSchema.shouldShowOnSummary;
        var label = title || summaryTitle;
        var shouldShow = shouldShowOnSummary
            ? shouldShowOnSummary(formData)
            : true;
        var showField = showIf ? showIf(formData) : true;
        var state = (_a = getStore()) === null || _a === void 0 ? void 0 : _a.getState();
        var value = formData[fieldKey] || "";
        if (valueSelector && state) {
            value = valueSelector(state, formData[fieldKey], formData) || "";
        }
        return shouldShow && showField && label ? (_jsxs(Stack, { direction: "row", gap: 2, children: [_jsx(Typography, { variant: "h5", sx: sx.content.label, children: label }), _jsx(Typography, { variant: "body1", children: String(formatSummary ? formatSummary(value) : value || "-") })] })) : null;
    };
    return (_jsxs(Box, { sx: sx.wrapper, children: [_jsx(Typography, { variant: "h4", sx: sx.content.formMainTitle, mb: 3, children: getLocalizedText("system.summary") }), _jsx(Stack, { direction: "column", gap: 3, children: filteredFormSteps.map(function (formStep, index) {
                    var _a = formStep.uiSchema, _b = _a.heading, heading = _b === void 0 ? "" : _b, fieldsSchema = _a.fields;
                    return (_jsxs(Stack, { direction: "column", gap: 1, children: [renderSummaryLabel(heading, index), _jsx(Stack, { direction: "column", gap: 1, children: fieldsSchema.map(function (fieldSchema) {
                                    return renderSummaryItem(fieldSchema);
                                }) })] }, index));
                }) }), _jsx(Box, { sx: sx.content.actions, children: actions })] }));
};

import { Box } from "@rebass/grid";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { EPaymentRequestFollowUpType } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  AccountList,
  DisplayText,
  ESpacings,
  TextField,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { IPaymentFollowUpScreenProps } from "../PaymentFollowUpModal.types";

export const AppScreen: React.FC<IPaymentFollowUpScreenProps> = ({
  followUpItem,
  postBody,
  renderActivities,
  setPostBody,
  onClose,
}) => {
  // Redux
  const isPendingFollowUpApp =
    useSelector((state: IRootStore) =>
      paymentRequestSelectors.isPendingFollowUp(
        state,
        followUpItem.id,
        EPaymentRequestFollowUpType.App,
      ),
    ) || false;
  const followUpErrorApp =
    useSelector((state: IRootStore) =>
      paymentRequestSelectors.followUpError(
        state,
        followUpItem.id,
        EPaymentRequestFollowUpType.App,
      ),
    ) || false;
  const prevIsPendingFollowUpApp = usePrevious(isPendingFollowUpApp);

  // Hooks
  React.useEffect(() => {
    if (
      !!prevIsPendingFollowUpApp &&
      !isPendingFollowUpApp &&
      !followUpErrorApp
    ) {
      onClose?.();
    }
  }, [prevIsPendingFollowUpApp, isPendingFollowUpApp, followUpErrorApp]);

  // Event handlers
  const handleMessageChange = ({ target: { value } }: any) => {
    setPostBody({ ...postBody, message: value });
  };

  // Render
  return (
    <>
      <DisplayText size="small">
        {getLocalizedText("contact.dashboard.contact_data.heading")}
      </DisplayText>

      <AccountList accounts={[followUpItem.payerAccount] as IAccount[]} />

      <Box mt={ESpacings.base}>
        <TextField
          value={postBody.message}
          name="message"
          label={getLocalizedText("payments.follow_up.app.content.label")}
          multiline={true}
          onChange={handleMessageChange}
        />
      </Box>

      {renderActivities}
    </>
  );
};

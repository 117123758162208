var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { all, call, put, select, takeLatest } from "redux-saga/effects";
import { CONFIG } from "../../config/app.config";
import logger from "../../logger";
import * as documentApi from "../../reduxV2/documents/document.api";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralCreateSuccessText, getLocalizedText, } from "../../utils/i18n/i18n.utils";
import { takeLatestWithUniquePayload } from "../utils/saga.utils";
import * as documentPackageActions from "./documentPackage.actions";
import * as documentPackageApi from "./documentPackage.api";
import * as documentPackageSelectors from "./documentPackage.selectors";
import { getDocumentCategoryId } from "./documentPackage.utils";
var ISWEB = true;
function createDocumentPackageStartFlow(action) {
    var payload, _a, documents, documentPackage_1, documentsToUpload_1, documentIds, presignedUrls, uploadedDocuments, data, unknownError_1, error;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                payload = action.payload;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 6, , 8]);
                _a = payload.documents, documents = _a === void 0 ? [] : _a, documentPackage_1 = __rest(payload, ["documents"]);
                documentsToUpload_1 = documents.filter(function (x) { return typeof x !== "string"; });
                documentIds = documents.filter(function (x) { return typeof x === "string"; });
                return [4 /*yield*/, all((documentsToUpload_1 || []).map(function (attachment) {
                        var _a, _b;
                        var file = {
                            // @ts-ignore
                            name: attachment.name,
                            // @ts-ignore
                            path: (_a = attachment.file) === null || _a === void 0 ? void 0 : _a.path,
                            // @ts-ignore
                            type: (_b = attachment.file) === null || _b === void 0 ? void 0 : _b.mime,
                        };
                        return call(documentApi.uploadDocumentToS3, ISWEB ? attachment : file);
                    }))];
            case 2:
                presignedUrls = _b.sent();
                return [4 /*yield*/, all(presignedUrls.map(function (presignedUrl, index) {
                        var metaData = {
                            categoryId: getDocumentCategoryId({
                                documentPackageType: documentPackage_1.type,
                            }),
                            filename: documentsToUpload_1 && documentsToUpload_1[index].name,
                            isInvisible: true,
                        };
                        return call(documentApi.uploadDocument, presignedUrl, metaData);
                    }))];
            case 3:
                uploadedDocuments = _b.sent();
                return [4 /*yield*/, call(documentPackageApi.createDocumentPackage, __assign(__assign({}, documentPackage_1), { documentsUploadedIds: __spreadArray(__spreadArray([], documentIds, true), uploadedDocuments.map(function (uploadedDocument) { return uploadedDocument.id; }), true) }))];
            case 4:
                data = (_b.sent()).data;
                return [4 /*yield*/, put(documentPackageActions.createDocumentPackage.actions.success(data))];
            case 5:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("document_package"),
                });
                return [3 /*break*/, 8];
            case 6:
                unknownError_1 = _b.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(documentPackageActions.createDocumentPackage.actions.failure({
                        error: error,
                    }))];
            case 7:
                _b.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function revokeDocumentPackageStartFlow(action) {
    var documentPackageId, unknownError_2, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                documentPackageId = action.payload;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(documentPackageApi.revokeDocumentPackage, documentPackageId)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(documentPackageActions.revokeDocumentPackage.actions.success(documentPackageId))];
            case 3:
                _a.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("document_package.revoke.success"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_2 = _a.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(documentPackageActions.revokeDocumentPackage.actions.failure({
                        error: error,
                    }))];
            case 5:
                _a.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getDocumentPackageStartFlow(action) {
    var payload, documentPackageId, data, unknownError_3, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                documentPackageId = payload.documentPackageId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(documentPackageApi.getDocumentPackage, documentPackageId)];
            case 2:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(documentPackageActions.getDocumentPackage.actions.success({
                        documentPackageId: documentPackageId,
                        documentPackage: data,
                    }))];
            case 3:
                _a.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_3 = _a.sent();
                error = unknownError_3;
                logger.logError({ error: error });
                return [4 /*yield*/, put(documentPackageActions.getDocumentPackage.actions.failure({
                        documentPackageId: documentPackageId,
                        error: error,
                    }))];
            case 5:
                _a.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function getDocumentPackagesStartFlow(action) {
    var payload, identifier, refetch, limit, order, filterData, documentPackages, _a, _b, data, isExhausted, unknownError_4, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                payload = action.payload;
                identifier = payload.identifier, refetch = payload.refetch, limit = payload.limit, order = payload.order, filterData = payload.filterData;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 5, , 7]);
                _b = (_a = documentPackageSelectors).getDocumentPackages;
                return [4 /*yield*/, select()];
            case 2:
                documentPackages = _b.apply(_a, [_c.sent(), identifier]);
                return [4 /*yield*/, call(documentPackageApi.getDocumentPackages, refetch ? 0 : (documentPackages || []).length, limit, order, filterData)];
            case 3:
                data = (_c.sent()).data;
                isExhausted = data.length < (limit || CONFIG.DEFAULT_FETCH_LIMIT);
                return [4 /*yield*/, put(documentPackageActions.getDocumentPackages.actions.success({
                        identifier: identifier,
                        documentPackages: data,
                        refetch: refetch,
                        isExhausted: isExhausted,
                    }))];
            case 4:
                _c.sent();
                return [3 /*break*/, 7];
            case 5:
                unknownError_4 = _c.sent();
                error = unknownError_4;
                logger.logError({ error: error });
                return [4 /*yield*/, put(documentPackageActions.getDocumentPackages.actions.failure({
                        error: error,
                        identifier: identifier,
                    }))];
            case 6:
                _c.sent();
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}
function getDocumentPackagesPagedStartFlow(action) {
    var payload, identifier, _a, page, _b, limit, order, filterData, skip, documentPackages, unknownError_5, error;
    return __generator(this, function (_c) {
        switch (_c.label) {
            case 0:
                payload = action.payload;
                identifier = payload.identifier, _a = payload.page, page = _a === void 0 ? 1 : _a, _b = payload.limit, limit = _b === void 0 ? CONFIG.DEFAULT_FETCH_LIMIT : _b, order = payload.order, filterData = payload.filterData;
                _c.label = 1;
            case 1:
                _c.trys.push([1, 4, , 6]);
                skip = (page - 1) * limit;
                return [4 /*yield*/, call(documentPackageApi.getDocumentPackages, skip, limit, order, filterData)];
            case 2:
                documentPackages = (_c.sent()).data;
                return [4 /*yield*/, put(documentPackageActions.getDocumentPackagesPaged.actions.success({
                        identifier: identifier,
                        page: page,
                        documentPackages: documentPackages,
                    }))];
            case 3:
                _c.sent();
                return [3 /*break*/, 6];
            case 4:
                unknownError_5 = _c.sent();
                error = unknownError_5;
                logger.logError({ error: error });
                return [4 /*yield*/, put(documentPackageActions.getDocumentPackagesPaged.actions.failure({
                        error: error,
                        identifier: identifier,
                        page: page,
                    }))];
            case 5:
                _c.sent();
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function remindDocumentPackageSignerStartFlow(action) {
    var payload, documentPackageId, signerAccountId, unknownError_6, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                documentPackageId = payload.documentPackageId, signerAccountId = payload.signerAccountId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 4, , 6]);
                return [4 /*yield*/, call(documentPackageApi.remindDocumentPackageSigner, documentPackageId, signerAccountId)];
            case 2:
                _a.sent();
                return [4 /*yield*/, put(documentPackageActions.remindDocumentPackageSigner.actions.success({
                        documentPackageId: documentPackageId,
                        signerAccountId: signerAccountId,
                    }))];
            case 3:
                _a.sent();
                showAlert({
                    type: "success",
                    message: getLocalizedText("document_package.remind_signer.alert.success"),
                });
                return [3 /*break*/, 6];
            case 4:
                unknownError_6 = _a.sent();
                error = unknownError_6;
                logger.logError({ error: error });
                return [4 /*yield*/, put(documentPackageActions.remindDocumentPackageSigner.actions.failure({
                        documentPackageId: documentPackageId,
                        signerAccountId: signerAccountId,
                        error: error,
                    }))];
            case 5:
                _a.sent();
                showAlert({
                    type: "error",
                    message: getLocalizedText("document_package.remind_signer.alert.error"),
                });
                return [3 /*break*/, 6];
            case 6: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(documentPackageActions.createDocumentPackage.types.START, createDocumentPackageStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(documentPackageActions.revokeDocumentPackage.types.START, revokeDocumentPackageStartFlow)];
            case 2:
                _a.sent();
                return [4 /*yield*/, takeLatest(documentPackageActions.getDocumentPackage.types.START, getDocumentPackageStartFlow)];
            case 3:
                _a.sent();
                return [4 /*yield*/, takeLatestWithUniquePayload(function (_a) {
                        var payload = _a.payload;
                        return payload.identifier;
                    }, documentPackageActions.getDocumentPackages.types.START, getDocumentPackagesStartFlow)];
            case 4:
                _a.sent();
                return [4 /*yield*/, takeLatest(documentPackageActions.getDocumentPackagesPaged.types.START, getDocumentPackagesPagedStartFlow)];
            case 5:
                _a.sent();
                return [4 /*yield*/, takeLatest(documentPackageActions.remindDocumentPackageSigner.types.START, remindDocumentPackageSignerStartFlow)];
            case 6:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;

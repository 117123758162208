import { Flex } from "@rebass/grid";
import ChatsLists from "@rentiohq/shared-frontend/dist/components/components/Chats";
import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import { useDebounce } from "@rentiohq/shared-frontend/dist/hooks/useDebounce";
import * as chatHooks from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.hooks";
import * as chatSelectors from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import * as chatUtils from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import { IChat } from "@rentiohq/shared-frontend/dist/types/chat.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  DisplayText,
  EmptyState,
  Filters,
  Icon,
  Loading,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../services/translationService";
import * as S from "./Chats.styled";
import { ChatConversation } from "./components/ChatConversation";

interface IStateProps {}

export const Chats: React.FC<IStateProps> = React.memo(props => {
  // External hooks
  const navigate = useNavigate();

  const params = useParams<{ chatId: string }>();
  const chatId = params.chatId ? +params.chatId : undefined;

  // State hooks
  const [queryInput, setQueryInput] = React.useState<string>("");
  const [showArchivedChats, setShowArchivedChats] =
    React.useState<boolean>(false);

  const [queryDebounced] = useDebounce(queryInput);

  const chat = useSelector((state: IRootStore) =>
    chatSelectors.getDetail(state, chatId),
  );

  const hasMessagesForNotFetchedChats = useSelector((state: IRootStore) =>
    chatSelectors.hasMessagesForNotFetchedChats(state),
  );

  // Reducer hooks
  const { archive } = chatHooks.useArchive({ id: chatId });

  const {
    items: chats = [],
    isFetching = false,
    refresh,
    loadMore,
    isExhausted,
  } = chatHooks.useInfiniteLoad({
    query: chatUtils.getChatsQuery(
      showArchivedChats
        ? {
            archived: true,
          }
        : {
            search: queryDebounced,
            archived: false,
          },
    ),
  });

  // Lifecycle hooks
  React.useEffect(() => {
    if (chat) {
      document.title = `Rentio | ${chatUtils.getTitle({ chat })}`;
    } else {
      document.title = `Rentio | ${getLocalizedText("system.entity.chats")}`;
    }
  }, [chat]);

  React.useEffect(() => {
    if (hasMessagesForNotFetchedChats) {
      refresh();
    }
  }, [hasMessagesForNotFetchedChats]);

  // Event handlers
  const handlePressChat = ({ id }: IChat) => {
    navigate(`/chats/${id}`);
  };

  const handleSearchChatChange = (newQuery: string) => {
    setQueryInput(newQuery);
  };

  const handleSearchChatRemove = () => {
    setQueryInput("");
  };

  const handleToggleShowArchivedChats = () => {
    setShowArchivedChats(!showArchivedChats);
  };

  const archiveChat = (chatId?: number) => {
    if (!chatId) {
      return;
    }

    confirm({
      title: t.archiveConfirm("chat"),
      type: "warning",
      primaryActions: [
        {
          title: t.system("archive"),
          onPress: () => {
            archive({});
          },
        },
      ],
    });
  };

  // Render
  const renderChats = (chats: IChat[]) => {
    return (
      <ChatsLists
        chats={chats}
        activeChatId={chatId}
        onPressChat={handlePressChat}
      />
    );
  };

  const renderChatList = () => {
    const hasChats = chats.length > 0;
    const emptyChats = chats.length === 0;

    if (hasChats) {
      return (
        <>
          {renderChats(chats)}
          {isFetching && <Loading />}
          <ListItemSeparator />
        </>
      );
    }

    if (isFetching) {
      return <Loading />;
    }

    if (emptyChats) {
      const action: IAction | undefined = !queryDebounced
        ? {
            content: t.chatsListEmptyAction(),
            appearance: "link",
            url: "/chats/add",
          }
        : undefined;

      return (
        <EmptyState isShownInline={true} action={action}>
          {t.chatsListEmptyText()}
        </EmptyState>
      );
    }

    return null;
  };

  const renderViewArchivedButton = () => {
    if (showArchivedChats) {
      return null;
    }

    return (
      <S.ArchivedButtonWrap>
        <Button appearance="link" onClick={handleToggleShowArchivedChats}>
          {t.chatsViewArchived()}
        </Button>
      </S.ArchivedButtonWrap>
    );
  };

  const renderShowAllButton = () => {
    if (isExhausted) {
      return;
    }

    return (
      <S.ShowAllButtonWrap>
        <Button appearance="link" onClick={loadMore}>
          {t.chatsListShowMore()}
        </Button>
      </S.ShowAllButtonWrap>
    );
  };

  const renderContent = () => {
    if (!chatId) {
      return;
    }

    const actions: IAction[] = [
      {
        content: (
          <TextStyle variation={"negative"}>
            {t.chatsArchiveActionTitle()}
          </TextStyle>
        ),
        onClick: () => archiveChat(chatId),
      },
    ];

    return <ChatConversation chatId={chatId} actions={actions} />;
  };

  return (
    <S.Wrapper>
      <S.Sidebar>
        <S.SidebarHeader>
          <Flex justifyContent="space-between" alignItems="center" mb={3}>
            {showArchivedChats ? (
              <>
                <Button
                  appearance="link"
                  onClick={handleToggleShowArchivedChats}
                >
                  {`‹ ${t.chatsTitle()}`}
                </Button>
              </>
            ) : (
              <>
                <DisplayText>{t.chatsTitle()}</DisplayText>
                <Button
                  appearance="primary"
                  size="small"
                  title={t.chatsAddActionTitle()}
                  url="/chats/add"
                >
                  <Icon source="messagesBubbleAdd" />
                </Button>
              </>
            )}
          </Flex>
          {!showArchivedChats && (
            <Filters
              queryValue={queryInput}
              queryPlaceholder={t.chatsSearchPlaceholder()}
              onQueryChange={handleSearchChatChange}
              onQueryClear={handleSearchChatRemove}
            />
          )}
        </S.SidebarHeader>

        {renderChatList()}

        {!isFetching && (
          <S.SidebarFooter>
            {renderViewArchivedButton()}
            {renderShowAllButton()}
          </S.SidebarFooter>
        )}
      </S.Sidebar>
      <S.Content>{renderContent()}</S.Content>
    </S.Wrapper>
  );
});

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { belgiumBounds, mapGooglePlaceToAddress, } from "@rentiohq/shared-frontend/dist/utils/address.google.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { address as addressUtils, object, } from "@rentiohq/web-shared/dist/utils";
import { debounce } from "lodash";
import React, { useMemo } from "react";
import scriptjs from "scriptjs";
import { AsyncAutoComplete } from "../AsyncAutoComplete";
export var AddressAutoComplete = function (_a) {
    var onChange = _a.onChange, value = _a.value, _b = _a.lookupTypes, lookupTypes = _b === void 0 ? ["address"] : _b;
    var _c = React.useState(""), inputValue = _c[0], setInputValue = _c[1];
    var _d = React.useState(null), autocompleteService = _d[0], setAutocompleteService = _d[1];
    var _e = React.useState(null), placesService = _e[0], setPlacesService = _e[1];
    var _f = React.useState(false), isMapsLoaded = _f[0], setIsMapsLoaded = _f[1];
    var _g = React.useState(false), isLoading = _g[0], setIsLoading = _g[1];
    var searchBoxRef = React.useRef();
    React.useEffect(function () {
        var timer = setTimeout(function () {
            var googleMapURL = "https://maps.googleapis.com/maps/api/js?key=".concat(CONFIG.GOOGLE_MAPS_API, "&libraries=places");
            scriptjs(googleMapURL, handleMapsLoaded);
        }, 200);
        return function () {
            clearTimeout(timer);
        };
    }, []);
    React.useEffect(function () {
        if (isMapsLoaded &&
            autocompleteService === null &&
            placesService === null) {
            setAutocompleteService(new window.google.maps.places.AutocompleteService(searchBoxRef.current));
            setPlacesService(new window.google.maps.places.PlacesService(searchBoxRef.current));
        }
    }, [searchBoxRef, isMapsLoaded]);
    React.useEffect(function () {
        if (value) {
            setInputValue(value);
        }
    }, [value]);
    var handleMapsLoaded = function () { return setIsMapsLoaded(true); };
    var getPlacePredictionsBase = function (params) {
        var input = params.input, onComplete = params.onComplete;
        if (!autocompleteService) {
            onComplete([]);
            return;
        }
        setIsLoading(true);
        autocompleteService.getPlacePredictions({
            input: input,
            types: lookupTypes,
            locationBias: belgiumBounds,
        }, function (predictions, status) {
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                onComplete([]);
            }
            var options = [];
            if (predictions) {
                options = predictions.map(function (prediction) { return (__assign({ label: prediction.description, value: prediction.place_id }, prediction)); });
            }
            onComplete(options);
            setIsLoading(false);
        });
    };
    var getPlacePredictions = useMemo(function () { return debounce(getPlacePredictionsBase, 550); }, [autocompleteService]);
    var handleLoadOptions = function (input, callback) {
        var inputSanitized = (input || "").trim();
        var MANUAL_OPTION = {
            label: getLocalizedText("form.address.cta.manual"),
            value: "-1",
        };
        if (inputSanitized.length === 0) {
            callback([MANUAL_OPTION]);
            return;
        }
        getPlacePredictions({
            input: inputSanitized,
            onComplete: function (options) {
                callback(__spreadArray(__spreadArray([], options, true), [MANUAL_OPTION], false));
            },
        });
    };
    var handleChange = function (place) {
        if (!place.place_id) {
            onChange({
                address: { country: "BE" },
                lookupAddress: place.description,
            });
            return;
        }
        placesService.getDetails({
            placeId: place.place_id,
            fields: ["address_component", "opening_hours", "geometry"],
        }, function (googlePlace, status) {
            // @ts-ignore
            if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
                return;
            }
            var address = mapGooglePlaceToAddress(googlePlace);
            onChange({
                address: object.removeEmpty(address),
                lookupAddress: place.description,
            });
            setInputValue(addressUtils.addressObjToString(address));
        });
    };
    // const locationIcon = React.useMemo(
    //   () => <Icon path={EAssets.AlertIcon} />,
    //   [],
    // );
    return (_jsxs(_Fragment, { children: [_jsx("div", { ref: searchBoxRef }), _jsx(AsyncAutoComplete, { customInputValue: inputValue, isLoading: isLoading, onChange: handleChange, loadOptions: handleLoadOptions, 
                // icon={locationIcon}
                placeholder: getLocalizedText("address.full_address.placeholder") })] }));
};

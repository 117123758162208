import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../../themes/types";
export var ChatMessageStyles = function () {
    var theme = useTheme();
    return {
        wrapper: function (_a) {
            var isSender = _a.isSender;
            return ({
                width: "100%",
                display: "flex",
                justifyContent: isSender ? "flex-end" : "flex-start",
            });
        },
        content: {
            sentMessage: {
                width: "fit-content",
                display: "flex",
                padding: "16px",
                flexDirection: "column",
                gap: "4px",
                borderRadius: "8px",
                background: theme.palette.success.main,
                fontWeight: EFontWeights.Regular,
                ".MuiTypography-root": {
                    color: theme.palette.text.white,
                },
                ".MuiTypography-root > div": {
                    color: theme.palette.text.white,
                },
            },
            sentDate: {
                width: "100%",
                textAlign: "right",
                color: theme.palette.text.white,
                fontWeight: EFontWeights.Regular,
            },
            receivedMessage: {
                width: "fit-content",
                display: "flex",
                padding: "16px",
                flexDirection: "column",
                gap: "4px",
                borderRadius: "8px",
                background: theme.palette.grey[50],
                fontWeight: EFontWeights.Regular,
                ".MuiTypography-root": {
                    color: theme.palette.grey[900],
                },
                ".MuiTypography-root > div": {
                    color: theme.palette.grey[900],
                },
            },
            receivedDate: {
                width: "100%",
                textAlign: "right",
                color: "".concat(theme.palette.grey[600], " !important"),
                fontWeight: EFontWeights.Regular,
            },
        },
    };
};

var _a, _b, _c;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./legalCase.constants";
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, 
// getInfiniteLoadStart,
// getInfiniteLoadFailed,
// getInfiniteLoadSucceeded,
createWithoutDocumentsStart = _a.createStart, createWithoutDocumentsSucceeded = _a.createSucceeded, createWithoutDocumentsFailed = _a.createFailed, updateWithoutDocumentsStart = _a.updateStart, updateWithoutDocumentsSucceeded = _a.updateSucceeded, updateWithoutDocumentsFailed = _a.updateFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed;
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["CreateWithDocuments"] = "createWithDocuments";
    EActionCustom["UpdateWithDocuments"] = "updateWithDocuments";
})(EActionCustom || (EActionCustom = {}));
var generateCreateLegalWithDocumentCaseActions = function (params) {
    var entity = params.entity;
    var createWithDocumentsStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.CreateWithDocuments,
        state: EActionState.Start,
    });
    var createWithDocumentsSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.CreateWithDocuments,
        state: EActionState.Success,
    });
    var createWithDocumentsFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.CreateWithDocuments,
        state: EActionState.Fail,
    });
    return {
        createWithDocumentsStart: createWithDocumentsStart,
        createWithDocumentsSucceeded: createWithDocumentsSucceeded,
        createWithDocumentsFailed: createWithDocumentsFailed,
    };
};
var generateUpdateLegalWithDocumentCaseActions = function (params) {
    var entity = params.entity;
    var updateWithDocumentsStart = createCustomAction({
        entity: entity,
        custom: EActionCustom.UpdateWithDocuments,
        state: EActionState.Start,
    });
    var updateWithDocumentsSucceeded = createCustomAction({
        entity: entity,
        custom: EActionCustom.UpdateWithDocuments,
        state: EActionState.Success,
    });
    var updateWithDocumentsFailed = createCustomAction({
        entity: entity,
        custom: EActionCustom.UpdateWithDocuments,
        state: EActionState.Fail,
    });
    return {
        updateWithDocumentsStart: updateWithDocumentsStart,
        updateWithDocumentsSucceeded: updateWithDocumentsSucceeded,
        updateWithDocumentsFailed: updateWithDocumentsFailed,
    };
};
export var createWithDocumentsFailed = (_b = generateCreateLegalWithDocumentCaseActions(GENERATOR_PARAMS), _b.createWithDocumentsFailed), createWithDocumentsStart = _b.createWithDocumentsStart, createWithDocumentsSucceeded = _b.createWithDocumentsSucceeded;
export var updateWithDocumentsStart = (_c = generateUpdateLegalWithDocumentCaseActions(GENERATOR_PARAMS), _c.updateWithDocumentsStart), updateWithDocumentsSucceeded = _c.updateWithDocumentsSucceeded, updateWithDocumentsFailed = _c.updateWithDocumentsFailed;

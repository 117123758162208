var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { useFormik } from "formik";
import React from "react";
import { generateValidationSchema } from "../../utils/form";
import { MuiDialog } from "../Dialog";
import { FormStep } from "../FormStep";
import { Summary } from "../Summary";
import { MultiStepFormStyles } from "./MultiStepForm.styles";
export var MultiStepForm = function (props) {
    var sx = MultiStepFormStyles();
    var _a = React.useState(null), validationSchema = _a[0], setValidationSchema = _a[1];
    var onSubmit = props.onSubmit, _b = props.showSummary, showSummary = _b === void 0 ? false : _b, getSchema = props.getSchema, renderAsModal = props.renderAsModal, modalProps = props.modalProps, returnFilteredSteps = props.returnFilteredSteps, actions = props.actions, summaryActions = props.summaryActions, WrapperComponent = props.wrapperComponent, handleGotoStep = props.handleGotoStep, activeStepIndex = props.activeStepIndex;
    var _c = modalProps || {}, _d = _c.modalTitle, modalTitle = _d === void 0 ? "" : _d, _e = _c.openDialog, openDialog = _e === void 0 ? false : _e, handleClose = _c.handleClose, _f = _c.modalActions, modalActions = _f === void 0 ? [] : _f, maxWidth = _c.maxWidth, width = _c.width;
    var _g = React.useMemo(function () {
        return getSchema();
    }, [getSchema]), initialValues = _g.initialValues, formSteps = _g.formStepsSchema;
    var formik = useFormik({
        initialValues: initialValues,
        onSubmit: onSubmit,
        validationSchema: validationSchema,
    }) || {};
    var getFilteredFormSteps = function () {
        return formSteps.filter(function (formStep) {
            return formStep.showIf ? formStep.showIf(formik === null || formik === void 0 ? void 0 : formik.values) : true;
        });
    };
    var filteredFormSteps = React.useMemo(function () { return getFilteredFormSteps(); }, [formik.values]);
    React.useEffect(function () {
        returnFilteredSteps(filteredFormSteps);
    }, [JSON.stringify(filteredFormSteps)]);
    React.useEffect(function () {
        var activeStep = filteredFormSteps[activeStepIndex];
        if (!activeStep) {
            return;
        }
        var stepFields = activeStep.uiSchema.fields;
        if (stepFields.length) {
            setValidationSchema(generateValidationSchema(formik, stepFields));
        }
    }, [activeStepIndex, formik.values]);
    var renderFormStep = function (_a) {
        var step = _a.step, actions = _a.actions, showHeading = _a.showHeading, sx = _a.sx;
        return (_jsx(Box, { sx: sx, children: _jsx(FormStep, { formStep: step, formikProps: formik, actions: actions, showHeading: showHeading }) }));
    };
    var renderSummary = function () {
        return (_jsx(Summary, { filteredFormSteps: filteredFormSteps, formData: formik.values, actions: summaryActions, handleGotoStep: handleGotoStep }));
    };
    var renderForm = function () {
        var isSingleStepForm = (formSteps === null || formSteps === void 0 ? void 0 : formSteps.length) === 1;
        return (_jsxs(Box, { children: [filteredFormSteps.map(function (formStep, index) { return (_jsx(WrapperComponent, { stepIndex: index, children: renderFormStep({
                        step: formStep,
                        actions: actions,
                        sx: isSingleStepForm
                            ? __assign(__assign({}, sx.singleStepWrapper), { p: renderAsModal ? 0 : 2 }) : { pl: 2 },
                        showHeading: !isSingleStepForm,
                    }) }, index)); }), showSummary && (_jsx(WrapperComponent, { stepIndex: filteredFormSteps === null || filteredFormSteps === void 0 ? void 0 : filteredFormSteps.length, children: renderSummary() }))] }));
    };
    var renderFormWrapper = function () {
        if (renderAsModal) {
            return (_jsx(MuiDialog, { title: modalTitle, open: openDialog, maxWidth: maxWidth || "1000px", width: width || "1000px", toggleOpen: handleClose, actions: modalActions, children: _jsx("form", { onSubmit: formik.handleSubmit, style: { width: "100%" }, children: renderForm() }) }));
        }
        return renderForm();
    };
    return (_jsx("form", { onSubmit: formik.handleSubmit, style: { width: "100%" }, children: renderFormWrapper() }));
};

import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { bankAccountModule } from "@rentiohq/shared-frontend/dist/reduxV2/bankAccount/bankAccount.module";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { getTerm } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatIban } from "@rentiohq/shared-frontend/dist/utils/iban.utils";
import { Icon, Stack, TextStyle } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { renderContractNames } from "utils/contract";

interface IProps {
  contractId?: string;
  showIbanisationInfo: boolean;
}

export const ContractInfoCardFooter = (props: IProps) => {
  const { showIbanisationInfo } = props;
  const { contractId } = props;

  const { internalModeEnabled } = useInternalMode();
  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const { detail: contract } = contractHooks.useDetail({
    shouldRefetch: false,
    id: contractId,
  });

  const { detail: ibanisationBankAccount } = bankAccountModule.hooks.useDetail({
    shouldRefetch: false,
    skipLegacy: internalModeEnabled && hasPaymentV2ForBroker,
    customPath:
      showIbanisationInfo && contractId
        ? `/contracts/${contractId}/ibanisation-bank-account`
        : undefined,
  });

  // Render
  const stackItems = [];

  if (ibanisationBankAccount) {
    stackItems.push(
      <Stack alignment="center" spacing="tight">
        <TextStyle variation={"subdued"}>
          <Icon source={"billStack"} size="small" />
        </TextStyle>

        <TextStyle variation={"subdued"}>
          {formatIban(ibanisationBankAccount.iban)}
        </TextStyle>
      </Stack>,
    );
  }

  if (contract) {
    stackItems.push(
      <Stack alignment="center" spacing="tight">
        <TextStyle variation={"subdued"}>
          <Icon source={"leaseContract"} size="small" />
        </TextStyle>

        <TextStyle variation={"subdued"}>
          {getTerm(contract, { extended: true })}
        </TextStyle>
      </Stack>,
    );

    stackItems.push(
      <Stack alignment="center" spacing="tight">
        <TextStyle variation={"subdued"}>
          <Icon source={"contact"} size="small" />
        </TextStyle>

        {renderContractNames({ contract })}
      </Stack>,
    );
  }

  if (!contractId) {
    stackItems.push(
      <Stack alignment="center" spacing="tight">
        <TextStyle variation={"subdued"}>
          <Icon source={"leaseContract"} size="small" />
        </TextStyle>

        <TextStyle variation={"subdued"}>
          {getLocalizedText("system.no_contract")}
        </TextStyle>
      </Stack>,
    );
  }

  if (stackItems.length === 0) {
    return null;
  }

  return (
    <Stack alignment="center" spacing="loose">
      {stackItems}
    </Stack>
  );
};

export var EAddressField;
(function (EAddressField) {
    EAddressField["Street"] = "street";
    EAddressField["Number"] = "number";
    EAddressField["Box"] = "box";
    EAddressField["Zip"] = "zip";
    EAddressField["City"] = "city";
    EAddressField["State"] = "state";
    EAddressField["Country"] = "country";
})(EAddressField || (EAddressField = {}));

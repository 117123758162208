import {
  IFormikValues,
  IFormStep,
} from "@rentiohq/web-shared-next/dist/utils/types/form";
import { uiSchema } from "./schema.addChat";
import { IExtraData } from "./schema.createChat.types";
import { getInitialValues } from "./schema.createChat.utils";

export const addChatSchema =
  (extraData: IExtraData) =>
  (): { formStepsSchema: IFormStep[]; initialValues: IFormikValues } => {
    const initialValues = getInitialValues(extraData);
    const formStepsSchema = [
      {
        uiSchema: uiSchema(extraData),
      },
    ];

    return { formStepsSchema, initialValues };
  };

import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import * as propertySelectors from "@rentiohq/shared-frontend/dist/reduxV2/property/property.selectors";
import { EPropertyFetchType } from "@rentiohq/shared-frontend/dist/reduxV2/property/property.types";
import * as propertyUtils from "@rentiohq/shared-frontend/dist/reduxV2/property/property.utils";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Loading, Page } from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../../../services/translationService";
import { PropertyForm } from "./PropertyForm";

interface IProps {
  isDuplicate?: boolean;
}

export const PropertyFormContainer: React.FC<IProps> = props => {
  const { isDuplicate } = props;

  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const contactMeMaster = useSelector((state: IRootStore) =>
    contactSelectors.getContactMeMaster(state),
  );
  const { user, isBroker } = authHooks.useSelf();

  const { detail: property, isFetching } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: propertyId,
  });

  const groupsCount = useSelector((state: IRootStore) =>
    propertySelectors.getCount(
      state,
      propertyUtils.getFilter(EPropertyFetchType.Groups),
    ),
  );
  const isFetchingGroupsCount = useSelector((state: IRootStore) =>
    propertySelectors.isFetchingCount(
      state,
      propertyUtils.getFilter(EPropertyFetchType.Groups),
    ),
  );

  if (!user) {
    return null;
  }

  const renderContent = () => {
    if (!!propertyId && isFetching) {
      return <Loading />;
    }

    if (isFetchingGroupsCount) {
      return <Loading />;
    }

    if (!contactMeMaster) {
      return <Loading />;
    }

    return (
      <PropertyForm
        isDuplicate={isDuplicate}
        property={property}
        groupsCount={groupsCount}
        brokerContact={isBroker ? contactMeMaster : undefined}
      />
    );
  };

  let title = t.propertyCreateTitle();
  if (propertyId) {
    if (isDuplicate) {
      title = getLocalizedText("properties.duplicate");
    } else {
      title = t.propertyEditTitle();
    }
  }

  return (
    <Page
      title={title}
      metadata={property ? formatAddress(property) : undefined}
      loading={isFetching}
      breadcrumbs={
        propertyId
          ? {
              to: `/properties/${propertyId}`,
              content: t.propertyDetailDashboardBreadcrumb(),
            }
          : { to: "/properties", content: t.propertyDetailBreadcrumb() }
      }
    >
      {renderContent()}
    </Page>
  );
};

import { useTheme } from "@mui/material/styles";
export var ChatMessageInputStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            pl: { xs: 2, md: 0 },
            pr: { xs: 2, md: 0 },
        },
        content: {
            outlinedInput: {
                background: "#EEF2F6",
                ".MuiOutlinedInput-input": {
                    background: "inherit",
                },
                ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "#E3E8EF",
                },
            },
            inputAdornment: {
                zIndex: 1,
                background: "#EEF2F6",
            },
            greyIcon: {
                color: theme.palette.grey[600],
                cursor: "pointer",
            },
            greenIcon: {
                color: theme.palette.success.main,
                cursor: "pointer",
            },
            attachmentWrapper: {
                position: "relative",
                background: "#fff",
                border: "1px solid #ccc",
                borderRadius: "4px",
                padding: "2px",
                cursor: "pointer",
            },
            removeAttachment: {
                width: "16px",
                position: "absolute",
                top: "-6px",
                right: "-6px",
                background: "#fff",
                height: "16px",
                border: "1px solid #ccc",
                borderRadius: "50%",
                padding: "2px",
                color: "#000",
            },
            attachmentText: {
                fontSize: "24px",
            },
            attachmentImage: {
                width: "32px",
                "object-fit": "contain",
            },
        },
    };
};

import { jsx as _jsx } from "react/jsx-runtime";
import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue, getValueOrExtraValue, } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToCamelCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import VariableInfo from "../../../Editor/components/VariableInfo";
import { DEFAULT_GROUPS, DEFAULT_VARIABLES, } from "../../../Editor/Editor.config";
import { VARIABLE_DATE_FORMATS } from "../../../Editor/Editor.constants";
import { EVariableType } from "../../../Editor/Editor.types";
import { OPTION_NEW, getGroupOptions, getNameOptions, } from "../variableForm.utils";
import { EField } from "./schema.insertVariable.types";
var NOW = new Date();
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        var fields = extraData.fields;
        return {
            required: [EField.Name],
            properties: (_a = {},
                _a[EField.Header1] = {
                    default: undefined,
                },
                _a[EField.Name] = {
                    default: getValue(EField.Name, initialValues, defaultValues),
                    // @ts-ignore
                    options: getNameOptions({ fields: fields, withNewOption: true }),
                },
                _a[EField.NameNew] = {
                    default: getValue(EField.NameNew, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function (formData) { return formData[EField.Name] === OPTION_NEW; },
                    // @ts-ignore
                    showIf: function (formData) { return formData[EField.Name] === OPTION_NEW; },
                },
                _a[EField.Header2] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function (formData) { return formData[EField.Name] === OPTION_NEW; },
                },
                _a[EField.Group] = {
                    default: getValue(EField.Group, initialValues, defaultValues),
                    // @ts-ignore
                    options: getGroupOptions({
                        fields: fields,
                    }),
                    requiredIf: function (formData) { return formData[EField.Name] === OPTION_NEW; },
                    // @ts-ignore
                    showIf: function (formData) { return formData[EField.Name] === OPTION_NEW; },
                },
                _a[EField.GroupNew] = {
                    default: getValue(EField.GroupNew, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Group] === OPTION_NEW;
                    },
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Group] === OPTION_NEW;
                    },
                },
                _a[EField.Header3] = {
                    default: undefined,
                    // @ts-ignore
                    showIf: function (formData) { return formData[EField.Name] === OPTION_NEW; },
                },
                _a[EField.InfoBox1] = {
                    default: getValue(EField.Name, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return ![undefined, OPTION_NEW].includes(formData[EField.Name]);
                    },
                    handleChange: function (fieldName, fieldValue, myFieldValue, formData) {
                        if (fieldName !== EField.Name) {
                            return { shouldSetNewValue: false };
                        }
                        return {
                            shouldSetNewValue: true,
                            newValue: fieldValue,
                        };
                    },
                },
                _a[EField.Type] = {
                    default: getValue(EField.Type, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(EVariableType).map(function (id) { return ({
                        value: getLocalizedText("editor.variable.type.".concat(id)),
                        id: id,
                    }); }),
                    // @ts-ignore
                    showIf: function (formData) { return formData[EField.Name] === OPTION_NEW; },
                },
                _a[EField.EditableByTenant] = {
                    default: getValue(EField.EditableByTenant, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        {
                            value: getLocalizedText("system.no"),
                            id: "no",
                        },
                        {
                            value: getLocalizedText("system.yes"),
                            id: "yes",
                        },
                    ],
                    // @ts-ignore
                    showIf: function (formData) { return formData[EField.Name] === OPTION_NEW; },
                },
                _a[EField.Required] = {
                    default: getValue(EField.Required, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        {
                            value: getLocalizedText("system.no"),
                            id: "no",
                        },
                        {
                            value: getLocalizedText("system.yes"),
                            id: "yes",
                        },
                    ],
                    // @ts-ignore
                    showIf: function (formData) { return formData[EField.Name] === OPTION_NEW; },
                },
                _a[EField.DefaultText] = {
                    default: getValue(EField.DefaultText, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            [EVariableType.String, EVariableType.Dropdown].includes(formData[EField.Type]);
                    },
                },
                _a[EField.IsMultilineText] = {
                    default: getValueOrExtraValue(EField.IsMultilineText, defaultValues, "no"),
                    // @ts-ignore
                    options: [
                        {
                            value: getLocalizedText("system.no"),
                            id: "no",
                        },
                        {
                            value: getLocalizedText("system.yes"),
                            id: "yes",
                        },
                    ],
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.String;
                    },
                },
                _a[EField.DefaultDate] = {
                    default: getValue(EField.DefaultDate, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.Date;
                    },
                },
                _a[EField.DefaultDateFormatting] = {
                    default: getValue(EField.DefaultDateFormatting, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.Date;
                    },
                    // @ts-ignore
                    options: VARIABLE_DATE_FORMATS.map(function (id) { return ({
                        value: formatDate(NOW, id),
                        id: id,
                    }); }),
                },
                _a[EField.MinDate] = {
                    default: getValue(EField.MinDate, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.Date;
                    },
                },
                _a[EField.MaxDate] = {
                    default: getValue(EField.MaxDate, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.Date;
                    },
                },
                _a[EField.DefaultNumber] = {
                    default: getValue(EField.DefaultNumber, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.Number;
                    },
                },
                _a[EField.MinNumber] = {
                    default: getValue(EField.MinDate, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.Number;
                    },
                },
                _a[EField.MaxNumber] = {
                    default: getValue(EField.MaxNumber, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.Number;
                    },
                },
                _a[EField.DefaultToggle] = {
                    default: getValueOrExtraValue(EField.DefaultToggle, defaultValues, "no"),
                    // @ts-ignore
                    options: [
                        {
                            value: getLocalizedText("system.no"),
                            id: "no",
                        },
                        {
                            value: getLocalizedText("system.yes"),
                            id: "yes",
                        },
                    ],
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.Boolean;
                    },
                },
                _a[EField.DropdownValues] = {
                    default: getValue(EField.DropdownValues, initialValues, defaultValues),
                    // @ts-ignore
                    showIf: function (formData) {
                        return formData[EField.Name] === OPTION_NEW &&
                            formData[EField.Type] === EVariableType.Dropdown;
                    },
                },
                _a[EField.Persisted] = {
                    default: getValue(EField.Persisted, initialValues, defaultValues),
                    // @ts-ignore
                    options: [
                        {
                            value: getLocalizedText("system.no"),
                            id: "no",
                        },
                        {
                            value: getLocalizedText("system.yes"),
                            id: "yes",
                        },
                    ],
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {},
        _a[EField.Header1] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("editor.insert_custom.insert_variable.header.variable"),
        },
        _a[EField.Name] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.variable"),
            isDisabled: function () { return extraData.forceCreateNewVariable || false; },
        },
        _a[EField.NameNew] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.variable_new"),
        },
        _a[EField.Header2] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("editor.insert_custom.insert_variable.header.group"),
        },
        _a[EField.Group] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.group"),
        },
        _a[EField.GroupNew] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.group_new"),
        },
        _a[EField.Header3] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("editor.insert_custom.insert_variable.header.meta"),
        },
        _a[EField.InfoBox1] = {
            "ui:field": ERentioFormField.HelpInfo,
            title: getLocalizedText("editor.insert_custom.insert_variable.info_existing"),
            getSubtitle: function (_, allFormData, name) {
                var variable = extraData.fields.variables.find(function (x) { return x.id === name; }) ||
                    DEFAULT_VARIABLES.find(function (x) { return x.id === name; });
                var group = extraData.fields.groups.find(function (x) { return x.id === (variable === null || variable === void 0 ? void 0 : variable.groupId); }) ||
                    DEFAULT_GROUPS.find(function (x) { return x.id === (variable === null || variable === void 0 ? void 0 : variable.groupId); });
                if (!variable || !group) {
                    return "Variable/group not found";
                }
                return _jsx(VariableInfo, { variable: variable, group: group });
            },
        },
        _a[EField.Type] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.type"),
        },
        _a[EField.EditableByTenant] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.editable_by_tenant"),
        },
        _a[EField.Required] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.required"),
        },
        _a[EField.DefaultText] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.default_text"),
            optional: true,
            placeholder: "-",
        },
        _a[EField.IsMultilineText] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.is_multiline_text"),
        },
        _a[EField.DefaultDate] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.default_date"),
            optional: true,
            placeholder: formatDate(new Date()),
        },
        _a[EField.DefaultDateFormatting] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.default_date_formatting"),
            optional: true,
            placeholder: formatDate(NOW, VARIABLE_DATE_FORMATS[0]),
        },
        _a[EField.MinDate] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.min_date"),
            optional: true,
            placeholder: formatDate(new Date()),
        },
        _a[EField.MaxDate] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.max_date"),
            optional: true,
            placeholder: formatDate(new Date()),
        },
        _a[EField.DefaultNumber] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.default_number"),
            optional: true,
            placeholder: "0",
        },
        _a[EField.MinNumber] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.min_number"),
            optional: true,
            placeholder: "0",
        },
        _a[EField.MaxNumber] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.max_number"),
            optional: true,
            placeholder: "0",
        },
        _a[EField.DefaultToggle] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.default_toggle"),
        },
        _a[EField.DropdownValues] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.dropdown_values"),
        },
        _a[EField.Persisted] = {
            "ui:field": ERentioFormField.SegmentedControl,
            title: getLocalizedText("editor.insert_custom.insert_variable.field.is_persisted"),
        },
        _a);
};
export var getValidate = function (extraData) { return function (formData, errors) {
    var fields = extraData.fields;
    if (formData[EField.NameNew]) {
        var newName = formData[EField.NameNew];
        var newNameAsId_1 = stringToCamelCase(newName);
        var nameOptions = getNameOptions({
            fields: fields,
            withNewOption: false,
        });
        var existingOption = nameOptions.find(function (option) { return option.id === newNameAsId_1; });
        if (existingOption) {
            errors[EField.NameNew].addError(getLocalizedText("editor.insert_custom.insert_variable.field.name_new.error.already_exists"));
        }
    }
    return errors;
}; };

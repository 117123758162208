import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import { IPartialRootState } from "@rentiohq/shared-frontend/dist/redux/types";
import * as paymentOrderActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentOrder/paymentOrder.actions";
import * as paymentOrderSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentOrder/paymentOrder.selectors";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { appendWhere } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Page } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { TabAction } from "@rentiohq/web-shared/dist/types";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation } from "react-router-dom";
import { PaymentHistory } from "./PaymentHistory";
import { PaymentOrders } from "./PaymentOrders";
import { PaymentOrders as PaymentOrdersV2 } from "./PaymentOrdersV2";

const PATH_PAYMENTS_OVERVIEW = "/payments-overview";

const PATH_PAYMENT_ORDERS = `${PATH_PAYMENTS_OVERVIEW}/payment-orders`;
const PATH_PAYMENT_HISTORY = `${PATH_PAYMENTS_OVERVIEW}/payment-history`;

export const PaymentsOverview = () => {
  // Hooks
  const location = useLocation();

  const dispatch = useDispatch();

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const paymentOrdersCountV2 = useSelector((state: IPartialRootState) =>
    paymentOrderSelectors.getCount(state, {
      filter: {
        completedAt: { is: null },
      },
    }),
  );

  React.useEffect(() => {
    if (internalModeEnabled && hasPaymentV2ForBroker) {
      dispatch(
        paymentOrderActions.getCountStart.getAction({
          query: {
            filter: {
              completedAt: { is: null },
            },
          },
        }),
      );
    }
  }, [internalModeEnabled, hasPaymentV2ForBroker]);

  const { count: paymentOrdersCount } = useCount({
    shouldRefetch: false,
    countBase: appendWhere("payment-orders/count", {
      completedAt: null,
    }),
  });

  const getTabs = () => {
    const tabs: TabAction[] = [];

    tabs.push({
      content: getLocalizedText("payments.follow_up.payment_history.heading"),
      url: PATH_PAYMENT_HISTORY,
      isActive: !!matchPath(PATH_PAYMENT_HISTORY, location.pathname),
    });

    tabs.push({
      content: getLocalizedText("payments.follow_up.payment_orders.heading"),
      url: PATH_PAYMENT_ORDERS,
      isActive: !!matchPath(PATH_PAYMENT_ORDERS, location.pathname),
      count:
        internalModeEnabled && hasPaymentV2ForBroker
          ? paymentOrdersCountV2
          : paymentOrdersCount,
    });

    return tabs;
  };

  const renderContent = () => {
    if (!!matchPath(PATH_PAYMENT_ORDERS, location.pathname)) {
      return internalModeEnabled && hasPaymentV2ForBroker ? (
        <PaymentOrdersV2 />
      ) : (
        <PaymentOrders />
      );
    }

    if (!!matchPath(PATH_PAYMENT_HISTORY, location.pathname)) {
      return <PaymentHistory />;
    }

    return <PaymentHistory />;
  };

  return (
    <Page
      title={getLocalizedText("payments_overview.heading")}
      metadata={getLocalizedText("payments_overview.page.description")}
      fullWidth
      tabs={getTabs()}
    >
      {renderContent()}
    </Page>
  );
};

import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EField, IExtraData } from "./schema.followUpSms.types";

export const getInitialValues = ({ contact }: IExtraData): IValues => {
  const initialValues = {
    [EField.SmsNumber]: contact.phone,
    [EField.SmsContent]: getLocalizedText("payments.follow_up.sms.content", {
      contact: contact?.firstname || "user",
    }).substring(0, 160),
  };

  return initialValues;
};

export var SegmentedControlStyles = function () {
    return {
        wrapper: {
            background: "#eef2f6",
            marginBottom: 0,
            borderBottom: "none",
            borderRadius: "8px",
            ".MuiTabs-scroller": {
                width: "100%",
                paddingRight: 0,
                ".MuiTabs-flexContainer": {
                    width: "-webkit-fill-available",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "0 6px",
                    columnGap: "6px",
                    background: "transparent",
                    ".MuiButtonBase-root": {
                        minHeight: "40px",
                        height: "40px",
                        borderRadius: "4px",
                        maxWidth: "100%",
                        background: "transparent",
                        flex: 1,
                        "&.Mui-selected": {
                            background: "#fff",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0px 0px 8px",
                        },
                    },
                },
                ".MuiTabs-indicator": {
                    display: "none",
                },
            },
        },
        content: {},
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { EButtonVariants } from "../../utils/types/button";
import { MultiStepForm } from "../MultiStepForm";
import { TabPanel } from "../TabPanel";
import { ETabOrientation, Tabs } from "../Tabs";
import { FormStyles } from "./Form.styles";
export var Form = function (props) {
    var sx = FormStyles();
    var _a = props.renderAsModal, renderAsModal = _a === void 0 ? false : _a, modalProps = props.modalProps, isSubmitting = props.isSubmitting, submitError = props.submitError, onSubmit = props.onSubmit, _b = props.showSummary, showSummary = _b === void 0 ? false : _b, getSchema = props.getSchema, asMultiStep = props.asMultiStep;
    var _c = React.useState(0), activeStepIndex = _c[0], setActiveStepIndex = _c[1];
    var _d = React.useState(false), editFromSummary = _d[0], setEditFromSummary = _d[1];
    var _e = React.useState([]), filteredFormSteps = _e[0], setFilteredFormSteps = _e[1];
    var handleBackToSummary = function () {
        setActiveStepIndex(filteredFormSteps.length);
    };
    var handlePrevious = function () {
        setEditFromSummary(false);
        setActiveStepIndex(function (prevState) { return prevState - 1; });
    };
    var handleGotoStep = function (tabIndex) {
        setActiveStepIndex(tabIndex);
        setEditFromSummary(true);
    };
    var handleOnSubmit = function (values) {
        var stepsLength = showSummary
            ? (filteredFormSteps === null || filteredFormSteps === void 0 ? void 0 : filteredFormSteps.length) + 1
            : filteredFormSteps === null || filteredFormSteps === void 0 ? void 0 : filteredFormSteps.length;
        var isLastStep = activeStepIndex === stepsLength - 1;
        var hasMoreSteps = activeStepIndex < stepsLength - 1;
        if (isLastStep && values) {
            onSubmit(values);
            return;
        }
        if (editFromSummary && hasMoreSteps) {
            setActiveStepIndex(filteredFormSteps === null || filteredFormSteps === void 0 ? void 0 : filteredFormSteps.length);
            return;
        }
        if (hasMoreSteps) {
            setActiveStepIndex(activeStepIndex + 1);
        }
    };
    var summaryActions = (_jsxs(Stack, { direction: "row", gap: 1, children: [_jsx(Button, { variant: EButtonVariants.Outlined, onClick: handlePrevious, children: getLocalizedText("system.previous") }), _jsx(Button, { variant: EButtonVariants.Contained, type: "submit", children: getLocalizedText("system.save") })] }));
    var renderActions = function () {
        var isSingleStepForm = (filteredFormSteps === null || filteredFormSteps === void 0 ? void 0 : filteredFormSteps.length) === 1;
        if (isSingleStepForm) {
            return (_jsx(Stack, { direction: "row", justifyContent: "flex-end", children: _jsx(Button, { variant: EButtonVariants.Contained, type: "submit", children: getLocalizedText("system.save") }) }));
        }
        if (editFromSummary) {
            return (_jsxs(Stack, { direction: "row", gap: 1, children: [_jsx(Button, { variant: EButtonVariants.Outlined, onClick: handleBackToSummary, children: getLocalizedText("forms.cta.back_to_summary") }), _jsx(Button, { variant: EButtonVariants.Contained, type: "submit", children: getLocalizedText("system.update") })] }));
        }
        return (_jsxs(Stack, { direction: "row", gap: 1, children: [activeStepIndex > 0 && (_jsx(Button, { variant: EButtonVariants.Outlined, onClick: handlePrevious, children: getLocalizedText("system.previous") })), _jsx(Button, { variant: EButtonVariants.Contained, type: "submit", children: getLocalizedText("system.next") })] }));
    };
    var tabLabel = function (_a) {
        var count = _a.count, title = _a.title;
        return (_jsxs(Stack, { direction: "row", gap: 3, children: [_jsx("span", { children: count }), _jsx("span", { children: title })] }));
    };
    var getTabs = function () {
        var tabs = filteredFormSteps.map(function (_a, index) {
            var uiSchema = _a.uiSchema;
            return {
                label: tabLabel({ count: index + 1, title: uiSchema.stepTitle || "" }),
                tabIndex: index,
                isDisabled: index > activeStepIndex,
            };
        });
        if (showSummary) {
            var tabIndex = filteredFormSteps.length;
            tabs.push({
                label: tabLabel({
                    count: tabIndex + 1,
                    title: getLocalizedText("system.summary"),
                }),
                tabIndex: tabIndex,
                isDisabled: tabIndex > activeStepIndex,
            });
        }
        return tabs;
    };
    var TabPanelWrapper = function (_a) {
        var children = _a.children, stepIndex = _a.stepIndex;
        return asMultiStep ? (_jsx(TabPanel, { value: stepIndex, activeTab: activeStepIndex, children: children })) : (_jsx("div", { children: children }));
    };
    var tabs = getTabs();
    return (_jsxs(Box, { sx: __assign(__assign({}, sx.multiStepWrapper), { visibility: renderAsModal ? "hidden" : "visible" }), children: [asMultiStep && (_jsx(Tabs, { orientation: ETabOrientation.Vertical, tabs: tabs, activeTab: activeStepIndex, handleTabChange: function (_, newKey) { return setActiveStepIndex(newKey); } })), _jsx(MultiStepForm, { isSubmitting: isSubmitting, submitError: submitError, onSubmit: handleOnSubmit, showSummary: showSummary, getSchema: getSchema, renderAsModal: renderAsModal, modalProps: modalProps, actions: renderActions(), activeStepIndex: activeStepIndex, summaryActions: summaryActions, returnFilteredSteps: setFilteredFormSteps, handleGotoStep: handleGotoStep, wrapperComponent: TabPanelWrapper })] }));
};

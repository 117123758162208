import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as paymentOrderActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentOrder/paymentOrder.actions";
import {
  EPaymentOrderOwnerPaymentMethod,
  EPaymentOrderType,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { IPaymentRequest } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { MultiStepForm } from "@rentiohq/web-shared/dist/components";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import {
  isPerformingSubmitActionSelector,
  manuallyMarkPaidSchemas,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "./form";

interface IProps {
  paymentRequest: IPaymentRequest;
  onClose: () => void;
  onSuccess: () => void;
  showFromRentRefreshPageCheck?: boolean;
}

export const ManuallyMarkPaidModal = (props: IProps) => {
  const {
    paymentRequest,
    onClose,
    onSuccess,
    showFromRentRefreshPageCheck = true,
  } = props;

  const formId = useRef(generateFormId());

  // Redux
  const dispatch = useDispatch();

  // Event handlers
  const handleSuccess = () => {
    // Show refresh modal there is a from rent payment order

    // Check if manually paid request is for rent
    if (
      !paymentRequest.paymentRequestItems.find(
        paymentRequest =>
          paymentRequest.paymentOrder.type === EPaymentOrderType.Rent,
      )
    ) {
      onSuccess();

      return;
    }

    if (!showFromRentRefreshPageCheck) {
      onSuccess();

      return;
    }

    // Get number of active payment orders with from rent
    dispatch(
      paymentOrderActions.getCountStart.getAction({
        query: {
          filter: {
            propertyId: { eq: paymentRequest.requestPropertyId },
            completedAt: { is: null },
            ownerPaymentMethod: {
              eq: EPaymentOrderOwnerPaymentMethod.FromRent,
            },
          },
        },
        onSuccess(result) {
          if (result.data.count === 0) {
            onSuccess();

            return;
          }

          // Show page refresh modal
          confirm({
            title: getLocalizedText(
              "payment_request.alert.from_rent_refresh.title",
            ),
            info: getLocalizedText(
              "payment_request.alert.from_rent_refresh.info",
            ),
            onClose: () => {
              onSuccess();
            },
            primaryActions: [
              {
                title: getLocalizedText(
                  "payment_request.alert.from_rent_refresh.cta.refresh",
                ),
                onPress: () => {
                  setTimeout(() => {
                    window.location.reload();
                  }, 300);
                },
              },
            ],
          });
        },
      }),
    );
  };

  // Render
  return (
    <MultiStepForm
      formId={`manually-mark-paid-${formId.current}`}
      schemas={manuallyMarkPaidSchemas({ paymentRequest })}
      isPerformingSubmitActionSelector={isPerformingSubmitActionSelector({
        paymentRequest,
      })}
      performingSubmitActionResultSelector={performingSubmitActionResultSelector(
        { paymentRequest },
      )}
      performingSubmitActionErrorSelector={performingSubmitActionErrorSelector({
        paymentRequest,
      })}
      submitActionCreator={submitActionCreator({ paymentRequest })}
      submitLabel={getLocalizedText("payment_request.mark_paid.action")}
      asModal={true}
      withAside={false}
      modalProps={{
        onClose,
        shouldCloseOnOverlayClick: true,
        heading: getLocalizedText(
          "payment_request.alert.mark_paid_confirm.message",
        ),
        width: "medium",
      }}
      onSuccess={formData => {
        handleSuccess();
      }}
    />
  );
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { getAddressFields, getLengthLimits, } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { getMappedCountries } from "@rentiohq/shared-frontend/dist/utils/location/location.utils";
import { address as addressUtils } from "@rentiohq/web-shared/dist/utils";
import { EAddressField } from "./types/address";
import { ERentioFormField, } from "./types/form";
export var getAddressFieldKeys = function (overridedFields) {
    if (overridedFields === void 0) { overridedFields = {}; }
    return (__assign({ street: EAddressField.Street, number: EAddressField.Number, box: EAddressField.Box, zip: EAddressField.Zip, city: EAddressField.City, state: EAddressField.State, country: EAddressField.Country }, overridedFields));
};
export var getAddressComponentFields = function (_a) {
    var _b = _a.lookupTypes, lookupTypes = _b === void 0 ? [] : _b, _c = _a.addressFieldKey, addressFieldKey = _c === void 0 ? "" : _c, overridedFields = _a.overridedFields, _d = _a.overridedSpans, overridedSpans = _d === void 0 ? {} : _d;
    var fields = getAddressFieldKeys(overridedFields);
    var addressFields = getAddressFields(lookupTypes[0]);
    var countries = getMappedCountries();
    var getTitle = function (key) { return getLocalizedText("address.".concat(key, ".label")); };
    var showField = function (formData, key) {
        return formData[addressFieldKey] && addressFields.includes(key);
    };
    var getMinLengthLimits = function (formData, key) {
        var _a;
        var lengthLimits = getLengthLimits(formData[fields.country]);
        return (_a = lengthLimits[key]) === null || _a === void 0 ? void 0 : _a.min;
    };
    var getMaxLengthLimits = function (formData, key) {
        var _a;
        var lengthLimits = getLengthLimits(formData[fields.country]);
        return (_a = lengthLimits[key]) === null || _a === void 0 ? void 0 : _a.max;
    };
    var getSpan = function (formData, key) {
        var defaultFieldSpans = {
            street: [4.5, 4.5],
            number: [2.5, 2.5],
            box: [2.5, 2.5],
            zip: [2.5, 2.5],
            city: [4, 4],
            state: [4, 4],
            country: [4, 4],
        };
        if (formData[fields.country] === "BE") {
            defaultFieldSpans = {};
        }
        return overridedSpans[key] || defaultFieldSpans[key] || [4, 6];
    };
    var createFieldSchema = function (key) {
        return {
            fieldKey: fields[key],
            uiComponent: ERentioFormField.TextField,
            title: getTitle(key),
            showIf: function (formData) { return showField(formData, key); },
            getSpan: function (formData) { return getSpan(formData, key); },
            onChange: function (value, formikProps) {
                var _a;
                var formData = formikProps === null || formikProps === void 0 ? void 0 : formikProps.values;
                var address = {};
                Object.keys(fields).forEach(function (fieldKey) {
                    if (addressFields.includes(fieldKey)) {
                        address[fieldKey] = formData[fields[fieldKey]];
                    }
                });
                formikProps.setFieldValue(fields[key], value);
                formikProps.setFieldValue(addressFieldKey, addressUtils.addressObjToString(__assign(__assign({}, address), (_a = {}, _a[key] = value, _a))));
            },
            componentProps: {
                placeholder: getTitle(key),
                minLength: function (formData) {
                    return getMinLengthLimits(formData, key);
                },
                maxLength: function (formData) {
                    return getMaxLengthLimits(formData, key);
                },
            },
        };
    };
    return [
        createFieldSchema("street"),
        createFieldSchema("number"),
        createFieldSchema("box"),
        createFieldSchema("zip"),
        createFieldSchema("city"),
        __assign(__assign({}, createFieldSchema("state")), { showIf: function (formData) {
                return formData[addressFieldKey] &&
                    addressFields.includes(fields.state) &&
                    formData[fields.country] !== "BE";
            } }),
        __assign(__assign({}, createFieldSchema("country")), { uiComponent: ERentioFormField.Select, onChange: function (value, formikProps) {
                formikProps.setFieldValue(fields.country, value);
                if (value === "BE") {
                    formikProps.setFieldValue(fields.state, null);
                }
            }, componentProps: {
                options: countries.map(function (country) { return ({
                    id: country.id,
                    value: country.value,
                    label: country.value,
                }); }),
            } }),
    ];
};

import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../../themes/types";
export var ChatHeaderStyles = function (open) {
    var theme = useTheme();
    return {
        desktopWrapper: {
            display: { xs: "none", md: "block" },
        },
        mobileWrapper: {
            background: theme.palette.success.main,
            color: theme.palette.text.white,
            display: { xs: "block", md: "none" },
            p: 2,
        },
        content: {
            divider: {
                my: 2,
            },
            avatar: {
                width: "45px",
                height: "45px",
                color: "#fff",
                background: "#E3E8EF",
            },
            title: {
                fontWeight: EFontWeights.Bold,
                color: { xs: theme.palette.text.white, md: theme.palette.grey[700] },
            },
            button: {
                cursor: "pointer",
            },
        },
    };
};

import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import { EPaymentRequestFollowUpType } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import {
  DisplayText,
  TextField,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import { useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import * as t from "../../../services/translationService";
import { IPaymentFollowUpScreenProps } from "../PaymentFollowUpModal.types";
import * as S from "./MailScreen.styled";

export const MailScreen: React.FC<IPaymentFollowUpScreenProps> = ({
  followUpItem,
  postBody,
  setPostBody,
  renderActivities,
  contact,
  onClose,
  setIsLoading,
}) => {
  // Redux
  const isPendingFollowUpMail =
    useSelector((state: IRootStore) =>
      paymentRequestSelectors.isPendingFollowUp(
        state,
        followUpItem.id,
        EPaymentRequestFollowUpType.Mail,
      ),
    ) || false;
  const followUpErrorMail =
    useSelector((state: IRootStore) =>
      paymentRequestSelectors.followUpError(
        state,
        followUpItem.id,
        EPaymentRequestFollowUpType.Mail,
      ),
    ) || false;
  const prevIsPendingFollowUpMail = usePrevious(isPendingFollowUpMail);

  // Lifecycle
  React.useEffect(() => {
    if (
      !!prevIsPendingFollowUpMail &&
      !isPendingFollowUpMail &&
      !followUpErrorMail
    ) {
      onClose?.();
    }
  }, [prevIsPendingFollowUpMail, isPendingFollowUpMail, followUpErrorMail]);

  // Event handlers
  const handleMessageChange = ({ target: { value } }: any) => {
    setPostBody({ ...postBody, message: value });
  };

  // Render
  if (!contact) {
    return null;
  }

  return (
    <>
      <S.Mail>
        <S.Header>
          <div>
            <TextStyle variation="strong">{t.system("to")}</TextStyle>:{" "}
            {`${getName(contact)} <${contact.email}>`}
          </div>
          <div>
            <TextStyle variation="strong">{t.system("subject")}</TextStyle>:{" "}
            {t.paymentsFollowUpMailHeading({ locale: contact.locale })}
          </div>
        </S.Header>
        <S.Content>
          <DisplayText size="medium">{postBody.title}</DisplayText>
          <TextField
            value={postBody.message}
            name="message"
            label={t.paymentsFollowUpMailContentLabel()}
            multiline={true}
            onChange={handleMessageChange}
          />
          {/* <Box mt={ESpacings.base}>
            <Button appearance="primary">{t.paymentsFollowUpMailAction()}</Button>
          </Box> */}
        </S.Content>
      </S.Mail>

      <TextStyle variation="subdued">
        {t.paymentsFollowUpMailFootnote()}
      </TextStyle>

      {renderActivities}
    </>
  );
};

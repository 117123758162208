import { IPartnership } from "@rentiohq/shared-frontend/dist/redux/expertInquiry/expertInquiry.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";

export enum ELocationDescriptionType {
  In = "in",
  Out = "out",
  Both = "both",
}

export enum EPropertySituation {
  Inhabited = "inhabited",
  Uninhabited = "uninhabited",
}

export interface IExtraData {
  partnership: IPartnership;
  property: IProperty;
  currentContract: IContract;
}

export enum EField {
  Header = "_header",
  PropertySituation = "propertySituation",
  PaymentDetail = "paymentDetail",
  LocationDescriptionType = "locationDescriptionType",
  BothInfo = "bothInfo",
  Contract = "contract",
  NotesForPartner = "notesForPartner",
  Document = "document",
}

import { Divider, Stack } from "@rentiohq/web-shared-next/dist/ui-components";

interface IProps {
  children: JSX.Element[];
  gap?: number;
  showDivider?: boolean;
}

export const DataList = ({ children, gap = 2, showDivider = true }: IProps) => {
  return (
    <Stack
      direction="column"
      gap={gap}
      divider={showDivider ? <Divider orientation="horizontal" /> : false}
    >
      {children}
    </Stack>
  );
};

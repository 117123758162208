var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { CONFIG } from "../../config/app.config";
import api from "../../utils/api/api.utils";
import { appendFilter } from "../../utils/api.utils";
export var getRentDeposit = function (id) {
    return api.get(appendFilter("/rent-deposits/".concat(id), {
        include: [
            "property",
            { rentDepositContractIn: ["documentPackages"] },
            { rentDepositContractOut: ["documentPackages"] },
        ],
        // include: [
        //   "property",
        //   { rentDepositContractIn: ["documentPackages"] },
        //   { rentDepositContractOut: ["documentPackages"] },
        //   { paymentOrder: ["paymentRequests", "payer", "payee"] },
        //   { paymentOrderOutRenter: ["paymentRequests", "payer", "payee"] },
        //   { paymentOrderOutTenant: ["paymentRequests", "payer", "payee"] },
        // ],
    }));
};
export var getRentDeposits = function (skip, extraFilterData, limit) {
    if (extraFilterData === void 0) { extraFilterData = {}; }
    if (limit === void 0) { limit = CONFIG.DEFAULT_FETCH_LIMIT; }
    var filter = __assign({ order: "createdAt DESC", limit: limit, skip: skip }, extraFilterData);
    return api.get(appendFilter("/rent-deposits", filter));
};
export var createRentDeposit = function (rentDeposit) {
    return api.post("/rent-deposits", { data: rentDeposit });
};
export var releaseRentDeposit = function (_a) {
    var rentDepositId = _a.rentDepositId, data = __rest(_a, ["rentDepositId"]);
    return api.post("/rent-deposits/".concat(rentDepositId, "/release"), {
        data: data,
    });
};
export var approveRentDeposit = function (rentDepositId) {
    return api.post("/rent-deposits/".concat(rentDepositId, "/approve-all"), {
        data: {},
    });
};
export var cancelRentDeposit = function (rentDepositId) {
    return api.delete("/rent-deposits/".concat(rentDepositId));
};

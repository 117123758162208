import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { useCount } from "@rentiohq/shared-frontend/dist/redux/count/count.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import * as paymentRequestHooksV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.hooks";
import { EPaymentOrderType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  EPaymentRequestFeature,
  EPaymentRequestStatus,
  IPaymentRequest,
} from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { append } from "@rentiohq/shared-frontend/dist/utils/api.utils";
import { differenceInCalendarDays } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import {
  formatDate,
  mapObjectWithTimeZonedDatesToUtcIsoString,
} from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getPaymentOrders } from "@rentiohq/shared-frontend/dist/utils/paymentRequest.utils";
import {
  AddressCell,
  DataTable,
  ESpacings,
  Loading,
  Lozenge,
  Pagination,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { TActions } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import hash from "object-hash";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import {
  EPaymentRequestFilterFrom,
  EPaymentRequestFilterPhase,
  EPaymentRequestFilterStatus,
  getPaymentRequestsFilter,
  getPaymentRequestsFilterV2,
} from "scenes/FollowUp/Payments/Payments.utils";
import { EContractStatus } from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ContractStatusChip/contractStatusChip.types";
import { NumberParam, withDefault } from "serialize-query-params";
import { ts } from "../../../services";
import {
  createTitle,
  getAppearanceByPhase,
  getFollowUpReason,
  getUrgencyByPhase,
  isFromRent,
} from "../../../utils/payment";
import { IbanisationStatusInfo } from "./IbanisationStatusInfo";

const FETCH_LIMIT = 10;

interface IProps {
  getPaymentRequestActions: (paymentRequest: IPaymentRequest) => TActions;
  propertyId?: number;
  search?: string;
  phase?: EPaymentRequestFilterPhase;
  status?: EPaymentRequestFilterStatus;
  contractStatus?: EContractStatus;
  from?: EPaymentRequestFilterFrom;
  paymentTypes?: EPaymentOrderType[];
}

const PaymentRequestsTableBase = (props: IProps) => {
  const {
    propertyId,
    search,
    phase,
    status,
    contractStatus,
    from,
    paymentTypes,
    getPaymentRequestActions,
  } = props;

  // const { internalModeEnabled } = useInternalMode();
  // const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);
  const isV2 = true;

  const [queryParams, setQueryParamValue] = useQueryParams({
    paymentRequestsTablePage: withDefault(NumberParam, 1),
    paymentRequestId: NumberParam,
  });

  const filterV1 = getPaymentRequestsFilter({
    propertyId,
    search,
    phase,
    status,
    contractStatus,
    from,
    paymentTypes,
  });
  const identifierV1 = hash(filterV1);
  const filterV2 = getPaymentRequestsFilterV2({
    propertyId,
    search,
    status,
    contractStatus,
    phase,
    from,
    paymentTypes,
  });

  // Redux
  const dispatch = useDispatch();

  const {
    items: paymentRequestsV2,
    isFetching: isFetchingV2,
    fetchError: fetchErrorV2,
    totalPages: pageCountV2 = 0,
  } = paymentRequestHooksV2.usePaged({
    shouldRefetch: true,
    query: isV2
      ? {
          ...filterV2,
          limit: FETCH_LIMIT,
          page: queryParams.paymentRequestsTablePage,
        }
      : undefined,
  });

  const paymentRequestsV1 = useSelector((state: IRootStore) =>
    !isV2
      ? paymentSelectors.getPaymentRequestsForPage(
          state,
          identifierV1,
          queryParams.paymentRequestsTablePage,
        )
      : undefined,
  );
  const isFetchingV1 = useSelector((state: IRootStore) =>
    !isV2
      ? paymentSelectors.isFetchingPaymentRequestsForPage(
          state,
          identifierV1,
          queryParams.paymentRequestsTablePage,
        )
      : undefined,
  );
  const fetchErrorV1 = useSelector((state: IRootStore) =>
    !isV2
      ? paymentSelectors.paymentRequestsForPageFetchError(
          state,
          identifierV1,
          queryParams.paymentRequestsTablePage,
        )
      : undefined,
  );
  const { count: countV1 = 0 } = useCount({
    shouldRefetch: true,
    countBase: !isV2
      ? append("/payment-requests/count", {
          where: mapObjectWithTimeZonedDatesToUtcIsoString(filterV1.where),
        })
      : undefined,
  });

  const paymentRequests = isV2 ? paymentRequestsV2 : paymentRequestsV1;
  const isFetching = isV2 ? isFetchingV2 : isFetchingV1;
  const fetchError = isV2 ? fetchErrorV2 : fetchErrorV1;
  const pageCount = isV2 ? pageCountV2 : Math.ceil(countV1 / FETCH_LIMIT);

  // Lifecycle
  React.useEffect(() => {
    setQueryParamValue({ paymentRequestsTablePage: 1 });
  }, [phase]);

  React.useEffect(() => {
    if (isFetching) {
      return;
    }

    if (!isV2) {
      dispatch(
        paymentActions.getPaymentRequestsPaged.actions.start({
          identifier: identifierV1,
          page: queryParams.paymentRequestsTablePage,
          filterData: mapObjectWithTimeZonedDatesToUtcIsoString(filterV1),
          limit: FETCH_LIMIT,
        }),
      );

      return;
    }
  }, [
    identifierV1,
    queryParams.paymentRequestsTablePage,
    search,
    phase,
    status,
    contractStatus,
    from,
    paymentTypes,
  ]);

  const handlePageClick = ({ selected }: any) => {
    setQueryParamValue({ paymentRequestsTablePage: selected + 1 });
  };

  const renderContent = () => {
    if (paymentRequests && paymentRequests.length > 0) {
      return (
        <>
          <DataTable
            columnContentTypes={[
              "text",
              "text",
              "text",
              "text",
              "text",
              "action",
            ]}
            headings={[
              getLocalizedText("payment_request.follow_up.property.heading"),
              `${getLocalizedText(
                "payment_request.follow_up.payment.heading",
              )} / ${getLocalizedText(
                "payment_request.follow_up.amount.heading",
              )}`,
              getLocalizedText("payment_request.follow_up.due_date.heading"),
              getLocalizedText("payment_request.follow_up.payer.heading"),
              getLocalizedText("payment_request.follow_up.status.heading"),
              "",
            ]}
            onRowClick={rowIndex => {
              setQueryParamValue({
                paymentRequestId: paymentRequests?.[rowIndex].id,
              });
            }}
            rows={paymentRequests.map(paymentRequest => {
              const paymentOrders = getPaymentOrders(paymentRequest);
              const dso = differenceInCalendarDays(
                new Date(),
                paymentRequest.dueDateAt,
              );

              const urgency = getUrgencyByPhase(phase);
              const hasFromRent = isFromRent(paymentOrders);
              const amount =
                paymentRequest.status === EPaymentRequestStatus.Partial
                  ? paymentRequest.amount
                  : paymentRequest.originalAmount;

              const contractId =
                paymentRequest.paymentRequestItems[0]?.paymentOrder?.contractId;

              return {
                id: paymentRequest.id,
                urgency,
                content: [
                  <AddressCell
                    propertyId={paymentRequest.requestPropertyId}
                    address={paymentRequest.propertyAddress}
                    cellKey={`${paymentRequest.id}-property`}
                  />,

                  <div>
                    <TextStyle variation={"strong"}>
                      {createTitle({
                        paymentOrders,
                        paymentRequest,
                        withPayoutType: true,
                      })}
                    </TextStyle>
                    <Box mt={ESpacings.tight}>
                      <TextStyle
                        variation={["code"]}
                        key={`${paymentRequest.id}-amount`}
                        element="div"
                      >
                        {formatCurrency(amount)}
                      </TextStyle>
                    </Box>
                  </div>,

                  <div>
                    {formatDate(paymentRequest.dueDateAt)}
                    {!hasFromRent && (
                      <Box mt={ESpacings.tight}>
                        <Lozenge appearance={getAppearanceByPhase(phase)}>
                          {ts.paymentsFollowUpCardDaysMessage({ days: dso })}
                        </Lozenge>
                      </Box>
                    )}
                  </div>,

                  getName(paymentRequest.payerAccount),

                  <div>
                    {getFollowUpReason(paymentRequest)}
                    {!!(paymentRequest.features || []).includes(
                      EPaymentRequestFeature.ThirdPartyPayInBrokerDirect,
                    ) && (
                      <Box mt={ESpacings.tight}>
                        <Lozenge isBold={true}>
                          {ts.paymentRequestFeatureThirdPartyAccountLabel()}
                        </Lozenge>
                      </Box>
                    )}

                    {propertyId && contractId && (
                      <IbanisationStatusInfo
                        propertyId={propertyId}
                        contractId={contractId}
                      />
                    )}
                  </div>,
                ],
                actions: getPaymentRequestActions(paymentRequest),
              };
            })}
          />
          {pageCount > 1 && (
            <Pagination
              initialPage={
                queryParams.paymentRequestsTablePage
                  ? queryParams.paymentRequestsTablePage - 1
                  : 0
              }
              pageCount={pageCount}
              onPageChange={handlePageClick}
            />
          )}
        </>
      );
    }
    if (fetchError) {
      return <p>{getLocalizedText("fetch.error")}</p>;
    }

    if (isFetching) {
      return <Loading />;
    }

    return <p>{getLocalizedText("payment_requests.empty")}</p>;
  };

  return <>{renderContent()}</>;
};

export const PaymentRequestsTable = React.memo(
  PaymentRequestsTableBase,
  utils.components.arePropsEqual,
);

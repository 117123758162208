import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../../themes/types";
import { ThemeMode } from "../../../utils/types/config";
export var ChatHistoryStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            maxHeight: "calc(100vh - 345px)",
            minHeight: "calc(100vh - 345px)",
            overflow: "auto",
            transform: "translate3d(0px, 0px, 0px) scaleY(-1)",
            height: "100%",
            overflowY: "auto",
            p: { xs: 2, md: 0 },
            pb: 0,
            "> div": {
                transform: "scaleY(-1)",
            },
        },
        content: {
            divider: {
                my: 2,
            },
            chatInfo: {
                textAlign: "center",
                width: "100%",
                display: "block",
                transform: "scaleY(-1)",
                color: theme.palette.grey[500],
                fontWeight: EFontWeights.Bold,
            },
            chatMessageWrapper: {
                padding: "8px",
                width: "100%",
                margin: "auto",
            },
            receivedChatCard: {
                display: "inline-block",
                float: "right",
                bgcolor: theme.palette.mode === ThemeMode.DARK
                    ? "grey.600"
                    : theme.palette.primary.light,
            },
            receivedChatCardContent: {
                p: 2,
                pb: "16px !important",
                width: "fit-content",
                ml: "auto",
            },
            senderChatCard: {
                display: "inline-block",
                float: "left",
                bgcolor: theme.palette.mode === ThemeMode.DARK
                    ? "dark.900"
                    : "secondary.light",
            },
            senderChatCardContent: {
                p: 2,
                pb: "16px !important",
            },
            text: {
                color: theme.palette.mode === ThemeMode.DARK ? "dark.900" : "",
            },
            time: {
                color: theme.palette.mode === ThemeMode.DARK ? "dark.900" : "",
            },
            loaderContainer: {
                width: "100%",
                display: "flex",
                justifyContent: "center",
            },
        },
    };
};

import { compact } from "lodash";
import { ERentioFormField } from "../../components/components/Form";
import { getValue } from "../../redux/form/form.utils";
import { EContractIbanisationStatus } from "../../types/contract.types";
import { getLocalizedText } from "../../utils/i18n/i18n.utils";
import { formatCurrency, getAmountWithVat } from "../../utils/number.utils";
import { join } from "../../utils/string.utils";
import { ETypeSelect } from "./schema.createPayment.0";
import { ECreatePaymentMethod, EField, } from "./schema.createPayment.types";
import { getNavigationTitle, getRelatedContract, } from "./schema.createPayment.utils";
var PRICE_LIMIT = 2500;
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return ({
            required: [EField.PaymentMethods],
            properties: (_a = {},
                _a[EField.Header + "4b"] = {
                    default: undefined,
                },
                _a[EField.PaymentMethodsTooLargeInfo] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        var price = formData[EField.Price] || 0;
                        var vat = formData[EField.PriceVat] || 0;
                        return getAmountWithVat(price, vat / 100) >= PRICE_LIMIT;
                    },
                },
                _a[EField.PaymentMethods] = {
                    default: (function () {
                        if (!defaultValues[EField.TypeSelect] && !extraData.paymentOrder) {
                            return undefined;
                        }
                        if (!extraData.paymentOrder) {
                            var isRecurring = defaultValues[EField.TypeSelect] === ETypeSelect.Recurring;
                            var result = defaultValues[EField.PaymentMethods] ||
                                compact([
                                    isRecurring ? ECreatePaymentMethod.SepaRecurring : undefined,
                                    ECreatePaymentMethod.Online,
                                ]);
                            var contract = getRelatedContract(extraData, defaultValues);
                            var ibanisationStatus = (contract === null || contract === void 0 ? void 0 : contract.ibanisationStatus) || EContractIbanisationStatus.None;
                            if ([
                                EContractIbanisationStatus.Enabling,
                                EContractIbanisationStatus.Enabled,
                            ].includes(ibanisationStatus) &&
                                !result.includes(ECreatePaymentMethod.Ibanisation)) {
                                result.push(ECreatePaymentMethod.Ibanisation);
                            }
                            var price = getValue(EField.Price, initialValues, defaultValues) || 0;
                            var vat = getValue(EField.PriceVat, initialValues, defaultValues) || 0;
                            if (getAmountWithVat(price, vat / 100) >= PRICE_LIMIT) {
                                result = result.filter(function (item) {
                                    return item !== ECreatePaymentMethod.SepaOnce &&
                                        item !== ECreatePaymentMethod.SepaRecurring;
                                });
                            }
                            return result;
                        }
                        return getValue(EField.PaymentMethods, initialValues, defaultValues);
                    })(),
                    // @ts-ignore
                    options: (function () {
                        var isRecurring = defaultValues[EField.TypeSelect] === ETypeSelect.Recurring;
                        return Object.values(ECreatePaymentMethod)
                            .filter(function (paymentMethod) {
                            if (paymentMethod === ECreatePaymentMethod.Ibanisation) {
                                return extraData.ibanisationEnabled || false;
                            }
                            if (paymentMethod === ECreatePaymentMethod.SepaRecurring) {
                                return isRecurring;
                            }
                            return true;
                        })
                            .map(function (paymentMethod) { return ({
                            id: paymentMethod,
                            disabled: (function () {
                                if (paymentMethod === ECreatePaymentMethod.Ibanisation) {
                                    return true;
                                }
                                // Disable SEPA for amounts greater than 2_500 (PRICE_LIMIT)
                                if (paymentMethod === ECreatePaymentMethod.SepaOnce ||
                                    paymentMethod === ECreatePaymentMethod.SepaRecurring) {
                                    var price = getValue(EField.Price, initialValues, defaultValues) || 0;
                                    var vat = getValue(EField.PriceVat, initialValues, defaultValues) ||
                                        0;
                                    if (getAmountWithVat(price, vat / 100) >= PRICE_LIMIT) {
                                        return true;
                                    }
                                }
                                return false;
                            })(),
                            value: getLocalizedText("payments.create.payment_methods.option.label.".concat(paymentMethod)),
                            subValue: getLocalizedText("payments.create.payment_methods.option.info.".concat(paymentMethod)),
                        }); });
                    })(),
                },
                _a[EField.PaymentMethodsIbanisationInfo] = {
                    // @ts-ignore
                    showIf: function (formData) {
                        return extraData.ibanisationEnabled &&
                            !!getRelatedContract(extraData, formData);
                    },
                },
                _a),
        });
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return _a = {
            "rentio:title": getLocalizedText("payments.create.payment_methods.heading.summary"),
            "rentio:navigationTitle": getNavigationTitle(extraData)
        },
        _a[EField.Header + "4b"] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("payments.create.payment_methods.heading"),
        },
        _a[EField.PaymentMethodsTooLargeInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            type: "warning",
            subtitle: getLocalizedText("payments.create.methods_if_too_large", {
                amount: formatCurrency(PRICE_LIMIT),
            }),
        },
        _a[EField.PaymentMethods] = {
            "ui:field": ERentioFormField.InlineSelect,
            summaryTitle: getLocalizedText("payments.create.payment_methods.option.title.summary"),
            formatSummary: function (formData) {
                return formData && formData.length > 0
                    ? join(formData.map(function (item) {
                        return getLocalizedText("payments.create.payment_methods.option.label.".concat(item));
                    }))
                    : undefined;
            },
            isMultiSelect: true,
        },
        _a[EField.PaymentMethodsIbanisationInfo] = {
            "ui:field": ERentioFormField.HelpInfo,
            getSubtitle: function (_, formData) {
                var contract = getRelatedContract(extraData, formData);
                if (!contract) {
                    return "";
                }
                var ibanisationStatus = contract.ibanisationStatus || EContractIbanisationStatus.None;
                if (ibanisationStatus === EContractIbanisationStatus.None) {
                    return getLocalizedText("payments.create.ibanisation.info.disabled");
                }
                else {
                    return getLocalizedText("payments.create.ibanisation.info.enabled");
                }
            },
        },
        _a;
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import * as S from "./ActivityContent/ActivityContent.styled";
import { ContentMeta } from "./ContentMeta";
var getEmailActor = function (input) {
    if (!input) {
        return;
    }
    if (typeof input === "string") {
        return input;
    }
    if (input.name) {
        return "".concat(input.name, " (").concat(input.email, ")");
    }
    return input.email;
};
export var EmailContent = function (_a) {
    var _b;
    var emailActivity = _a.emailActivity;
    var id = emailActivity.id, from = emailActivity.from, to = emailActivity.to, cc = emailActivity.cc, title = emailActivity.title, subject = emailActivity.subject, body = emailActivity.body;
    return (_jsxs(S.Wrapper, { children: [_jsx(ContentMeta, { meta: (_b = {},
                    _b[getLocalizedText("activity.content.email.from")] = getEmailActor(from),
                    _b[getLocalizedText("activity.content.email.to")] = getEmailActor(to),
                    _b[getLocalizedText("activity.content.email.cc")] = getEmailActor(cc === null || cc === void 0 ? void 0 : cc[0]),
                    _b[getLocalizedText("activity.content.email.title")] = title,
                    _b[getLocalizedText("activity.content.email.subject")] = subject,
                    _b) }), _jsxs(S.ContentTitle, { children: [getLocalizedText("activity.content.email.content"), ":"] }), _jsx(S.MessageWrapper, { children: _jsx(Markdown, { skipHtml: false, rehypePlugins: [rehypeRaw], children: body }) })] }, id));
};

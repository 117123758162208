var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createElement as _createElement } from "react";
import Grid from "@mui/material/Grid";
import { Field } from "../Field";
var getColSpans = function (span) {
    return {
        lg: span[0] || 12,
        md: span[1] || 12,
        sm: span[2] || 12,
        xs: span[3] || 12,
    };
};
export var FieldsRenderer = function (_a) {
    var _b = _a.fieldsSchema, fieldsSchema = _b === void 0 ? [] : _b, formikProps = _a.formikProps;
    return (_jsx(Grid, { container: true, spacing: 2, children: fieldsSchema.map(function (fieldSchema, index) {
            var getSpan = fieldSchema.getSpan, showIf = fieldSchema.showIf;
            var _a = (formikProps || {}).values, values = _a === void 0 ? {} : _a;
            var showField = showIf ? showIf(values) : true;
            return !showField ? null : (_createElement(Grid, __assign({ item: true }, getColSpans((getSpan === null || getSpan === void 0 ? void 0 : getSpan(values)) || []), { key: index }),
                _jsx(Field, { fieldSchema: fieldSchema, formikProps: formikProps })));
        }) }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLatest } from "redux-saga/effects";
import logger from "../../logger";
import * as contractActionsV2 from "../../reduxV2/contract/contract.actions";
import * as documentApi from "../../reduxV2/documents/document.api";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralCreateFailureText, getGeneralCreateSuccessText, } from "../../utils/i18n/i18n.utils";
import * as expertInquiryActions from "./expertInquiry.actions";
import * as expertInquiryApi from "./expertInquiry.api";
var DOCUMENT_LOCATION_DESCRIPTION_CATEGORY = 6;
function getPartnershipsStartFlow() {
    var data, unknownError_1, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 3, , 5]);
                return [4 /*yield*/, call(expertInquiryApi.getPartnerships)];
            case 1:
                data = (_a.sent()).data;
                return [4 /*yield*/, put(expertInquiryActions.getPartnerships.actions.success({
                        partnerships: data,
                    }))];
            case 2:
                _a.sent();
                return [3 /*break*/, 5];
            case 3:
                unknownError_1 = _a.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(expertInquiryActions.getPartnerships.actions.failure({
                        error: error,
                    }))];
            case 4:
                _a.sent();
                return [3 /*break*/, 5];
            case 5: return [2 /*return*/];
        }
    });
}
function uploadDocument(file, meta) {
    var preSignedUrl, metaData;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, call(
                // @ts-ignore
                documentApi.uploadDocumentToS3, file)];
            case 1:
                preSignedUrl = _a.sent();
                metaData = __assign(__assign({}, meta), { categoryId: DOCUMENT_LOCATION_DESCRIPTION_CATEGORY, filename: "".concat(file.name) });
                return [4 /*yield*/, call(documentApi.uploadDocument, preSignedUrl, metaData)];
            case 2: 
            // @ts-ignore
            return [2 /*return*/, _a.sent()];
        }
    });
}
function requestLocationDescriptionStartFlow(_a) {
    var request, document, documentId, documentData, data, task, unknownError_2, error;
    var payload = _a.payload;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                request = payload.request, document = payload.document;
                _b.label = 1;
            case 1:
                _b.trys.push([1, 10, , 12]);
                documentId = request.documentId;
                if (!document) return [3 /*break*/, 3];
                return [4 /*yield*/, uploadDocument(document, {
                        propertyId: request.propertyId,
                        // isSignable: false,
                    })];
            case 2:
                documentData = _b.sent();
                documentId = documentData.id;
                _b.label = 3;
            case 3: return [4 /*yield*/, call(expertInquiryApi.requestLocationDescription, __assign(__assign({}, request), { documentId: documentId }))];
            case 4:
                data = (_b.sent()).data;
                task = data;
                if (!documentId) return [3 /*break*/, 6];
                return [4 /*yield*/, call(documentApi.update, {
                        id: documentId,
                        data: {
                            taskId: task.id,
                        },
                    })];
            case 5:
                _b.sent();
                _b.label = 6;
            case 6:
                if (!request.contractId) return [3 /*break*/, 8];
                return [4 /*yield*/, put(contractActionsV2.getDetailStart.getAction({
                        id: request.contractId,
                    }))];
            case 7:
                _b.sent();
                _b.label = 8;
            case 8: return [4 /*yield*/, put(expertInquiryActions.requestLocationDescription.actions.success({
                    request: request,
                    task: task,
                }))];
            case 9:
                _b.sent();
                showAlert({
                    type: "success",
                    message: getGeneralCreateSuccessText("location_description_inquiry"),
                });
                return [3 /*break*/, 12];
            case 10:
                unknownError_2 = _b.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                showAlert({
                    type: "error",
                    message: getGeneralCreateFailureText("location_description_inquiry"),
                });
                return [4 /*yield*/, put(expertInquiryActions.requestLocationDescription.actions.failure({
                        request: request,
                        error: error,
                    }))];
            case 11:
                _b.sent();
                return [3 /*break*/, 12];
            case 12: return [2 /*return*/];
        }
    });
}
function saga() {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(expertInquiryActions.getPartnerships.types.START, getPartnershipsStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(expertInquiryActions.requestLocationDescription.types.START, requestLocationDescriptionStartFlow)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default saga;

import logger from "@rentiohq/shared-frontend/dist/logger";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as contactActions from "@rentiohq/shared-frontend/dist/redux/contact/contact.actions";
import * as contactApi from "@rentiohq/shared-frontend/dist/redux/contact/contact.api";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as propertyApi from "@rentiohq/shared-frontend/dist/reduxV2/property/property.api";
import {
  EIntakeStep,
  IApplication,
  IApplicationContact,
} from "@rentiohq/shared-frontend/dist/types/application.types";
import { EContactInitialType } from "@rentiohq/shared-frontend/dist/types/contact.types";
import { IProperty } from "@rentiohq/shared-frontend/dist/types/property.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  MultiStepForm,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { toast } from "@rentiohq/web-shared/dist/managers/Toast";
import * as systemSelectors from "@rentiohq/web-shared/dist/redux/system/system.selectors";
import utils from "@rentiohq/web-shared/dist/utils";
import { uniq } from "lodash";
import React, { useCallback, useContext, useEffect } from "react";
import { DragDropContext, DragStart, DropResult } from "react-beautiful-dnd";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import * as CONFIG from "scenes/Settings/scenes/TenantProfileNotificationPreferences/TenantProfileNotificationPreferences.config";
import { IFlowItem } from "utils/application.utils";
import createPropertyInviteApplicationForm from "../../../../forms/propertyInviteApplication";
import * as t from "../../../../services/translationService";
import { ArchivedApplications } from "./components/ArchivedApplications";
import {
  CustomFeedbackModal,
  ICustomFeedbackParams,
} from "./components/CustomFeedbackModal";
import { DropAreasList } from "./components/DropAreasList";
import { PropertyDetailApplicationEmptyStateMemo } from "./components/PropertyDetailApplicationEmptyState";
import { DragAndDropContext } from "./context/DragAndDropContext";
import * as S from "./PropertyDetailApplications.styled";
import { IStatusChange } from "./PropertyDetailApplicationsContainer";

export interface IApplicationDropDownHandler {
  (
    applicationIds: string[],
    step: number,
    duplicatedApplication?: IApplication,
  ): void;
}

interface IProps {
  property: IProperty;
  applications: IApplication[];
  type: number | null;
  visitorSteps?: IFlowItem[];
  candidateSteps: IFlowItem[];
  notEligibleSteps: IFlowItem[];
  handleOneStatusChange: (params: IStatusChange) => void;
}

export interface IDropArea {
  id: number;
  items: IApplication[];
  isDisabled: boolean;
  allowedSteps: number[];
}

enum ModalVariants {
  Candidate = "candidate",
  Tenant = "tenant",
  NotEligible = "not-eligible",
  Visitor = "visitor",
  PlanForVisit = "plan-for-visit",
  WaitingList = "waiting-list",
}

const formId = generateFormId();

enum EDropAreaAction {
  Init = "init",
  SetAreas = "setAreas",
  SetPermissions = "setPermissions",
}

interface IDropAreaAction {
  type: EDropAreaAction;
  payload: any;
}

const dropAreaReducer = (state: any[], action: IDropAreaAction) => {
  switch (action.type) {
    case EDropAreaAction.Init:
      return action.payload;

    case EDropAreaAction.SetAreas:
      return state.map((area, index: number) => {
        area.items = action.payload[index].items;
        return area;
      });

    case EDropAreaAction.SetPermissions:
      return state.map((area, index: number) => {
        area.isDisabled = action.payload[index].isDisabled;
        return area;
      });

    default:
    // console.warn("Action type not found.");
  }
};

type TQueueAction = (onReady: VoidFunction) => void;

const PropertyDetailApplications: React.FC<IProps> = ({
  property,
  applications = [],
  visitorSteps,
  candidateSteps = [],
  notEligibleSteps = [],
  handleOneStatusChange,
}) => {
  const dispatch = useDispatch();
  const [isLoadingInvite, setLoadingInvite] = React.useState<boolean>(false);
  const [customFeedbackParams, setCustomFeedbackParams] =
    React.useState<ICustomFeedbackParams>();
  const { user, broker, isBroker } = authHooks.useSelf();

  // 1. Push actions to modal queue
  // 2. Whenever there is an action (function) in the queue, show the modal
  // 3. When a modal is closed, remove the first item from the queue
  // 4. Repeat until queue is empty
  const [modalQueue, setModalQueue] = React.useState<TQueueAction[]>([]);

  useEffect(() => {
    if (modalQueue.length > 0) {
      const modal = modalQueue[0];
      modal(() => {
        modalQueue.shift();
        setModalQueue([...modalQueue]);
      });
    }
  }, [modalQueue]);

  const { selectedItems, setAllDropAreas, replaceSelectedItems } =
    useContext(DragAndDropContext);

  const visitorEnabled = useSelector((state: IRootStore) =>
    systemSelectors.getPreference(
      state,
      CONFIG.PREFERENCE_VISITOR_ENABLED,
      true,
    ),
  );

  const notEligibleEnabled = useSelector((state: IRootStore) =>
    systemSelectors.getPreference(
      state,
      CONFIG.PREFERENCE_NOT_ELIGIBLE_ENABLED,
      true,
    ),
  );

  const planForVisitEnabled = useSelector((state: IRootStore) =>
    systemSelectors.getPreference(
      state,
      CONFIG.PREFERENCE_PLAN_FOR_VISIT_ENABLED,
      true,
    ),
  );

  const waitingListEnabled = useSelector((state: IRootStore) =>
    systemSelectors.getPreference(
      state,
      CONFIG.PREFERENCE_WAITING_LIST_ENABLED,
      true,
    ),
  );

  const [visitorDropAreas, dispatchVisitorDropAreas] = React.useReducer(
    dropAreaReducer,
    [],
  );
  const [candidateDropAreas, dispatchCandidateDropAreas] = React.useReducer(
    dropAreaReducer,
    [],
  );
  const [notEligibleDropAreas, dispatchNotEligibleDropAreas] = React.useReducer(
    dropAreaReducer,
    [],
  );

  const [inviteModalOpen, setInviteModalOpen] = React.useState<boolean>(false);

  const handleChange = (statusChanges: IStatusChange[]) => {
    statusChanges.forEach(statusChange => {
      handleOneStatusChange(statusChange);
    });
    replaceSelectedItems([]);
  };

  React.useEffect(() => {
    const all = [
      ...visitorDropAreas,
      ...candidateDropAreas,
      ...notEligibleDropAreas,
    ];
    setAllDropAreas(all);
  }, [visitorDropAreas, candidateDropAreas, notEligibleDropAreas]);

  React.useEffect(() => {
    const allAvailableSteps = [
      ...(visitorSteps || []),
      ...candidateSteps,
      ...notEligibleSteps,
    ];
    const availableStepIds: number[] = allAvailableSteps.map(step => step.id);
    const firstAvailableStepId = availableStepIds[0];

    const initDropAreas = (steps: IFlowItem[]) => {
      const dropArea = steps.map((step: any) => {
        return {
          id: step.id,
          items: applications.filter((application: IApplication) => {
            if (application.archivedAt) {
              return false;
            }

            if (application.rejectedAt && step === EIntakeStep.notEligible) {
              return true;
            }

            if (
              !availableStepIds.includes(application.step) &&
              step.id === firstAvailableStepId &&
              isBroker
            ) {
              return true;
            }

            return step.id === application.step;
          }),
          allowedSteps: step.allowedSteps,
          isDisabled: false,
        };
      }) as IDropArea[];

      return dropArea;
    };

    dispatchVisitorDropAreas({
      type: EDropAreaAction.Init,
      payload: initDropAreas(visitorSteps || []),
    });

    dispatchCandidateDropAreas({
      type: EDropAreaAction.Init,
      payload: initDropAreas(candidateSteps),
    });

    dispatchNotEligibleDropAreas({
      type: EDropAreaAction.Init,
      payload: initDropAreas(notEligibleSteps),
    });
  }, [applications, visitorSteps, candidateSteps, notEligibleSteps]);

  const toggleModal = (
    params: {
      type: ModalVariants;
      statusChange: IStatusChange;
      application: IApplication;
      contact?: IApplicationContact;
    },
    onReadyCallback?: VoidFunction,
  ) => {
    const { type, statusChange, application, contact } = params;

    switch (type) {
      case ModalVariants.Candidate:
        confirm({
          title: getLocalizedText(
            "application.assign.candidate.confirm.heading",
          ),
          info: getLocalizedText("application.assign.candidate.confirm.body"),
          secondaryAction: {
            title: getLocalizedText("system.cancel"),
            onPress: () => {
              onReadyCallback?.();
            },
          },
          primaryActions: [
            {
              title: getLocalizedText("system.ok"),
              onPress: () => {
                handleChange([statusChange]);
                onReadyCallback?.();
              },
            },
          ],
        });

        return;
      case ModalVariants.Tenant:
        if (!application) {
          return;
        }

        confirm({
          title: getLocalizedText("application.assign.tenant.confirm.heading"),
          info: getLocalizedText("application.assign.tenant.confirm.body", {
            phone: contact?.phone || "",
          }),
          secondaryAction: {
            title: getLocalizedText("system.no"),
            onPress: () => {
              onReadyCallback?.();
            },
          },
          primaryActions: [
            {
              title: getLocalizedText("system.yes"),
              onPress: () => {
                addTenantAsContact(contact);
                onReadyCallback?.();
              },
            },
          ],
        });
        return;

      case ModalVariants.NotEligible:
      case ModalVariants.PlanForVisit:
      case ModalVariants.Visitor:
      case ModalVariants.WaitingList:
        setCustomFeedbackParams({ statusChange, application, onReadyCallback });
        return;

      default:
        return;
    }
  };

  const resetAreaPermissions = () => {
    const newVisitorDropAreaPermissions = visitorDropAreas.map((area: any) => ({
      ...area,
      isDisabled: false,
    }));

    const newCandidateDropAreaPermissions = candidateDropAreas.map(
      (area: any) => ({
        ...area,
        isDisabled: false,
      }),
    );

    const newNotEligibleDropAreaPermissions = notEligibleDropAreas.map(
      (area: any) => ({
        ...area,
        isDisabled: false,
      }),
    );

    dispatchVisitorDropAreas({
      type: EDropAreaAction.SetPermissions,
      payload: newVisitorDropAreaPermissions,
    });
    dispatchCandidateDropAreas({
      type: EDropAreaAction.SetPermissions,
      payload: newCandidateDropAreaPermissions,
    });
    dispatchNotEligibleDropAreas({
      type: EDropAreaAction.SetPermissions,
      payload: newNotEligibleDropAreaPermissions,
    });
  };

  const addTenantAsContact = async (contact: any) => {
    const input = utils.object.filterKeys(contact, [
      "firstname",
      "lastname",
      "phone",
      "email",
    ]);
    const address = utils.object.filterKeys(property, [
      "number",
      "street",
      "city",
      "zip",
      "countryId",
      "box",
    ]);
    dispatch(
      contactActions.createContact.actions.start({
        contact: {
          ...input,
          ...address,
          initialType: EContactInitialType.Tenant,
        },
        sendInvite: true,
      }),
    );
  };

  const checkIfTenantIsContact = async (
    statusChange: IStatusChange,
    application: IApplication,
    onReadyCallback?: VoidFunction,
  ) => {
    await application.contacts.forEach(async contact => {
      const { phone } = contact;
      const { data: existingContacts } = await contactApi.searchContacts("", {
        where: { phone },
        limit: 1,
      });

      if (existingContacts.length === 0) {
        addTenantAsContact(contact);
        return;
      }

      toggleModal(
        { type: ModalVariants.Tenant, statusChange, application, contact },
        onReadyCallback,
      );
    });
  };

  const setAreaItems = (
    sourceAreas: IDropArea[],
    applicationIds: string[],
    destinationAreaKey: string,
  ) => {
    const destinationId = Number(destinationAreaKey);

    const removedApplications = sourceAreas.reduce(
      (allRemovedApplications: IApplication[], sourceArea: IDropArea) => {
        const removedForThisDropArea = sourceArea.items.filter(
          (item: IApplication) => applicationIds.includes(item.id),
        );
        const unique = new Set([
          ...allRemovedApplications,
          ...removedForThisDropArea,
        ]);
        return Array.from(unique);
      },
      [],
    );

    if (!removedApplications) {
      return;
    }

    const statusChanges = removedApplications.map(
      (application: IApplication) => {
        return {
          id: application.id,
          step: destinationId,
        };
      },
    );

    let allActions: ((onReady: VoidFunction) => void)[] = [];
    statusChanges.forEach(statusChange => {
      const removedAreaItem = removedApplications.find(
        a => a.id === statusChange.id,
      );
      if (!removedAreaItem) return;

      switch (destinationId) {
        case EIntakeStep.waitingList:
          if (waitingListEnabled) {
            allActions.push((onReady: VoidFunction) => {
              toggleModal(
                {
                  type: ModalVariants.WaitingList,
                  statusChange,
                  application: removedAreaItem,
                },
                onReady,
              );
            });
            break;
          }
          handleChange([statusChange]);
          break;

        case EIntakeStep.planAsVisitor:
          if (planForVisitEnabled) {
            allActions.push((onReady: VoidFunction) => {
              toggleModal(
                {
                  type: ModalVariants.PlanForVisit,
                  statusChange,
                  application: removedAreaItem,
                },
                onReady,
              );
            });

            break;
          }

          handleChange([statusChange]);
          break;
        case EIntakeStep.visitor: {
          if (visitorEnabled) {
            allActions.push((onReady: VoidFunction) => {
              toggleModal(
                {
                  type: ModalVariants.Visitor,
                  statusChange,
                  application: removedAreaItem,
                },
                onReady,
              );
            });
            break;
          }

          handleChange([statusChange]);

          break;
        }

        case EIntakeStep.candidateTenant:
          allActions.push((onReady: VoidFunction) => {
            toggleModal(
              {
                type: ModalVariants.Candidate,
                statusChange,
                application: removedAreaItem,
              },
              onReady,
            );
          });
          break;

        case EIntakeStep.tenant:
          allActions.push((onReady: VoidFunction) => {
            handleChange([statusChange]);
            checkIfTenantIsContact(statusChange, removedAreaItem, onReady);
          });

          break;
        case EIntakeStep.notEligible: {
          if (notEligibleEnabled) {
            allActions.push((onReady: VoidFunction) => {
              toggleModal(
                {
                  type: ModalVariants.NotEligible,
                  statusChange,
                  application: removedAreaItem,
                },
                onReady,
              );
            });

            break;
          }

          handleChange([statusChange]);
          break;
        }

        default:
          handleChange([statusChange]);
          break;
      }

      setModalQueue([...modalQueue, ...allActions]);
    });
  };

  const setAreaPermissions = (sourceAreaKey: number) => {
    const allowedVisitorSteps = visitorDropAreas.find(
      (area: any) => area.id === sourceAreaKey,
    )?.allowedSteps;
    const allowedCandidateSteps = candidateDropAreas.find(
      (area: any) => area.id === sourceAreaKey,
    )?.allowedSteps;
    const allowedNotEligibleSteps = notEligibleDropAreas.find(
      (area: any) => area.id === sourceAreaKey,
    )?.allowedSteps;

    const allAllowedSteps = uniq([
      ...(allowedVisitorSteps || []),
      ...(allowedCandidateSteps || []),
      ...(allowedNotEligibleSteps || []),
    ]);

    const newVisitorDropAreaPermissions = visitorDropAreas.map((area: any) => ({
      ...area,
      isDisabled: !allAllowedSteps.includes(area.id),
    }));

    const newCandidatesDropAreaPermissions = candidateDropAreas.map(
      (area: any) => ({
        ...area,
        isDisabled: !allAllowedSteps.includes(area.id),
      }),
    );

    const newNotEligibleDropAreaPermissions = notEligibleDropAreas.map(
      (area: any) => ({
        ...area,
        isDisabled: !allAllowedSteps.includes(area.id),
      }),
    );

    dispatchVisitorDropAreas({
      type: EDropAreaAction.SetPermissions,
      payload: newVisitorDropAreaPermissions,
    });
    dispatchCandidateDropAreas({
      type: EDropAreaAction.SetPermissions,
      payload: newCandidatesDropAreaPermissions,
    });
    dispatchNotEligibleDropAreas({
      type: EDropAreaAction.SetPermissions,
      payload: newNotEligibleDropAreaPermissions,
    });
  };

  // Dropdown handler
  const handler = (
    applicationIds: string[],
    step: number,
    duplicatedApplication?: IApplication,
  ) => {
    if (duplicatedApplication) {
      if (step === EIntakeStep.tenant) {
        checkIfTenantIsContact(
          { id: duplicatedApplication.id, step },
          duplicatedApplication,
        );
      }

      return;
    }

    const dropAreas = [
      ...visitorDropAreas,
      ...candidateDropAreas,
      ...notEligibleDropAreas,
    ];

    const sourceAreas = applicationIds.map(applicationId =>
      dropAreas.find(dropArea =>
        dropArea.items.find((item: IApplication) => item.id === applicationId),
      ),
    );

    setAreaItems(sourceAreas, applicationIds, step.toString());
  };

  const getSourceAreas = useCallback(
    (applicationIds: string[]) => {
      const allDropAreas = [
        ...visitorDropAreas,
        ...candidateDropAreas,
        ...notEligibleDropAreas,
      ];
      return applicationIds.map(applicationId =>
        allDropAreas.find(dropArea =>
          dropArea.items.find(
            (item: IApplication) => item.id === applicationId,
          ),
        ),
      );
    },
    [visitorDropAreas, candidateDropAreas, notEligibleDropAreas],
  );

  const onDragStart = (result: DragStart): void => {
    const { source, draggableId: applicationId } = result;

    const sourceId = source.droppableId;
    setAreaPermissions(+sourceId);

    //See if the application being dragged it is included in the multiselect
    const draggedApplicationIsInSelection = !!selectedItems.find(
      i => i.id === applicationId,
    );

    if (!draggedApplicationIsInSelection) {
      // Replace the selected items with the dragged item
      replaceSelectedItems(applications.filter(i => i.id === applicationId));
    }
  };

  const onDragEnd = (result: DropResult): void => {
    const { destination, draggableId: applicationId } = result;

    resetAreaPermissions();
    if (!destination) {
      return;
    }
    //See if the application being dragged it is included in the multiselect
    const draggedApplicationIsInSelection = !!selectedItems.find(
      i => i.id === applicationId,
    );

    let sourceAreas = [];
    let applicationIds = [];
    if (draggedApplicationIsInSelection) {
      // If it is -> move all selected items
      sourceAreas = getSourceAreas(selectedItems.map(i => i.id));
      applicationIds = selectedItems.map(i => i.id);
    } else {
      // If it is not -> move only the dragged item
      // select this one
      sourceAreas = getSourceAreas([applicationId]);
      applicationIds = [applicationId];
    }

    setAreaItems(sourceAreas, applicationIds, destination.droppableId);
  };

  const handleInvite = async (input: any) => {
    try {
      setLoadingInvite(true);
      const { data } = await propertyApi.sendApplicationInvitation(
        property.id,
        input,
      );
      if (data.data.success) {
        toast({
          heading: t.propertyInviteApplicationToastSucceededHeading(),
          variation: "success",
        });
      } else {
        throw new Error();
      }

      handleInviteModalClose();
      setLoadingInvite(false);
    } catch (unknownError) {
      const error = unknownError as any;
      logger.logError({ error });
      toast({
        heading: t.propertyInviteApplicationToastFailedHeading(),
        content: t.propertyInviteApplicationToastFailedContent(),
        variation: "error",
      });
    }
  };

  const handleInviteModalClose = () => {
    setInviteModalOpen(false);
  };
  const handleInviteModalOpen = () => {
    setInviteModalOpen(true);
  };

  if (!visitorSteps && !candidateSteps && !notEligibleSteps) {
    return <PropertyDetailApplicationEmptyStateMemo />;
  }

  return (
    <>
      <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
        {visitorSteps && (
          <>
            <S.PhaseHeader>
              <TextStyle variation="strong" size="large">
                {getLocalizedText("application.phase.visit")}
              </TextStyle>
            </S.PhaseHeader>
            <Card>
              <DropAreasList
                property={property}
                broker={broker}
                dropAreas={visitorDropAreas}
                handler={handler}
                showActions={true}
                onClickInviteViaEmail={handleInviteModalOpen}
              />
            </Card>
          </>
        )}

        <S.PhaseHeader>
          <TextStyle variation="strong" size="large">
            {getLocalizedText("application.phase.candidate")}
          </TextStyle>
        </S.PhaseHeader>

        <Card>
          <DropAreasList
            property={property}
            broker={broker}
            dropAreas={candidateDropAreas}
            handler={handler}
            showActions={!visitorSteps}
            onClickInviteViaEmail={handleInviteModalOpen}
          />
        </Card>

        <Card>
          <DropAreasList
            property={property}
            broker={broker}
            dropAreas={notEligibleDropAreas}
            handler={handler}
            showActions={false}
            onClickInviteViaEmail={handleInviteModalOpen}
          />
          <ArchivedApplications applications={applications} />
        </Card>
      </DragDropContext>

      {inviteModalOpen && (
        <MultiStepForm
          formId={`invite-user-application-${formId}`}
          schemas={createPropertyInviteApplicationForm()}
          asModal={true}
          withAside={false}
          onSuccess={handleInvite}
          submitLabel={t.system("submit")}
          modalProps={{ onClose: handleInviteModalClose, width: "small" }}
          isLoading={isLoadingInvite}
        />
      )}

      {customFeedbackParams && user && (
        <CustomFeedbackModal
          customFeedbackParams={customFeedbackParams}
          user={user}
          property={property}
          formId={formId}
          isLoadingInvite={isLoadingInvite}
          handleChange={change => handleChange([change])}
          setCustomFeedbackParams={setCustomFeedbackParams}
        />
      )}
    </>
  );
};

const PropertyDetailApplicationsMemo = React.memo(
  PropertyDetailApplications,
  utils.components.arePropsEqual,
);

// eslint-disable-next-line import/no-default-export
export default PropertyDetailApplicationsMemo;

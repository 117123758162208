import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import addLegalCaseSchemas from "@rentiohq/shared-frontend/dist/forms/addLegalCase";
import {
  isPerformingSubmitActionSelector,
  performingSubmitActionErrorSelector,
  performingSubmitActionResultSelector,
  submitActionCreator,
} from "@rentiohq/shared-frontend/dist/forms/addLegalCase/schema.addLegalCase.actions";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import * as registrationApi from "@rentiohq/shared-frontend/dist/redux/registration/registration.api";
import * as rentDepositActions from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.actions";
import * as rentDepositSelectors from "@rentiohq/shared-frontend/dist/redux/rentDeposit/rentDeposit.selectors";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as legalCaseHooks from "@rentiohq/shared-frontend/dist/reduxV2/legalCase/legalCase.hooks";
import * as paymentOrderActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentOrder/paymentOrder.actions";
import * as paymentOrderUtils from "@rentiohq/shared-frontend/dist/reduxV2/paymentOrder/paymentOrder.utils";
import * as paymentRequestActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import {
  EContractMemberTypes,
  IContract,
} from "@rentiohq/shared-frontend/dist/types/contract.types";
import { ELegalCaseStatus } from "@rentiohq/shared-frontend/dist/types/legalCase.types";
import { EPayoutType } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { EPaymentRequestStatus } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import {
  ERegistrationContractStatus,
  ERegistrationDocumentType,
  IRegistration,
} from "@rentiohq/shared-frontend/dist/types/registration.types";
import { ERentDepositStatus } from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  Loading,
  MultiStepForm,
  Page,
} from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { StringParam } from "serialize-query-params";
import { getContractCost } from "utils/contract";

interface IProps {
  isEdit: boolean;
}

const NOW = new Date();
const formId = generateFormId();

const paymentRequestQuery = (contract?: IContract) => {
  if (!contract) {
    return { page: 1, limit: CONFIG.DEFAULT_FETCH_LIMIT };
  }
  return {
    page: 1,
    limit: CONFIG.DEFAULT_FETCH_LIMIT,
    filter: {
      requestPropertyId: { eq: contract?.propertyId },
      type: { eq: EPayoutType.Charge },
      status: {
        neq: EPaymentRequestStatus.Paid,
      },
      dueDateAt: { lte: NOW },
      requestPayerAccountId: {
        in: contract?.members
          .filter(m => m.roles.includes(EContractMemberTypes.Tenant))
          .map(t => t.account.id),
      },
    },
  };
};

export const RentioLegalCaseForm = (props: IProps) => {
  const { isEdit } = props;

  const dispatch = useDispatch();

  const [{ contractId }] = useQueryParams({
    contractId: StringParam,
  });

  const [registration, setRegistration] = useState<IRegistration>();
  const [isFetchingRegistration, setIsFetchingRegistration] = useState(false);

  const navigate = useNavigate();

  const { internalModeEnabled } = useInternalMode();

  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const RENT_DEPOSIT_IDENTIFIER = `rent-deposit-${contractId}`;
  const DOCUMENT_PACKAGE_ID = `document-package-${contractId}`;
  const LATE_PAYMENTS_ID = `late-payments-${contractId}`;
  const PAYMENT_ORDERS_FOR_CONTRACT_ID = `payment-orders-${contractId}`;

  const { detail: contract, isFetching: isFetchingContract } =
    contractHooks.useDetail({
      shouldRefetch: false,
      id: contractId,
    });

  const {
    items: legalCasesForContract,
    isFetching: isFetchingLegalCasesForContract,
  } = legalCaseHooks.useGetAll({
    shouldRefetch: true,
    query: isEdit
      ? {
          filter: {
            and: [
              {
                contractId: { eq: contractId },
              },
              {
                status: { neq: ELegalCaseStatus.Closed },
              },
            ],
          },
        }
      : undefined,
  });

  //Lease agreement
  const leaseAgreementDocumentPackages = useSelector<
    IRootStore,
    ReturnType<typeof documentPackageSelectors.getDocumentPackages>
  >(state =>
    documentPackageSelectors.getDocumentPackages(state, DOCUMENT_PACKAGE_ID),
  );

  const isFetchingLeaseAgreementDocumentPackages = useSelector<
    IRootStore,
    ReturnType<typeof documentPackageSelectors.isFetchingDocumentPackages>
  >(state =>
    documentPackageSelectors.isFetchingDocumentPackages(
      state,
      DOCUMENT_PACKAGE_ID,
    ),
  );

  const rentDeposits = useSelector<
    IRootStore,
    ReturnType<typeof rentDepositSelectors.getRentDeposits>
  >(state =>
    rentDepositSelectors.getRentDeposits(state, RENT_DEPOSIT_IDENTIFIER),
  );
  const isFetchingRentDeposits = useSelector<
    IRootStore,
    ReturnType<typeof rentDepositSelectors.isFetchingRentDeposits>
  >(state =>
    rentDepositSelectors.isFetchingRentDeposits(state, RENT_DEPOSIT_IDENTIFIER),
  );

  const latePayments = useSelector<
    IRootStore,
    ReturnType<typeof paymentSelectors.getPaymentRequestsByIdentifier>
  >(state =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentRequestSelectors.getPaged(state, paymentRequestQuery(contract))
          ?.items
      : paymentSelectors.getPaymentRequestsByIdentifier(
          state,
          LATE_PAYMENTS_ID,
        ),
  );
  const isFetchingLatePayments = useSelector<
    IRootStore,
    ReturnType<typeof paymentSelectors.isFetchingPaymentRequestsByIdentifier>
  >(state =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentRequestSelectors.getPaged(state, paymentRequestQuery(contract))
          ?.isFetching || false
      : paymentSelectors.isFetchingPaymentRequestsByIdentifier(
          state,
          LATE_PAYMENTS_ID,
        ),
  );

  //Registration
  useEffect(() => {
    const fetchRegistration = async (registrationId: number) => {
      const { data } = await registrationApi.getRegistration(registrationId);
      if (
        data.rentContractStatus === ERegistrationContractStatus.Registered ||
        data.rentContractStatus ===
          ERegistrationContractStatus.RegisteredManually
      ) {
        setRegistration(data);
      }
    };
    if (contract?.rentRegistrationId) {
      setIsFetchingRegistration(true);
      fetchRegistration(contract.rentRegistrationId);
      setIsFetchingRegistration(false);
    }
  }, [contract]);

  useEffect(() => {
    if (contract) {
      dispatch(
        documentPackageActions.getDocumentPackages.actions.start({
          refetch: true,
          identifier: DOCUMENT_PACKAGE_ID,
          filterData: {
            where: {
              contractId,
            },
          },
        }),
      );
      dispatch(
        rentDepositActions.getRentDeposits.actions.start({
          identifier: RENT_DEPOSIT_IDENTIFIER,
          refetch: true,
          limit: 30,
          extraFilterData: {
            where: {
              basicContractId: contractId,
              status: { eq: ERentDepositStatus.Established },
            },
          },
        }),
      );
    }
  }, [contract]);

  useEffect(() => {
    // TODO: To be removed internalModeEnabled
    if (internalModeEnabled && hasPaymentV2ForBroker) {
      dispatch(
        paymentOrderActions.getPagedStart.getAction({
          query: paymentOrderUtils.getPaymentOrdersQuery({
            contractId,
          }),
        }),
      );

      return;
    }

    dispatch(
      paymentActions.getPaymentOrdersByIdentifier.actions.start({
        paymentOrdersIdentifier: PAYMENT_ORDERS_FOR_CONTRACT_ID,
        refetch: false,
        filterData: {
          where: {
            contractId,
          },
        },
      }),
    );
  }, []);

  useEffect(() => {
    if (latePayments) {
      return;
    }
    if (isFetchingLatePayments) {
      return;
    }
    if (!contract) {
      return;
    }

    // TODO: To be removed internalModeEnabled
    if (internalModeEnabled && hasPaymentV2ForBroker) {
      dispatch(
        paymentRequestActions.getPagedStart.getAction({
          query: paymentRequestQuery(contract),
        }),
      );

      return;
    }

    dispatch(
      paymentActions.getPaymentRequestsByIdentifier.actions.start({
        paymentRequestsIdentifier: LATE_PAYMENTS_ID,
        refetch: true,
        filterData: {
          where: {
            requestPropertyId: contract?.propertyId,
            type: EPayoutType.Charge,
            status: {
              neq: EPaymentRequestStatus.Paid,
            },
            dueDateAt: { lte: NOW },
            requestPayerAccountId: {
              inq: contract?.members
                .filter(m => m.roles.includes(EContractMemberTypes.Tenant))
                .map(t => t.account.id),
            },
          },
        },
      }),
    );
  }, [contract, latePayments, isFetchingLatePayments]);

  const isLoading =
    isFetchingContract ||
    isFetchingLeaseAgreementDocumentPackages ||
    isFetchingRegistration ||
    isFetchingRentDeposits ||
    isFetchingLatePayments;

  const rentDeposit = rentDeposits?.[0];
  const rentDepositDocumentId: string | undefined =
    rentDeposit?.rentDepositContractIn?.documentPackages?.[0]
      ?.documentsInPackageIds[0];

  const propertyInspectionReport = registration?.documents.find(
    d => d.documentType === ERegistrationDocumentType.PropertyInspectionReport,
  );
  const proofOfPropertyInspectionRegistration =
    propertyInspectionReport?.certificateDocumentId;

  const proofOfContractRegistration = registration?.documents.find(
    d => d.documentType === ERegistrationDocumentType.Contract,
  )?.certificateDocumentId;

  const leaseAgreementDocumentId: string | undefined =
    leaseAgreementDocumentPackages?.[0]?.documentsInPackageIds[0];
  const leaseAgreementDocumentPackageId: string | undefined =
    leaseAgreementDocumentPackages?.[0]?.id;

  if (!contractId || !contract || isLoading || isFetchingLegalCasesForContract)
    return <Loading />;

  const ongoingLegalCase =
    legalCasesForContract && legalCasesForContract.length > 0
      ? legalCasesForContract[0]
      : undefined;

  return (
    <Page
      title={
        isEdit
          ? getLocalizedText("rentio_legal.actions.edit_case")
          : getLocalizedText("rentio_legal.actions.start_new_case")
      }
      breadcrumbs={{
        to: "/rentio-legal",
        content: getLocalizedText("system.back_to_overview"),
      }}
    >
      <Card>
        <MultiStepForm
          formId={formId}
          withSummary={true}
          activeStep={isEdit ? "summary" : undefined}
          schemas={addLegalCaseSchemas({
            contract,
            contractCost: getContractCost(contract!),
            allLatePayments: latePayments ?? [],
            contractId,
            proofOfContractRegistration,
            proofOfPropertyInspectionRegistration,
            rentDepositDocumentId,
            leaseAgreementDocumentId,
            propertyInspectionDocumentId: propertyInspectionReport?.documentId,
            legalCase: ongoingLegalCase,
          })}
          isPerformingSubmitActionSelector={isPerformingSubmitActionSelector(
            isEdit ? "update" : "create",
            ongoingLegalCase?.id,
          )}
          performingSubmitActionResultSelector={performingSubmitActionResultSelector(
            isEdit ? "update" : "create",
            ongoingLegalCase?.id,
          )}
          performingSubmitActionErrorSelector={performingSubmitActionErrorSelector(
            isEdit ? "update" : "create",
            ongoingLegalCase?.id,
          )}
          submitActionCreator={submitActionCreator({
            contract,
            registration,
            rentDeposit,
            leaseAgreementDocumentPackageId,
            onSuccess: result => {
              navigate(`/rentio-legal?legalCaseId=${result.data.id}`);
            },
            legalCase: legalCasesForContract?.[0],
          })}
          withAside={true}
        />
      </Card>
    </Page>
  );
};

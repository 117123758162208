var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { call, put, takeLatest } from "redux-saga/effects";
import logger from "../../logger";
import * as contractActions from "../../reduxV2/contract/contract.actions";
import { showAlert } from "../../utils/alert/alert.utils";
import { getGeneralUpdateSuccessText } from "../../utils/i18n/i18n.utils";
import * as insuranceActions from "./insurance.actions";
import * as insuranceApi from "./insurance.api";
function resetInsuranceProposalStartFlow(action) {
    var payload, externalCode, contractId, onSuccess, data, unknownError_1, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                externalCode = payload.externalCode, contractId = payload.contractId, onSuccess = payload.onSuccess;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 6, , 8]);
                return [4 /*yield*/, call(insuranceApi.resetInsuranceProposal, externalCode)];
            case 2:
                data = (_a.sent()).data;
                if (!(data.status === "ok")) return [3 /*break*/, 5];
                return [4 /*yield*/, put(insuranceActions.resetInsuranceProposal.actions.success({
                        contractId: contractId,
                    }))];
            case 3:
                _a.sent();
                return [4 /*yield*/, put(contractActions.getDetailStart.getAction({ id: contractId }))];
            case 4:
                _a.sent();
                onSuccess === null || onSuccess === void 0 ? void 0 : onSuccess(contractId);
                showAlert({
                    type: "success",
                    message: getGeneralUpdateSuccessText("Payment Request"),
                });
                _a.label = 5;
            case 5: return [3 /*break*/, 8];
            case 6:
                unknownError_1 = _a.sent();
                error = unknownError_1;
                logger.logError({ error: error });
                return [4 /*yield*/, put(insuranceActions.resetInsuranceProposal.actions.failure({
                        contractId: contractId,
                        error: error,
                    }))];
            case 7:
                _a.sent();
                showAlert({
                    type: "error",
                    error: error,
                });
                return [3 /*break*/, 8];
            case 8: return [2 /*return*/];
        }
    });
}
function getInsuranceStartFlow(action) {
    var payload, insuranceId, contractId, insurance, fetchedInsurance, fetchedInsurance, unknownError_2, error;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                payload = action.payload;
                insuranceId = payload.insuranceId, contractId = payload.contractId;
                _a.label = 1;
            case 1:
                _a.trys.push([1, 7, , 9]);
                insurance = void 0;
                if (!contractId) return [3 /*break*/, 3];
                return [4 /*yield*/, call(insuranceApi.getInsuranceForContract, contractId)];
            case 2:
                fetchedInsurance = (_a.sent()).data;
                insurance = fetchedInsurance;
                _a.label = 3;
            case 3:
                if (!insuranceId) return [3 /*break*/, 5];
                return [4 /*yield*/, call(insuranceApi.getInsurance, insuranceId)];
            case 4:
                fetchedInsurance = (_a.sent()).data;
                insurance = fetchedInsurance;
                _a.label = 5;
            case 5:
                if (!insurance) {
                    throw new Error("Could not fetch insurance for ID ".concat(insuranceId, " or contract ID ").concat(contractId));
                }
                return [4 /*yield*/, put(insuranceActions.getInsurance.actions.success({
                        insuranceId: insurance.id,
                        contractId: insurance.contractId,
                        insurance: insurance,
                    }))];
            case 6:
                _a.sent();
                return [3 /*break*/, 9];
            case 7:
                unknownError_2 = _a.sent();
                error = unknownError_2;
                logger.logError({ error: error });
                return [4 /*yield*/, put(insuranceActions.getInsurance.actions.failure({
                        insuranceId: insuranceId,
                        contractId: contractId,
                        error: error,
                    }))];
            case 8:
                _a.sent();
                return [3 /*break*/, 9];
            case 9: return [2 /*return*/];
        }
    });
}
// eslint-disable-next-line import/no-default-export
export default function () {
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, takeLatest(insuranceActions.resetInsuranceProposal.types.START, resetInsuranceProposalStartFlow)];
            case 1:
                _a.sent();
                return [4 /*yield*/, takeLatest(insuranceActions.getInsurance.types.START, getInsuranceStartFlow)];
            case 2:
                _a.sent();
                return [2 /*return*/];
        }
    });
}

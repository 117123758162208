import * as paymentOrderHooks from "@rentiohq/shared-frontend/dist/reduxV2/paymentOrder/paymentOrder.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Card,
  EmptyState,
  Error,
  Loading,
} from "@rentiohq/web-shared/dist/components";
import React from "react";
import CollectionFollowUp from "./CollectionFollowUp";

interface IProps {
  propertyId?: number;
}

const CollectionFollowUpContainer: React.FC<IProps> = props => {
  const { propertyId } = props;

  const {
    missingKYCs,
    isFetching,
    fetchingError: error,
    dispatchMissingKYCs,
  } = paymentOrderHooks.useMissingKYCs(propertyId);

  const fetchData = (refetch: boolean) => {
    if (isFetching) {
      return;
    }

    if (!refetch) {
      return;
    }

    dispatchMissingKYCs({ propertyId, refetch });
  };

  React.useEffect(() => {
    fetchData(true);
  }, []);

  // TODO: Paging
  // React.useEffect(() => {
  //   if (!!missingKYCs && !isExhausted) {
  //     fetchData(false);
  //   }
  // }, [missingKYCs, isExhausted]);

  const handleRefetch = () => {
    fetchData(true);
  };

  // Render
  if (propertyId) {
    if (!missingKYCs || missingKYCs.length === 0) {
      if (error) {
        return <Error errors={[error]} />;
      }

      return null;
    }

    return <CollectionFollowUp items={missingKYCs} refetch={handleRefetch} />;
  }

  if (!missingKYCs) {
    if (isFetching) {
      return <Loading />;
    }

    if (error) {
      return <Error errors={[error]} />;
    }

    return null;
  }

  if (missingKYCs.length === 0) {
    return (
      <Card>
        <EmptyState
          heading={getLocalizedText(
            "payments.collection.follow_up.empty.heading",
          )}
        />
      </Card>
    );
  }

  return <CollectionFollowUp items={missingKYCs} refetch={handleRefetch} />;
};

// eslint-disable-next-line import/no-default-export
export default CollectionFollowUpContainer;

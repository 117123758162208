import { documentModule } from "@rentiohq/shared-frontend/dist/reduxV2/documents";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import {
  ERecurrenceType,
  ITask,
} from "@rentiohq/shared-frontend/dist/types/task.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  getIsLastTask,
  getIsTaskAdmin,
} from "@rentiohq/shared-frontend/dist/utils/task.utils";
import {
  Activities,
  Card,
  ESpacings,
  Grid,
  Icon,
  Loading,
  Page,
  TextStyle,
  Tooltip,
} from "@rentiohq/web-shared/dist/components";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { matchPath } from "react-router";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { ts } from "../../../../services";
import * as t from "../../../../services/translationService";
import { TaskArchivedCard } from "../TaskArchivedCard";
import { TaskDetails } from "../TaskDetails";
import { TaskDocumentsOverviewContainer } from "../TaskDocuments/TaskDocumentsOverviewContainer";
import { TaskMeta } from "./TaskMeta";

export const TaskDetailsDashboard = () => {
  const { taskId: taskIdAsString } = useParams<{ taskId: string }>();
  const taskId = useMemo(() => Number(taskIdAsString), [taskIdAsString]);

  const navigate = useNavigate();
  const location = useLocation();

  const { detail: task } = taskModule.hooks.useDetail({
    shouldRefetch: false,
    id: taskId,
  });
  const { update } = taskModule.hooks.useUpdate({ id: taskId });
  const { remove, isRemoving } = taskModule.hooks.useRemove({
    id: taskId,
  });

  const { archive, isArchiving } = taskModule.hooks.useArchive({
    id: taskId,
  });

  // Redux
  const dispatch = useDispatch();

  const documentsForTaskCount = useSelector((state: IRootStore) =>
    documentModule.selectors.getCount(state, {
      filter: {
        taskId: { eq: taskId },
      },
    }),
  );
  const isFetchingDocumentsForTaskCount = useSelector((state: IRootStore) =>
    documentModule.selectors.isFetchingCount(state, {
      filter: {
        taskId: { eq: taskId },
      },
    }),
  );

  // Lifecycle
  React.useEffect(() => {
    if (isFetchingDocumentsForTaskCount) {
      return;
    }

    dispatch(
      documentModule.actions.getCountStart.getAction({
        query: {
          filter: {
            taskId: { eq: taskId },
          },
        },
      }),
    );
  }, [taskId]);

  // Event handlers
  const onEditTask = () => {
    let url = `/tasks/${taskId}/edit`;
    if (task?.status !== undefined && task?.status !== null) {
      url = `${url}?keypoint=1`;
    }
    navigate(url);
  };

  // Render
  const tabs = [
    {
      content: t.taskDetailTabsInformation(),
      url: `/tasks/${task?.id}`,
      isActive: !!matchPath("/tasks/:taskId", location.pathname),
    },
    {
      content: t.taskDetailTabsDocuments(),
      url: `/tasks/${task?.id}/documents`,
      isActive: !!matchPath("/tasks/:taskId/documents", location.pathname),
      count: documentsForTaskCount,
    },
    {
      content: getLocalizedText("task.detail.tabs.history"),
      url: `/tasks/${task?.id}/history`,
      isActive: !!matchPath("/tasks/:taskId/history", location.pathname),
    },
  ];

  const navigateToOverview = () => navigate("/tasks");
  const handleDelete = (task?: ITask) => {
    if (!task) return;

    let title = ts.removeConfirm("task");
    let info = undefined;
    const primaryActions = [
      {
        title: ts.system("remove"),
        onPress: () => {
          remove({
            onSuccess: navigateToOverview,
          });
        },
      },
    ];

    if (task.recurrenceType !== ERecurrenceType.Once) {
      title = getLocalizedText("task.remove.repeating.title");
      info = getLocalizedText("task.remove.repeating.info");
      primaryActions.push({
        title: "Remove and stop repeating",
        onPress: () => {
          update({
            data: {
              recurrenceType: ERecurrenceType.Once,
            },
            onSuccess: () =>
              remove({
                onSuccess: navigateToOverview,
              }),
          });
        },
      });
    }

    confirm({
      title,
      info,
      primaryActions,
    });
  };

  const handleArchive = (task?: ITask) => {
    if (!task) return;

    confirm({
      title: t.archiveConfirm("task"),
      type: "warning",
      primaryActions: [
        {
          title: t.system("archive"),
          onPress: () => {
            archive({});
          },
        },
      ],
    });
  };

  const dropdown = useMemo(() => {
    if (!task) return [];

    const { archivedAt } = task;

    return [
      !archivedAt &&
        getIsLastTask(task) && {
          content: <TextStyle>{t.edit()}</TextStyle>,
          onClick: onEditTask,
        },
      getIsTaskAdmin(task) && {
        content: (
          <TextStyle variation={"negative"}>{ts.deleteDocument()}</TextStyle>
        ),
        onClick: () => handleDelete(task),
      },
      !archivedAt && {
        content: <TextStyle variation={"negative"}>{t.archive()}</TextStyle>,
        onClick: () => handleArchive(task),
      },
    ].filter(Boolean) as {
      content: JSX.Element;
      onClick: () => void;
    }[];
  }, [task]);

  const renderGeneral = () => <TaskDetails taskId={taskId} task={task} />;

  const renderDocuments = () => (
    <TaskDocumentsOverviewContainer taskId={taskId} task={task} />
  );

  const renderHistory = () => (
    <Card heading={getLocalizedText("task.detail.tabs.history")}>
      <Activities
        identifier={`task-${taskId}`}
        filter={{
          where: {
            taskId,
          },
        }}
        hasFetchMore={true}
      />
    </Card>
  );

  const renderContent = () => {
    if (matchPath("/tasks/:taskId/documents", location.pathname)) {
      return renderDocuments();
    }

    if (matchPath("/tasks/:taskId/history", location.pathname)) {
      return renderHistory();
    }

    return renderGeneral();
  };

  const renderTitle = () => (
    <Grid alignItems="center">
      <Grid.Item>
        <Grid spacing="tight" alignItems="center">
          {task?.title && <Grid.Item>{task.title}</Grid.Item>}
          {task && task.recurrenceType !== ERecurrenceType.Once && (
            <Grid.Item mt={ESpacings.extraTight}>
              <Icon source="synchronizeArrows" color="neutral70" />
            </Grid.Item>
          )}
          {task?.nextReminderDate && (
            <Grid.Item mt={ESpacings.extraTight}>
              <Tooltip tooltipContent={formatDate(task.nextReminderDate)}>
                <Icon source="bell" color="neutral70" />
              </Tooltip>
            </Grid.Item>
          )}
        </Grid>
      </Grid.Item>
    </Grid>
  );

  const renderSubtitle = () => {
    const components = [];

    if (task?.createdAt) {
      components.push(t.taskCreatedAt(formatDate(task?.createdAt)));
    }

    if (task?.finishedAt) {
      components.push(t.taskCompletedAt(formatDate(task?.finishedAt)));
    }

    if (components.length > 0) {
      return components.join(" - ");
    }

    return <p style={{ color: "transparent" }}>&nbsp;</p>;
  };

  if (isRemoving || isArchiving) {
    return <Loading />;
  }

  if (!task) {
    return null;
  }

  return (
    <Page
      breadcrumbs={{
        content: t.tasksOverview(),
        to: "/tasks",
      }}
      title={renderTitle()}
      subtitle={renderSubtitle()}
      metadata={<TaskMeta task={task} />}
      tabs={tabs}
      dropdown={dropdown.length > 0 ? dropdown : undefined}
      documentTitle={task?.title || getLocalizedText("system.task")}
      variation={task.archivedAt ? "error" : undefined}
    >
      {task.archivedAt ? <TaskArchivedCard task={task} /> : renderContent()}
    </Page>
  );
};

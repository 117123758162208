import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import "draft-js/dist/Draft.css";
import React from "react";
import { DisplayTypeContext } from "../../../Editor/contexts/DisplayType.context";
import { FieldsContext } from "../../../Editor/contexts/Fields.context";
import { LoopContext } from "../../../Editor/contexts/Loop.context";
import { VariablesDataContext } from "../../../Editor/contexts/VariablesData.context";
import SwitchFlickerAnimation from "../SwitchFlickerAnimation/SwitchFlickerAnimation";
import { conditionIsTrue } from "./Condition.utils";
var Condition = function (props) {
    var _a, _b, _c;
    var conditionId = props.id;
    // Context
    var fields = React.useContext(FieldsContext).fields;
    var variablesData = React.useContext(VariablesDataContext).variablesData;
    var loopConfig = React.useContext(LoopContext).loopConfig;
    var isPrint = React.useContext(DisplayTypeContext).isPrint;
    // Helpers
    var condition = (_a = fields.conditions) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === conditionId; });
    var variableId = condition === null || condition === void 0 ? void 0 : condition.variableId;
    var variable = (_b = fields.variables) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.id === variableId; });
    var index = 0;
    if (loopConfig) {
        var isLoopedVariable = false;
        if (variable && (variable === null || variable === void 0 ? void 0 : variable.groupId) === loopConfig.groupId) {
            isLoopedVariable = true;
        }
        if (variableId === loopConfig.variableId) {
            isLoopedVariable = true;
        }
        if (isLoopedVariable) {
            (index = loopConfig.index);
        }
    }
    var variableData = variableId
        ? (_c = variablesData[variableId]) === null || _c === void 0 ? void 0 : _c[index]
        : undefined;
    var conditionIsTrueComputed = condition && conditionIsTrue({ variableData: variableData, condition: condition });
    // Render
    if (!condition || !variableId) {
        return null;
    }
    if (isPrint) {
        return _jsx(_Fragment, { children: conditionIsTrueComputed ? props.children : undefined });
    }
    return (_jsx("span", { className: variable ? "condition-variable-".concat(variable === null || variable === void 0 ? void 0 : variable.id) : undefined, children: _jsx(SwitchFlickerAnimation, { value: conditionIsTrueComputed, children: _jsx("div", { style: {
                    display: "inline",
                }, children: conditionIsTrueComputed ? props.children : undefined }) }) }));
};
// eslint-disable-next-line import/no-default-export
export default Condition;

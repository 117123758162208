import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import * as contactHooks from "@rentiohq/shared-frontend/dist/redux/contact/contact.hooks";
import * as contactSelectors from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import {
  Card,
  Loading,
  ResourceList,
} from "@rentiohq/web-shared/dist/components";
import { ContactOverviewRow } from "@rentiohq/web-shared/dist/components/ContactOverviewRow/ContactOverviewRow";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";

export const DuplicateContacts = () => {
  const params = useParams<{ contactId: string }>();
  const contactId = params.contactId ? +params.contactId : undefined;

  const contact = useSelector((state: IRootStore) =>
    contactId ? contactSelectors.getContact(state, contactId) : undefined,
  );

  const { data: duplicateContacts, isFetching: isFetchingDuplicateContacts } =
    contactHooks.usePaged(
      contact
        ? {
            shouldRefetch: true,
            id: `duplicate-contacts-${contact.id}`,
            page: 1,
            limit: CONFIG.DEFAULT_FETCH_LIMIT,
            filter: {
              where: {
                and: [
                  { or: [{ email: contact.email }, { phone: contact.phone }] },
                  { id: { neq: contact.id } },
                ],
              },
            },
          }
        : {
            shouldRefetch: false,
          },
    );

  // Render
  if (!contact) {
    return null;
  }

  const renderContactItem = (item: any) => (
    <ContactOverviewRow contact={item} clickable={true} />
  );

  return (
    <Card>
      {isFetchingDuplicateContacts &&
        (duplicateContacts || []).length === 0 && <Loading />}

      <ResourceList
        items={duplicateContacts || []}
        renderItem={renderContactItem}
      />
    </Card>
  );
};

import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import { getContactMeMaster } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import * as beneficiaryReportHooks from "@rentiohq/shared-frontend/dist/reduxV2/reportBeneficiary/report.beneficiary.hooks";
import { EPaymentRequestStatus } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import {
  endOfMonth,
  startOfMonth,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  EDateQuickFilters,
  EFilterType,
  EPaymentQuickFilters,
  Filters,
  getRangeForQuickFilter,
  Stages,
  translateDateRange,
} from "@rentiohq/web-shared/dist/components";
import { EPreferencePersistScope } from "@rentiohq/web-shared/dist/redux/system/system.types";
import { compact } from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import usePreference from "scenes/Settings/hooks/usePreference";
import { createEnumParam } from "serialize-query-params";
import {
  EBeneficiaryReportReported,
  EBeneficiaryReportReportingEnabledValues,
} from "./BeneficiaryReport.types";

export interface IResultingFilter {
  customFilters?: Array<string>;
  filter?: Record<string, any>;
}

interface IBeneficiaryReportFilterProps {
  searchQuery?: string | null;
  onFilterChange: (resultingFilter: IResultingFilter) => void;
  onSearchChange: (searchTerm: string) => void;
}

enum EPaidFilter {
  Paid = "paid",
  NotPaid = "NOT_PAID",
}

const PREFERENCE_KEY_PAYOUT_DATE_QUICK =
  "pref_beneficiary_reports_payout_date_quick";
const PREFERENCE_KEY_REPORTED = "pref_beneficiary_reports_reported";
const PREFERENCE_KEY_PAID = "pref_beneficiary_reports_paid";
const PREFERENCE_KEY_DATE_RANGE = "pref_beneficiary_reports_date_range";
const PREFERENCE_KEY_PAYMENT_PERIOD_QUICK =
  "pref_beneficiary_reports_payment_period_quick";
const PREFERENCE_KEY_PAYMENT_DATE_RANGE =
  "pref_beneficiary_reports_payment_date_range";

// No payouts created before this date are shown.
export const BENEFICIARY_REPORTS_CUTOFF_DATE = new Date(2022, 11, 1);

// eslint-disable-next-line import/no-default-export
export default function BeneficiaryReportFilter({
  searchQuery,
  onFilterChange,
  onSearchChange,
}: IBeneficiaryReportFilterProps) {
  const [queryParams] = useQueryParams({
    reported: createEnumParam(Object.values(EBeneficiaryReportReported)),
  });

  const contactMeMaster = useSelector((state: IRootStore) =>
    getContactMeMaster(state),
  );

  const [
    reported = EBeneficiaryReportReported.PaymentRequestNotReported,
    setReported,
  ] = usePreference<EBeneficiaryReportReported | null>({
    preferenceKey: PREFERENCE_KEY_REPORTED,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [paid = EPaidFilter.Paid, setPaid] = usePreference<EPaidFilter | null>({
    preferenceKey: PREFERENCE_KEY_PAID,
    preferencePersistScope: EPreferencePersistScope.LocalStorage,
  });

  const [payoutDateQuick, setPayoutDateQuick, clearPayoutDateQuick] =
    usePreference<EDateQuickFilters | null>({
      preferenceKey: PREFERENCE_KEY_PAYOUT_DATE_QUICK,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  const [paymentPeriodQuick, setPaymentPeriodQuick, clearPaymentPeriodQuick] =
    usePreference<EPaymentQuickFilters | null>({
      preferenceKey: PREFERENCE_KEY_PAYMENT_PERIOD_QUICK,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  useEffect(() => {
    if (queryParams?.reported) {
      setReported(queryParams.reported);
      setPaid(null);
      setPayoutDateQuick(null);
      setPaymentPeriodQuick(null);
    }
  }, [queryParams]);

  const [payoutDateRange, setPayoutDateRange, clearPayoutDateRange] =
    usePreference<[Date | null, Date | null]>({
      preferenceKey: PREFERENCE_KEY_DATE_RANGE,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  const [paymentDateRange, setPaymentDateRange, clearPaymentDateRange] =
    usePreference<[Date | null, Date | null]>({
      preferenceKey: PREFERENCE_KEY_PAYMENT_DATE_RANGE,
      preferencePersistScope: EPreferencePersistScope.LocalStorage,
    });

  useEffect(() => {
    let dateRangeForPayout: any[] = [];
    let dateRangeForPayment: any[] = [];

    if (payoutDateRange) {
      dateRangeForPayout = [null, null];
      const dateFilterKey =
        paid === EPaidFilter.NotPaid ? "createdAt" : "payoutDate";

      const start = payoutDateRange[0];
      const end = payoutDateRange[1];

      if (start) {
        dateRangeForPayout[0] = {
          [dateFilterKey]: { gte: startOfMonth(start) },
        };
      }

      if (end) {
        dateRangeForPayout[1] = {
          [dateFilterKey]: { lte: endOfMonth(end) },
        };
      }
    } else if (
      payoutDateQuick &&
      payoutDateQuick !== EDateQuickFilters.DateRange
    ) {
      const dateFilterKey =
        paid === EPaidFilter.NotPaid ? "createdAt" : "payoutDate";
      const range = getRangeForQuickFilter(payoutDateQuick, dateFilterKey);
      if (range) dateRangeForPayout = range;
    }

    if (paymentDateRange) {
      dateRangeForPayment = [null, null];

      const start = paymentDateRange[0];
      const end = paymentDateRange[1];

      if (start) {
        dateRangeForPayment[0] = {
          startedAt: { gte: startOfMonth(start) },
        };
      }

      if (end) {
        dateRangeForPayment[1] = {
          startedAt: { lte: endOfMonth(end) },
        };
      }
    } else if (
      paymentPeriodQuick &&
      paymentPeriodQuick !== EPaymentQuickFilters.DateRange
    ) {
      const range = getRangeForQuickFilter(paymentPeriodQuick, "startedAt");
      if (range) dateRangeForPayment = range;
    }

    let paidFilter;
    if (paid !== null) {
      const condition = paid === EPaidFilter.Paid ? "eq" : "neq";
      paidFilter = {
        status: {
          [condition]: EPaymentRequestStatus.Paid,
        },
      };
    }

    const filterObject: IResultingFilter = {};
    const customFilters: (
      | EBeneficiaryReportReported
      | EBeneficiaryReportReportingEnabledValues
    )[] = [];
    if (reported) {
      customFilters.push(reported);
    }
    // if (reportingEnabled) {
    //   customFilters.push(reportingEnabled);
    // }
    if (customFilters.length > 0) {
      filterObject.customFilters = customFilters;
    }

    const filters = compact([
      ...dateRangeForPayout,
      ...dateRangeForPayment,
      paidFilter,
      { createdAt: { gte: BENEFICIARY_REPORTS_CUTOFF_DATE } },
      { requestPayeeAccountId: { neq: contactMeMaster?.accountId } },
    ]);

    if (filters.length > 0) {
      filterObject.filter = { and: filters };
    }

    onFilterChange(filterObject);
  }, [
    reported,
    payoutDateQuick,
    paymentPeriodQuick,
    paymentDateRange,
    payoutDateRange,
    paid,
    contactMeMaster?.accountId,
  ]);

  //Counts
  const { count: notReportedCount } = beneficiaryReportHooks.useCount({
    shouldRefetch: true,
    query: {
      filter: {
        createdAt: { gte: BENEFICIARY_REPORTS_CUTOFF_DATE },
        requestPayeeAccountId: { neq: contactMeMaster?.accountId },
      },
      customFilters: [EBeneficiaryReportReported.PaymentRequestNotReported],
    },
  });

  const { count: reportedCount } = beneficiaryReportHooks.useCount({
    shouldRefetch: true,
    query: {
      filter: {
        createdAt: { gte: BENEFICIARY_REPORTS_CUTOFF_DATE },
        requestPayeeAccountId: { neq: contactMeMaster?.accountId },
      },
      customFilters: [EBeneficiaryReportReported.PaymentRequestReported],
    },
  });

  return (
    <>
      <Stages
        stages={[
          {
            heading: getLocalizedText(
              "reports.beneficiary.payouts.not_reported",
              {
                cutoffdate: formatDate(BENEFICIARY_REPORTS_CUTOFF_DATE),
              },
            ),
            icon: "fileQuestion",
            count: notReportedCount ?? 0,
            // appearance: "warning",
            onClick: () => {
              clearPayoutDateRange();
              setPayoutDateQuick(null);
              // setReportingEnabled(null);
              setReported(EBeneficiaryReportReported.PaymentRequestNotReported);
              setPaid(null);
            },
          },
          {
            heading: getLocalizedText("reports.beneficiary.payouts.reported"),
            icon: "sendingEnvelope",
            count: reportedCount ?? 0,
            // appearance: "success",
            onClick: () => {
              clearPayoutDateRange();
              setPayoutDateQuick(null);
              // setReportingEnabled(null);
              setReported(EBeneficiaryReportReported.PaymentRequestReported);
              setPaid(null);
            },
          },
        ]}
      />

      <Spacer weight={ESpacerWeight.W16} />

      <Filters
        queryPlaceholder={getLocalizedText(
          "reports.beneficiary.search.placeholder",
        )}
        queryValue={searchQuery ?? ""}
        onQueryChange={searchTerm => {
          onSearchChange(searchTerm);
        }}
        onQueryClear={() => {
          onSearchChange("");
        }}
        filterConfigs={[
          {
            filters: [
              {
                type: EFilterType.SingleSelect,
                options: [EPaidFilter.Paid, EPaidFilter.NotPaid],
                values: paid ? [paid] : [],
                translate: value =>
                  getLocalizedText(
                    `report.beneficiary.filters.payment_status.${(
                      value as string
                    ).toLowerCase()}`,
                  ),
                filterKey: "paid-filter",
                onChange: values => {
                  setPaid(values[0]);
                },
                onRemove: () => setPaid(null),
              },
            ],
            label: getLocalizedText("reports.beneficiary.header.payout"),
            groupKey: "paid",
          },
          {
            filters: [
              {
                type: EFilterType.SingleSelect,
                options: Object.values(EBeneficiaryReportReported),
                values: reported ? [reported] : [],
                translate: value =>
                  getLocalizedText(
                    `reports.beneficiary.filters.custom.${(
                      value as string
                    ).toLowerCase()}`,
                  ),
                filterKey: "reported-filter",
                onChange: values => {
                  setReported(values[0]);
                },
                onRemove: () => setReported(null),
              },
            ],
            label: getLocalizedText("reports.report"),
            groupKey: "report",
          },
          {
            filters: [
              {
                label: getLocalizedText("filters.title.quick_filters"),
                filterKey: "payout-date-quick",
                type: EFilterType.SingleSelect,
                options: Object.values(EDateQuickFilters),
                values: payoutDateQuick ? [payoutDateQuick] : undefined,
                appliedFiltersPrefix: `${getLocalizedText(
                  "payment_history.filter.in_out.section.out.option.payout",
                )}: `,
                translate: value =>
                  getLocalizedText(`filters.quick_dates.${value}`),
                onChange: quickFilter => {
                  setPayoutDateQuick(quickFilter?.[0]);
                  clearPayoutDateRange();
                },
                onRemove: () => {
                  clearPayoutDateQuick();
                },
                showInAppliedFilters: values =>
                  values?.[0] !== EDateQuickFilters.DateRange,
              },
              {
                label: getLocalizedText("filters.title.date_range"),
                filterKey: "payout-date-range",
                type: EFilterType.MonthRange,
                appliedFiltersPrefix: `${getLocalizedText(
                  "payment_history.filter.in_out.section.out.option.payout",
                )}: `,
                values: payoutDateRange
                  ? {
                      start:
                        typeof payoutDateRange[0] === "string"
                          ? new Date(payoutDateRange[0])
                          : payoutDateRange[0],
                      end:
                        typeof payoutDateRange[1] === "string"
                          ? new Date(payoutDateRange[1])
                          : payoutDateRange[1],
                    }
                  : undefined,
                minStartDate: BENEFICIARY_REPORTS_CUTOFF_DATE,
                maxStartDate: new Date(),
                minEndDate: BENEFICIARY_REPORTS_CUTOFF_DATE,
                maxEndDate: new Date(),
                translate: (value: any) => translateDateRange(value),
                onRemove: () => {
                  clearPayoutDateRange();
                },
                onChange: (newValues: {
                  start: Date | null;
                  end: Date | null;
                }) => {
                  setPayoutDateRange([newValues.start, newValues.end]);
                  setPayoutDateQuick(EDateQuickFilters.DateRange);
                },
              },
            ],
            label: getLocalizedText(
              "payment_history.filter.payment_date.section.quick.title",
            ),
            groupKey: "payout-date",
          },
          {
            filters: [
              {
                label: getLocalizedText("filters.title.quick_filters"),
                filterKey: "payout-date-quick",
                type: EFilterType.SingleSelect,
                options: Object.values(EPaymentQuickFilters),
                values: paymentPeriodQuick ? [paymentPeriodQuick] : undefined,
                appliedFiltersPrefix: `${getLocalizedText(
                  "payment_history.filter.in_out.section.out.option.payment",
                )}: `,
                translate: value =>
                  getLocalizedText(`filters.quick_dates.${value}`),
                onChange: quickFilter => {
                  setPaymentPeriodQuick(quickFilter?.[0]);
                  clearPaymentDateRange();
                },
                onRemove: () => {
                  clearPaymentPeriodQuick();
                },
                showInAppliedFilters: values =>
                  values?.[0] !== EDateQuickFilters.DateRange,
              },
              {
                label: getLocalizedText("filters.title.date_range"),
                filterKey: "payment-period-range",
                type: EFilterType.MonthRange,
                appliedFiltersPrefix: `${getLocalizedText(
                  "payment_history.filter.in_out.section.out.option.payment",
                )}: `,
                values: paymentDateRange
                  ? {
                      start:
                        typeof paymentDateRange[0] === "string"
                          ? new Date(paymentDateRange[0])
                          : paymentDateRange[0],
                      end:
                        typeof paymentDateRange[1] === "string"
                          ? new Date(paymentDateRange[1])
                          : paymentDateRange[1],
                    }
                  : undefined,
                minStartDate: BENEFICIARY_REPORTS_CUTOFF_DATE,
                maxStartDate: new Date(),
                minEndDate: BENEFICIARY_REPORTS_CUTOFF_DATE,
                maxEndDate: new Date(),
                translate: (value: any) => translateDateRange(value),
                onRemove: () => clearPaymentDateRange(),
                onChange: (newValues: {
                  start: Date | null;
                  end: Date | null;
                }) => {
                  setPaymentDateRange([newValues.start, newValues.end]);
                  setPaymentPeriodQuick(EPaymentQuickFilters.DateRange);
                },
              },
            ],
            label: getLocalizedText(
              "payment_history.filter.payment_period.section.quick.title",
            ),
            groupKey: "payment-period",
          },
        ]}
      />
    </>
  );
}

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatNumber } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { utcToZonedTime } from "date-fns-tz";
import "draft-js/dist/Draft.css";
import React from "react";
import { DisplayTypeContext } from "../../../Editor/contexts/DisplayType.context";
import { FieldsContext } from "../../../Editor/contexts/Fields.context";
import { GroupSidebarContext } from "../../../Editor/contexts/GroupSidebar.context";
import { LoopContext } from "../../../Editor/contexts/Loop.context";
import { VariablesDataContext } from "../../../Editor/contexts/VariablesData.context";
import { EVariableType } from "../../../Editor/Editor.types";
import * as S from "./Variable.styles";
var Variable = function (props) {
    var _a, _b, _c;
    var variableId = props.id;
    // Context
    var isPrint = React.useContext(DisplayTypeContext).isPrint;
    var fields = React.useContext(FieldsContext).fields;
    var variablesData = React.useContext(VariablesDataContext).variablesData;
    var loopConfig = React.useContext(LoopContext).loopConfig;
    var onOpenGroup = React.useContext(GroupSidebarContext).onOpenGroup;
    // Helpers
    var variable = (_a = fields.variables) === null || _a === void 0 ? void 0 : _a.find(function (x) { return x.id === variableId; });
    var groupId = variable === null || variable === void 0 ? void 0 : variable.groupId;
    var group = (_b = fields.groups) === null || _b === void 0 ? void 0 : _b.find(function (x) { return x.id === groupId; });
    var index = 0;
    if (loopConfig) {
        var isLoopedVariable = loopConfig.variableId === variableId || loopConfig.groupId === groupId;
        if (isLoopedVariable) {
            (index = loopConfig.index);
        }
    }
    var variableData = (_c = variablesData[variableId]) === null || _c === void 0 ? void 0 : _c[index];
    // Render
    if (!variable || !group) {
        return _jsx(S.Wrap, { filledIn: false, children: "Error loading variable" });
    }
    if (variable.type === EVariableType.Image) {
        return _jsx(S.Wrap, { filledIn: false, children: "Error: can not render inline image" });
    }
    var formattedFieldData = variableData;
    switch (variable === null || variable === void 0 ? void 0 : variable.type) {
        case EVariableType.Date:
            if (variableData) {
                var variableDataAsDate = variableData;
                formattedFieldData = formatDate(utcToZonedTime(variableDataAsDate, "Europe/Brussels"), variable.defaultDateFormatting);
            }
            break;
        case EVariableType.Boolean:
            if (variableData) {
                formattedFieldData = getLocalizedText("system.yes");
            }
            else {
                formattedFieldData = getLocalizedText("system.no");
            }
            break;
        case EVariableType.Number:
            if (variableData) {
                var variableDataAsNumber = +variableData;
                if (!isNaN(variableDataAsNumber)) {
                    formattedFieldData = formatNumber(variableDataAsNumber, false);
                }
            }
            break;
        case EVariableType.String:
        case EVariableType.Dropdown:
        default:
            break;
    }
    var renderContent = function () { return (_jsxs(S.ContentWrap, { children: [!!formattedFieldData && _jsx(_Fragment, { children: formattedFieldData }), !formattedFieldData && group && variable && (_jsx(_Fragment, { children: "".concat(getLocalizedText(group.name), " - ").concat(getLocalizedText(variable.name)) }))] })); };
    if (isPrint) {
        return renderContent();
    }
    return (_jsx(S.Wrap, { filledIn: !!formattedFieldData, className: "variable-".concat(variable.id), onClick: function () {
            onOpenGroup(variable.groupId, true);
        }, children: renderContent() }));
};
// eslint-disable-next-line import/no-default-export
export default Variable;

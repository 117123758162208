import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { payInModule } from "@rentiohq/shared-frontend/dist/reduxV2/payin";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { TextStyle } from "@rentiohq/web-shared/dist/components";
import { getPayInFilter } from "../../../scenes/Properties/scenes/Payments/components/PayInsFollowUp/PayInsFollowUp";

interface IProps {
  propertyId: number;
  contractId: string;
}

export const IbanisationStatusInfo = (props: IProps) => {
  const { propertyId, contractId } = props;

  // Redux
  const { items } = payInModule.hooks.usePaged({
    shouldRefetch: true,
    query: {
      page: 1,
      limit: CONFIG.DEFAULT_FETCH_LIMIT,
      sort: [{ field: "createdAt", method: "DESC" }],
      filter: getPayInFilter(),
    },
    customPath: propertyId
      ? `/properties/${propertyId}/payment-pay-ins`
      : undefined,
  });

  const payInsForContract = items?.filter(
    item => item.contractId === contractId,
  );

  // Render
  if (!payInsForContract) {
    return null;
  }

  if (payInsForContract.length === 0) {
    return null;
  }

  return (
    <TextStyle element="div" variation="subdued">
      {getLocalizedText("payments.follow_up.has_unmatched_pay_ins")}
    </TextStyle>
  );
};

var _a;
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { generateHooks } from "../generators/generator.hooks";
import { activateLegalStart, indexAskOwnerStart, indexSkipStart, indexStart, renewStart, } from "./contract.actions";
import { GENERATOR_PARAMS } from "./contract.constants";
import { activationError as activationErrorSelector, isActivatingLegal as isActivatingLegalSelector, isIndexing as isIndexingSelector, isRenewing as isRenewingSelector, renewError as renewErrorSelector, } from "./contract.selectors";
export var useDetail = (_a = generateHooks(GENERATOR_PARAMS), _a.useDetail), useUpdate = _a.useUpdate, useCount = _a.useCount, useGetAll = _a.useGetAll, usePaged = _a.usePaged, useRemove = _a.useRemove;
export var useIndex = function (params) {
    var contractId = params.contractId;
    var dispatch = useDispatch();
    var isIndexing = useSelector(function (state) {
        return contractId ? isIndexingSelector(state, contractId) : false;
    });
    var index = function (params) {
        var newPrice = params.newPrice, retroactivityMonths = params.retroactivityMonths, forceFullIndexation = params.forceFullIndexation, onSuccess = params.onSuccess, onFailure = params.onFailure;
        if (!contractId) {
            return;
        }
        dispatch(indexStart.getAction({
            id: contractId,
            newPrice: newPrice,
            retroactivityMonths: retroactivityMonths,
            forceFullIndexation: forceFullIndexation,
            onSuccess: onSuccess,
            onFailure: onFailure,
        }));
    };
    var askOwner = function (params) {
        var onSuccess = params.onSuccess, onFailure = params.onFailure;
        if (!contractId) {
            return;
        }
        dispatch(indexAskOwnerStart.getAction({
            id: contractId,
            onSuccess: onSuccess,
            onFailure: onFailure,
        }));
    };
    var skip = function (params) {
        var onSuccess = params.onSuccess, onFailure = params.onFailure;
        if (!contractId) {
            return;
        }
        dispatch(indexSkipStart.getAction({
            id: contractId,
            onSuccess: onSuccess,
            onFailure: onFailure,
        }));
    };
    return {
        index: index,
        askOwner: askOwner,
        skip: skip,
        isIndexing: isIndexing,
    };
};
export var useRenew = function (params) {
    var contractId = params.contractId;
    var dispatch = useDispatch();
    var isRenewing = useSelector(function (state) {
        return contractId ? isRenewingSelector(state, contractId) : false;
    });
    var renewError = useSelector(function (state) {
        return contractId ? renewErrorSelector(state, contractId) : undefined;
    });
    var renew = function (params) {
        var contractLengthType = params.contractLengthType, stopDate = params.stopDate, tacitRenewal = params.tacitRenewal, onSuccess = params.onSuccess;
        if (!contractId) {
            return;
        }
        dispatch(renewStart.getAction({
            id: contractId,
            contractLengthType: contractLengthType,
            stopDate: stopDate,
            tacitRenewal: tacitRenewal,
            onSuccess: onSuccess,
        }));
    };
    return {
        renew: renew,
        isRenewing: isRenewing,
        renewError: renewError,
    };
};
export var useActivateLegal = function () {
    var dispatch = useDispatch();
    var _a = useState(), passedId = _a[0], setPassedId = _a[1];
    var isActivating = useSelector(function (state) {
        return passedId ? isActivatingLegalSelector(state, passedId) : false;
    });
    var activationError = useSelector(function (state) {
        return passedId ? activationErrorSelector(state, passedId) : undefined;
    });
    var activateLegal = function (params) {
        var id = params.id, onSuccess = params.onSuccess, onFailure = params.onFailure;
        setPassedId(id);
        dispatch(activateLegalStart.getAction({
            id: id,
            onFailure: onFailure,
            onSuccess: onSuccess,
        }));
    };
    return {
        activateLegal: activateLegal,
        isActivating: isActivating,
        activationError: activationError,
    };
};

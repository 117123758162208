import { Box } from "@rebass/grid";
import PaymentOrderFetch from "@rentiohq/shared-frontend/dist/components/components/PaymentOrderFetch";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import {
  EDocumentPackageSignerStatus,
  IDocumentPackage,
} from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { IPaymentOrder } from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  ERentDepositStatus,
  IRentDeposit,
} from "@rentiohq/shared-frontend/dist/types/rentDeposit.types";
import { getMessageForError } from "@rentiohq/shared-frontend/dist/utils/error.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  AccountList,
  Activities,
  Banner,
  Card,
  DisplayText,
  ESpacings,
  Icon,
  Loading,
  ResourceList,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { getRentDepositActivityFilter } from "@rentiohq/web-shared/dist/utils/rentDeposit";
import React from "react";
import { useDispatch } from "react-redux";
import { ts } from "../../../../../../services";
import { documentPackageUtils } from "../../../../../../utils";
import { getValidDocumentPackage } from "../../../../../../utils/contract";
import { ERentDepositTabs } from "../../RentDeposit.types";
import { DocumentRow } from "./components/DocumentRow";
import { PaymentOrderRow } from "./components/PaymentOrderRow";

interface IProps {
  rentDeposit: IRentDeposit;
  setTab?: (tab: any) => void;
}

export type TDocumentPackage = IDocumentPackage & {
  documentsInPackage: IDocument[];
};

export const RentDepositInfo: React.FC<IProps> = ({ rentDeposit, setTab }) => {
  const dispatch = useDispatch();

  const {
    paymentOrderId,
    // paymentOrder,
    paymentOrderOutRenterId,
    // paymentOrderOutRenter,
    paymentOrderOutTenantId,
    // paymentOrderOutTenant,
    rentDepositContractOut,
    rentDepositContractIn,
  } = rentDeposit;

  const documentPackages = [
    ...(rentDepositContractIn?.documentPackages || []),
    ...(rentDepositContractOut?.documentPackages || []),
  ];
  const currentDocumentPackage = getValidDocumentPackage(
    rentDepositContractOut || rentDepositContractIn,
  );

  const paymentOrderIds = [
    paymentOrderId,
    paymentOrderOutRenterId,
    paymentOrderOutTenantId,
  ].filter(Boolean);

  // const paymentOrders = [
  //   paymentOrder,
  //   paymentOrderOutRenter,
  //   paymentOrderOutTenant,
  // ];

  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const handleActivityMoreClick = () => {
    setTab?.(ERentDepositTabs.History);
  };

  const renderDocumentPackage = (item: TDocumentPackage) => (
    <DocumentRow documentPackage={item} hasRevoke={false} />
  );

  const renderPaymentOrderRow = (paymentOrderId: number) => (
    <PaymentOrderFetch
      id={paymentOrderId}
      renderPaymentOrder={(paymentOrder: IPaymentOrder) => (
        <PaymentOrderRow paymentOrder={paymentOrder} />
      )}
      renderLoading={() => <Loading />}
      renderError={(_: () => void, error?: Error) => {
        // const paymentOrder = paymentOrders.find(
        //   randomPaymentOrder => randomPaymentOrder?.id === paymentOrderId,
        // );
        // if (paymentOrder) {
        //   return <PaymentOrderRow paymentOrder={paymentOrder} />;
        // }

        return (
          <TextStyle variation={["subdued", "negative"]}>
            {error
              ? getMessageForError(error)
              : getLocalizedText("general.error")}
          </TextStyle>
        );
      }}
    />
  );

  const createSignerSubtitle = (accountIds: number[]) => {
    const signer = currentDocumentPackage?.signers.find(signer =>
      accountIds.includes(signer.accountId),
    );

    if (!signer) {
      return null;
    }
    return documentPackageUtils.renderSignerSubtitle(signer);
  };

  const renderIbanBanner =
    rentDeposit.status === ERentDepositStatus.WaitingForIBAN ? (
      <Box mb={ESpacings.loose}>
        <Banner
          variation="warning"
          icon="alertDiamond"
          title={ts.followUpRentDepositsWaitingForIbanStatusNoticeHeading()}
          hasDismiss={false}
        >
          {ts.followUpRentDepositsWaitingForIbanStatusNoticeContent()}
        </Banner>
      </Box>
    ) : null;

  const renderWaitingForValidationBanner =
    rentDeposit.status === ERentDepositStatus.WaitingForValidations ? (
      <Box mb={ESpacings.loose}>
        <Banner
          variation="warning"
          icon="alertDiamond"
          title={ts.followUpRentDepositsWaitingForValidationsStatusNoticeHeading()}
          hasDismiss={false}
        >
          {ts.followUpRentDepositsWaitingForValidationsStatusNoticeContent()}
        </Banner>
      </Box>
    ) : null;

  const renderWaitingForVerificationBanner =
    rentDeposit.status === ERentDepositStatus.ManualVerification ? (
      <Box mb={ESpacings.loose}>
        <Banner
          variation="warning"
          icon="alertDiamond"
          title={ts.followUpRentDepositsManualVerificationStatusNoticeHeading()}
          hasDismiss={false}
        >
          {ts.followUpRentDepositsManualVerificationStatusNoticeContent()}
        </Banner>
      </Box>
    ) : null;

  const getActions = (params: { accountIds: number[] }) => {
    const { accountIds } = params;

    if (!currentDocumentPackage) {
      return [];
    }

    const signer = currentDocumentPackage.signers.find(randomSigner =>
      accountIds.includes(randomSigner.accountId),
    );
    if (!signer) {
      return [];
    }

    const result = [];

    // Open connective url
    if (
      signer.connectiveUrl &&
      signer.status !== EDocumentPackageSignerStatus.Finished
    ) {
      result.push({
        content: getLocalizedText("document_package.sign_for_signer.cta.title"),
        media: <Icon source="contentPenWrite" />,
        onClick: () => {
          window.open(signer.connectiveUrl, "_blank");
        },
      });
    }

    // Reminder
    if (signer.status !== EDocumentPackageSignerStatus.Finished) {
      result.push({
        content: getLocalizedText("document_package.remind_signer.cta.title"),
        media: <Icon source="email" />,
        onClick: () => {
          dispatch(
            documentPackageActions.remindDocumentPackageSigner.actions.start({
              documentPackageId: currentDocumentPackage.id,
              signerAccountId: signer.accountId,
            }),
          );
        },
      });
    }

    return result;
  };

  return (
    <>
      {renderIbanBanner}
      {renderWaitingForValidationBanner}
      {renderWaitingForVerificationBanner}
      {documentPackages.length > 0 && (
        <Card
          heading={renderCardHeader(ts.system("documents"))}
          space={ESpacings.base}
        >
          <ResourceList
            items={documentPackages}
            renderItem={renderDocumentPackage}
          />
        </Card>
      )}
      {currentDocumentPackage && (
        <Card
          heading={renderCardHeader(ts.system("signers"))}
          space={ESpacings.base}
        >
          <AccountList
            accounts={
              currentDocumentPackage
                ? (currentDocumentPackage.signers.map(signer => ({
                    id: signer.accountId,
                    firstname: signer.firstname,
                    lastname: signer.lastname,
                    company: signer.company,
                  })) as IAccount[])
                : []
            }
            getActions={getActions}
            getSubtitle={createSignerSubtitle}
          />
          {/* <ResourceList items={currentDocumentPackage ? currentDocumentPackage.signers : []} renderItem={SignerRow} /> */}
        </Card>
      )}
      {paymentOrderIds.length > 0 && (
        <Card
          heading={renderCardHeader(ts.system("payment_orders"))}
          space={ESpacings.base}
        >
          <ResourceList
            items={paymentOrderIds}
            renderItem={renderPaymentOrderRow}
          />
        </Card>
      )}
      {/* <Card heading={renderCardHeader(ts.system('property'))} space={ESpacings.base}>
        <ResourceList items={[property]} renderItem={PropertyRow} />
      </Card> */}
      <Card
        heading={renderCardHeader(ts.system("history"))}
        space={ESpacings.base}
      >
        <Box mb={ESpacings.base}>
          <Activities
            identifier={`rent-deposit-${rentDeposit.id}`}
            filter={getRentDepositActivityFilter(rentDeposit)}
            limit={3}
            isCompact={true}
            onMoreClick={handleActivityMoreClick}
          />
        </Box>
      </Card>
    </>
  );
};

var _a, _b, _c, _d, _e;
import { generateActions } from "../generators/generator.actions";
import { EActionState, createCustomAction } from "../utils/action.utils";
import { GENERATOR_PARAMS } from "./chat.constants";
var EActionCustom;
(function (EActionCustom) {
    EActionCustom["UpdateChat"] = "UPDATE_CHAT";
    // Chat Messages
    EActionCustom["ChatGetMessages"] = "CHAT_GET_MESSAGES";
    EActionCustom["ChatRequestForMessages"] = "CHAT_REQUEST_FOR_MESSAGES";
    EActionCustom["ChatRequestForMessagesFulfilled"] = "CHAT_REQUEST_FOR_MESSAGES_FULFILLED";
    EActionCustom["ChatExhausted"] = "CHAT_EXHAUSTED";
    EActionCustom["ChatSendMessage"] = "CHAT_SEND_MESSAGE";
    EActionCustom["ChatSendTyping"] = "CHAT_SEND_TYPING";
    EActionCustom["ChatSendReadReceipt"] = "CHAT_SEND_READ_RECEIPT";
    EActionCustom["ChatStopTyping"] = "CHAT_STOP_TYPING";
    EActionCustom["ChatMessagesReceived"] = "CHAT_MESSAGES_RECEIVED";
    EActionCustom["ChatIsTypingReceived"] = "CHAT_IS_TYPING_RECEIVED";
    EActionCustom["ChatCounterInfoReceived"] = "CHAT_COUNTER_INFO_RECEIVED";
    EActionCustom["ChatStatusInfoReceived"] = "CHAT_STATUS_INFO_RECEIVED";
})(EActionCustom || (EActionCustom = {}));
// Chat Actions
var generateUpdateChatActions = function () { return ({
    updateChatStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.UpdateChat,
        state: EActionState.Start,
    }),
}); };
// Chat Messages Actions
var generateGetMessagesActions = function () { return ({
    getMessagesStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatGetMessages,
        state: EActionState.Start,
    }),
    getMessagesSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatGetMessages,
        state: EActionState.Success,
    }),
    getMessagesFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatGetMessages,
        state: EActionState.Fail,
    }),
}); };
var generateRequestForMessagesActions = function () { return ({
    requestForMessagesStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatRequestForMessages,
        state: EActionState.Start,
    }),
}); };
var generateRequestForMessagesFulfilledActions = function () { return ({
    requestForMessagesFulfilledStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatRequestForMessagesFulfilled,
        state: EActionState.Start,
    }),
}); };
var generateSetChatIsExhaustedActions = function () { return ({
    setChatIsExhaustedStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatExhausted,
        state: EActionState.Start,
    }),
}); };
var generateSendMessageActions = function () { return ({
    sendMessageStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatSendMessage,
        state: EActionState.Start,
    }),
    sendMessageSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatSendMessage,
        state: EActionState.Success,
    }),
    sendMessageFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatSendMessage,
        state: EActionState.Fail,
    }),
}); };
var generateSendTypingActions = function () { return ({
    sendTypingStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatSendTyping,
        state: EActionState.Start,
    }),
    sendTypingSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatSendTyping,
        state: EActionState.Success,
    }),
    sendTypingFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatSendTyping,
        state: EActionState.Fail,
    }),
}); };
var generateSendReadReceiptActions = function () { return ({
    sendReadReceiptStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatSendReadReceipt,
        state: EActionState.Start,
    }),
    sendReadReceiptSucceeded: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatSendReadReceipt,
        state: EActionState.Success,
    }),
    sendReadReceiptFailed: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatSendReadReceipt,
        state: EActionState.Fail,
    }),
}); };
var generateStopTypingActions = function () { return ({
    stopTypingStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatStopTyping,
        state: EActionState.Start,
    }),
}); };
var generateMessagesReceivedActions = function () { return ({
    messagesReceivedStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatMessagesReceived,
        state: EActionState.Start,
    }),
}); };
var generateIsTypingReceivedActions = function () { return ({
    isTypingReceivedStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatIsTypingReceived,
        state: EActionState.Start,
    }),
}); };
var generateCounterInfoReceivedActions = function () { return ({
    counterInfoReceivedStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatCounterInfoReceived,
        state: EActionState.Start,
    }),
}); };
var generateStatusInfoReceivedActions = function () { return ({
    statusInfoReceivedStart: createCustomAction({
        entity: GENERATOR_PARAMS.entity,
        custom: EActionCustom.ChatStatusInfoReceived,
        state: EActionState.Start,
    }),
}); };
export var getDetailStart = (_a = generateActions(GENERATOR_PARAMS), _a.getDetailStart), getDetailSucceeded = _a.getDetailSucceeded, getDetailFailed = _a.getDetailFailed, getPagedStart = _a.getPagedStart, getPagedFailed = _a.getPagedFailed, getPagedSucceeded = _a.getPagedSucceeded, getCountStart = _a.getCountStart, getCountFailed = _a.getCountFailed, getCountSucceeded = _a.getCountSucceeded, 
// getInfiniteLoadStart,
// getInfiniteLoadFailed,
// getInfiniteLoadSucceeded,
createStart = _a.createStart, createSucceeded = _a.createSucceeded, createFailed = _a.createFailed, updateStart = _a.updateStart, updateSucceeded = _a.updateSucceeded, updateFailed = _a.updateFailed, updateMembersStart = _a.updateMembersStart, updateMembersSucceeded = _a.updateMembersSucceeded, updateMembersFailed = _a.updateMembersFailed, removeStart = _a.removeStart, removeSucceeded = _a.removeSucceeded, removeFailed = _a.removeFailed, archiveStart = _a.archiveStart, archiveSucceeded = _a.archiveSucceeded, archiveFailed = _a.archiveFailed, unarchiveStart = _a.unarchiveStart, unarchiveSucceeded = _a.unarchiveSucceeded, unarchiveFailed = _a.unarchiveFailed;
export var updateChatStart = generateUpdateChatActions().updateChatStart;
// Chat Messages
export var getMessagesStart = (_b = generateGetMessagesActions(), _b.getMessagesStart), getMessagesSucceeded = _b.getMessagesSucceeded, getMessagesFailed = _b.getMessagesFailed;
export var requestForMessagesStart = generateRequestForMessagesActions().requestForMessagesStart;
export var requestForMessagesFulfilledStart = generateRequestForMessagesFulfilledActions().requestForMessagesFulfilledStart;
export var setChatIsExhaustedStart = generateSetChatIsExhaustedActions().setChatIsExhaustedStart;
export var sendMessageStart = (_c = generateSendMessageActions(), _c.sendMessageStart), sendMessageSucceeded = _c.sendMessageSucceeded, sendMessageFailed = _c.sendMessageFailed;
export var sendTypingStart = (_d = generateSendTypingActions(), _d.sendTypingStart), sendTypingSucceeded = _d.sendTypingSucceeded, sendTypingFailed = _d.sendTypingFailed;
export var sendReadReceiptStart = (_e = generateSendReadReceiptActions(), _e.sendReadReceiptStart), sendReadReceiptSucceeded = _e.sendReadReceiptSucceeded, sendReadReceiptFailed = _e.sendReadReceiptFailed;
export var stopTypingStart = generateStopTypingActions().stopTypingStart;
export var messagesReceivedStart = generateMessagesReceivedActions().messagesReceivedStart;
export var isTypingReceivedStart = generateIsTypingReceivedActions().isTypingReceivedStart;
export var counterInfoReceivedStart = generateCounterInfoReceivedActions().counterInfoReceivedStart;
export var statusInfoReceivedStart = generateStatusInfoReceivedActions().statusInfoReceivedStart;

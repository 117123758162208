import { Box } from "@rebass/grid";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as countActions from "@rentiohq/shared-frontend/dist/redux/count/count.actions";
import * as countSelectors from "@rentiohq/shared-frontend/dist/redux/count/count.selectors";
import * as countUtils from "@rentiohq/shared-frontend/dist/redux/count/count.utils";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import * as paymentUtils from "@rentiohq/shared-frontend/dist/redux/payment/payment.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { IPaymentOrder } from "@rentiohq/shared-frontend/dist/types/payment.types";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  canAddPaymentOrders,
  groupPaymentOrdersByContract,
} from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import {
  Button,
  Card,
  DisplayText,
  ESpacings,
  EmptyState,
  Error,
  Grid,
  Loading,
  ResourceList,
  Washout,
} from "@rentiohq/web-shared/dist/components";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import * as t from "../../../../../../../services/translationService";
import { ContractInfoCardFooter } from "../../../components/PayInsFollowUp/ContractInfoCardFooter";
import { PaymentOrderRow } from "./../PaymentOrderRow";

interface IProps {
  propertyId: number;
}

export const PropertyPaymentOrders = (props: IProps) => {
  const { propertyId } = props;

  const navigate = useNavigate();
  const [showInactive, setShowInactive] = React.useState<boolean>(false);

  // Redux
  const dispatch = useDispatch();

  const { isBroker } = authHooks.useSelf();

  const { detail: property } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: propertyId,
  });

  const paymentOrdersIdentifier =
    paymentUtils.getPaymentOrdersIdentifierForProperty(propertyId);
  const activePaymentOrders = useSelector((state: IRootStore) =>
    paymentSelectors.getPaymentOrdersByIdentifier(
      state,
      paymentOrdersIdentifier,
    ),
  );
  const isFetchingActivePaymentOrders = useSelector(
    (state: IRootStore) =>
      state.payment.paymentOrdersByIdentifier[paymentOrdersIdentifier]
        ?.isFetching,
  );
  const isExhaustedFetchingActivePaymentOrders = useSelector(
    (state: IRootStore) =>
      state.payment.paymentOrdersByIdentifier[paymentOrdersIdentifier]
        ?.isExhausted,
  );
  const fetchErrorActivePaymentOrders = useSelector(
    (state: IRootStore) =>
      state.payment.paymentOrdersByIdentifier[paymentOrdersIdentifier]
        ?.fetchError,
  );

  const inactivePaymentOrdersCountIdentifier =
    countUtils.getCountIdentifierInactivePaymentOrdersForProperty(propertyId);
  const inactivePaymentOrdersCount = useSelector(
    (state: IRootStore) =>
      countSelectors.getCount(state, inactivePaymentOrdersCountIdentifier) || 0,
  );

  const inactivePaymentOrdersIdentifier = `property-${propertyId}-inactive-orders`;
  const inactivePaymentOrders = useSelector((state: IRootStore) =>
    paymentSelectors.getPaymentOrdersByIdentifier(
      state,
      inactivePaymentOrdersIdentifier,
    ),
  );
  const isExhaustedInactivePaymentOrders = useSelector(
    (state: IRootStore) =>
      state.payment.paymentOrdersByIdentifier[inactivePaymentOrdersIdentifier]
        ?.isExhausted,
  );
  const isFetchingInactivePaymentOrders = useSelector(
    (state: IRootStore) =>
      state.payment.paymentOrdersByIdentifier[inactivePaymentOrdersIdentifier]
        ?.isFetching,
  );

  // Data
  const fetchPaymentOrders = (refetch: boolean) => {
    if (isFetchingActivePaymentOrders) {
      return;
    }

    if (!refetch && isExhaustedFetchingActivePaymentOrders) {
      return;
    }

    dispatch(
      paymentActions.getPaymentOrdersByIdentifier.actions.start({
        paymentOrdersIdentifier,
        refetch,
        filterData: {
          where: { completedAt: null, propertyId },
          order: "startedAt DESC, id DESC",
        },
      }),
    );
  };

  const fetchInactivePaymentOrders = (refetch: boolean) => {
    if (isFetchingInactivePaymentOrders) {
      return;
    }

    if (!refetch && isExhaustedInactivePaymentOrders) {
      return;
    }

    dispatch(
      paymentActions.getPaymentOrdersByIdentifier.actions.start({
        refetch,
        paymentOrdersIdentifier: inactivePaymentOrdersIdentifier,
        filterData: {
          where: { completedAt: { neq: null }, propertyId },
          order: "startedAt DESC, id DESC",
        },
      }),
    );
  };

  // Lifecycle
  React.useEffect(() => {
    fetchPaymentOrders(true);

    dispatch(
      countActions.getCount.actions.start(
        countUtils.getActionPayloadInactivePaymentOrdersForProperty(propertyId),
      ),
    );
  }, []);

  React.useEffect(() => {
    if (!!activePaymentOrders && !isExhaustedFetchingActivePaymentOrders) {
      fetchPaymentOrders(false);
    }
  }, [activePaymentOrders, isExhaustedFetchingActivePaymentOrders]);

  React.useEffect(() => {
    fetchInactivePaymentOrders(false);
  }, [inactivePaymentOrders, isExhaustedInactivePaymentOrders]);

  const groupedPaymentOrders = groupPaymentOrdersByContract(
    activePaymentOrders || [],
  );

  const groupedInactivePaymentOrders = inactivePaymentOrders
    ? groupPaymentOrdersByContract(inactivePaymentOrders)
    : undefined;

  // Event handlers
  const handleRemoveWithConfirm = (paymentOrderId: number) => {
    const handleRemove = (paymentOrderId: number) => () => {
      dispatch(
        paymentActions.deletePaymentOrder.actions.start({
          paymentOrderId,
        }),
      );
    };

    confirm({
      title: t.removeConfirm("payment_order"),
      primaryActions: [
        {
          title: t.system("remove"),
          onPress: handleRemove(paymentOrderId),
        },
      ],
    });
  };

  const handleEdit = (paymentOrder: IPaymentOrder) => {
    navigate(`/properties/${propertyId}/payments/${paymentOrder.id}/edit`);
  };

  const handleShowInactiveClick = () => {
    fetchInactivePaymentOrders(true);

    setShowInactive(prevResult => !prevResult);
  };

  // Render
  if (!property) {
    return null;
  }

  const renderContent = () => {
    if (!activePaymentOrders) {
      if (isFetchingActivePaymentOrders) {
        return (
          <Card hasSections={true}>
            <Loading />
          </Card>
        );
      }

      if (fetchErrorActivePaymentOrders) {
        return (
          <Card hasSections={true}>
            <Error errors={[fetchErrorActivePaymentOrders]} />
          </Card>
        );
      }

      return null;
    }

    if (activePaymentOrders.length === 0 && inactivePaymentOrdersCount === 0) {
      if (!canAddPaymentOrders({ property, isBroker })) {
        return null;
      }

      return (
        <Card hasSections={true}>
          <EmptyState
            size="large"
            heading={t.paymentsOverviewEmptyStateTitle()}
            backgroundColor="white"
            action={{
              content: t.paymentsOverviewEmptyStatePrimaryCTA(),
              url: `/properties/${propertyId}/payments/add`,
            }}
          >
            <p>{t.paymentsOverviewEmptyStateContent()}</p>
          </EmptyState>
        </Card>
      );
    }

    const renderPaymentOrder = (paymentOrder: IPaymentOrder) => (
      <PaymentOrderRow
        paymentOrder={paymentOrder}
        onEdit={handleEdit}
        onRemove={handleRemoveWithConfirm}
      />
    );

    return (
      <>
        {isEmpty(activePaymentOrders) ? (
          <>
            <p>{t.paymentsOverviewEmptyStateNoResultContent()}</p>
          </>
        ) : (
          groupedPaymentOrders.map(group => (
            <Card
              footerContent={
                <ContractInfoCardFooter
                  contractId={group.contract?.id}
                  contract={group.contract}
                  showIbanisationInfo={false}
                />
              }
            >
              <ResourceList
                items={group.paymentOrders}
                renderItem={renderPaymentOrder}
              />
            </Card>
          ))
        )}

        {inactivePaymentOrdersCount > 0 && !showInactive && (
          <Box my={ESpacings.loose}>
            <Button appearance="link" onClick={handleShowInactiveClick}>
              {getLocalizedText("payments.overview.show_inactive.action", {
                value: `${inactivePaymentOrdersCount}`,
              })}
            </Button>
          </Box>
        )}

        {showInactive && (
          <Box my={ESpacings.loose}>
            <DisplayText size="medium">
              {t.paymentsOverviewInactiveHeading()}
            </DisplayText>

            {groupedInactivePaymentOrders &&
              groupedInactivePaymentOrders.length > 0 && (
                <Washout variation="medium">
                  {groupedInactivePaymentOrders.map(group => (
                    <Card
                      footerContent={
                        <ContractInfoCardFooter
                          contractId={group.contract?.id}
                          contract={group.contract}
                          showIbanisationInfo={false}
                        />
                      }
                    >
                      <ResourceList
                        items={group.paymentOrders}
                        renderItem={renderPaymentOrder}
                      />
                    </Card>
                  ))}
                </Washout>
              )}

            {isFetchingInactivePaymentOrders && <Loading asDots />}

            <Box mt={ESpacings.loose}>
              <Button appearance="link" onClick={handleShowInactiveClick}>
                {getLocalizedText(
                  "payments.overview.hide_inactive_contract.action",
                )}
              </Button>
            </Box>
          </Box>
        )}
      </>
    );
  };

  return (
    <>
      <Grid
        mb={ESpacings.base}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid.Item>
          <DisplayText size="medium">
            {getLocalizedText("payment_orders.title")}
          </DisplayText>
        </Grid.Item>

        {canAddPaymentOrders({ property, isBroker }) && (
          <Grid.Item>
            <Button
              appearance="primary"
              size="small"
              url={`/properties/${property.id}/payments/add`}
            >
              {t.paymentsOverviewCreatePrimaryCTA()}
            </Button>
          </Grid.Item>
        )}
      </Grid>

      {renderContent()}
    </>
  );
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
export var AutoComplete = function (_a) {
    var options = _a.options, name = _a.name, value = _a.value, onChange = _a.onChange;
    return (_jsx(Autocomplete, { freeSolo: true, size: "small", value: value, disablePortal: true, onChange: function (_, value) { return onChange(value); }, options: options, getOptionLabel: function (option) { return option.label; }, renderOption: function (props, option) {
            return (_jsx(Box, __assign({ component: "li" }, props, { children: option === null || option === void 0 ? void 0 : option.label }), option === null || option === void 0 ? void 0 : option.value));
        }, renderInput: function (params) { return _jsx(TextField, __assign({ name: name }, params)); } }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { generateRef } from "@rentiohq/shared-frontend/dist/redux/socket/socket.utils";
import { getMessagesStart } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import { getIsChatExhausted, getMessagesSelector, makeGetMessagesDataSource, } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { subDays } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import utils from "@rentiohq/web-shared/dist/utils";
import { spacing } from "@rentiohq/web-theme";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SpinningLoader } from "../../SpinningLoader";
import { ChatMessage } from "../ChatMessage";
import { TypingIndicator } from "../TypingIndicator";
import { ChatHistoryStyles } from "./ChatHistory.styles";
export var ChatHistory = function (_a) {
    var chat = _a.chat, chatId = _a.chatId, onPressMessage = _a.onPressMessage, onLongPressMessage = _a.onLongPressMessage, onPressUrl = _a.onPressUrl;
    var sx = ChatHistoryStyles();
    var containerRef = React.useRef();
    var dispatch = useDispatch();
    React.useLayoutEffect(function () {
        if (containerRef.current) {
            containerRef.current.addEventListener("wheel", invertedWheelEvent);
        }
        return function () { };
    }, [containerRef]);
    var getMessagesDataSource = makeGetMessagesDataSource();
    var messages = useSelector(function (state) {
        return getMessagesSelector(state, chatId);
    });
    var messagesDataSource = useSelector(function (state) {
        return getMessagesDataSource(state, chatId);
    }) || [];
    var isExhausted = useSelector(function (state) { return getIsChatExhausted(state, chatId) || false; });
    var invertedWheelEvent = function (e) {
        var _a, _b, _c, _d, _e;
        (_a = e.preventDefault) === null || _a === void 0 ? void 0 : _a.call(e);
        (_b = e.stopPropagation) === null || _b === void 0 ? void 0 : _b.call(e);
        (_c = e.stopImmediatePropagation) === null || _c === void 0 ? void 0 : _c.call(e);
        (_e = (_d = e.nativeEvent) === null || _d === void 0 ? void 0 : _d.stopImmediatePropagation) === null || _e === void 0 ? void 0 : _e.call(_d);
        containerRef.current.scrollTop -= e.deltaY;
    };
    var handlePress = function (message) { return function () {
        onPressMessage(message);
    }; };
    // const handleLongPress = (message: IMessage) => () => {
    //   onLongPressMessage?.(message);
    // };
    var handlePressUrl = function (message) { return function (url) {
        onPressUrl === null || onPressUrl === void 0 ? void 0 : onPressUrl(url, message);
    }; };
    var renderMessage = function (item, index) {
        if (typeof item === "string") {
            if (item === "typing") {
                return (_jsx(Box, { children: _jsx(TypingIndicator, {}) }, "chat-message-".concat(index)));
            }
        }
        var message = item;
        var sendBy = message.sendBy, text = message.text;
        if (!sendBy) {
            if (!text) {
                return null;
            }
            return (_jsx(Box, { children: _jsx(Typography, { variant: "caption", sx: __assign(__assign({}, sx.content.chatInfo), { transform: "none" }), component: "span", children: text }) }, "chat-message-".concat(index)));
        }
        return (_jsx(Box, { sx: sx.content.chatMessageWrapper, children: _jsx(ChatMessage, { chat: chat, message: message, onPress: handlePress(message), 
                // onLongPress={handleLongPress(message)}
                onPressUrl: handlePressUrl(message), conversationIsActive: !document.hidden }) }, "chat-message-".concat(index)));
    };
    return (_jsxs(Box, { children: [_jsx(Box, { sx: sx.wrapper, ref: containerRef, children: !messages ? (_jsx(Box, { sx: sx.content.loaderContainer, children: _jsx(SpinningLoader, {}) })) : (_jsxs(_Fragment, { children: [messagesDataSource.map(function (section, index) {
                            return (_jsxs(React.Fragment, { children: [section.data.map(renderMessage), section.key && (_jsx(Typography, { component: "span", sx: sx.content.chatInfo, children: utils.date.diffForHumans(new Date(section.key), {
                                            to: subDays(new Date(), 2),
                                        }) }))] }, index));
                        }), !isExhausted && (_jsx("div", { style: {
                                display: "flex",
                                justifyContent: "center",
                                paddingTop: spacing("loose"),
                            }, children: _jsx(Button, { onClick: function () {
                                    var ref = generateRef();
                                    dispatch(getMessagesStart.getAction({
                                        chatId: chatId,
                                        shouldReset: false,
                                        ref: ref,
                                    }));
                                }, children: getLocalizedText("chats.list.cta.show_more") }) }))] })) }), _jsx(Divider, { sx: sx.content.divider })] }));
};

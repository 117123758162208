import { EIdCardOptions } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.1.broker";
import {
  EField,
  IExtraData,
} from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import { getMappedLanguages } from "@rentiohq/shared-frontend/dist/utils/location/location.utils";
import { address as addressUtils } from "@rentiohq/web-shared/dist/utils";
import {
  IFormikValues,
  IFormStep,
} from "@rentiohq/web-shared-next/dist/utils/types/form";
import { uiSchema } from "./schema.addContact";

export interface IInitialContactValues {
  [EField.Firstname]: string;
  [EField.Lastname]: string;
  [EField.Email]: string;
  [EField.Phone]: string;
  [EField.Address]?: string | string[];
  [EField.PlaceOfBirth]?: string | string[];
  [EField.CountryOfBirth]?: string;
  [EField.InitialType]?: string;
  [EField.Language]: string;
  [EField.NationalRegisterNumber]?: string;
  [EField.ForeignIdentityNumber]?: string;
  [EField.DateOfBirth]?: Date;
  [EField.Nationality]?: string;
  [EField.IdType]:
    | EIdCardOptions.BelgianIdCardNumber
    | EIdCardOptions.PassportNumber;
  [EField.PrefillAddress]: "yes" | "no";
  [EField.SendInvite]: "yes" | "no";
  [EField.CountryId]?: string;
}

export const getInitialValues = ({
  contact,
  prefilledAddress,
  initialType,
}: IExtraData): IInitialContactValues => {
  let address = undefined;
  if (contact?.street) {
    address = {
      street: contact.street,
      number: contact.number,
      box: contact.box,
      zip: contact.zip,
      city: contact.city,
      state: contact.state,
      country: contact.countryId,
    };
  }

  let placeOfBirth = undefined;
  if (contact?.placeOfBirth) {
    placeOfBirth = {
      city: contact.placeOfBirth,
      country: contact.countryOfBirth,
    };
  }

  return {
    [EField.InitialType]: contact?.initialType || initialType,
    [EField.Firstname]: contact?.firstname || "",
    [EField.Lastname]: contact?.lastname || "",
    [EField.Email]: contact?.email || "",
    [EField.Phone]: contact?.phone || "",
    [EField.Language]: getMappedLanguages()[0].id,
    [EField.NationalRegisterNumber]:
      contact?.nationalRegisterNumber || undefined,
    [EField.ForeignIdentityNumber]: contact?.foreignIdentityNumber || undefined,
    [EField.DateOfBirth]: contact?.dateOfBirth || undefined,
    [EField.Nationality]: contact?.nationality || undefined,
    [EField.IdType]:
      contact?.nationalRegisterNumber || contact?.nationality === "BE"
        ? EIdCardOptions.BelgianIdCardNumber
        : EIdCardOptions.PassportNumber,
    [EField.PrefillAddress]: prefilledAddress ? "yes" : "no",
    [EField.SendInvite]: "yes",
    [EField.Address]: addressUtils.addressObjToString(address || {}),
    [EField.PlaceOfBirth]: addressUtils.addressObjToString(placeOfBirth || {}),
    [EField.CountryOfBirth]: contact?.countryOfBirth,
    [EField.CountryId]: contact?.countryId,
    ...address,
  };
};

export const addContactSchema =
  (extraData: IExtraData) =>
  (): { formStepsSchema: IFormStep[]; initialValues: IFormikValues } => {
    const initialValues = getInitialValues(extraData);
    const formStepsSchema = [
      {
        uiSchema: uiSchema(extraData),
      },
    ];

    return { formStepsSchema, initialValues };
  };

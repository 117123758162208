import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../../themes/types";
export var ChatDrawerStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            width: { xs: "100%", md: "auto" },
        },
        content: {
            drawer: {
                width: { md: 320, xl: 415 },
                flexShrink: 0,
                position: "relative",
                overflow: "auto",
                maxHeight: "calc(100vh - 140px)",
                height: "100%",
                minWidth: "320px",
                zIndex: 0,
                "& .MuiDrawer-paper": {
                    height: { xs: "100%", lg: "auto" },
                    width: "auto",
                    boxSizing: "border-box",
                    position: "relative",
                    p: 2,
                    minHeight: { lg: "calc(100vh - 250px)", xl: "calc(100vh - 140px)" },
                    borderRadius: { xs: "none", lg: "8px" },
                    border: "1px solid ".concat(theme.palette.grey[200]),
                    ".MuiCard-root": {
                        borderRadius: "4px",
                    },
                },
            },
            card: {
                minHeight: { lg: "calc(100vh - 250px)", xl: "calc(100vh - 140px)" },
                overflow: "unset",
            },
            title: {
                color: theme.palette.grey[900],
                fontWeight: EFontWeights.Bold,
            },
            headerContainer: {
                display: "flex",
                flexDirection: "column",
            },
            divider: {
                my: 2,
            },
            addChatBtn: {
                minWidth: "24px",
                height: "24px",
                width: "24px",
                padding: "4px",
                borderRadius: "4px",
                fontSize: "20px",
            },
            clearButton: {
                cursor: "pointer",
            },
            searchInput: {
                ".MuiOutlinedInput-notchedOutline": {
                    borderColor: theme.palette.grey[200],
                },
            },
        },
    };
};

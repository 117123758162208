var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import * as React from "react";
export var SplitButton = function (_a) {
    var btnLabel = _a.btnLabel, options = _a.options;
    var anchorRef = React.useRef(null);
    var _b = React.useState(false), open = _b[0], setOpen = _b[1];
    var handleToggle = function () {
        setOpen(function (prevOpen) { return !prevOpen; });
    };
    var handleClose = function (event) {
        var _a;
        if ((_a = anchorRef === null || anchorRef === void 0 ? void 0 : anchorRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    return (_jsxs(React.Fragment, { children: [_jsxs(ButtonGroup, { variant: "outlined", ref: anchorRef, disableElevation: true, "aria-label": "Button group with a nested menu", children: [_jsx(Button, { onClick: handleToggle, children: btnLabel }), _jsx(Button, { size: "small", "aria-controls": open ? "split-button-menu" : undefined, "aria-expanded": open ? "true" : undefined, "aria-label": "select merge strategy", "aria-haspopup": "menu", onClick: handleToggle, children: _jsx(ArrowDropDownIcon, {}) })] }), _jsx(Popper, { sx: { zIndex: 1 }, open: open, anchorEl: anchorRef.current, role: undefined, transition: true, disablePortal: true, children: function (_a) {
                    var TransitionProps = _a.TransitionProps, placement = _a.placement;
                    return (_jsx(Grow, __assign({}, TransitionProps, { style: {
                            transformOrigin: placement === "bottom" ? "center top" : "center bottom",
                        }, children: _jsx(Paper, { children: _jsx(ClickAwayListener, { onClickAway: handleClose, children: _jsx(MenuList, { id: "split-button-menu", autoFocusItem: true, children: options.map(function (option) { return (_jsx(MenuItem, { onClick: function (event) {
                                            option === null || option === void 0 ? void 0 : option.onClick();
                                            setOpen(false);
                                        }, children: option === null || option === void 0 ? void 0 : option.label }, option === null || option === void 0 ? void 0 : option.label)); }) }) }) }) })));
                } })] }));
};

import { useTheme } from "@mui/material/styles";
export var FormStepStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            width: "100%",
        },
        content: {
            formMainTitle: {
                color: theme.palette.text.dark,
                mb: 1,
            },
            actions: {
                mt: 2,
            },
        },
    };
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { useState } from "react";
import { SpinningLoader } from "../../SpinningLoader";
import { ChatAttachmentPreviewStyles } from "./ChatAttachmentPreview.styles";
export var ChatAttachmentPreview = function (_a) {
    var isSender = _a.isSender, image = _a.image;
    var sx = ChatAttachmentPreviewStyles();
    var _b = useState(false), isLoading = _b[0], setIsLoading = _b[1];
    var _c = useState(false), loadingFailed = _c[0], setLoadingFailed = _c[1];
    var handleLoadStart = function () {
        setIsLoading(true);
    };
    var handleLoad = function () {
        setIsLoading(false);
        setLoadingFailed(false);
    };
    return (_jsxs(Box, { sx: sx.wrapper, children: [(isLoading || loadingFailed) && _jsx(SpinningLoader, {}), !loadingFailed && image && (_jsx("img", { alt: "chatImage", src: image, onLoadStart: handleLoadStart, onLoad: handleLoad }))] }));
};

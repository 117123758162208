import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { getName } from "@rentiohq/shared-frontend/dist/redux/contact/contact.utils";
import { ISocketReadyState } from "@rentiohq/shared-frontend/dist/redux/socket/socket.types";
import { generateRef } from "@rentiohq/shared-frontend/dist/redux/socket/socket.utils";
import { sendReadReceiptStart } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import { getFormattedChatDate } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import Hyperlink from "react-native-hyperlink";
import { useDispatch, useSelector } from "react-redux";
import { EAssets } from "../../../assets";
import { Icon } from "../../Icon";
import { ChatAttachmentPreview } from "../ChatAttachmentPreview";
import { ChatMessageStyles } from "./ChatMessage.styles";
export var ChatMessage = function (props) {
    var sx = ChatMessageStyles();
    var isBroker = useSelf().isBroker;
    var dispatch = useDispatch();
    var _a = useSelector(function (state) { return state.socket; }), readyState = _a.readyState, pendingRefs = _a.pendingRefs;
    var pendingRef;
    var chat = props.chat, message = props.message, conversationIsActive = props.conversationIsActive, onPress = props.onPress, onPressUrl = props.onPressUrl;
    var text = message.text, account = message.account, attachment = message.attachment, user = message.user, status = message.status;
    React.useEffect(function () {
        handleSendReadReceipt();
    }, []);
    var getIsSender = function () { return !!message.account.activeAccountId; };
    var isGroupChat = chat.members.length > 2;
    var isSender = getIsSender();
    var showName = !isSender && isGroupChat;
    var showEmployeeName = isSender && isBroker;
    var getMyActiveAccountId = function () {
        var memberSelf = chat.members.find(function (member) { return !!member.account.activeAccountId; });
        if (memberSelf) {
            return memberSelf.account.activeAccountId;
        }
        return -1;
    };
    var renderStatus = function () {
        var myActiveAccountId = getMyActiveAccountId();
        if (!isSender) {
            return null;
        }
        var didDeliver = true;
        var didRead = true;
        status.forEach(function (_a) {
            var accountId = _a.accountId, deliveredAt = _a.deliveredAt, readAt = _a.readAt;
            if (accountId === myActiveAccountId) {
                return;
            }
            if (!deliveredAt) {
                didDeliver = false;
            }
            if (!readAt) {
                didRead = false;
            }
        });
        if (didRead) {
            return _jsx(Icon, { path: EAssets.ChatReadIcon, width: "14px", height: "14px" });
        }
        if (didDeliver) {
            return _jsx(Icon, { path: EAssets.ChatSentIcon, width: "14px", height: "14px" });
        }
        return (_jsx(Icon, { path: EAssets.ChatSentIcon, realColor: "#fff", width: "14px", height: "14px" }));
    };
    var handlePressUrl = function (url) {
        if (onPressUrl) {
            onPressUrl(url);
        }
    };
    var handleSendReadReceipt = function () {
        if (!conversationIsActive) {
            return;
        }
        if (readyState !== ISocketReadyState.Open) {
            return;
        }
        if (getIsSendingReadReceipt()) {
            return;
        }
        var myAccountId = getMyAccountId();
        var status = message.status.find(function (_a) {
            var accountId = _a.accountId;
            return accountId === myAccountId;
        });
        if (!status || status.readAt) {
            return;
        }
        var ref = generateRef();
        pendingRef = ref;
        dispatch(sendReadReceiptStart.getAction({
            ref: ref,
            chatId: message.chatId,
            messageId: message.id,
            accountId: status.accountId,
        }));
    };
    var getIsSendingReadReceipt = function () {
        return pendingRef && pendingRefs.includes(pendingRef);
    };
    var getMyAccountId = function () {
        var memberSelf = chat.members.find(function (member) { return !!member.account.activeAccountId; });
        if (memberSelf) {
            return memberSelf.account.id;
        }
        return -1;
    };
    return (_jsx(Box, { sx: sx.wrapper({ isSender: isSender }), children: _jsxs(Box, { sx: isSender ? sx.content.sentMessage : sx.content.receivedMessage, onClick: onPress, children: [attachment && (_jsx(Box, { children: attachment.previewUrl ? (_jsx(ChatAttachmentPreview, { image: attachment.url, isSender: isSender })) : (_jsx(Typography, { variant: "caption", children: getLocalizedText("chat.attachment.preview.unavailable") })) })), showName && account && (_jsx(Typography, { variant: "caption", fontStyle: "italic", children: getName(account) })), showEmployeeName && user && (_jsx(Typography, { variant: "caption", fontStyle: "italic", children: getName(user) })), text && (
                // @ts-ignore
                _jsx(Hyperlink, { onPress: handlePressUrl, linkStyle: { textDecorationLine: "underline" }, children: _jsx(Typography, { variant: "body2", children: text }) })), _jsxs(Stack, { direction: "row", gap: 1, alignItems: "center", children: [_jsx(Typography, { variant: "caption", sx: isSender ? sx.content.sentDate : sx.content.receivedDate, children: getFormattedChatDate(message) }), renderStatus()] })] }) }));
};

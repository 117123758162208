export var FormStyles = function () {
    return {
        multiStepWrapper: {
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
            p: 2,
        },
        singleStepWrapper: {
            flexGrow: 1,
            bgcolor: "background.paper",
            display: "flex",
        },
    };
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import ButtonBase from "@mui/material/ButtonBase";
import Chip from "@mui/material/Chip";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, matchPath, useLocation } from "react-router-dom";
import { SHOW_APP_MODAL } from "../../../redux/actions";
import { Icon } from "../../../ui-components/Icon";
import { NavItemStyles } from "./NavItem.styles";
var NavItem = function (_a) {
    var item = _a.item, sidebarOpened = _a.sidebarOpened, _b = _a.count, count = _b === void 0 ? 0 : _b;
    var sx = NavItemStyles();
    var dispatch = useDispatch();
    var ref = useRef(null);
    var pathname = useLocation().pathname;
    var isSelected = item.matchPaths.some(function (path) { return matchPath(path, pathname); });
    var _c = useState(false), hoverStatus = _c[0], setHoverStatus = _c[1];
    useEffect(function () {
        compareSize();
        window.addEventListener("resize", compareSize);
        window.removeEventListener("resize", compareSize);
    }, []);
    var compareSize = function () {
        var compare = ref.current && ref.current.scrollWidth > ref.current.clientWidth;
        setHoverStatus(compare);
    };
    var renderButtonContent = function () {
        return (_jsxs(_Fragment, { children: [_jsx(Tooltip, { title: item.title, placement: "right", disableHoverListener: sidebarOpened, children: _jsx(ButtonBase, { "aria-label": "theme-icon", sx: sx.content.buttonBase, disableRipple: sidebarOpened, children: _jsx(ListItemIcon, { sx: sidebarOpened
                                ? __assign(__assign({}, sx.content.listItemIcon.open), (isSelected
                                    ? sx.content.listItemIcon.openSelected
                                    : {})) : __assign(__assign({}, sx.content.listItemIcon.close), (isSelected
                                ? sx.content.listItemIcon.closeSelected
                                : {})), children: _jsx(Icon, { path: item === null || item === void 0 ? void 0 : item.icon, width: "24px", height: "24px" }) }) }) }), sidebarOpened && (_jsx(Tooltip, { title: item.title, disableHoverListener: !hoverStatus, children: _jsx(ListItemText, { primary: _jsx(Typography, { ref: ref, noWrap: true, overflow: "hidden", textOverflow: "ellipsis", variant: "body1", color: "inherit", width: 102, children: item.title }), secondary: item.caption && (_jsx(Typography, { variant: "caption", sx: sx.content.typography, display: "block", gutterBottom: true, children: item.caption })) }) })), sidebarOpened && Boolean(count) && (_jsx(Chip, { label: count, size: "small", sx: sx.content.chip }))] }));
    };
    var handleShowConfirmModal = function () {
        dispatch({
            type: SHOW_APP_MODAL,
            payload: {
                link: item === null || item === void 0 ? void 0 : item.url,
                title: item === null || item === void 0 ? void 0 : item.title,
                showAppModal: true,
                icon: item === null || item === void 0 ? void 0 : item.icon,
                description: item === null || item === void 0 ? void 0 : item.message,
                btnText: getLocalizedText("system.open.website"),
            },
        });
    };
    return item.openModal ? (_jsx(ListItemButton, { disabled: item.disabled, disableRipple: !sidebarOpened, sx: sidebarOpened ? sx.wrapper.open : sx.wrapper.close, selected: isSelected, onClick: function () { return handleShowConfirmModal(); }, children: renderButtonContent() })) : (_jsx(ListItemButton, { component: Link, to: item.url, disabled: item.disabled, disableRipple: !sidebarOpened, sx: sidebarOpened ? sx.wrapper.open : sx.wrapper.close, selected: isSelected, target: item.target || "_self", children: renderButtonContent() }));
};
// eslint-disable-next-line import/no-default-export
export default NavItem;

import { jsx as _jsx } from "react/jsx-runtime";
import TextField from "@mui/material/TextField";
import { formatPhoneWhileTyping, formatPhoneWhileTypingWithoutSpaces, } from "@rentiohq/shared-frontend/dist/utils/phone/phone.utils";
export var Phone = function (props) {
    var value = props.value, id = props.id, name = props.name, onChange = props.onChange;
    var handleChange = function (input) {
        var formattedInput = formatPhoneWhileTypingWithoutSpaces(input);
        onChange(formattedInput || "");
    };
    return (_jsx(TextField, { id: id, name: name, size: "small", defaultValue: "+324", value: formatPhoneWhileTyping(value), onChange: function (e) { return handleChange(e.target.value); } }));
};

import { Box } from "@rebass/grid";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import {
  EPaymentOrderRole,
  EPaymentOrderType,
  EPaymentRepetitionType,
  IPaymentOrder,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import {
  addMonths,
  getDay,
  isAfter,
  setDay,
} from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatCurrency } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import { getTitle } from "@rentiohq/shared-frontend/dist/utils/paymentOrder.utils";
import {
  DisplayText,
  ESpacings,
  Grid,
  Icon,
  ResourceListItem,
  TextStyle,
  Tooltip,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { NumberParam } from "serialize-query-params";
import { ts } from "../../../../../../../services";
import { getPaymentOrderTypeById } from "../../../helpers/paymentOrder";
import { getAmounts } from "../../PaymentsOverview";

interface IProps {
  paymentOrder: IPaymentOrder;
  showProperty?: boolean;
  onEdit?: (order: IPaymentOrder) => void;
  onRemove?: (id: number) => void;
}

export const PaymentOrderRow: React.FC<IProps> = ({
  paymentOrder,
  showProperty,
  onEdit,
  onRemove,
}) => {
  const { detail: contract } = contractHooks.useDetail({
    shouldRefetch: false,
    id: paymentOrder?.contractId,
  });

  const paymentOrderType = getPaymentOrderTypeById(paymentOrder.type!);
  const { mandate } = paymentOrder;
  const hasMandate = !!mandate;
  const amounts = getAmounts(paymentOrder);
  const paymentStartDate = paymentOrder.startedAt!;

  const [, setQueryParamValue] = useQueryParams({
    paymentOrderId: NumberParam,
  });

  const description = getTitle(paymentOrder);

  /**
   * Show first payment info when:
   * - the repetitionType is NOT once
   * - The first payment period hasn't ended yet
   * - The startedAt day is different then the repetitionDay
   */

  let firstPaymentEndDate = paymentOrder.startedAt;

  if (paymentOrder.isRepetitionDelayed) {
    firstPaymentEndDate = addMonths(firstPaymentEndDate, 2);
  } else {
    firstPaymentEndDate = addMonths(firstPaymentEndDate, 1);
  }

  // -1 to get get last day of pro-rata
  firstPaymentEndDate = setDay(
    firstPaymentEndDate,
    -1 + paymentOrder.repetitionDay,
  );

  const showFirstPaymentInfo = (() => {
    if (
      paymentOrder.repetitionType !== EPaymentRepetitionType.Once &&
      getDay(paymentOrder.startedAt) !== paymentOrder.repetitionDay &&
      isAfter(firstPaymentEndDate, new Date()) &&
      paymentOrder.proRataAmount > 0
    ) {
      return true;
    }
    return false;
  })();

  const handleDetailClick = () => {
    setQueryParamValue({ paymentOrderId: paymentOrder.id });
  };

  const actions: IAction[] = [];

  if (!paymentOrder.completedAt) {
    actions.push({
      content: getLocalizedText("payment_order.view_detail.action"),
      onClick: handleDetailClick,
    });

    if (
      paymentOrder.isEditable &&
      paymentOrder.roles.includes(EPaymentOrderRole.Write) &&
      !paymentOrder.deletedAt
    ) {
      if (onEdit) {
        actions.push({
          content: ts.paymentsOverviewPaymentOrderEdit(),
          onClick: () => onEdit(paymentOrder),
        });
      }

      if (onRemove) {
        actions.push({
          content: <TextStyle variation={"negative"}>{ts.remove()}</TextStyle>,
          onClick: () => onRemove(paymentOrder.id!),
        });
      }
    }
  }

  return (
    <ResourceListItem
      item={paymentOrder}
      media={
        <Icon source={paymentOrderType?.icon || "billStack"} size="large" />
      }
      actions={actions}
      onClick={handleDetailClick}
    >
      <Grid alignItems="center" justifyContent="space-between">
        <Grid.Item flex={1}>
          <Box mb={1}>
            <Grid.Item>
              <Grid spacing="tight" alignItems="center">
                <Grid.Item>
                  <DisplayText size="small">{description}</DisplayText>
                </Grid.Item>
                {hasMandate && (
                  <Grid.Item>
                    <Tooltip
                      tooltipContent={ts.paymentOrderMandateIconTooltip()}
                    >
                      <Icon source="mandateGiven" color="primary" />
                    </Tooltip>
                  </Grid.Item>
                )}
              </Grid>
            </Grid.Item>
          </Box>

          <TextStyle variation="subdued">
            {ts.paymentOverviewFrequencyMeta({
              paymentOrder: paymentOrder as IPaymentOrder,
            })}
          </TextStyle>

          <TextStyle variation="subdued">
            {ts.paymentOverviewPaymentRequestMeta({
              paymentOrder,
            })}
          </TextStyle>

          {contract?.rentDiscountEnabled &&
            paymentOrder.type === EPaymentOrderType.Rent && (
              <TextStyle variation="subdued" element="div">
                {getLocalizedText(
                  "payment.overview.payment_order.rent_discount_info",
                  {
                    amount: `${formatCurrency(
                      contract?.rentDiscountAmount || 0,
                    )}`,
                    offset: `${contract?.rentDiscountOffset || 0}`,
                  },
                  contract?.rentDiscountOffset || 0,
                )}
              </TextStyle>
            )}

          {showFirstPaymentInfo && amounts.firstAmountWithVat && (
            <TextStyle element="div" variation="info">
              <Box mt={2}>
                {ts.paymentOverviewFirstPay({
                  startDate: utils.date.format(paymentStartDate, "d MMMM yyyy"),
                  stopDate: utils.date.format(
                    firstPaymentEndDate,
                    "d MMMM yyyy",
                  ),
                  amount: `${formatCurrency(amounts.firstAmountWithVat)}`,
                })}
              </Box>
            </TextStyle>
          )}

          {showProperty && paymentOrder.propertyTypeId && (
            <Grid
              spacing="extraTight"
              alignItems="center"
              mt={ESpacings.extraTight}
            >
              <Grid.Item>
                <Icon
                  color="neutral60"
                  size="small"
                  source={utils.properties.getIcon(paymentOrder.propertyTypeId)}
                />
              </Grid.Item>
              <Grid.Item>
                <TextStyle variation="subdued">
                  {paymentOrder.propertyAddress?.full}
                </TextStyle>
              </Grid.Item>
            </Grid>
          )}
        </Grid.Item>

        {paymentOrder.amount && (
          <Grid.Item>
            <TextStyle
              variation="code"
              element="div"
              style={{ textAlign: "right" }}
            >
              {formatCurrency(amounts.amount)}
            </TextStyle>

            {amounts.vat > 0 && (
              <TextStyle
                variation="subdued"
                element="div"
                style={{ textAlign: "right" }}
              >
                <TextStyle variation="code" size="small">
                  {getLocalizedText("system.plus_vat", {
                    amount: formatCurrency(amounts.amountVat),
                  })}
                </TextStyle>
              </TextStyle>
            )}
          </Grid.Item>
        )}
      </Grid>
    </ResourceListItem>
  );
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { useSelector } from "react-redux";
import { loadingStateSelector } from "../../redux/selectors";
import { Link } from "../Link";
import { PageTitle } from "../PageTitle";
import { SimpleInfoBox } from "../SimpleInfoBox";
export var Page = function (_a) {
    var children = _a.children, title = _a.title, isLoading = _a.isLoading, _b = _a.isEmpty, isEmpty = _b === void 0 ? false : _b, _c = _a.emptyHeading, emptyHeading = _c === void 0 ? "" : _c, _d = _a.emptyMessage, emptyMessage = _d === void 0 ? "" : _d, _e = _a.breadcrumbLabel, breadcrumbLabel = _e === void 0 ? "" : _e, _f = _a.breadcrumbLink, breadcrumbLink = _f === void 0 ? "" : _f, actions = _a.actions, emptyActions = _a.emptyActions;
    var showLoader = useSelector(loadingStateSelector);
    return (_jsxs(Box, { children: [breadcrumbLabel && (_jsx(Link, { label: "\u2039 ".concat(getLocalizedText(breadcrumbLabel)), link: breadcrumbLink, underlined: false })), title && (_jsx(PageTitle, { title: getLocalizedText(title), actions: actions, isLoading: isLoading || showLoader })), _jsx(_Fragment, { children: isEmpty ? (_jsx(SimpleInfoBox, { headline: getLocalizedText(emptyHeading), details: getLocalizedText(emptyMessage), actions: emptyActions })) : (children) })] }));
};

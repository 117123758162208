import { IChatMember } from "@rentiohq/shared-frontend/dist/types/chat.types";
import { IFormikValues } from "@rentiohq/web-shared-next/dist/utils/types/form";
import { EField, IExtraData } from "./schema.createChat.types";

export const getInitialValues = ({
  chat,
  accountIds,
}: IExtraData): IFormikValues => {
  if (chat) {
    return {
      [EField.Members]: chat.members.map(
        (member: IChatMember) => member.account.id,
      ),
      [EField.Name]: chat.members.length > 2 ? chat.name : undefined,
      [EField.Property]: chat.propertyId,
    };
  }
  return {
    [EField.Members]: accountIds,
    [EField.Name]: undefined,
    [EField.Property]: undefined,
  };
};

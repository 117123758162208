import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getSubtitle, getTitle, } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { useNavigate } from "react-router-dom";
import { EAssets } from "../../../assets";
import { EButtonVariants } from "../../../utils/types/button";
import { Avatar } from "../../Avatar";
import { Icon } from "../../Icon";
import { Link } from "../../Link";
import { ChatHeaderStyles } from "./ChatHeader.styles";
export var ChatHeader = function (_a) {
    var chat = _a.chat, handleEditChat = _a.handleEditChat;
    var navigate = useNavigate();
    var sx = ChatHeaderStyles();
    var members = chat.members, archivedAt = chat.archivedAt, propertyId = chat.propertyId;
    var property = propertyHooks.useDetail({
        shouldRefetch: false,
        id: propertyId,
    }).detail;
    var title = chat && getTitle({ chat: chat });
    var subtitle = chat && getSubtitle(chat);
    var navigateToDetail = function () {
        if (!property) {
            return;
        }
        navigate("/properties/".concat(property.id));
    };
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: sx.desktopWrapper, children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", children: [_jsxs(Stack, { direction: "row", gap: 1, alignItems: "center", children: [_jsx(Avatar, { sx: sx.content.avatar, children: members.length > 2 ? (_jsx(Icon, { path: EAssets.MultiUsersIcon })) : (_jsx(Icon, { path: EAssets.UserIcon, width: "24px", height: "24px" })) }), _jsxs(Box, { children: [title && (_jsx(Typography, { variant: "h6", sx: sx.content.title, children: title })), subtitle && (_jsx(Typography, { variant: "h6", sx: sx.content.title, children: subtitle })), property && (_jsx(Link, { label: formatAddress(property), underlined: false, fontSize: 12, onClick: navigateToDetail }))] })] }), !archivedAt && (_jsx(Button, { variant: EButtonVariants.Outlined, onClick: handleEditChat, children: getLocalizedText("chats.chat.info") }))] }), _jsx(Divider, { sx: sx.content.divider })] }), _jsx(Box, { sx: sx.mobileWrapper, children: _jsxs(Stack, { direction: "row", alignItems: "center", justifyContent: "space-between", children: [_jsx(Box, { onClick: function () { return navigate("/chats"); }, sx: sx.content.button, children: _jsx(Icon, { path: EAssets.ArrowLeftIcon, width: "18px", height: "18px" }) }), title && (_jsx(Typography, { variant: "h5", sx: sx.content.title, children: title })), _jsx(Box, { sx: sx.content.button, children: _jsx(Icon, { path: EAssets.InfoIcon, width: "18px", height: "18px" }) })] }) })] }));
};

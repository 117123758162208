import { IValues } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { isFutureContract } from "@rentiohq/shared-frontend/dist/utils/contract.utils";
import {
  EField,
  ELocationDescriptionType,
  IExtraData,
} from "./schema.orderLocationDescription.types";

export const getInitialValues = (extraData: IExtraData): IValues => {
  const { currentContract } = extraData;

  return {
    [EField.LocationDescriptionType]: isFutureContract(currentContract)
      ? ELocationDescriptionType.Both
      : ELocationDescriptionType.Out,
    [EField.Contract]: currentContract.id,
  };
};

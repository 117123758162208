import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import ButtonBasic from "@rentiohq/shared-frontend/dist/components/components/ButtonBasic";
import ListItemSeparator from "@rentiohq/shared-frontend/dist/components/components/ListItemSeparator";
import Spacer, { ESpacerWeight, } from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { DisplayText, Icon } from "../../../../../../components";
import { DisplayTypeContext } from "../../contexts/DisplayType.context";
import { FieldsContext } from "../../contexts/Fields.context";
import { VariablesDataContext } from "../../contexts/VariablesData.context";
import { getGroupCompletionInfo, getNewVariablesDataForGroupRemoved, getVariablesForGroup, groupInstancesToRender, groupIsLooped, } from "../../Editor.utils";
import GroupModal from "../GroupModal";
import VariablesFormVariable from "../VariablesFormVariable";
import * as S from "./VariablesFormGroup.styles";
export var VariablesFormGroup = function (props) {
    var group = props.group, isOpen = props.isOpen, onToggleOpenGroup = props.onToggleOpenGroup, 
    // onPressPreviousGroup,
    onPressNextGroup = props.onPressNextGroup;
    // State
    var _a = React.useState(false), showGroupModal = _a[0], setShowGroupModal = _a[1];
    // Context
    var _b = React.useContext(DisplayTypeContext), isTemplate = _b.isTemplate, isTemplateDocument = _b.isTemplateDocument, isTemplateDocumentEditor = _b.isTemplateDocumentEditor, isTemplateDocumentExternal = _b.isTemplateDocumentExternal;
    var _c = React.useContext(VariablesDataContext), variablesData = _c.variablesData, setVariablesData = _c.setVariablesData;
    var fields = React.useContext(FieldsContext).fields;
    // helpers
    var completionInfo = React.useMemo(function () {
        return getGroupCompletionInfo({
            groupId: group.id,
            fields: fields,
            variablesData: variablesData,
        });
    }, [group, fields, variablesData]);
    // State
    // const [isOpen, setIsOpen] = React.useState(
    //   false,
    //   // completionInfo.completed !== completionInfo.total,
    // );
    // Helpers
    var isLooped = !isTemplate && groupIsLooped({ groupId: group.id, fields: fields });
    var instancesToRender = isTemplate
        ? 1
        : groupInstancesToRender({
            groupId: group.id,
            fields: fields,
            variablesData: variablesData,
            withTrailingDummyInstance: true,
        });
    // Event handlers
    var handlePressRemove = function (instanceIndex) { return function () {
        var newVariablesData = getNewVariablesDataForGroupRemoved({
            instanceIndexGroup: instanceIndex,
            groupId: group.id,
            variablesData: variablesData,
            fields: fields,
        });
        setVariablesData(newVariablesData);
    }; };
    // Render
    var renderInstance = function (instanceIndex) {
        var variables = getVariablesForGroup({
            groupId: group.id,
            fields: fields,
            variablesData: variablesData,
            skipCondition: isTemplate,
            instanceIndex: instanceIndex,
            isTemplateDocumentExternal: isTemplateDocumentExternal,
        });
        var showRemove = isTemplateDocument &&
            instancesToRender - 1 > 0 &&
            // Don't show remove on last index
            (instancesToRender === 0 ||
                (instancesToRender > 0 && instanceIndex !== instancesToRender - 1));
        return (_jsxs(S.InstanceWrap, { children: [(isTemplateDocument ||
                    isTemplateDocumentEditor ||
                    isTemplateDocumentExternal) &&
                    isLooped && (_jsxs(_Fragment, { children: [_jsx(Spacer, { weight: ESpacerWeight.W08 }), instanceIndex > 0 && _jsx(ListItemSeparator, {}), _jsx(Spacer, { weight: ESpacerWeight.W08 }), _jsxs(S.InfoRemoveWrap, { children: [_jsx(DisplayText, { space: "none", size: "extraSmall", subdued: true, children: instanceIndex + 1 }), showRemove && (_jsx(S.RemoveWrap, { children: _jsx("button", { onClick: handlePressRemove(instanceIndex), children: _jsx(Icon, { source: "bin", size: "small" }) }) }))] })] })), variables.map(function (variable) {
                    return (_jsx(VariablesFormVariable, { variable: variable, instanceIndexGroup: instanceIndex, groupInstancesToRender: instancesToRender }, variable.id));
                })] }));
    };
    return (_jsxs(S.Wrap, { className: "sidebar-group-".concat(group.id), children: [_jsx(S.TitleButton, { onClick: function () {
                    onToggleOpenGroup(group);
                }, children: _jsxs(S.TitleWrap, { children: [_jsxs(S.TitleIconWrap, { children: [_jsx(Icon, { source: isOpen ? "chevronDown" : "chevronRight", size: "mediumLarge", color: "gray" }), _jsx(DisplayText, { space: "none", size: "small", children: "".concat(getLocalizedText(group.name)).concat(isLooped ? " 🔁" : "") }), isTemplate && (_jsx(S.InfoButton, { onPress: function () {
                                        setShowGroupModal(true);
                                    }, children: _jsx(S.InfoIcon, {}) }))] }), (isTemplateDocument ||
                            isTemplateDocumentEditor ||
                            isTemplateDocumentExternal) && (_jsx(S.CompletedBadge, { isEmpty: completionInfo.completed === 0, isDone: completionInfo.completed === completionInfo.total, children: "".concat(completionInfo.completed, "/").concat(completionInfo.total) }))] }) }), isOpen && (_jsxs(_Fragment, { children: [Array(instancesToRender)
                        .fill(0)
                        .map(function (_, instanceIndex) {
                        return (_jsx(React.Fragment, { children: renderInstance(instanceIndex) }, instanceIndex));
                    }), (isTemplateDocument || isTemplateDocumentExternal) && (_jsx(S.NextPreviousWrap, { children: onPressNextGroup && (_jsx(ButtonBasic, { title: getLocalizedText("system.next"), onPress: function () {
                                onPressNextGroup(group);
                            } })) }))] })), showGroupModal && (_jsx(GroupModal, { groupId: group.id, onClose: function () {
                    setShowGroupModal(false);
                } }))] }));
};
// eslint-disable-next-line import/no-default-export
export default VariablesFormGroup;

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as registrationDocumentHooks from "@rentiohq/shared-frontend/dist/redux/registrationDocument/registrationDocument.hooks";
import { EContractMemberTypes } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { ERegistrationContractStatus, } from "@rentiohq/shared-frontend/dist/types/registration.types";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import React from "react";
import { NumberParam } from "serialize-query-params";
import { renderCellContractRoles, renderCellProperty, renderCellStatus, } from "../../utils/registration";
import { Card } from "../Card";
import { DataTable } from "../DataTable";
import { EmptyState } from "../EmptyState";
import { Error } from "../Error";
import { Loading } from "../Loading";
import { Pagination } from "../Pagination";
var ORDER_MAP = [
    "createdAt",
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
];
export var RegistrationDocuments = function (props) {
    var baseFilter = props.filter, renderFilters = props.renderFilters, onClick = props.onClick;
    var _a = useQueryParams({
        registrationId: NumberParam,
    }), setQueryParamValue = _a[1];
    // State
    var _b = React.useState(0), page = _b[0], setPage = _b[1];
    var _c = React.useState({
        field: "createdAt",
        direction: "DESC",
    }), sort = _c[0], setSort = _c[1];
    var filter = __assign(__assign({}, baseFilter), { order: "".concat(sort.field, " ").concat(sort.direction) });
    // Redux
    var _d = registrationDocumentHooks.usePaged({
        shouldRefetch: true,
        page: page,
        filter: filter,
        withCount: true,
    }), data = _d.data, isFetching = _d.isFetching, fetchError = _d.fetchError, pageCount = _d.pageCount;
    //Event handlers
    var handlePageClick = function (_a) {
        var selected = _a.selected;
        setPage(selected);
    };
    var handleClickRow = function (index) {
        var registrationDocument = data === null || data === void 0 ? void 0 : data[index];
        if (!registrationDocument) {
            return;
        }
        onClick === null || onClick === void 0 ? void 0 : onClick(registrationDocument);
    };
    var handleSort = function (headingIndex, direction) {
        var field = ORDER_MAP[headingIndex];
        if (!field) {
            return;
        }
        setSort({ field: field, direction: direction });
    };
    // Renders
    var renderTable = function () {
        if (data && data.length > 0) {
            return (_jsx(DataTable, { onRowClick: handleClickRow, columnContentTypes: Array(7).fill("text"), headings: [
                    getLocalizedText("system.created"),
                    getLocalizedText("system.contract_start_date"),
                    getLocalizedText("system.address"),
                    getLocalizedText("system.tenants"),
                    getLocalizedText("system.owners"),
                    getLocalizedText("system.status"),
                    "",
                ], sortable: ORDER_MAP.map(function (x) { return !!x; }), sortColumnIndex: ORDER_MAP.indexOf(sort.field), sortDirection: sort.direction, onSort: handleSort, rows: data.map(function (registrationDocument) {
                    var _a, _b;
                    var rentRegistration = registrationDocument.rentRegistration;
                    if (!rentRegistration) {
                        return {
                            id: registrationDocument.id,
                            content: ["-", "-", "-", "-", "-", "-"],
                            actions: [],
                        };
                    }
                    return {
                        id: registrationDocument.id,
                        content: [
                            formatDate(rentRegistration === null || rentRegistration === void 0 ? void 0 : rentRegistration.createdAt),
                            ((_a = rentRegistration.contract) === null || _a === void 0 ? void 0 : _a.startDate)
                                ? formatDate((_b = rentRegistration.contract) === null || _b === void 0 ? void 0 : _b.startDate)
                                : "-",
                            renderCellProperty(rentRegistration.propertyId, rentRegistration.property),
                            renderCellContractRoles(rentRegistration, [
                                EContractMemberTypes.Tenant,
                                EContractMemberTypes.Parent,
                            ]),
                            renderCellContractRoles(rentRegistration, [
                                EContractMemberTypes.Owner,
                            ]),
                            renderCellStatus(registrationDocument.status ||
                                ERegistrationContractStatus.None),
                        ],
                        actions: [
                            {
                                id: "view",
                                content: getLocalizedText("system.view_detail"),
                                onClick: function () {
                                    setQueryParamValue({ registrationId: rentRegistration.id });
                                },
                            },
                        ],
                    };
                }) }));
        }
        if (fetchError) {
            return _jsx(Error, { errors: [fetchError], backLink: "/" });
        }
        if (isFetching) {
            return _jsx(Loading, {});
        }
        return (_jsx(EmptyState, { heading: getLocalizedText("registration_documents.empty") }));
    };
    var renderPaging = function () {
        if (pageCount <= 1) {
            return null;
        }
        return (_jsx(Pagination, { initialPage: page, pageCount: pageCount, onPageChange: handlePageClick }));
    };
    return (_jsxs(Card, { children: [renderFilters(), renderTable(), renderPaging()] }));
};

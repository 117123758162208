var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CONFIG } from "../../config/app.config";
import api from "../../utils/api/api.utils";
import { appendFilter } from "../../utils/api.utils";
export var createDocumentPackage = function (documentPackage) {
    return api.post("/document-packages", {
        isServerless: true,
        data: documentPackage,
    });
};
export var revokeDocumentPackage = function (_a) {
    var documentPackageId = _a.documentPackageId;
    return api.post("/document-packages/".concat(documentPackageId, "/revoke"), { isServerless: true });
};
export var getDocumentPackage = function (documentPackageId) {
    return api.get("/document-packages/".concat(documentPackageId));
};
export var getDocumentPackages = function (skip, limit, order, filterData) {
    if (limit === void 0) { limit = CONFIG.DEFAULT_FETCH_LIMIT; }
    if (order === void 0) { order = "createdAt DESC"; }
    if (filterData === void 0) { filterData = {}; }
    var filter = __assign({ order: order, limit: limit, skip: skip }, filterData);
    return api.get(appendFilter("/document-packages", filter));
};
export var remindDocumentPackageSigner = function (documentPackageId, signerAccountId) {
    return api.post("/document-packages/".concat(documentPackageId, "/reminder/").concat(signerAccountId), { isServerless: true });
};

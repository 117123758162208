import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as paymentActions from "@rentiohq/shared-frontend/dist/redux/payment/payment.actions";
import * as paymentSelectors from "@rentiohq/shared-frontend/dist/redux/payment/payment.selectors";
import * as paymentActionsV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentSelectorsV2 from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { Error, Loading } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { PaymentRequest } from "./PaymentRequest";
import { IContainerProps } from "./PaymentRequest.types";

const PaymentRequestContainer: React.FC<IContainerProps> = ({
  paymentRequestId,
  ...rest
}) => {
  const { internalModeEnabled } = useInternalMode();
  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  const dispatch = useDispatch();
  const paymentRequest = useSelector((state: IRootStore) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentSelectorsV2.getDetail(state, paymentRequestId)
      : paymentSelectors.getPaymentRequest(state, paymentRequestId),
  );
  const isFetching = useSelector((state: IRootStore) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentSelectorsV2.isFetchingDetail(state, paymentRequestId)
      : paymentSelectors.getIsFetchingPaymentRequest(state, paymentRequestId),
  );
  const error = useSelector((state: IRootStore) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentSelectorsV2.fetchDetailError(state, paymentRequestId)
      : paymentSelectors.getFetchErrorPaymentRequest(state, paymentRequestId),
  );

  const isDeletingPaymentRequest = useSelector((state: IRootStore) =>
    internalModeEnabled && hasPaymentV2ForBroker
      ? paymentSelectorsV2.isRemoving(state, paymentRequestId)
      : state.payment.deletePaymentRequests[paymentRequestId]?.isDeleting ||
        false,
  );
  const prevIsDeletingPaymentRequest = usePrevious(isDeletingPaymentRequest);

  React.useEffect(() => {
    if (!!prevIsDeletingPaymentRequest && !isDeletingPaymentRequest) {
      rest.onDrawerClose();
    }
  }, [isDeletingPaymentRequest, prevIsDeletingPaymentRequest]);

  React.useEffect(() => {
    if (internalModeEnabled && hasPaymentV2ForBroker) {
      dispatch(
        paymentActionsV2.getDetailStart.getAction({ id: paymentRequestId }),
      );
      return;
    }

    dispatch(
      paymentActions.getPaymentRequest.actions.start({ id: paymentRequestId }),
    );
  }, []);

  if (error) {
    return <Error errors={[error]} />;
  }

  if (isFetching || !paymentRequest) {
    return <Loading />;
  }

  return (
    <PaymentRequest
      paymentRequest={paymentRequest}
      paymentRequestId={paymentRequestId}
      {...rest}
    />
  );
};

// eslint-disable-next-line import/no-default-export
export default PaymentRequestContainer;

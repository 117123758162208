import { jsx as _jsx } from "react/jsx-runtime";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { DatePickerStyles } from "./DatePicker.styles";
export var DatePicker = function (props) {
    var sx = DatePickerStyles();
    var value = props.value, onChange = props.onChange;
    return (_jsx(LocalizationProvider, { dateAdapter: AdapterDayjs, children: _jsx(MuiDatePicker, { value: dayjs(value), onChange: function (date) { return date && onChange(date.format()); }, sx: sx.wrapper }) }));
};

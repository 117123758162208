var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CONFIG } from "../../config/app.config";
import api from "../../utils/api/api.utils";
import { appendFilter } from "../../utils/api.utils";
var FIELDS_LIST = { data: false, documentIds: false };
var FIELDS_DETAIL = { data: true, fields: true, documentIds: true };
export var createTemplate = function (params) {
    return api.post("/templates", { data: params.data });
};
export var updateTemplate = function (params) {
    return api.put(appendFilter("/templates/".concat(params.id), {
        fields: FIELDS_LIST,
    }), {
        data: params.data,
    });
};
export var deleteTemplate = function (params) {
    return api.delete("/templates/".concat(params.id));
};
export var getTemplate = function (params) {
    return api.get(appendFilter("/templates/".concat(params.id), {
        fields: FIELDS_LIST,
    }));
};
export var getTemplateDetail = function (params) {
    return api.get(appendFilter("/templates/".concat(params.id), {
        fields: FIELDS_DETAIL,
    }));
};
export var getTemplates = function (params) {
    var skip = params.skip, _a = params.limit, limit = _a === void 0 ? CONFIG.DEFAULT_FETCH_LIMIT : _a, _b = params.order, order = _b === void 0 ? ["createdAt DESC"] : _b, filter = params.filter;
    var filterFinal = __assign({ skip: skip, limit: limit, order: order, fields: FIELDS_LIST }, filter);
    return api.get(appendFilter("/templates", filterFinal));
};
export var duplicateTemplate = function (params) {
    return api.post("/templates/".concat(params.id, "/duplicate"));
};

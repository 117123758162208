import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EField, IExtraData } from "./schema.shareDocumentMessage.types";

export const getSchema =
  (
    initialValues: {
      [key: string]: any;
    },
    extraData: IExtraData,
  ) =>
  (defaultValues: { [key: string]: any }) => ({
    required: [EField.CustomMessageBody],
    properties: {
      [EField.CustomMessageBody]: {
        default: getValue(
          EField.CustomMessageBody,
          initialValues,
          defaultValues,
        ),
      },
    },
  });

export const uiSchema = (_: IExtraData) => ({
  "rentio:navigationTitle": getLocalizedText(
    "share.document.custom_message.title",
  ),
  [EField.CustomMessageBody]: {
    "ui:field": ERentioFormField.Text,
    title: getLocalizedText("share.document.custom_message.label"),
    autoFocus: true,
    multiline: true,
  },
});

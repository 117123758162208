import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "../../../assets";
import { EButtonVariants } from "../../../utils/types/button";
import { MainCard } from "../../cards/MainCard";
import { Icon } from "../../Icon";
import { Link } from "../../Link";
import { SpinningLoader } from "../../SpinningLoader";
import { ContactList } from "../ContactList";
import { ChatDrawerStyles } from "./ChatDrawer.styles";
export var ChatDrawer = function (_a) {
    var chatId = _a.chatId, chats = _a.chats, showArchivedChats = _a.showArchivedChats, isLoading = _a.isLoading, queryInput = _a.queryInput, setQueryInput = _a.setQueryInput, openChatDrawer = _a.openChatDrawer, handleLoadMore = _a.handleLoadMore, handleDrawerOpen = _a.handleDrawerOpen, toggleShowArchivedChats = _a.toggleShowArchivedChats, handleCreateChat = _a.handleCreateChat;
    var sx = ChatDrawerStyles();
    var handleOnChange = function (newQuery) {
        setQueryInput(newQuery);
    };
    var handleClear = function () {
        setQueryInput("");
    };
    return (_jsx(Box, { sx: sx.wrapper, children: _jsx(Drawer, { sx: sx.content.drawer, variant: "persistent", anchor: "left", open: openChatDrawer, ModalProps: { keepMounted: true }, onClose: handleDrawerOpen, children: openChatDrawer && (_jsxs(MainCard, { border: true, content: false, sx: sx.content.card, children: [_jsxs(Box, { sx: sx.content.headerContainer, children: [_jsxs(Stack, { direction: "row", justifyContent: "space-between", alignItems: "center", children: [_jsx(Typography, { variant: "h5", sx: sx.content.title, children: showArchivedChats
                                            ? getLocalizedText("chats.archived.title")
                                            : getLocalizedText("chats.title") }), !showArchivedChats && (_jsx(Button, { variant: EButtonVariants.Contained, sx: sx.content.addChatBtn, disableElevation: true, onClick: handleCreateChat, children: "+" }))] }), _jsx(Divider, { sx: sx.content.divider }), !showArchivedChats && (_jsx(OutlinedInput, { fullWidth: true, id: "input-search-header", placeholder: "Search", value: queryInput, onChange: function (e) { return handleOnChange(e.target.value); }, sx: sx.content.searchInput, startAdornment: _jsx(InputAdornment, { position: "start", children: _jsx(Box, { children: _jsx(Icon, { path: EAssets.SearchIcon, width: "20px", height: "unset" }) }) }), endAdornment: queryInput ? (_jsx(InputAdornment, { position: "end", children: _jsx(Box, { onClick: handleClear, sx: sx.content.clearButton, children: _jsx(Icon, { path: EAssets.CloseIcon, height: "unset", width: "16px", realColor: "#4B5565" }) }) })) : null }))] }), _jsxs(Box, { children: [isLoading && (_jsx(Box, { pt: 2, children: _jsx(SpinningLoader, {}) })), !isLoading && _jsx(ContactList, { chats: chats, chatId: chatId })] }), !isLoading && (_jsxs(Stack, { direction: "row", justifyContent: "space-between", mb: { lg: 3, xl: 0 }, children: [_jsx(Link, { label: showArchivedChats
                                    ? getLocalizedText("tenant.chats.action.show_active")
                                    : getLocalizedText("tenant.chats.action.show_archived"), onClick: toggleShowArchivedChats }), handleLoadMore && (_jsx(Link, { label: getLocalizedText("chats.list.cta.show_more"), onClick: handleLoadMore }))] }))] })) }) }));
};

import { Box } from "@rebass/grid";
import { PropertyFetchListItem } from "@rentiohq/shared-frontend/dist/components/components/PropertyFetchListItem";
import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as templateDocumentActions from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.actions";
import * as documentActions from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.actions";
import * as documentHooks from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.hooks";
import { getDocumentCategoryName } from "@rentiohq/shared-frontend/dist/reduxV2/documents/document.utils";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { taskModule } from "@rentiohq/shared-frontend/dist/reduxV2/task";
import { IDocument } from "@rentiohq/shared-frontend/dist/types/document.types";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { confirm } from "@rentiohq/shared-frontend/dist/utils/confirm.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatBytes } from "@rentiohq/shared-frontend/dist/utils/number.utils";
import {
  Button,
  check,
  Grid,
  Icon,
  Loading,
  Lozenge,
  MultiStepForm,
  Page,
  Stack,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import createDocumentForm from "../../../../forms/createDocument";
import { ts } from "../../../../services";
import DocumentInfo from "./components/DocumentInfo";
import { IDocumentProps } from "./Document.types";

const formId = generateFormId();

const Document: React.FC<IDocumentProps> = props => {
  const { documentId, onDrawerClose } = props;

  // State
  const [showEdit, setShowEdit] = React.useState(false);

  // Redux
  const dispatch = useDispatch();

  const { detail: document, isFetching } = documentHooks.useDetail({
    shouldRefetch: false,
    id: documentId,
  });

  const { detail: linkedProperty } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: document?.propertyId,
  });

  const linkedTask = useSelector((state: IRootStore) =>
    document?.taskId
      ? taskModule.selectors.getDetail(state, document.taskId)
      : undefined,
  );

  // Helpers
  const documentTypeData = document
    ? utils.document.getTypeData(document.mimeType)
    : undefined;

  const canEditDocument = document
    ? check(document.roles, "document:update")
    : false;
  const canDeleteDocument = document
    ? check(document.roles, "document:delete")
    : false;

  // Lifecycle
  React.useEffect(() => {
    if (isFetching) {
      return;
    }

    dispatch(
      documentActions.getDetailStart.getAction({
        id: documentId,
      }),
    );
  }, [documentId]);

  // Event handlers
  const handleEdit = (values: IDocument) => {
    if (!document) {
      return;
    }

    dispatch(
      documentActions.updateStart.getAction({ id: document.id, data: values }),
    );

    setShowEdit(false);
  };

  // Render
  if (!document || !documentTypeData) {
    if (isFetching) {
      return <Loading />;
    }

    return null;
  }

  const mainActions: IAction[] = [];
  if (document.url) {
    mainActions.push({
      content: getLocalizedText("document.view"),
      onClick: () => utils.document.viewDocument(document),
    });
  }
  if (document.downloadUrl) {
    mainActions.push({
      content: getLocalizedText("document.download"),
      onClick: () => (window.location.href = document.downloadUrl),
    });
  }
  if (canEditDocument) {
    mainActions.push({
      content: getLocalizedText("document.edit"),
      onClick: () => setShowEdit(true),
    });
  }
  if (canDeleteDocument) {
    mainActions.push({
      content: (
        <TextStyle variation={"negative"}>
          {getLocalizedText("document.delete")}
        </TextStyle>
      ),
      onClick: () => {
        confirm({
          title: ts.removeConfirm("document"),
          primaryActions: [
            {
              title: ts.system("remove"),
              onPress: () => {
                if (document.templateDocumentId) {
                  dispatch(
                    templateDocumentActions.deleteObject.actions.start({
                      id: document.templateDocumentId,
                      suppressSuccessMessage: true,
                    }),
                  );
                }

                dispatch(
                  documentActions.removeStart.getAction({ id: document.id }),
                );

                onDrawerClose();
              },
            },
          ],
        });
      },
    });
  }

  const renderInfo = () => (
    <DocumentInfo
      document={document}
      linkedProperty={linkedProperty}
      linkedTask={linkedTask}
      mainActions={mainActions}
      {...props}
    />
  );

  const renderSubtitle = () => {
    const items: React.ReactNode[] = [];

    items.push(
      <Lozenge isBold={true}>
        {getDocumentCategoryName(document.categoryId)}
      </Lozenge>,
    );

    if (document.templateDocumentId) {
      items.push(
        <Lozenge isBold={true} appearance="info">
          {getLocalizedText("document.tag.template_document")}
        </Lozenge>,
      );
    }

    if (items.length > 0) {
      return <Stack spacing="tight">{items}</Stack>;
    }
  };

  const renderMeta = () => (
    <div>
      {document.propertyId && (
        <PropertyFetchListItem
          shouldRefetch={false}
          propertyId={document.propertyId}
          property={linkedProperty}
          renderAlways={() => {
            if (!linkedProperty) {
              return null;
            }

            return (
              <>
                <Spacer weight={ESpacerWeight.W08} />
                <Grid spacing="extraTight" alignItems="center">
                  <Grid.Item>
                    <Icon
                      source={utils.properties.getIcon(
                        linkedProperty.typeId || 0,
                      )}
                      size="small"
                    />
                  </Grid.Item>
                  <Grid.Item>
                    <Button
                      appearance="link"
                      url={`/properties/${linkedProperty.id}`}
                    >
                      {formatAddress(linkedProperty)}
                    </Button>
                  </Grid.Item>
                </Grid>
              </>
            );
          }}
        />
      )}

      <Spacer weight={ESpacerWeight.W08} />
      <Box>
        <Grid spacing="extraTight" alignItems="center">
          <Grid.Item>
            <Icon source="calendar" size="small" />
          </Grid.Item>
          <Grid.Item>
            {`${getLocalizedText("system.date.upload")}: ${formatDate(
              document.createdAt,
            )}`}
          </Grid.Item>
        </Grid>
      </Box>

      {!!document.filesize && document.filesize > 0 && (
        <>
          <Spacer weight={ESpacerWeight.W08} />
          <Box>
            <Grid spacing="extraTight" alignItems="center">
              <Grid.Item>
                <Icon source={documentTypeData.icon} size="small" />
              </Grid.Item>
              <Grid.Item>{formatBytes(document.filesize)}</Grid.Item>
            </Grid>
          </Box>
        </>
      )}

      <Spacer weight={ESpacerWeight.W08} />
      <Box>
        <Grid spacing="extraTight" alignItems="center">
          <Grid.Item>
            <Icon source={documentTypeData.icon} size="small" />
          </Grid.Item>
          <Grid.Item>{documentTypeData.name}</Grid.Item>
        </Grid>
      </Box>
    </div>
  );

  return (
    <>
      <Page
        title={document.filename}
        subtitle={renderSubtitle()}
        metadata={renderMeta()}
        setDocumentTitle={false}
        // dropdown={mainActions}
      >
        {renderInfo()}
      </Page>
      {showEdit && (
        <MultiStepForm
          formId={`edit-document-${formId}-${document.id}`}
          schemas={createDocumentForm({
            document,
          })}
          asModal={true}
          withAside={false}
          onSuccess={handleEdit}
          modalProps={{ onClose: () => setShowEdit(false) }}
          submitLabel={ts.system("submit")}
        />
      )}
    </>
  );
};

// eslint-disable-next-line import/no-default-export
export default Document;

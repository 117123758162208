import { EIdCardOptions } from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.1.broker";
import {
  CONTACT_AGE_RANGE_MAX,
  CONTACT_AGE_RANGE_MIN,
} from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.constants";
import {
  EField,
  IExtraData,
} from "@rentiohq/shared-frontend/dist/forms/addContact/schema.addContact.types";
import {
  CONTACT_INITIAL_TYPE_READABLE,
  EContactInitialType,
} from "@rentiohq/shared-frontend/dist/types/contact.types";
import { TDate } from "@rentiohq/shared-frontend/dist/utils/date-fns.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  getMappedLanguages,
  getMappedNationalities,
} from "@rentiohq/shared-frontend/dist/utils/location/location.utils";
import { getAddressComponentFields } from "@rentiohq/web-shared-next/dist/utils/address";
import { EAddressField } from "@rentiohq/web-shared-next/dist/utils/types/address";
import {
  ERentioFormField,
  ERentioFormFormat,
  IFormikProps,
  IFormikValues,
  IUiSchema,
} from "@rentiohq/web-shared-next/dist/utils/types/form";

export const uiSchema = (extraData: IExtraData): IUiSchema => {
  return {
    fields: [
      {
        fieldKey: EField.InitialType,
        uiComponent: ERentioFormField.Select,
        title: getLocalizedText("contact.initial_type.label"),
        info: getLocalizedText("contact.initial_type.info"),
        required: true,
        componentProps: {
          placeholder: getLocalizedText("contact.initial_type.placeholder"),
          options: Object.values(EContactInitialType).map(initialType => ({
            id: initialType,
            value: getLocalizedText(
              `contact.initial_type.${CONTACT_INITIAL_TYPE_READABLE[initialType]}`.toLowerCase(),
            ),
          })),
        },
      },
      {
        fieldKey: EField.Language,
        uiComponent: ERentioFormField.Select,
        title: getLocalizedText("system.language"),
        required: true,
        componentProps: {
          placeholder: getLocalizedText("system.language"),
          options: getMappedLanguages(),
        },
      },
      {
        fieldKey: EField.Firstname,
        uiComponent: ERentioFormField.TextField,
        required: true,
        title: getLocalizedText("system.firstname"),
        componentProps: {
          placeholder: getLocalizedText("system.firstname"),
        },
      },
      {
        fieldKey: EField.Lastname,
        uiComponent: ERentioFormField.TextField,
        required: true,
        title: getLocalizedText("system.lastname"),
        componentProps: {
          placeholder: getLocalizedText("system.lastname"),
        },
      },
      {
        fieldKey: EField.Email,
        uiComponent: ERentioFormField.Email,
        required: true,
        title: getLocalizedText("system.email"),
        componentProps: {
          placeholder: getLocalizedText("system.email"),
        },
      },
      {
        fieldKey: EField.Phone,
        uiComponent: ERentioFormField.Phone,
        required: true,
        title: getLocalizedText("system.phone"),
        componentProps: {
          placeholder: getLocalizedText("system.phone"),
        },
      },
      {
        fieldKey: EField.Address,
        uiComponent: ERentioFormField.AddressAutoComplete,
        title: getLocalizedText("system.address"),
        optional: true,
        onChange: (params, formikProps: IFormikProps<IFormikValues>) => {
          const { address, lookupAddress } = params as any;

          if (lookupAddress) {
            formikProps.setFieldValue(EField.Address, lookupAddress);
          }

          if (address) {
            formikProps.setFieldValue(EAddressField.Country, address?.country);
            formikProps.setFieldValue("countryId", address?.country);
            formikProps.setFieldValue(EAddressField.Street, address?.street);
            formikProps.setFieldValue(EAddressField.Number, address?.number);
            formikProps.setFieldValue(EAddressField.Box, address?.box);
            formikProps.setFieldValue(EAddressField.Zip, address?.zip);
            formikProps.setFieldValue(EAddressField.City, address?.city);
            formikProps.setFieldValue(EAddressField.State, address?.state);
          }
        },
        componentProps: {
          placeholder: getLocalizedText("system.address"),
        },
      },
      ...getAddressComponentFields({
        addressFieldKey: EField.Address,
        overridedFields: {
          country: "countryId",
        },
      }),
      {
        fieldKey: EField.SendInvite,
        uiComponent: ERentioFormField.SegmentedControl,
        title: getLocalizedText("contact.send_invite.label"),
        info: getLocalizedText("contact.send_invite.info"),
        showIf: (formData: IFormikValues) =>
          !extraData?.contact && Boolean(extraData.showSendInvite),
        componentProps: {
          options: ["yes", "no"].map(id => ({
            id,
            value: getLocalizedText(`system.${id.toLowerCase()}`),
          })),
        },
      },
      {
        fieldKey: EField.DateOfBirth,
        uiComponent: ERentioFormField.Date,
        title: getLocalizedText("system.date_of_birth"),
        formatSummary: value => (value ? formatDate(value as TDate) : ""),
        optional: true,
        componentProps: {
          minDate: CONTACT_AGE_RANGE_MIN,
          maxDate: CONTACT_AGE_RANGE_MAX,
        },
      },
      {
        fieldKey: EField.PlaceOfBirth,
        uiComponent: ERentioFormField.AddressAutoComplete,
        title: getLocalizedText("system.place_of_birth"),
        optional: true,
        onChange: (params, formikProps: IFormikProps<IFormikValues>) => {
          const { address } = params as any;
          if (address) {
            formikProps.setFieldValue(EField.PlaceOfBirth, address?.city);
            formikProps.setFieldValue("countryOfBirth", address?.country);
          }
        },
        componentProps: {
          lookupTypes: ["(cities)"],
        },
      },
      ...getAddressComponentFields({
        addressFieldKey: EField.PlaceOfBirth,
        lookupTypes: ["(cities)"],
        overridedSpans: {
          city: [6],
          country: [6],
        },
        overridedFields: {
          city: EField.PlaceOfBirth,
          country: "countryOfBirth",
        },
      }),
      {
        fieldKey: EField.Nationality,
        uiComponent: ERentioFormField.Select,
        title: getLocalizedText("system.nationality"),
        optional: true,
        componentProps: {
          options: getMappedNationalities(),
        },
      },
      {
        fieldKey: EField.IdType,
        uiComponent: ERentioFormField.SegmentedControl,
        title: getLocalizedText("system.identification"),
        showIf: formData => formData[EField.Nationality] !== "BE",
        componentProps: {
          options: [
            EIdCardOptions.BelgianIdCardNumber,
            EIdCardOptions.PassportNumber,
          ].map(id => ({
            id,
            value: getLocalizedText(`system.national_register_number.${id}`),
          })),
        },
      },
      {
        fieldKey: EField.NationalRegisterNumber,
        uiComponent: ERentioFormField.MaskedInput,
        title: getLocalizedText("system.national_register_number"),
        optional: true,
        showIf: formData =>
          formData[EField.Nationality] === "BE" ||
          (formData[EField.Nationality] !== "BE" &&
            formData[EField.IdType] === EIdCardOptions.BelgianIdCardNumber),
        format: ERentioFormFormat.NationalRegisterNumber,
        componentProps: {
          iMaskProps: {
            mask: "00.00.00-000.00",
            lazy: false,
            unmask: true,
          },
        },
      },
      {
        fieldKey: EField.ForeignIdentityNumber,
        uiComponent: ERentioFormField.TextField,
        title: getLocalizedText("system.national_register_number.foreigner"),
        optional: true,
        showIf: formData =>
          formData[EField.Nationality] !== "BE" &&
          formData[EField.IdType] === EIdCardOptions.PassportNumber,
      },
    ],
  };
};

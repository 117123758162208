import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import OutlinedInput from "@mui/material/OutlinedInput";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "../../..//assets";
import { Icon } from "../../Icon";
import { SpinningLoader } from "../../SpinningLoader";
import { ChatMessageInputStyles } from "./ChatMessageInput.styles";
export var ChatMessageInput = function (_a) {
    var chat = _a.chat, isSending = _a.isSending, message = _a.message, attachmentPreviewText = _a.attachmentPreviewText, attachmentPreviewData = _a.attachmentPreviewData, onPressSelectAttachment = _a.onPressSelectAttachment, onPressRemoveAttachment = _a.onPressRemoveAttachment, onPressSend = _a.onPressSend, setMessage = _a.setMessage;
    var sx = ChatMessageInputStyles();
    var roles = chat.roles;
    var handleEnter = function (event) {
        if ((event === null || event === void 0 ? void 0 : event.key) !== "Enter") {
            return;
        }
        onPressSend();
    };
    var handlePressSend = function () {
        onPressSend();
    };
    var isDeletedFromChat = roles.length === 0;
    if (isDeletedFromChat) {
        return (_jsx(Typography, { children: getLocalizedText("chat.member.removed_self") }));
    }
    var renderAttachmentIcon = function () {
        if (attachmentPreviewData) {
            return (_jsxs(Box, { onClick: onPressRemoveAttachment, sx: sx.content.attachmentWrapper, children: [_jsx("img", { alt: "chatImage", src: attachmentPreviewData === null || attachmentPreviewData === void 0 ? void 0 : attachmentPreviewData.uri, style: sx.content.attachmentImage }), _jsx(Box, { sx: sx.content.removeAttachment, children: _jsx(Icon, { path: EAssets.CloseIcon }) })] }));
        }
        if (attachmentPreviewText) {
            return (_jsxs(Box, { onClick: onPressRemoveAttachment, sx: sx.content.attachmentWrapper, children: [_jsx(Typography, { sx: sx.content.attachmentText, children: attachmentPreviewText }), _jsx(Box, { sx: sx.content.removeAttachment, children: _jsx(Icon, { path: EAssets.CloseIcon }) })] }));
        }
        return (_jsx(Box, { sx: sx.content.greyIcon, onClick: onPressSelectAttachment, children: _jsx(Icon, { path: EAssets.AttachmentIcon, width: "20px", height: "20px" }) }));
    };
    var hasAttachment = !!attachmentPreviewText || !!attachmentPreviewData;
    return (_jsx(Box, { sx: sx.wrapper, children: _jsx(OutlinedInput, { id: "message-send", fullWidth: true, value: message, onChange: function (e) { return setMessage(e.target.value); }, onKeyPress: handleEnter, type: "textarea", placeholder: "Type a Message", sx: sx.content.outlinedInput, endAdornment: _jsx(InputAdornment, { position: "end", sx: sx.content.inputAdornment, children: _jsxs(Stack, { direction: "row", gap: 1, alignItems: "center", children: [renderAttachmentIcon(), _jsx(Divider, { orientation: "vertical" }), isSending ? (_jsx(SpinningLoader, {})) : (_jsx(IconButton, { sx: sx.content.greenIcon, disabled: message.length === 0 && !hasAttachment, onClick: handlePressSend, children: _jsx(Icon, { path: EAssets.SendChatIcon, width: "24px", height: "24px" }) }))] }) }), "aria-describedby": "search-helper-text", inputProps: { "aria-label": "weight" } }) }));
};

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../themes/types";
export var ChatStyles = function (open) {
    var _a;
    var theme = useTheme();
    return {
        wrapper: {
            display: "flex",
            columnGap: "8px",
        },
        content: {
            subTitle: {
                color: theme.palette.success.main,
                fontWeight: EFontWeights.Regular,
            },
            chatContainer: __assign((_a = { flexGrow: 1, p: 3, transition: theme.transitions.create("margin", {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.shorter,
                    }) }, _a[theme.breakpoints.down("lg")] = {
                paddingLeft: 0,
                marginLeft: 0,
            }, _a), (open && {
                transition: theme.transitions.create("margin", {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.shorter,
                }),
                marginLeft: 0,
            })),
            card: {
                bgcolor: theme.palette.background.paper,
                width: "100%",
                minHeight: "100%",
                ".MuiCardContent-root": {
                    p: { xs: 0, md: 2 },
                },
            },
        },
    };
};

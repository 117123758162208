import { jsx as _jsx } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { makeGetUnreadChatsCount } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { useSelector } from "react-redux";
import { sidebarStateSelector } from "../../redux/selectors";
import menuItems, { EMenuItems } from "../../utils/menu-items";
import NavItem from "./NavItem";
export var MenuList = function () {
    var sidebarOpened = useSelector(sidebarStateSelector);
    var unreadChatsCount = useSelector(makeGetUnreadChatsCount());
    var navItems = menuItems
        .filter(function (item) { return !item.disabled; })
        .map(function (item) {
        var count = 0;
        if ((item === null || item === void 0 ? void 0 : item.id) === EMenuItems.Chats) {
            count = unreadChatsCount;
        }
        return (_jsx(List, { sx: { py: 0 }, children: _jsx(NavItem, { item: item, sidebarOpened: sidebarOpened, count: count }) }, item.id));
    });
    return _jsx(Box, { sx: { mt: 1.5 }, children: navItems });
};

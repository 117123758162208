var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
export var getPaymentOrdersPagedQuery = function (params) {
    var limit = params.limit, page = params.page, search = params.search;
    return { limit: limit, page: page, search: search, filter: {}, sort: [] };
};
export var getPaymentOrdersQuery = function (params) {
    var limit = params.limit, propertyId = params.propertyId, contractId = params.contractId, filter = params.filter, sort = params.sort;
    return {
        page: 1,
        limit: limit || 20,
        sort: sort || [
            { field: "startedAt", method: "DESC" },
            { field: "id", method: "DESC" },
        ],
        filter: __assign(__assign(__assign({}, (propertyId ? { propertyId: { eq: propertyId } } : {})), (contractId ? { contractId: { eq: contractId } } : {})), filter),
    };
};

import { ERentioFormField } from "@rentiohq/shared-frontend/dist/components/components/Form";
import { getValue } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { stringToSnakeCase } from "@rentiohq/shared-frontend/dist/utils/string.utils";
import { showContentDate, showContentNumber, showContentText, } from "../../Editor/decorators/ConditionRenderer/condition.utils";
import { EConditionType } from "../../Editor/Editor.types";
import { getNameOptions } from "../../InsertVariableModal/schemas/variableForm.utils";
import { EField } from "./schema.insertCondition.types";
export var getSchema = function (initialValues, extraData) {
    return function (defaultValues) {
        var _a;
        return {
            required: [],
            properties: (_a = {},
                _a[EField.Header1] = {
                    default: undefined,
                },
                _a[EField.Name] = {
                    default: getValue(EField.Name, initialValues, defaultValues),
                    // @ts-ignore
                    options: getNameOptions({
                        fields: extraData.fields,
                        withNewOption: false,
                    }),
                },
                _a[EField.Header3] = {
                    default: undefined,
                },
                _a[EField.Type] = {
                    default: getValue(EField.Type, initialValues, defaultValues),
                    // @ts-ignore
                    options: Object.values(EConditionType).map(function (id) { return ({
                        id: id,
                        value: getLocalizedText("editor.condition.type.".concat(stringToSnakeCase(id))),
                    }); }),
                    // // @ts-ignore
                    // getOptions: (formData: any) => {
                    //   const variableId = formData[EField.Name];
                    //   const variable = [
                    //     ...extraData.fields.variables,
                    //     ...DEFAULT_VARIABLES,
                    //   ].find(x => x.id === variableId);
                    //   const conditionTypes = getConditionsForVariableType(variable?.type);
                    //   return conditionTypes.map(id => ({
                    //     id,
                    //     value: getLocalizedText(
                    //       `editor.condition.type.${stringToSnakeCase(id)}`,
                    //     ),
                    //   }));
                    // },
                },
                _a[EField.ContentText] = {
                    default: getValue(EField.ContentText, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function (formData) { return showContentText(formData[EField.Type]); },
                    // @ts-ignore
                    showIf: function (formData) { return showContentText(formData[EField.Type]); },
                },
                _a[EField.ContentDate] = {
                    default: getValue(EField.ContentDate, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function (formData) { return showContentDate(formData[EField.Type]); },
                    // @ts-ignore
                    showIf: function (formData) { return showContentDate(formData[EField.Type]); },
                },
                _a[EField.ContentNumber] = {
                    default: getValue(EField.ContentNumber, initialValues, defaultValues),
                    // @ts-ignore
                    requiredIf: function (formData) {
                        return showContentNumber(formData[EField.Type]);
                    },
                    // @ts-ignore
                    showIf: function (formData) { return showContentNumber(formData[EField.Type]); },
                },
                _a),
        };
    };
};
export var uiSchema = function (extraData) {
    var _a;
    return (_a = {},
        _a[EField.Header1] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("editor.insert_custom.insert_condition.header.variable"),
        },
        _a[EField.Name] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.insert_custom.insert_condition.field.variable"),
        },
        _a[EField.Header3] = {
            "ui:field": ERentioFormField.SectionHeader,
            title: getLocalizedText("editor.insert_custom.insert_condition.header.meta"),
        },
        _a[EField.Type] = {
            "ui:field": ERentioFormField.DropdownSelect,
            title: getLocalizedText("editor.insert_custom.insert_condition.field.type"),
        },
        _a[EField.ContentText] = {
            "ui:field": ERentioFormField.Text,
            title: getLocalizedText("editor.insert_custom.insert_condition.field.content_text"),
        },
        _a[EField.ContentDate] = {
            "ui:field": ERentioFormField.Date,
            title: getLocalizedText("editor.insert_custom.insert_condition.field.content_date"),
        },
        _a[EField.ContentNumber] = {
            "ui:field": ERentioFormField.Number,
            title: getLocalizedText("editor.insert_custom.insert_condition.field.content_number"),
        },
        _a);
};
export var getValidate = function (_) { return function (formData, errors) {
    return errors;
}; };

import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../../themes/types";
export var ContactListStyles = function () {
    var theme = useTheme();
    return {
        content: {
            listItemBtn: function (isActive) { return ({
                padding: "6px 4px",
                borderRadius: "6px",
                backgroundColor: isActive
                    ? theme.palette.secondary.light
                    : theme.palette.background.paper,
            }); },
            listItemAvatar: {
                minWidth: "40px",
            },
            avatar: {
                width: "32px",
                height: "32px",
                color: "#fff",
            },
            contactName: {
                maxWidth: "200px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                color: theme.palette.grey[600],
            },
            dateTime: {
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                display: "block",
                color: theme.palette.success.main,
                fontWeight: EFontWeights.ExtraBold,
            },
            divider: {
                margin: "12px 0",
            },
            lastMessage: {
                color: theme.palette.grey[600],
                fontWeight: EFontWeights.Regular,
            },
            chip: {
                width: 20,
                height: 20,
                "& .MuiChip-label": {
                    px: 0.5,
                },
            },
        },
    };
};

import { useTheme } from "@mui/material/styles";
import { EFontWeights } from "../../themes/types";
export var TabsStyles = function (_a) {
    var _b = _a.isVertical, isVertical = _b === void 0 ? false : _b;
    var theme = useTheme();
    return {
        wrapper: {
            ".MuiTab-root": {
                minHeight: "auto",
                minWidth: 10,
                px: isVertical ? 1.5 : 0,
                mx: isVertical ? 0 : 1.5,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: isVertical ? "flex-start" : "center",
                textTransform: "uppercase",
                fontWeight: EFontWeights.Medium,
                "&:first-child": {
                    pl: 0,
                    ml: isVertical ? "12px" : 0,
                },
            },
            "& a > svg": {
                mb: "0px !important",
                mr: 1.1,
            },
        },
        content: {
            tabChip: {
                color: "secondary.main",
                bgcolor: "secondary.light",
                ml: 1.3,
            },
            tab: function (isCompleted) { return ({
                color: isCompleted
                    ? theme.palette.text.dark
                    : theme.palette.text.lightDark,
            }); },
        },
    };
};

import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as cardActions from "@rentiohq/shared-frontend/dist/redux/card/card.actions";
import * as cardSelectors from "@rentiohq/shared-frontend/dist/redux/card/card.selectors";
import {
  ECardType,
  ICard,
} from "@rentiohq/shared-frontend/dist/redux/card/card.types";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { ICountQuery } from "@rentiohq/shared-frontend/dist/reduxV2/utils/api.types";
import { EPropertyMemberTypes } from "@rentiohq/shared-frontend/dist/types/property.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "@rentiohq/web-shared-next/dist/assets";
import {
  Box,
  EIconAvatarVariant,
  Grid,
  Icon,
  IconAvatar,
  MainCard,
  Page,
  SimpleInfoBox,
  SpinningLoader,
  Stack,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import { getContractStatusFilter } from "scenes/FollowUp/EndingContractsV2/EndingContracts.utils";
import { EContractStatus } from "scenes/Properties/scenes/Contracts/components/ContractCard/components/ContractStatusChip/contractStatusChip.types";
import { DataList } from "../../components/DataList";
import { DataListItem } from "../../components/DataList/DataListItem";
import { NotificationCard } from "../../components/NotificationCard";
import { PropertyCard } from "../../components/PropertyCard";

export const DashboardContainer = () => {
  const dispatch = useDispatch();
  const self = useSelf();

  const getFilters = ({
    isActive,
  }: {
    isArchived?: boolean;
    isActive?: boolean;
    isContractEnded?: boolean;
    isUpcoming?: boolean;
  }) => {
    const result: ICountQuery = {};

    const andFilters: { [key: string]: any }[] = [];

    if (isActive) {
      andFilters.push({ archivedAt: { is: null } });
      const contractStatusFilter = getContractStatusFilter(
        EContractStatus.Running,
      );
      contractStatusFilter && andFilters.push(contractStatusFilter);
    }

    result.filter = { and: andFilters };

    return result;
  };

  const notifications =
    useSelector<
      IRootStore,
      ReturnType<typeof cardSelectors.unclosedCardsSelector>
    >(state => cardSelectors.unclosedCardsSelector(state)) || [];

  const isFetchingNotifications = useSelector<IRootStore>(
    state => state.card.isFetchingCards,
  );

  const fetchNotificationsError = useSelector<IRootStore, Error | undefined>(
    state => state?.card?.fetchCardsError,
  );

  const {
    items: properties = [],
    isFetching: isFetchingProperties,
    fetchError: fetchErrorProperties,
  } = propertyHooks.usePaged({
    shouldRefetch: true,
    query: {
      limit: 5,
      page: 1,
      sort: [
        {
          field: { contracts: "startDate" },
          method: "DESC",
        },
      ],
      rolesFilter: [EPropertyMemberTypes.Tenant],
      ...getFilters({ isActive: true }),
    },
  });

  React.useEffect(() => {
    document.title = `Rentio | ${getLocalizedText("dashboard.welcome")}`;
  }, []);

  React.useEffect(() => {
    dispatch(
      cardActions.getCards.actions.start({
        payload: {
          filter: {
            type: { eq: "task" },
          },
        },
      }),
    );
  }, []);

  React.useEffect(() => {
    const errorMessage = fetchErrorProperties || fetchNotificationsError;
    if (errorMessage) {
      enqueueSnackbar({
        message: errorMessage?.message,
        variant: "error",
      });
    }
  }, [fetchErrorProperties, fetchNotificationsError]);

  const handleClickCloseButton = (card: ICard) => {
    dispatch(cardActions.closeCard.actions.start({ cardId: card.id }));
  };

  const renderCardsList = (filteredNotifications: ICard[]) =>
    filteredNotifications.map(notification => (
      <DataListItem
        key={notification?.id}
        configuration={{
          action: notification?.hasCloseButton ? (
            <Box
              onClick={() => handleClickCloseButton(notification)}
              sx={{ cursor: "pointer" }}
            >
              <Icon
                path={EAssets.CloseIcon}
                width="12px"
                height="12px"
                realColor="#D84315"
              />
            </Box>
          ) : undefined,
        }}
        mainContent={<NotificationCard notification={notification} />}
      />
    ));

  const renderMessages = () => {
    if (isFetchingProperties || isFetchingNotifications) {
      return <SpinningLoader />;
    }

    const filteredNotifications = notifications.filter(
      notification =>
        notification?.type &&
        [ECardType.Payment, ECardType.Payment].includes(notification?.type),
    );

    if (filteredNotifications?.length > 0) {
      return (
        <MainCard
          title={getLocalizedText("system.messages")}
          border={true}
          startIcon={
            <IconAvatar
              icon={EAssets.AlertIcon}
              variant={EIconAvatarVariant.Warning}
            />
          }
        >
          <DataList>{renderCardsList(filteredNotifications)}</DataList>
        </MainCard>
      );
    }

    if (filteredNotifications?.length === 0) {
      return (
        <SimpleInfoBox
          headline={getLocalizedText("tenant.no_messages.heading")}
          details={getLocalizedText("tenant.no_messages.details")}
        />
      );
    }
  };

  const renderProperties = () => {
    if (isFetchingProperties) {
      return <SpinningLoader />;
    }

    if (properties?.length > 0) {
      return (
        <Stack gap={1}>
          {properties.map(property => (
            <PropertyCard property={property} isActive={true} />
          ))}
        </Stack>
      );
    }

    if (properties?.length === 0) {
      return (
        <SimpleInfoBox
          headline={getLocalizedText("tenant.no_rental.heading")}
          details={getLocalizedText("tenant.no_rental.details")}
        />
      );
    }
  };

  return (
    <Page
      title={getLocalizedText("dashboard.welcome_user", {
        user: self?.user?.firstname || "",
      })}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
          {renderMessages()}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
          {renderProperties()}
        </Grid>
      </Grid>
    </Page>
  );
};

import Spacer, {
  ESpacerWeight,
} from "@rentiohq/shared-frontend/dist/components/components/Spacer";
import * as templateDocumentHooks from "@rentiohq/shared-frontend/dist/redux/templateDocument/templateDocument.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import { formatAddress } from "@rentiohq/shared-frontend/dist/utils/address.utils";
import { formatDate } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  Button,
  Error,
  Grid,
  Icon,
  Loading,
  Lozenge,
  Page,
} from "@rentiohq/web-shared/dist/components";
import utils from "@rentiohq/web-shared/dist/utils";
import React from "react";
import TemplateDocumentInfo from "./components/TemplateDocumentInfo";

interface IProps {
  templateDocumentId: string;
  onDrawerClose?: () => void;
}

const TemplateDocument: React.FC<IProps> = props => {
  const { templateDocumentId, onDrawerClose } = props;

  const {
    detail: templateDocument,
    isFetching: isFetchingTemplateDocument,
    fetchError: fetchTemplateDocumentError,
  } = templateDocumentHooks.useDetail({
    shouldRefetch: false,
    id: templateDocumentId,
  });

  const {
    detail: property,
    isFetching: isFetchingProperty,
    fetchError: propertyFetchError,
  } = propertyHooks.useDetail({
    shouldRefetch: false,
    id: templateDocument?.propertyId,
  });

  // Render
  if (!templateDocument || !property) {
    if (isFetchingProperty || isFetchingTemplateDocument) {
      return <Loading />;
    }

    if (fetchTemplateDocumentError) {
      return <Error errors={[fetchTemplateDocumentError]} />;
    }

    if (propertyFetchError) {
      return <Error errors={[propertyFetchError]} />;
    }

    return null;
  }

  const metadata = (
    <>
      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon
            source={utils.properties.getIcon(property.typeId || 0)}
            size="small"
          />
        </Grid.Item>
        <Grid.Item>
          <Button appearance="link" url={`/properties/${property.id}`}>
            {formatAddress(property)}
          </Button>
        </Grid.Item>
      </Grid>

      <Spacer weight={ESpacerWeight.W08} />
      <Grid spacing="extraTight" alignItems="center">
        <Grid.Item>
          <Icon source="calendar" size="small" />
        </Grid.Item>
        <Grid.Item>
          {getLocalizedText("system.created_at", {
            date: formatDate(templateDocument.createdAt),
          })}
        </Grid.Item>
      </Grid>
    </>
  );

  return (
    <Page
      title={templateDocument.name}
      subtitle={
        <Lozenge isBold={true} appearance="info">
          {getLocalizedText("document.tag.template_document")}
        </Lozenge>
      }
      metadata={metadata}
      setDocumentTitle={false}
    >
      <TemplateDocumentInfo
        templateDocument={templateDocument}
        property={property}
        onDrawerClose={onDrawerClose}
      />
    </Page>
  );
};

// eslint-disable-next-line import/no-default-export
export default TemplateDocument;

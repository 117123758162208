import { makeGetUnreadChatsCount } from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import React from "react";
import { useSelector } from "react-redux";

interface IBackgroundChecksProps {}

export const BackgroundChecks: React.FC<IBackgroundChecksProps> = () => {
  const faviconElement = React.useRef<HTMLLinkElement>(
    document.querySelector("link[rel*='icon']"),
  );

  const unreadChatsCount: number = useSelector(makeGetUnreadChatsCount());

  React.useEffect(() => {
    if (!faviconElement.current) {
      return;
    }

    if (unreadChatsCount > 0) {
      faviconElement.current.href = faviconElement.current.dataset
        .notificationIcon as string;
    } else {
      faviconElement.current.href = faviconElement.current.dataset
        .originalIcon as string;
    }
  }, [unreadChatsCount]);

  return <></>;
};

import { getContactMeMaster } from "@rentiohq/shared-frontend/dist/redux/contact/contact.selectors";
import { closeSocket } from "@rentiohq/shared-frontend/dist/redux/socket/socket.actions";
import * as chatActions from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.actions";
import * as chatSelectors from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.selectors";
import { EChatMemberTypes } from "@rentiohq/shared-frontend/dist/types/chat.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Form } from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import { IFormikValues } from "@rentiohq/web-shared-next/dist/utils/types/form";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import { addChatSchema } from "../../../forms/createChat/schema.addChat.list";
import { EField } from "../../../forms/createChat/schema.createChat.types";

export interface IProps {
  open: boolean;
  accountIds?: number[];
  hideActions?: boolean;
  handleClose: () => void;
}

export const AddChat = ({
  open,
  accountIds,
  hideActions,
  handleClose,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const newChat = useSelector((state: IRootStore) =>
    chatSelectors.getNewCreatedChat(state),
  );

  const isCreatingChat = useSelector((state: IRootStore) =>
    chatSelectors.isCreating(state),
  );

  const createChatError = useSelector((state: IRootStore) =>
    chatSelectors.createError(state),
  );

  const prevIsCreatingChat = usePrevious(isCreatingChat);

  const contactMeMaster = useSelector((state: IRootStore) =>
    getContactMeMaster(state),
  );

  React.useEffect(() => {
    if (prevIsCreatingChat && !isCreatingChat) {
      if (createChatError) {
        enqueueSnackbar({
          message: getLocalizedText("system.error"),
          variant: "error",
        });
        handleClose();
      }
      if (newChat?.id) {
        dispatch(
          closeSocket.actions.start({
            autoReopenAfterClose: false,
            sendPendingMessagesOnNextOpen: true,
          }),
        );
        navigate(`/chats/${newChat.id}`);
        handleClose();
      }
    }
  }, [newChat, createChatError, history, isCreatingChat, prevIsCreatingChat]);

  const handleSubmit = (formData: IFormikValues) => {
    const newMembers =
      formData[EField.Members].map((contactId: number) => ({
        accountId: contactId,
        roles: [EChatMemberTypes.Participant],
      })) || [];

    dispatch(
      chatActions.createStart.getAction({
        data: {
          name: formData[EField.Name] || "Chat",
          propertyId: formData[EField.Property],
          members: newMembers,
        },
      }),
    );
    enqueueSnackbar({
      message: getLocalizedText("system.publish.confirmed"),
      variant: "success",
    });
  };

  if (!contactMeMaster) {
    return null;
  }

  return (
    <Form
      renderAsModal={true}
      onSubmit={handleSubmit}
      getSchema={addChatSchema({
        accountIds,
        hideActions,
        contactMeMaster,
      })}
      modalProps={{
        modalTitle: getLocalizedText("chats.create.title"),
        openDialog: open,
        handleClose,
        maxWidth: "700px",
        width: "700px",
      }}
    />
  );
};

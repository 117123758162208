import { Box } from "@rebass/grid";
import { generateFormId } from "@rentiohq/shared-frontend/dist/redux/form/form.utils";
import * as paymentRequestActions from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.actions";
import * as paymentRequestSelectors from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.selectors";
import { IAccount } from "@rentiohq/shared-frontend/dist/types/auth.types";
import { EPaymentRequestFollowUpType } from "@rentiohq/shared-frontend/dist/types/paymentRequest.types";
import { usePrevious } from "@rentiohq/shared-frontend/dist/utils/hooks.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  AccountList,
  DisplayText,
  ESpacings,
  MultiStepForm as Form,
} from "@rentiohq/web-shared/dist/components";
import isEmpty from "ramda/es/isEmpty";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootStore } from "redux/reducers";
import createFollowUpPhoneForm from "../forms/followUpPhone";
import { EField } from "../forms/followUpPhone/schema.followUpPhone.types";
import { IPaymentFollowUpScreenProps } from "../PaymentFollowUpModal.types";

const formId = generateFormId();

export const PhoneScreen: React.FC<any> = React.forwardRef(
  (
    {
      followUpItem,
      renderActivities,
      onClose,
      setIsLoading,
    }: IPaymentFollowUpScreenProps,
    formRef: any,
  ) => {
    const dispatch = useDispatch();
    const isPendingFollowUp =
      useSelector((state: IRootStore) =>
        paymentRequestSelectors.isPendingFollowUp(
          state,
          followUpItem.id,
          EPaymentRequestFollowUpType.Phone,
        ),
      ) || false;
    const followUpError =
      useSelector((state: IRootStore) =>
        paymentRequestSelectors.followUpError(
          state,
          followUpItem.id,
          EPaymentRequestFollowUpType.Phone,
        ),
      ) || false;
    const prevIsPendingFollowUp = usePrevious(isPendingFollowUp);

    React.useEffect(() => {
      setIsLoading?.(isPendingFollowUp);
    }, [isPendingFollowUp, setIsLoading]);

    React.useEffect(() => {
      if (!!prevIsPendingFollowUp && !isPendingFollowUp && !followUpError) {
        onClose?.();
      }
    }, [prevIsPendingFollowUp, isPendingFollowUp, followUpError]);

    const handleSuccess = (values: any) => {
      const sendReminder =
        !values[EField.SendToPayer] || isEmpty(values[EField.SendToPayer])
          ? false
          : true;
      const postBody = {
        summary: values[EField.Summary],
        paymentPromisedAt: values[EField.PromisedDate],
        hasAnswered: values[EField.HasAnswered] === "1" ? true : false,
        mailReminder: sendReminder,
        // chatReminder: sendReminder,
      };

      dispatch(
        paymentRequestActions.reminderPhoneStart.getAction({
          id: followUpItem.id,
          data: postBody,
        }),
      );
    };

    return (
      <>
        <DisplayText size="small">
          {getLocalizedText("contact.dashboard.contact_data.heading")}
        </DisplayText>

        <AccountList accounts={[followUpItem.payerAccount] as IAccount[]} />

        <Box mt={ESpacings.loose}>
          <Form
            ref={formRef}
            formId={`follow-up-phone-${formId}`}
            preventSubmitThroughEnter={true}
            // schemas={schemas}
            schemas={createFollowUpPhoneForm({})}
            showControls={false}
            withAside={false}
            onSuccess={handleSuccess}
          />
        </Box>

        {renderActivities}
      </>
    );
  },
);

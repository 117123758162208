import { jsx as _jsx } from "react/jsx-runtime";
import MenuItem from "@mui/material/MenuItem";
import MuiSelect from "@mui/material/Select";
import React from "react";
var ITEM_HEIGHT = 48;
var ITEM_PADDING_TOP = 8;
var MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};
export var Select = function (props) {
    var options = props.options, onChange = props.onChange, value = props.value, id = props.id, name = props.name;
    var _a = React.useState(""), defaultValue = _a[0], setDefaultValue = _a[1];
    React.useEffect(function () {
        setDefaultValue(value);
    }, [value]);
    return (_jsx(MuiSelect, { name: name, id: id, onChange: function (e) { return onChange(e.target.value); }, MenuProps: MenuProps, value: defaultValue, size: "small", children: options.map(function (option) {
            var value = option.value, label = option.label;
            return (_jsx(MenuItem, { value: value, children: label }, value));
        }) }));
};

import { CONFIG } from "@rentiohq/shared-frontend/dist/config/app.config";
import { getActiveContracts } from "@rentiohq/shared-frontend/dist/forms/createPayment/schema.createPayment.utils";
import { useSelf } from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import * as documentPackageActions from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.actions";
import * as documentPackageSelectors from "@rentiohq/shared-frontend/dist/redux/documentPackage/documentPackage.selectors";
import * as contractHooks from "@rentiohq/shared-frontend/dist/reduxV2/contract/contract.hooks";
import * as paymentRequestsHooks from "@rentiohq/shared-frontend/dist/reduxV2/paymentRequest/paymentRequest.hooks";
import * as propertyHooks from "@rentiohq/shared-frontend/dist/reduxV2/property/property.hooks";
import * as taskHooks from "@rentiohq/shared-frontend/dist/reduxV2/task/task.hooks";
import { ETaskCustomFilter } from "@rentiohq/shared-frontend/dist/reduxV2/task/task.utils";
import { EContractType } from "@rentiohq/shared-frontend/dist/types/contract.types";
import { EDocumentPackageStatus } from "@rentiohq/shared-frontend/dist/types/documentPackage.types";
import { ETaskMemberType } from "@rentiohq/shared-frontend/dist/types/task.types";
import { twoWeeksLater } from "@rentiohq/shared-frontend/dist/utils/date.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { SHOW_LOADER } from "@rentiohq/web-shared-next/dist/redux/actions";
import {
  Box,
  Grid,
  SimpleCard,
  Stack,
  TabPanel,
} from "@rentiohq/web-shared-next/dist/ui-components";
import { enqueueSnackbar } from "@rentiohq/web-shared-next/dist/utils/snackbar";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { IRootStore } from "redux/reducers";
import {
  EPaymentRequestFilterPhase,
  getPaymentRequestsFilterV2,
} from "scenes/FollowUp/Payments/Payments.utils";
import { QuickActions } from "../../../QuickActions";
import { ContractList } from "../../components/ContractList";
import { PropertyManagersCard } from "../../components/PropertyManagersCard";
import { TodoList } from "../../components/TodoList";
import { EPropertyDetailsTabs } from "../../propertyDetails.types";
import { getSignableDocumentPackages } from "../../propertyDetails.utils";

const NOW = new Date();
const TWO_WEEKS_LATER = twoWeeksLater();

export const Overview = () => {
  const dispatch = useDispatch();

  const { user } = useSelf();

  const params = useParams<{ propertyId: string }>();
  const propertyId = params.propertyId ? +params.propertyId : undefined;

  const documentPackagesIdentifier = `document-packages-property-${propertyId}`;

  const documentPackages = useSelector((state: IRootStore) =>
    documentPackageSelectors.getDocumentPackages(
      state,
      documentPackagesIdentifier,
    ),
  );

  const isFetchingDocumentPackages = useSelector((state: IRootStore) =>
    documentPackageSelectors.isFetchingDocumentPackages(
      state,
      documentPackagesIdentifier,
    ),
  );

  const {
    detail: property,
    isFetching: isFetchingDetails,
    fetchError: propertyDetailAPIError,
  } = propertyHooks.useDetail({
    shouldRefetch: true,
    id: propertyId,
  });

  const {
    items: contracts = [],
    isFetching: isFetchingContracts,
    fetchError: contractsAPIError,
  } = contractHooks.useGetAll({
    shouldRefetch: true,
    query: {
      filter: {
        and: [
          { propertyId: { eq: propertyId } },
          { contractType: { eq: EContractType.Basic } },
          // { dateOfCancellation: { is: null } },
          { stopDate: { gte: NOW } },
        ],
      },
      sort: [
        {
          field: "startDate",
          method: "DESC",
        },
      ],
    },
  });

  const {
    items: executableTasks,
    isFetching: isFetchingExecutableTasks,
    fetchError: executableTasksAPIError,
  } = taskHooks.usePaged({
    shouldRefetch: true,
    query: {
      page: 1,
      limit: CONFIG.DEFAULT_FETCH_LIMIT,
      sort: [
        {
          field: "createdAt",
          method: "DESC",
        },
      ],
      rolesFilter: [ETaskMemberType.Executor],
      customFilters: [ETaskCustomFilter.NotArchived],
      filter: {
        propertyId: { eq: propertyId },
        and: [
          { finishedAt: { is: null } },
          {
            or: [
              { executeDate: { lte: TWO_WEEKS_LATER } },
              { dueDate: { is: null } },
              { dueDate: { lte: TWO_WEEKS_LATER } },
              { nextReminderDate: { lte: TWO_WEEKS_LATER } },
            ],
          },
        ],
      },
    },
  });

  const {
    items: upcomingPaymentRequests = [],
    isFetching: isFetchingUpcomingPaymentRequests,
    fetchError: upcomingPaymentRequestsAPIError,
  } = paymentRequestsHooks.useGetAll({
    shouldRefetch: true,
    query: getPaymentRequestsFilterV2({
      propertyId,
      phase: EPaymentRequestFilterPhase.Incoming,
    }),
  });

  const {
    items: openPaymentRequests = [],
    isFetching: isFetchingOpenPaymentRequests,
    fetchError: openPaymentRequestsAPIError,
  } = paymentRequestsHooks.useGetAll({
    shouldRefetch: true,
    query: getPaymentRequestsFilterV2({
      propertyId,
      phase: EPaymentRequestFilterPhase.Rentio,
    }),
  });

  const {
    items: latePaymentRequests = [],
    isFetching: isFetchingLatePaymentRequests,
    fetchError: latePaymentRequestsAPIError,
  } = paymentRequestsHooks.useGetAll({
    shouldRefetch: true,
    query: getPaymentRequestsFilterV2({
      propertyId,
      phase: EPaymentRequestFilterPhase.Action,
    }),
  });

  React.useEffect(() => {
    dispatch(
      documentPackageActions.getDocumentPackages.actions.start({
        identifier: documentPackagesIdentifier,
        refetch: true,
        filterData: {
          where: {
            // type: [
            //   EDocumentPackageTypes.Contract,
            //   EDocumentPackageTypes.RentDeposit,
            // ],
            propertyId,
            status: {
              nin: [
                EDocumentPackageStatus.Revoked,
                EDocumentPackageStatus.Expired,
                EDocumentPackageStatus.Failed,
                EDocumentPackageStatus.Rejected,
                EDocumentPackageStatus.WorkerFailed,
              ],
            },
          },
          order: "createdAt DESC",
        },
      }),
    );
  }, []);

  const isLoading =
    isFetchingDetails ||
    isFetchingContracts ||
    isFetchingExecutableTasks ||
    isFetchingOpenPaymentRequests ||
    isFetchingLatePaymentRequests ||
    isFetchingUpcomingPaymentRequests ||
    isFetchingDocumentPackages;

  const apiError =
    propertyDetailAPIError ||
    contractsAPIError ||
    executableTasksAPIError ||
    openPaymentRequestsAPIError ||
    latePaymentRequestsAPIError ||
    upcomingPaymentRequestsAPIError;

  React.useEffect(() => {
    dispatch({ type: SHOW_LOADER, payload: isLoading });
  }, [isLoading]);

  React.useEffect(() => {
    if (apiError?.message) {
      enqueueSnackbar(apiError.message, { variant: "error" });
    }
  }, [apiError]);

  const signableDocumentPackages = getSignableDocumentPackages(
    documentPackages,
    user,
  );

  const showTodoList = Boolean(
    signableDocumentPackages?.length ||
      openPaymentRequests?.length ||
      latePaymentRequests?.length ||
      upcomingPaymentRequests?.length ||
      executableTasks?.length,
  );

  const renderQuickActions = () => {
    if (!property) {
      return;
    }

    return (
      <SimpleCard title={getLocalizedText("system.quick.actions")}>
        <QuickActions property={property} />
      </SimpleCard>
    );
  };

  const renderPropertyManagersList = () => {
    if (!property) {
      return;
    }

    return <PropertyManagersCard property={property} />;
  };

  return (
    <TabPanel
      value={EPropertyDetailsTabs.Info}
      activeTab={EPropertyDetailsTabs.Info}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          sx={{ display: { xs: "block", lg: "none" } }}
        >
          {renderQuickActions()}
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={9}>
          <Stack direction="column" gap={1}>
            {showTodoList && (
              <TodoList
                documentPackages={signableDocumentPackages || []}
                paymentRequests={[
                  ...upcomingPaymentRequests,
                  ...openPaymentRequests,
                  ...latePaymentRequests,
                ]}
                tasks={executableTasks || []}
              />
            )}
            <ContractList
              contracts={getActiveContracts(contracts)}
              title={getLocalizedText("contract.title.running")}
              showDetails={false}
            />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={3}>
          <Stack direction="column" gap={1}>
            <Box sx={{ display: { xs: "none", lg: "block" } }}>
              {renderQuickActions()}
            </Box>
            {renderPropertyManagersList()}
          </Stack>
        </Grid>
      </Grid>
    </TabPanel>
  );
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import * as chatHooks from "@rentiohq/shared-frontend/dist/reduxV2/chats/chat.hooks";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "../../../assets";
import { Icon } from "../../Icon";
import { Link } from "../../Link";
export var ArchivedChatBanner = function (_a) {
    var chat = _a.chat;
    var unarchive = chatHooks.useUnarchive({ id: chat === null || chat === void 0 ? void 0 : chat.id }).unarchive;
    var handleUnarchiveProperty = function () {
        if (chat.archivedAt) {
            unarchive({});
        }
    };
    return (_jsxs(Stack, { direction: "row", alignItems: "center", gap: 2, children: [_jsx(Icon, { path: EAssets.ArchivedPropertyIcon, realColor: "red" }), _jsxs(Stack, { direction: "column", children: [_jsx(Typography, { variant: "h3", fontSize: "16px", children: getLocalizedText("chat.archived.card.title") }), _jsx(Typography, { variant: "caption", children: getLocalizedText("chat.archived.card.content") }), _jsx(Link, { label: getLocalizedText("chat.archived.card.cta"), onClick: handleUnarchiveProperty })] })] }));
};

export var ChatAttachmentPreviewStyles = function () {
    return {
        wrapper: {
            maxWidth: "200px",
            img: {
                width: "100%",
                objectFit: "contain",
            },
        },
    };
};

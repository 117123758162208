var _a;
import { EPropertyTypeId } from "@rentiohq/shared-frontend/dist/types/property.types";
import { EAssets } from "../../assets";
export var PropertyIcon = (_a = {},
    _a[EPropertyTypeId.House] = EAssets.HouseIcon,
    _a[EPropertyTypeId.Apartment] = EAssets.ApartmentIcon,
    _a[EPropertyTypeId.StudentResidence] = EAssets.StudentRoomIcon,
    _a[EPropertyTypeId.Commercial] = EAssets.CommercialPropertyIcon,
    _a[EPropertyTypeId.Office] = EAssets.OfficeIcon,
    _a[EPropertyTypeId.ParkingLot] = EAssets.ParkingSpotIcon,
    _a[EPropertyTypeId.Garage] = EAssets.GarageIcon,
    _a[EPropertyTypeId.Storage] = EAssets.StorageIcon,
    _a[EPropertyTypeId.Shed] = EAssets.ShedIcon,
    _a[EPropertyTypeId.Warehouse] = EAssets.WarehouseIcon,
    _a);

var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import { Outlet } from "react-router-dom";
import useConfig from "../../hooks/useConfig";
import { Loader } from "../../ui-components/Loader";
import { BottomMenu } from "../BottomMenu";
import { Header } from "../Header";
import { MainContentStyled } from "../MainContent";
import { Sidebar } from "../Sidebar";
import { MainLayoutStyles } from "./MainLayout.styles";
export var MainLayout = function () {
    var isLoading = false;
    var theme = useTheme();
    var _a = useConfig(), borderRadius = _a.borderRadius, container = _a.container, menuOrientation = _a.menuOrientation;
    var drawerOpen = true;
    if (isLoading)
        return _jsx(Loader, {});
    var sx = MainLayoutStyles();
    return (_jsxs(_Fragment, { children: [_jsxs(Box, { sx: sx.wrapper, children: [_jsx(AppBar, { enableColorOnDark: true, position: "fixed", color: "inherit", elevation: 0, sx: sx.content.appBar, children: _jsx(Toolbar, { sx: sx.content.toolbar, children: _jsx(Header, {}) }) }), _jsx(Sidebar, {}), _jsx(MainContentStyled, { borderRadius: borderRadius, menuOrientation: menuOrientation, open: drawerOpen, theme: theme, children: _jsx(Container, __assign({ maxWidth: container ? "lg" : false }, (!container && { sx: sx.content.container }), { children: _jsx(Outlet, {}) })) })] }), _jsx(BottomMenu, {})] }));
};

import { useQueryParams } from "@rentiohq/shared-frontend/dist/hooks/useQueryParams";
import * as authHooks from "@rentiohq/shared-frontend/dist/redux/auth/auth.hooks";
import { useBrokerFeature } from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import {
  EBrokerFeature,
  EClientType,
} from "@rentiohq/shared-frontend/dist/types/broker.types";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { Page } from "@rentiohq/web-shared/dist/components";
import { useInternalMode } from "@rentiohq/web-shared/dist/redux/system/system.hooks";
import React from "react";
import { matchPath } from "react-router";
import { NumberParam } from "serialize-query-params";
import { ts } from "../../services";
import { ExportRevenues } from "./components/ExportRevenues";
import { ExternalInvoiceDetailModal } from "./components/ExternalInvoiceDetailModal";
import { RevenueTabList } from "./components/RevenueTabList";
import { RevenueTabList as RevenueTabListV2 } from "./components/RevenueTabListV2";
import { ERevenueType } from "./Revenues.utils";

export const Revenues: React.FC<{}> = () => {
  const [, setQueryParamValue] = useQueryParams({
    paymentRequestId: NumberParam,
  });

  const [showExport, setShowExport] = React.useState(false);

  const [externalInvoiceDetailModalId, setExternalInvoiceDetailModalId] =
    React.useState<string>();

  const { broker } = authHooks.useSelf();

  const { internalModeEnabled } = useInternalMode();
  const hasPaymentV2ForBroker = useBrokerFeature(EBrokerFeature.PaymentV2);

  // Event handlers
  const handleShowPaymentRequest = (params: { id: number }) => {
    const { id } = params;

    setQueryParamValue({ paymentRequestId: id });
  };

  // Helpers
  const getSupportedRevenueTypes = () => {
    if (broker?.type === EClientType.PatrimonyCompany) {
      return [ERevenueType.Income, ERevenueType.Other];
    }

    return [ERevenueType.Income];
  };

  const getTabs = () => {
    const result = getSupportedRevenueTypes().map((revenueType, index) => {
      const isFirst = index === 0;

      const isActive =
        !!matchPath(`/revenues/${revenueType}`, location.pathname) ||
        (isFirst && !!matchPath("/revenues", location.pathname));

      const clientTypeSuffix =
        broker?.type === EClientType.Broker ? "_broker" : "";

      return {
        content: getLocalizedText(
          `revenues.tab.${revenueType}${clientTypeSuffix}`,
        ),
        url: `/revenues/${revenueType}`,
        isActive,
      };
    });

    return result;
  };

  // Render
  const renderContent = () => {
    if (!!matchPath(`/revenues/${ERevenueType.Other}`, location.pathname)) {
      if (internalModeEnabled && hasPaymentV2ForBroker) {
        return (
          <RevenueTabListV2
            key={ERevenueType.Other}
            type={ERevenueType.Other}
            onShowPaymentRequest={handleShowPaymentRequest}
          />
        );
      }

      return (
        <RevenueTabList
          key={ERevenueType.Other}
          type={ERevenueType.Other}
          onShowPaymentRequest={handleShowPaymentRequest}
        />
      );
    }

    if (!!matchPath(`/revenues/${ERevenueType.Deleted}`, location.pathname)) {
      if (internalModeEnabled && hasPaymentV2ForBroker) {
        return (
          <RevenueTabListV2
            key={ERevenueType.Deleted}
            type={ERevenueType.Deleted}
            onShowPaymentRequest={handleShowPaymentRequest}
          />
        );
      }

      return (
        <RevenueTabList
          key={ERevenueType.Deleted}
          type={ERevenueType.Deleted}
          onShowPaymentRequest={handleShowPaymentRequest}
        />
      );
    }

    if (internalModeEnabled && hasPaymentV2ForBroker) {
      return (
        <RevenueTabListV2
          key={ERevenueType.Income}
          type={ERevenueType.Income}
          onShowPaymentRequest={handleShowPaymentRequest}
        />
      );
    }

    return (
      <RevenueTabList
        key={ERevenueType.Income}
        type={ERevenueType.Income}
        onShowPaymentRequest={handleShowPaymentRequest}
      />
    );
  };

  return (
    <>
      <Page
        fullWidth
        title={ts.revenuesPageHeading({ broker })}
        metadata={ts.revenuesPageSubHeading({ broker })}
        breadcrumbs={{ to: "/", content: ts.revenuesPageBreadcrumb() }}
        dropdown={[
          {
            content: getLocalizedText("revenues.cta.export"),
            onClick: () => {
              setShowExport(true);
            },
          },
        ]}
        tabs={getTabs()}
      >
        {renderContent()}
      </Page>

      {showExport && <ExportRevenues onClose={() => setShowExport(false)} />}

      {externalInvoiceDetailModalId && (
        <ExternalInvoiceDetailModal
          externalInvoiceId={externalInvoiceDetailModalId}
          onClose={() => {
            setExternalInvoiceDetailModalId(undefined);
          }}
        />
      )}
    </>
  );
};

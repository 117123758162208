import { Box } from "@rebass/grid";
import * as brokerHooks from "@rentiohq/shared-frontend/dist/redux/broker/broker.hooks";
import * as bankAccountHooks from "@rentiohq/shared-frontend/dist/reduxV2/bankAccount/bankAccount.hooks";
import { EBrokerFeature } from "@rentiohq/shared-frontend/dist/types/broker.types";
import { IContract } from "@rentiohq/shared-frontend/dist/types/contract.types";
import {
  EPaymentMethod,
  IPaymentOrder,
} from "@rentiohq/shared-frontend/dist/types/payment.types";
import { showAlert } from "@rentiohq/shared-frontend/dist/utils/alert/alert.utils";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { formatIban } from "@rentiohq/shared-frontend/dist/utils/iban.utils";
import {
  join,
  stringToSnakeCase,
} from "@rentiohq/shared-frontend/dist/utils/string.utils";
import {
  Button,
  Card,
  DisplayText,
  ESpacings,
  Icon,
  ResourceList,
  ResourceListItem,
  TextStyle,
} from "@rentiohq/web-shared/dist/components";
import { IAction } from "@rentiohq/web-shared/dist/types";
import { flatten, uniq } from "lodash";

type TRenderRow<T> = T & { actions: (item?: any) => IAction[] };

enum EVisualPaymentMethod {
  Sepa = "SEPA",
  Online = "ONLINE",
  BankTransfer = "BANK_TRANSFER",
}

interface IProps {
  paymentMethods: EPaymentMethod[];
  ibanisationReference?: string;
  paymentOrder?: IPaymentOrder;
  contractId?: string;
  contract?: IContract;
}

export const PaymentMethodsCard = (props: IProps) => {
  const { paymentMethods, ibanisationReference, paymentOrder, contractId } =
    props;

  // Hooks
  const { detail: ibanisationBankAccount } = bankAccountHooks.useDetail({
    shouldRefetch: false,
    skipLegacy: true,
    customPath: contractId
      ? `/contracts/${contractId}/ibanisation-bank-account`
      : undefined,
  });

  const hasIbanisationForBroker = brokerHooks.useBrokerFeature(
    EBrokerFeature.Ibanisation,
  );

  // Event handlers
  const handleCopyValue = async (value?: string) => {
    if (!value) {
      return;
    }

    try {
      await navigator.clipboard.writeText(value);

      showAlert({
        type: "success",
        message: getLocalizedText("system.pasteboard.copied.success", {
          value,
        }),
      });
    } catch (error) {
      showAlert({
        type: "error",
        error,
      });
    }
  };

  // Render
  const renderCardHeader = (heading: string) => (
    <DisplayText size="extraSmall" space="tight">
      {heading}
    </DisplayText>
  );

  const renderVisualPaymentMethod = (
    visualPaymentMethod: TRenderRow<EVisualPaymentMethod>,
  ) => {
    const paymentMethodsForVisualPaymentMethods: EPaymentMethod[] = [];

    switch (visualPaymentMethod) {
      case EVisualPaymentMethod.Sepa: {
        const paymentMethodsToAdd = paymentMethods
          .filter(paymentMethod =>
            [
              EPaymentMethod.Sepa,
              EPaymentMethod.SepaOnce,
              EPaymentMethod.SepaRecurring,
            ].includes(paymentMethod),
          )
          // Map plain SEPA to SEPA Once & SEPA Recurring
          .map(x => {
            if (x === EPaymentMethod.Sepa) {
              return [EPaymentMethod.SepaOnce, EPaymentMethod.SepaRecurring];
            }

            return x;
          });

        paymentMethodsForVisualPaymentMethods.push(
          ...uniq(flatten(paymentMethodsToAdd)),
        );

        break;
      }

      case EVisualPaymentMethod.Online:
        paymentMethodsForVisualPaymentMethods.push(
          ...paymentMethods.filter(paymentMethod =>
            [
              EPaymentMethod.Bancontact,
              EPaymentMethod.Payconiq,
              EPaymentMethod.Ideal,
            ].includes(paymentMethod),
          ),
        );
        break;

      case EVisualPaymentMethod.BankTransfer:
        paymentMethodsForVisualPaymentMethods.push(
          ...paymentMethods.filter(paymentMethod =>
            [EPaymentMethod.Ibanisation, EPaymentMethod.BankWire].includes(
              paymentMethod,
            ),
          ),
        );
        break;

      default:
        break;
    }

    const isEnabled = paymentMethodsForVisualPaymentMethods.length > 0;

    return (
      <ResourceListItem
        boxProps={{ py: ESpacings.base }}
        item={visualPaymentMethod}
        media={
          <Icon
            source={isEnabled ? "checkFillChecked" : "checkFillCancel"}
            color={isEnabled ? "green" : "red"}
          />
        }
        mediaSize="medium"
      >
        <Box mb={ESpacings.extraTight}>
          <TextStyle>
            {getLocalizedText(
              `payment_method.visual.title.${stringToSnakeCase(
                visualPaymentMethod,
              )}`,
            )}
          </TextStyle>
        </Box>

        {visualPaymentMethod === EVisualPaymentMethod.Sepa && (
          <Box>
            <TextStyle variation="subdued">
              {join(
                (paymentMethodsForVisualPaymentMethods.length === 0
                  ? [EPaymentMethod.SepaOnce, EPaymentMethod.SepaRecurring]
                  : paymentMethodsForVisualPaymentMethods
                ).map(paymentMethod =>
                  getLocalizedText(`payment_request.method.${paymentMethod}`),
                ),
              )}
            </TextStyle>
          </Box>
        )}

        {visualPaymentMethod === EVisualPaymentMethod.Online && (
          <Box>
            <TextStyle variation="subdued">
              {join(
                (paymentMethodsForVisualPaymentMethods.length === 0
                  ? [
                      EPaymentMethod.Bancontact,
                      EPaymentMethod.Payconiq,
                      EPaymentMethod.Ideal,
                    ]
                  : paymentMethodsForVisualPaymentMethods
                ).map(paymentMethod =>
                  getLocalizedText(`payment_request.method.${paymentMethod}`),
                ),
              )}
            </TextStyle>
          </Box>
        )}

        {ibanisationBankAccount &&
          paymentMethodsForVisualPaymentMethods.includes(
            EPaymentMethod.Ibanisation,
          ) && (
            <Box>
              <TextStyle variation="subdued">
                {[
                  {
                    label: getLocalizedText(
                      "payment_method_info.ibanisation.iban",
                      {
                        value: formatIban(ibanisationBankAccount.iban) || "",
                      },
                    ),
                    value: formatIban(ibanisationBankAccount.iban),
                  },
                  ...(ibanisationBankAccount.mangoWalletIbanBic
                    ? [
                        {
                          label: getLocalizedText(
                            "payment_method_info.ibanisation.bic",
                            {
                              value: ibanisationBankAccount.mangoWalletIbanBic,
                            },
                          ),
                          value: ibanisationBankAccount.mangoWalletIbanBic,
                        },
                      ]
                    : []),
                  {
                    label: getLocalizedText(
                      "payment_method_info.ibanisation.reference",
                      {
                        value: ibanisationReference || "/",
                      },
                    ),
                    value: ibanisationReference,
                  },
                ].map(item => {
                  if (!item.value || item.value.length === 0) {
                    return null;
                  }

                  return (
                    <Box>
                      <Button
                        appearance="reset"
                        onClick={() => {
                          handleCopyValue(item.value);
                        }}
                        iconAfter={<Icon source="copy" size="medium" />}
                      >
                        {item.label}
                      </Button>
                    </Box>
                  );
                })}
              </TextStyle>
            </Box>
          )}
      </ResourceListItem>
    );
  };

  return (
    <Card
      heading={renderCardHeader(getLocalizedText("system.payment_methods"))}
      space={ESpacings.base}
      actions={
        paymentOrder?.isEditable
          ? [
              {
                content: getLocalizedText("payment_order.edit.payment_methods"),
                link: `/properties/${paymentOrder.propertyId}/payments/${paymentOrder.id}/edit`,
              },
            ]
          : []
      }
    >
      <ResourceList
        items={Object.values(EVisualPaymentMethod).filter(paymentMethod => {
          if (paymentMethod === EVisualPaymentMethod.BankTransfer) {
            return (
              hasIbanisationForBroker ||
              paymentMethods.includes(EPaymentMethod.BankWire)
            );
          }

          return true;
        })}
        renderItem={renderVisualPaymentMethod}
      />
    </Card>
  );
};

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { ChatContent } from "../ChatContent";
import { EmailContent } from "../EmailContent";
import { PhoneContent } from "../PhoneContent";
import { PushContent } from "../pushContent";
import { SmsContent } from "../SmsContent";
import { hasChatContent, hasEmailContent, hasPhoneContent, hasPushContent, hasSmsContent, } from "./ActivityContent.util";
export var ActivityContent = function (_a) {
    var activity = _a.activity;
    var hasEmail = hasEmailContent(activity);
    var hasSms = hasSmsContent(activity);
    var hasPush = hasPushContent(activity);
    var hasPhone = hasPhoneContent(activity);
    var hasChat = hasChatContent(activity);
    return (_jsxs(_Fragment, { children: [hasEmail && _jsx(EmailContent, { emailActivity: activity.emailActivity }), hasSms && (_jsx(SmsContent, { smsContent: activity.smsContent, smsNumber: activity.smsNumber })), hasPush && (_jsx(PushContent, { pushTitle: activity.pushTitle, pushMessage: activity.pushMessage })), hasPhone && (_jsx(PhoneContent, { phone: activity.phone, phoneHasAnswered: activity.phoneHasAnswered, phonePaymentPromisedAt: activity.phonePaymentPromisedAt, phoneSummary: activity.phoneSummary })), hasChat && (_jsx(ChatContent, { chatMessageId: activity.chatMessageId, message: activity.message }))] }));
};

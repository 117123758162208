import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import {
  ERentioFormField,
  IFormikValues,
  IUiSchema,
} from "@rentiohq/web-shared-next/dist/utils/types/form";
import { EField, IExtraData } from "./schema.createChat.types";

export const uiSchema = ({
  chat,
  accountIds,
  hideActions,
}: IExtraData): IUiSchema => {
  const maxLimit = chat ? 2 : 1;
  return {
    fields: [
      {
        fieldKey: EField.Members,
        required: true,
        uiComponent: ERentioFormField.ContactSelectInline,
        title: getLocalizedText("chat.participants"),
        componentProps: {
          hideForm: !!accountIds,
          hideActions,
          placeholder: getLocalizedText("contact_select.search_or_add.label"),
        },
      },
      {
        fieldKey: EField.Name,
        uiComponent: ERentioFormField.TextField,
        title: getLocalizedText("chats.subject.label"),
        info: getLocalizedText("chats.subject.description"),
        showIf: (formData: IFormikValues) =>
          formData[EField.Members] &&
          formData[EField.Members].length > maxLimit,
        requiredIf: (formData: IFormikValues) =>
          Boolean(
            formData[EField.Members] &&
              formData[EField.Members].length > maxLimit,
          ),
        componentProps: {
          placeholder: getLocalizedText("chats.subject.placeholder"),
          maxLength: (formData: IFormikValues) => 64,
        },
      },
      {
        fieldKey: EField.Property,
        required: true,
        uiComponent: ERentioFormField.PropertySelectInline,
        title: getLocalizedText("system.property"),
        componentProps: {
          maxItems: 1,
          placeholder: getLocalizedText("property_select.search.placeholder"),
        },
      },
    ],
  };
};

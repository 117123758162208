var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Box from "@mui/material/Box";
import FormHelperText from "@mui/material/FormHelperText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { getLocalizedText } from "@rentiohq/shared-frontend/dist/utils/i18n/i18n.utils";
import { EAssets } from "../../assets";
import { fields } from "../../utils/formConfig";
import { Icon } from "../Icon";
import { FieldStyles } from "./Field.styles";
export var Field = function (_a) {
    var fieldSchema = _a.fieldSchema, formikProps = _a.formikProps;
    var sx = FieldStyles();
    var uiComponent = fieldSchema.uiComponent, componentProps = fieldSchema.componentProps, fieldKey = fieldSchema.fieldKey, _b = fieldSchema.disabled, fieldDisabled = _b === void 0 ? false : _b, isDisabled = fieldSchema.isDisabled, title = fieldSchema.title, info = fieldSchema.info, extraInfoUrl = fieldSchema.extraInfoUrl, optional = fieldSchema.optional, onChange = fieldSchema.onChange;
    var errors = formikProps.errors, formData = formikProps.values, submitCount = formikProps.submitCount;
    var _c = componentProps || {}, _d = _c.options, options = _d === void 0 ? [] : _d, getOptions = _c.getOptions, restComponentProps = __rest(_c, ["options", "getOptions"]);
    var disabled = fieldDisabled || (isDisabled === null || isDisabled === void 0 ? void 0 : isDisabled(formData)) || false;
    var error = errors[fieldKey];
    var mappedOptions = ((getOptions === null || getOptions === void 0 ? void 0 : getOptions(formData)) || options).map(function (option) {
        var result = __assign(__assign({}, option), { value: option.id, label: option.value });
        if (option.getValue) {
            result.label = option.getValue(formData);
        }
        if (option.getSubValue) {
            result.description = option.getSubValue(formData);
        }
        return result;
    });
    var handlePressExtraInfo = function () {
        window.open(extraInfoUrl, "_blank");
    };
    var Component = fields[uiComponent];
    if (!Component) {
        return null;
    }
    var props = __assign(__assign({}, restComponentProps), { id: fieldKey, name: fieldKey, disabled: disabled, value: formData[fieldKey], formikProps: formikProps });
    if (mappedOptions === null || mappedOptions === void 0 ? void 0 : mappedOptions.length) {
        props.options = mappedOptions;
    }
    props.onChange = function (value) {
        if (onChange) {
            onChange(value, formikProps);
        }
        else {
            formikProps.setFieldValue(fieldKey, value);
        }
    };
    return (_jsxs(Stack, { direction: "column", children: [_jsxs(Stack, { direction: "row", gap: 0.5, alignItems: "center", mb: 1, children: [_jsx(Typography, { variant: "h4", fontSize: "16px", children: title }), optional && (_jsxs(Typography, { variant: "caption", children: ["(", getLocalizedText("system.optional"), ")"] })), extraInfoUrl && (_jsx(Box, { onClick: handlePressExtraInfo, sx: sx.content.infoBtn, children: _jsx(Icon, { path: EAssets.InfoIcon, width: "14px", height: "14px" }) }))] }), _jsx(Component, __assign({}, props)), info && (_jsx(FormHelperText, { children: _jsx(Typography, { variant: "caption", children: info }) })), (formikProps.touched[fieldKey] || Boolean(submitCount)) && error && (_jsx(FormHelperText, { error: true, children: _jsx(Typography, { variant: "caption", color: "inherit", children: error }) }))] }));
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TextStyle, TruncateMiddle } from "../..";
export var AddressCell = function (_a) {
    var cellKey = _a.cellKey, propertyId = _a.propertyId, address = _a.address;
    var line1 = address === null || address === void 0 ? void 0 : address.line1;
    var line2 = address === null || address === void 0 ? void 0 : address.line2;
    if (!line1 || !line2) {
        return null;
    }
    return (_jsxs("div", { children: [_jsx(TruncateMiddle, { input: line1, skipInitialChars: 10, maxWidthAfterTruncation: 100 }), _jsx(TextStyle, { variation: "subdued", children: _jsx(TruncateMiddle, { input: line2, maxWidthAfterTruncation: 100, skipInitialChars: 100 }) })] }, cellKey !== null && cellKey !== void 0 ? cellKey : propertyId));
};

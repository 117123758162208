import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import * as S from "./ActivityContent/ActivityContent.styled";
export var ContentMeta = function (props) {
    var meta = props.meta;
    return (_jsx("table", { children: _jsx("tbody", { children: Object.entries(meta)
                .filter(function (_a) {
                var value = _a[1];
                return !!value;
            })
                .map(function (_a) {
                var key = _a[0], value = _a[1];
                return (_jsxs("tr", { children: [_jsxs(S.ValueCell, { children: [key, ":"] }), _jsx("td", { style: { textAlign: "start" }, children: value })] }));
            }) }) }));
};

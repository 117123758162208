var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { keyframes, useTheme } from "@mui/material/styles";
var blink = keyframes(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  0%, 80%, 100% {\n    opacity: 0;\n  }\n  40% {\n    opacity: 1;\n  }\n"], ["\n  0%, 80%, 100% {\n    opacity: 0;\n  }\n  40% {\n    opacity: 1;\n  }\n"])));
export var TypingIndicatorStyles = function () {
    var theme = useTheme();
    return {
        wrapper: {
            display: "inline-flex",
            padding: "12px",
            borderRadius: "4px",
            borderBottomLeftRadius: "0px",
            columnGap: "6px",
            span: {
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                margin: "0 1px",
                backgroundColor: theme.palette.success.main,
                display: "block",
                opacity: 0.4,
                "&:nth-of-type(1)": {
                    animation: "1s ".concat(blink, " infinite ").concat(1 * 0.3333, "s"),
                },
                "&:nth-of-type(2)": {
                    animation: "1s ".concat(blink, " infinite ").concat(2 * 0.3333, "s"),
                },
                "&:nth-of-type(3)": {
                    animation: "1s ".concat(blink, " infinite ").concat(3 * 0.3333, "s"),
                },
            },
        },
    };
};
var templateObject_1;
